export const SET_SELECTED_FILTERS_LIST_ACTION =
  "SET_SELECTED_FILTERS_LIST_ACTION";
export const SET_PAGE_ACTION = "SET_PAGE_ACTION";
export const SET_EXPAND_FILTERS_ACTION = "SET_EXPAND_FILTERS_ACTION";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";
export const SET_SORT_BY_ACTION = "SET_SORT_BY";

/**
 * Reducer for handling filter associated actions and state changes
 */
const reducer = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

export default reducer;
