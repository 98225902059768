export const SET_PARTNER_ID_ACTION = "SET_PARTNER_ID";
export const SET_PARTNER_HEI_ID_ACTION = "SET_PARTNER_HEI_ID";
export const SET_APPROVING_PERSON_ACTION = "SET_APPROVING_PERSON";
export const SET_STUDENT_FIRST_NAME_ACTION = "SET_STUDENT_FIRST_NAME";
export const SET_STATUS_ACTION = "SET_STATUS";
export const SET_EXPAND_FILTERS_ACTION = "SET_EXPAND_FILTERS";
export const SET_SELECTED_FILTERS_LIST_ACTION = "SET_SELECTED_FILTERS_LIST";
export const SET_SORT_BY_ACTION = "SET_SORT_BY";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";

/**
 * Reducer for handling filter associated actions and state changes
 */
export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload,
    }
}