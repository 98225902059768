import { useState } from "react";
import "./index.scss";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import IconButton from "../../../../components/IconButton";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../../../components/CustomButton";
import COLORS from "../../../../data/colors";


const Listing = ({ data, loading, error, changeShowModal, type, handleDelete }) => {

    const [searchText, setSearchText] = useState("");
    data = data?.filter(el => (el?.title.includes(searchText)));

    if (loading) {
        return (
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <Spinner
                    as="div"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="align-middle my-auto"
                />
                <span>Loading...</span>
            </div>
        );
    }


    if (error)
        return (
            <div className="text-center text-danger mx-auto">An error occured</div>
        );

    return (
        <div className="d-flex justify-content-between mx-5 pt-4 pt-xxl-5">
            <Container>
                <Row className="mb-4">
                    <Col md={9}>
                        <FormControl sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                placeholder="Search"
                                className="search"
                                id="outlined-adornment-weight"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                startAdornment={<InputAdornment position="start">
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="with-icon" />
                                </InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />

                        </FormControl>

                    </Col>
                    <Col md={3}>

                        <div style={{ float: "right" }}> <CustomButton
                            small
                            buttonType={"primary"}
                            text={"Add a new " + type}
                            icon={faPlus}
                            hasIcon={true}
                            iconColor={COLORS.white}
                            handleClick={changeShowModal}
                        />
                        </div>
                    </Col>
                </Row>
                <Row xs={1} md={3} lg={4}>
                    {data?.map((i) => (
                        <Col> <Card className="erasmus-app-card">
                            <Card.Body>
                                <Card.Text style={{ padding: "30px" }}>
                                    {i?.title}
                                </Card.Text>
                                <div style={{
                                    float: "right"
                                }}>
                                    <IconButton
                                        variant="delete"
                                        disabled={false}
                                        title="Delete account"
                                        onClick={() => {
                                            handleDelete(i?.id,i?.externalID)
                                        }}
                                    />
                                </div>
                            </Card.Body>
                        </Card></Col>))}
                </Row>

            </Container>
        </div >
    );
};

export default Listing;
