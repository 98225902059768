import { gql } from "@apollo/client";

const GET_TERMS = gql`
query terms
    {terms{
        id
        terms
    
    }
    
}`
;

export default GET_TERMS;
