import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal";
import COLORS from "../../../data/colors";
import Listing from "../components/Listing";
import { Col, Form, Row } from "react-bootstrap";
import "./../index.scss";
import Select from "../../../components/Select";
import { useRef, useState } from "react";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import GET_EVENTS from "../../../graphql/queries/erasmusapp/getEvents";
import { useMutation, useQuery } from "@apollo/client";
import ADD_EVENT from "../../../graphql/mutations/erasmusapp/addEvent";
import { toast, ToastContainer } from "react-toastify";
import toastConfiguration from "../../../data/toastConfiguration";
import { erasmusPlusTags, programmeList, mobilityPhaseList } from "../../../data/erasmusPlusListData";
import DELETE_EVENT from "../../../graphql/mutations/erasmusapp/deleteEvent";
import { handleFileRead, getFilterValueForMultiSelectField } from "../components/CommonFunctions";
import DatePicker from "react-datepicker";
import MultiSelect from "../../../components/MultiSelect";

const Event = () => {
    const [showModal, setShowModal] = useState(false)
    const [validated, setValidated] = useState(false)
    const [title, setTitle] = useState(null)
    const [summary, setSummary] = useState(null)
    const [moreInfo, setMoreInfo] = useState(null)
    const [website, setWebsite] = useState(null)
    const [image, setImage] = useState(null)
    const [address, setAddress] = useState(null)
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)
    const [latLng, setLatLng] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [programme, setProgramme] = useState(null)
    const [mobilityPhase, setMobilityPhase] = useState(null)
    const [tags, setTags] = useState([])
    const [invalidImageLength,setInvalidImageLength] = useState(false);
    const formRef = useRef(null);

    const { data, error, loading, refetch } = useQuery(GET_EVENTS);

    const [addEvent, { loading: loadingAddEvent }] = useMutation(ADD_EVENT);

    const [deleteEvent, { loading: loadingDeleteEvent }] = useMutation(DELETE_EVENT);


    const formIsValidated = (e) => {
        setValidated(true);
        e.preventDefault();

        if (!formRef.current.checkValidity()) {
            e.stopPropagation();
            return false;
        }

        return true;
    };

    const handleAdd = async (e) => {
        if (!formIsValidated(e)) return;

        const toastId = toast.loading("Creating event...", toastConfiguration);
        try {
            const result = await addEvent({
                variables: {
                    eventInput: {
                        eventStartDate: startDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                        eventEndDate: endDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                        title: title,
                        body: { summary: summary, value: moreInfo },
                        city: null,
                        country: null,
                        image: image,
                        programmes: programme,
                        mobilityPhases: mobilityPhase,
                        tags: tags,
                        website: website,
                        location: { "address": address, "geolocation": latLng }
                    }
                }
            });
            if (result?.data?.addEvent) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully created the event`,
                    type: "success",
                    isLoading: false,
                });
                refetch();
                handleCloseModal();
            }
            else {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Could not create the event`,
                    type: "error",
                    isLoading: false,
                });
            }
        } catch (err) {
            toast.update(toastId, {
                ...toastConfiguration,
                render: `Could not create the event`,
                type: "error",
                isLoading: false,
            });
        }
    };

    const handleDelete = async (id, externalID) => {

        const toastId = toast.loading("Deleting event...", toastConfiguration);
        try {
            const result = await deleteEvent({
                variables: {
                    id: id,
                    externalID: externalID
                }
            });
            if (result?.data?.deleteEvent) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully delete the event`,
                    type: "success",
                    isLoading: false,
                });
                refetch();
            }
            else {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Could not delete the event`,
                    type: "error",
                    isLoading: false,
                });
            }
        } catch (err) {
            toast.update(toastId, {
                ...toastConfiguration,
                render: `Could not delete the event`,
                type: "error",
                isLoading: false,
            });
        }
    };

    const handleCloseModal = () => {
        setTitle("");
        setSummary("");
        setMoreInfo("");
        setWebsite("");
        setImage(null);
        setAddress("");
        setCity("");
        setCountry("");
        setLatLng("");
        setStartDate("");
        setEndDate("");
        setProgramme(null);
        setMobilityPhase(null);
        setTags([]);
        setShowModal(false);
        setValidated(false);
    }

    return (
        <div>
            <Listing data={data?.events} changeShowModal={() => setShowModal(!showModal)} type={"Event"} loading={loading} error={error} handleDelete={(id, externalID) => handleDelete(id, externalID)} />
            <CustomModal
                size={"lg"}
                show={showModal}
                handleClose={handleCloseModal}
                disabledCancelButton={loadingAddEvent}
                bodyClassName="modal-body"
                body={
                    (
                        <div>
                            <Form validated={validated} noValidate onSubmit={() => setValidated(true)} ref={formRef}>
                                <div className='group-fields-title mt-4 mb-2' style={{ color: "#3D619C", marginTop: 15, marginLeft: 20 }}>
                                    Event Details
                                </div>
                                <Row style={{ marginBottom: 12, marginLeft: 25, marginRight: 25 }}>
                                    <Form.Group className="mb-3" controlId="formRoleName">
                                        <Form.Label>Title <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control
                                            placeholder="Title"
                                            value={title}
                                            required
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a title.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formRoleName">
                                        <Form.Label>Short Summary <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder=""
                                            value={summary}
                                            required
                                            onChange={(e) => setSummary(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a description.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formRoleName">
                                        <Form.Label>More Information For The Student <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder=""
                                            value={moreInfo}
                                            required
                                            onChange={(e) => setMoreInfo(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a description.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formRoleName">
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control
                                            placeholder="Website"
                                            value={website}
                                            pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                            onChange={(e) => setWebsite(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid website.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formRoleName">
                                        <Form.Label>Image (Max Size: 4 MB)</Form.Label>
                                        <Form.Control
                                            type="file"
                                            isInvalid={invalidImageLength}
                                            accept=".png,.jpg,.jpeg"
                                            onChange={(e) => handleFileRead(e,setImage,setInvalidImageLength)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        Please provide a valid image file with maximum size 4MB.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className='group-fields-title mt-4 mb-2' style={{ color: "#3D619C", marginTop: 15, marginLeft: 20 }}>
                                    Where
                                </div>
                                <Row style={{ marginBottom: 12, marginLeft: 25, marginRight: 25 }}>
                                    <Form.Group className="mb-3" controlId="formRoleName">
                                        <Form.Label>Location <span style={{ color: "red" }}>*</span></Form.Label>
                                        {/* <Form.Control
                                            placeholder="Location"
                                            value={location}
                                            required
                                            onChange={(e) => setLocation(e.target.value)}
                                        /> */}
                                        <PlacesAutocomplete validated={validated}
                                            address={address}
                                            setLocation={(address, city, country, latLng) => {
                                                setAddress(address)
                                                setCity(city)
                                                setCountry(country)
                                                setLatLng(latLng);
                                            }} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a location.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className='group-fields-title mt-4 mb-2' style={{ color: "#3D619C", marginTop: 15, marginLeft: 20 }}>
                                    When
                                </div>
                                <Row style={{ marginBottom: 12, marginLeft: 25, marginRight: 25 }}>

                                    <Col md={6}>
                                        <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                            <Form.Group className="col-12" controlId="formBasicName">
                                                <Form.Label>Start Date <span style={{ color: "red" }}>*</span></Form.Label>
                                                <DatePicker required selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} className="form-control" popperPlacement='bottom' />
                                                <Form.Control.Feedback type="invalid">
                                                    Not valid
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                            <Form.Group className="col-12" controlId="formBasicName">
                                                <Form.Label>End Date <span style={{ color: "red" }}>*</span></Form.Label>
                                                <DatePicker required selected={endDate} dateFormat="dd/MM/yyyy" onChange={(date) => setEndDate(date)} className="form-control" popperPlacement='bottom' />
                                                <Form.Control.Feedback type="invalid">
                                                    Not valid
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='group-fields-title mt-4 mb-2' style={{ color: "#3D619C", marginTop: 15, marginLeft: 20 }}>
                                    For whom
                                </div>
                                <Row style={{ marginBottom: 12, marginLeft: 25, marginRight: 25 }}>

                                    <Col md={6}>
                                        <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                            <Form.Group className="mb-3" controlId="formRoleName">
                                                <Form.Label>Programme  <span style={{ color: "red" }}>*</span></Form.Label>
                                                <Select
                                                    options={programmeList?.map((el) => ({
                                                        value: el,
                                                        label: el
                                                    }))}
                                                    placeholder="Select programme"
                                                    onChange={(event) => setProgramme(event?.value)}
                                                    validated={validated}
                                                    invalid={!programme}
                                                    value={{
                                                        "label": programme
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a programme.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{ marginBottom: 5, marginTop: 10 }} className='label-style'>
                                            <Form.Group className="mb-3" controlId="formRoleName">
                                                <Form.Label>Mobility Phase  <span style={{ color: "red" }}>*</span></Form.Label>
                                                <Select
                                                    options={mobilityPhaseList?.map((el) => ({
                                                        value: el,
                                                        label: el
                                                    }))}
                                                    placeholder="Select mobility phase"
                                                    onChange={(event) => setMobilityPhase(event?.value)}
                                                    validated={validated}
                                                    invalid={!mobilityPhase}
                                                    value={{
                                                        "label": mobilityPhase
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a mobility phase.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </Col>

                                    <Form.Group className="mb-3" controlId="formRoleName">
                                        <Form.Label>Tags</Form.Label>
                                        <MultiSelect
                                            value={getFilterValueForMultiSelectField(tags)}
                                            onChange={(item) => {
                                                const tempTags = item.map((i) => i.label);
                                                setTags(tempTags)
                                                
                                            }}
                                            options={erasmusPlusTags?.filter(
                                                (x) => !tags.find((x2) => x === x2)
                                            )?.map((el) => ({
                                                value: el,
                                                label: el
                                            }))}
                                            placeholder="Select one or more tags..."
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a tag.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <div className="modal-body-text">
                                        Only submit content that you own. By submitting an update, you warrant that you have all the necessary rights to use and license the content and that this does not violate, misappropriate or infringe any law or any third parties' rights, including, without limitation, privacy rights and copyright. See more information in Terms and Conditions and Privacy Policy.
                                    </div>
                                </Row>
                            </Form>
                        </div>
                    )
                }
                button={
                    <CustomButton
                        buttonType={"primary"}
                        disabled={loadingAddEvent}
                        text={"Publish event"}
                        icon={faPlus}
                        hasIcon={true}
                        iconColor={COLORS.white}
                        handleClick={handleAdd}
                    />

                }
                title={
                    <div>Add a new <span style={{ color: "#3D619C" }}>Event</span></div>
                }
                subtitle={
                    ""
                }
                hasIcon
                fullscreen
            />
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default Event;
