import Select, { createFilter } from "react-select";

import MenuList from "../SelectMenuList";

import { ReactComponent as InvalidIcon } from "../../assets/invalid.svg";
import { ReactComponent as ValidIcon } from "../../assets/valid.svg";

import COLORS from "../../data/colors";

const MultiSelect = ({
  options,
  placeholder,
  invalid,
  invalidText,
  validated,
  value,
  onChange,
  isSearchable,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Select
        className={`${validated ? "validated" : "not-validated"} ${
          validated && (invalid ? "invalid" : "valid")
        }`}
        classNamePrefix="custom-multi-select"
        closeMenuOnSelect={false}
        isMulti
        options={options}
        value={value}
        onChange={onChange}
        isClearable={false}
        isSearchable={isSearchable}
        components={{
          IndicatorSeparator: () =>
            validated && (invalid ? <InvalidIcon /> : <ValidIcon />),
          MenuList,
        }}
        placeholder={placeholder}
        styles={{
          option: (styles) => ({
            ...styles,
            ":hover": {
              backgroundColor: COLORS.multiSelectOnHover,
            },
          }),
          multiValue: (styles) => ({
            ...styles,
            borderRadius: "0.6rem",
            backgroundColor: "#F2F2F2",
            fontSize: "1rem",
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            ":hover": {
              backgroundColor: COLORS.removeMultiValueBackground,
              color: COLORS.removeMultiValue,
            },
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            color: COLORS.greyText,
          }),
        }}
        filterOption={createFilter({ ignoreAccents: false })}
      />
      {validated && invalid && (
        <div className="invalid-feedback d-block">{invalidText}</div>
      )}
    </div>
  );
};

export default MultiSelect;
