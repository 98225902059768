import { gql } from "@apollo/client";

const CHANGE_PASSWORD_ACCOUNT = gql`
  mutation changePasswordAccount(
    $email: String
    $password: String
    $token: String
  ) {
    changePasswordAccount(email: $email, password: $password, token: $token) {
      id
    }
  }
`;

export default CHANGE_PASSWORD_ACCOUNT;
