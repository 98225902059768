import { gql } from "@apollo/client";

const GET_SHORT_TERM_MOBILITY = gql`
  query shortTermMobility(
      $ID: String!,
      $pathName: String!){
        shortTermMobility(
        ID: $ID,
        pathName: $pathName)
      {
        
            shortTermID
            title
            created
            field_academic_year
            field_la_status
            field_planned_period_from
            field_planned_period_to
            field_student_firstname
            field_student_lastname
            field_student_email
            field_student_birthdate
            mobility_type
            gender
            nationality
            isced
            field_of_education_comment
            preliminary_table_c
            receiving_contact_person
            sending_contact_person
            receiving_resp_person
            sending_resp_person      
            sending_institution
            receiving_institution 
      }
    }`
;

export default GET_SHORT_TERM_MOBILITY;
