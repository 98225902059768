import { reducer } from "../reducers/registerReducer";
import { useReducer, useCallback } from "react";

import { UPDATE_MULTIPLE_VALUES_ACTION } from "../reducers/registerReducer";

export const defaultState = {
  institutionalEmail: "",
  phoneCode: "",
  institutionalPhone: "",
  institutionalWebsite: "",
  institutionCountry: "",
  institutionCity: "",
  institution: "",
};

export const useRegister = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const updateMultipleValues = useCallback((data) => {
    dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data });
  }, []);

  return {
    ...state,
    updateMultipleValues,
  };
};
