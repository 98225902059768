import { gql } from "@apollo/client";

const CHANGE_VALIDATION_REGISTERED_INSTITUTION = gql`
  mutation changeValidationRegisteredInstitution(
    $id: ID
    $valid: Int
    $email: String
  ) {
    changeValidationRegisteredInstitution(
      id: $id
      valid: $valid
      email: $email
    ) {
      Institution {
        NameInLatinCharacterSet
      }
    }
  }
`;

export default CHANGE_VALIDATION_REGISTERED_INSTITUTION;
