import CustomButton from '../../components/CustomButton';
import { useNavigate  } from "react-router-dom";


const UnauthorizedPage = () => {

  let navigate  = useNavigate();

  return (
    <main className="h-auto text-center mt-4 px-3">
      <h4 className="text-center text-danger mx-auto">You do not have permission to access the requested page.</h4>
      <CustomButton buttonType={"primary"} small={true} text={"Return to Previous Page"} handleClick={() => navigate(-2)} />
    </main>
  );
};

export default UnauthorizedPage;
