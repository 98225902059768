import { gql } from "@apollo/client";

const GET_NOMINATIONS_COUNT = gql`
  query nominationsCount(
        $homeHei: String, 
        $hostHei: String, 
        $academicYear: String, 
        $status: String, 
        $studentName: String,
        $studentEmail: String, 
        $erasmusCode: String, 
        $academicTerm: String, 
        $studentEQF: String,
        $studentISCED: String
     ){
        nominationsCount(
            homeHei: $homeHei,
            hostHei: $hostHei,
            academicYear: $academicYear,
            status: $status,
            studentName: $studentName,
            studentEmail: $studentEmail,
            erasmusCode: $erasmusCode,
            academicTerm: $academicTerm, 
            studentEQF: $studentEQF,
            studentISCED: $studentISCED
        )
      
        
    
      
    }`
    ;

export default GET_NOMINATIONS_COUNT;
