import { reducer, SET_STUDENT_EMAIL_ACTION, SET_STUDENT_EQF_ACTION, SET_STUDENT_ISCED_ACTION, SET_STUDENT_NAME_ACTION, SET_SORT_BY_ACTION, UPDATE_MULTIPLE_VALUES_ACTION, SET_STATUS_ACTION,
    SET_EXPAND_FILTERS_ACTION,
    SET_SELECTED_FILTERS_LIST_ACTION, SET_ACADEMIC_YEAR_ACTION, SET_ACADEMIC_TERM_ACTION,
    SET_ERASMUS_CODE_ACTION, } from '../reducers/useNominationsFiltersReducer';
import { useReducer, useCallback } from 'react';

// fallback state
export const defaultState = {
    academicYear: "",
    academicTerm: "",
    erasmusCode: "",
    studentName: "",
    studentEmail: "",
    studentISCED: "",
    studentEQF: "",
    status: "",
    selectedFiltersList:[],
    sortBy:"",
    expandFilters: false
}

/**
 * Used to handle filters for IIA queries
 * 
 * @param {Object} initialState the initial state of the filters, fallbacks to a default state with empty string and lists if no initial state is provided
 * @returns Filters state as well as handlers for changing the state
 */
export const useNominationsFilters = (initialState = defaultState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setAcademicYear = useCallback((data) => {
        dispatch({ type: SET_ACADEMIC_YEAR_ACTION, payload: { academicYear: data } });
    }, []);

    const setAcademicTerm = useCallback((data) => {
        dispatch({ type: SET_ACADEMIC_TERM_ACTION, payload: { academicTerm: data } });
    }, []);

    const setErasmusCode = useCallback((data) => {
        dispatch({ type: SET_ERASMUS_CODE_ACTION, payload: { erasmusCode: data } });
    }, []);

    const setStudentName = useCallback((data) => {
        dispatch({ type: SET_STUDENT_NAME_ACTION, payload: { studentName: data } });
    }, []);

    const setStudentEmail = useCallback((data) => {
        dispatch({ type: SET_STUDENT_EMAIL_ACTION, payload: { studentEmail: data } });
    }, []);

    const setStudentEQF = useCallback((data) => {
        dispatch({ type: SET_STUDENT_EQF_ACTION, payload: { studentEQF: data } });
    }, []);

    const setStudentISCED = useCallback((data) => {
        dispatch({ type: SET_STUDENT_ISCED_ACTION, payload: { studentISCED: data } });
    }, []);

    const setStatus = useCallback((data) => {
        dispatch({ type: SET_STATUS_ACTION, payload: { status: data } });
    }, []);

    const setExpandFilters = useCallback((data) => {
        dispatch({ type: SET_EXPAND_FILTERS_ACTION, payload: { expandFilters: data } });
    }, []);

    const setSelectedFiltersList = useCallback((data) => {
        dispatch({ type: SET_SELECTED_FILTERS_LIST_ACTION, payload: { selectedFiltersList: data } });
    }, []);

    const setSortBy = useCallback((data) => {
        dispatch({ type: SET_SORT_BY_ACTION, payload: { sortBy: data } });
    }, []);

    const updateMultipleValues = useCallback((data) => {
        dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data })
    }, []);

    return {
        state,
        setAcademicYear,
        setAcademicTerm,
        setStudentName,
        setStudentEmail,
        setErasmusCode,
        setStudentEQF,
        setStudentISCED,
        setStatus,
        setExpandFilters,
        setSelectedFiltersList,
        setSortBy,
        updateMultipleValues
    };
}