import { gql } from "@apollo/client";

const GET_EVENTS = gql`
query events
    {  events{
        id
        externalID
        organisations
        title
      }

    
}`
;

export default GET_EVENTS;
