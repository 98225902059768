import { gql } from "@apollo/client";

const GET_FACTSHEET = gql`
    query factsheet($heiID: String!){
        factsheet(heiID: $heiID) {
            
            
              visa_info{
                email
                phone_number{
                  ext
                  e164
                }
                website_urls
              }
              additional_requirements{
                name
                details
                information_websites
                ects
                eqf_level
                isced_f_code
                isced_f_code_name
              }
              calendar {
                student_app_autumn_term
                student_app_spring_term
                student_nom_autumn_term
                student_nom_spring_term
              }
              accessibilities {
                description
                name
                type
                info {
                  email
                  phone_number {
                    e164
                  }
                  website_urls
                }
              }
              housing_info {
                email
                phone_number {
                  e164
                }
                website_urls
              }
              visa_info {
                email
                phone_number {
                  e164
                }
                website_urls
              }
              insurance_info {
                email
                phone_number {
                  e164
                }
                website_urls
              }
              application_info {
                email
                phone_number {e164}
                website_urls 
              }
              tor_weeks_limit
              decision_weeks_limit
              additional_infos {
                info {
                  phone_number { e164 }
                  website_urls 
                  email
                }
                type
              }
        }
    }`
;

export default GET_FACTSHEET;
