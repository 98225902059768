export const erasmusPlusTags = [
  "Accessibility",
  "Safety",
  "Accommodation",
  "Volunteering & Social outreach",
  "Culture & Free time",
  "Food & Drinks",
  "Travel insurance",
  "Student Visa",
  "Education & Training",
  "Health & Well-being",
  "Managing your money",
  "Going green & Environment",
  "Technology & Communications",
  "Travel & Transport",
  "Paperwork & Documents",
  "General",
  "Check the deadlines",
  "Information sessions",
  "Application for Erasmus",
  "Learning Agreement",
  "Grant Agreement",
  "Recognition",
  "Transcript of Records",
  "Traineeships",
  "Shopping",
  "Sports"
];

export const erasmusPlusUsefulInfoCategory = [
  "Study mobility - Information sessions (outgoing students, before application)",
  "Study mobility - Check the deadlines (outgoing students, before application)",
  "Study mobility - Application for Erasmus (outgoing students, during application)",
  "Study mobility - Learning Agreement (outgoing students, during application)",
  "Study mobility - Grant Agreement (outgoing students, during application)",
  "Study mobility - Student Visa (outgoing students, before you go)",
  "Study mobility - Accommodation (incoming students, before you go)",
  "Study mobility - Going green & Environment (outgoing students, before you go)",
  "Study mobility - Safety (incoming students, during mobility)",
  "Study mobility - Learning Agreement (outgoing students, during mobility)",
  "Study mobility - Transcript of Records (incoming students, during mobility)",
  "Study mobility - Recognition (outgoing students, after mobility)",
  "Traineeship mobility - Information sessions (outgoing students, before application)",
  "Traineeship mobility - Check the deadlines (outgoing students, before application)",
  "Traineeship mobility - Traineeships (outgoing students, during application)",
  "Traineeship mobility - Student Visa (outgoing students, before you go)",
  "Traineeship mobility - Safety (outgoing students, during mobility)",
  "Erasmus Mundus - Going green & Environment (outgoing students, before you go)",
  "Erasmus Mundus - Student Visa (incoming students, before you go)",
];

export const erasmusPlusUsefulInfoCategoryInfo = [
  {
    category: "Study mobility - Information sessions (outgoing students, before application)",
    title: "Inform yourself",
    subtitle: "Check if your institution holds information sessions for students who are interested in mobility abroad",
    text: "Higher Education Institutions (HEIs) usually organise information sessions for students who want to spend time abroad, where they explain available opportunities for mobility. Look for relevant announcements and attend the sessions to learn about existing opportunities. There, you can also meet other interested students or those who have returned from mobility periods and want to share their experiences with you.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "Before application",
    tag: "Information sessions"
  },
  {
    category: "Study mobility - Check the deadlines (outgoing students, before application)",
    title: "Check the deadlines",
    subtitle: "Keep a lookout for important announcements and deadlines.",
    text: "Your institution will usually announce the necessary requirements you should fulfil and all the relevant information for a successful application. Pay attention to the internal deadlines set by your home institution and make sure you don’t miss them! For this, have a look at the website, social media posts of your institution or your mailbox.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "Before application",
    tag: "Check the deadlines"
  },
  {
    category: "Study mobility - Application for Erasmus (outgoing students, during application)",
    title: "Discover possible study destinations",
    subtitle: "Get in touch with the office in charge of student exchanges at your institution or faculty.",
    text: "Your institution most likely has an international relations office (IRO) or a student mobility office. There, coordinators for the Erasmus+ Programme know their partner institutions well and can give you advice on the best options for you to go abroad. They can also provide you with information on the steps to follow, eligibility criteria, grants and much more. The coordinator can also provide you with information about partner institutions such as guidebooks, course lists, curricula, language requirements, housing information and so on. All of this information is very useful for helping you choose your destination and to be ready to become a mobile student! We also encourage you to take a look at the u-multirank website. The platform helps you find the perfect university to match your needs and interests by giving you a personalised university ranking list. Find your best matching university. Planning to go abroad during the pandemic? Check out whether your host HEI is welcoming exchange students and/or offering classes online. Erasmus+ Covid-19 Mobility Status tool",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "During application",
    tag: "Application for Erasmus"
  },
  {
    category: "Study mobility - Learning Agreement (outgoing students, during application)",
    title: "Get your Online Learning Agreement signed",
    subtitle: "Get your Online Learning Agreement signed.",
    text: "This step is crucial for the recognition of your successfully completed courses abroad. The Online Learning Agreement defines the programme of studies you will follow abroad and what courses they will replace in your study programme at home upon your return. It must be approved by you, your home institution and your host institution before the start of your mobility. The Online Learning Agreement consists of all of the educational components that will be included in your degree upon successful completion of studying abroad. Before you leave, discuss with your Erasmus+ Academic Coordinator which courses you want to take at the host institution and what courses will be replaced so that they are recognised when you return. Ask also if and how the grades received abroad will be converted. Your choice of courses cannot always be guaranteed, thus if any courses are cancelled or you do not meet the requirements for them, you can always submit a request to change your Online Learning Agreement. The Online Learning Agreement can be completed via www.learning-agreement.eu or via the mobility software supported by your institution. Remember: When in doubt, contact your international office!",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "During application",
    tag: "Learning Agreement"
  },
  {
    category: "Study mobility - Grant Agreement (outgoing students, during application)",
    title: "Get your Grant Agreement signed",
    subtitle: "Get your Grant Agreement signed",
    text: "You need to sign the grant agreement. It will specify the duration of your mobility, the amount of the grant that you will receive and other rights and obligations you may have in this regard. The amount of the grant depends on your destination country and the duration of your mobility. For students with fewer opportunities due to their economic or personal circumstances (such as parent or caregiving responsibilities), a disability or a physical, mental or health-related condition you have the opportunity to apply for a supplementary grant (top-up). Be sure to ask your Erasmus+ Academic Coordinator or International office for more information. Remember that if you return early from your Erasmus+ mobility, or if you fail to meet the terms of your Erasmus+ grant agreement, it is likely that you will have to return either part or the full sum of your Erasmus+ grant.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "During application",
    tag: "Grant Agreement"
  },
  {
    category: "Study mobility - Student Visa (outgoing students, before you go)",
    title: "Make sure you get your Visa and Residence Permit on time",
    subtitle: "Check if you need a visa or a residence permit.",
    text: "Before your departure, you will need to check whether your destination country requires a visa or a residence permit. If this is the case, make all the necessary arrangements. Higher Education Institutions usually provide details of a contact person who can assist in solving visa-related issues and provide all necessary documents (such as invitation letters, certificates of acceptance, and so on). Establish contact with this person to get support if needed.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "Before you go",
    tag: "Student Visa"
  },
  {
    category: "Study mobility - Accommodation (incoming students, before you go)",
    title: "Look for accommodation",
    subtitle: "",
    text: "Students are responsible for finding suitable accommodation but your host institution must provide you with support in finding accommodation. Some host institutions are able to offer accommodation for Erasmus+ students and will be able to send you the necessary registration form. The local Erasmus Student Network section or other student organisations at your institution may be able to help you with that as well.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "Before you go",
    tag: "Accommodation"
  },
  {
    category: "Study mobility - Going green & Environment (outgoing students, before you go)",
    title: "I booked my travel",
    subtitle: "Think green when booking your travel to your host country.",
    text: "When booking your trip to the mobility destination, check if it is reachable via train or bus. This will not only lower the environmental impact of your mobility but you will also see other parts of Europe on your journey in addition to your host country. Also, when you choose a more environmentally friendly way to travel, you may receive a “top-up” to your grant. Your mobility coordinator will be able to provide you with further information.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "Before you go",
    tag: "Going green & Environment"
  },
  {
    category: "Study mobility - Safety (incoming students, during mobility)",
    title: "Stay safe",
    subtitle: "Be one-step ahead - stay safe",
    text: "Inform yourself about what measures related to safety, public health and other topics are in place in your host institution. Provide your local phone number (as soon as you have one) to your host and home institutions as well as your emergency contacts' details (close family or friends). Check regularly on your home country's Ministry of Foreign Affairs' website to see if there are any specific recommendations for the country where you are. If you are planning to travel by yourself in your host country, make sure to inform for example your buddy about your plans and to ask your host institution about the information on safe travel. Going abroad during the pandemic? Follow the information on Re-open EU and explore what national tracing apps are being used.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "During mobility",
    tag: "Safety"
  },
  {
    category: "Study mobility - Learning Agreement (outgoing students, during mobility)",
    title: "Update your Online Learning Agreement",
    subtitle: "",
    text: "Upon arrival, make sure your Online Learning Agreement is still up to date. At this stage, you can submit a request to make necessary amendments to your Online Learning Agreement (via www.learning-agreement.eu or via the mobility software supported by your institution) if the courses you initially selected before your departure have changed or have been cancelled. Whenever the Online Learning Agreement is changed, it must be approved again by each of the three parties (you, your home institution and your host institution). Changes must be notified and agreed upon within five weeks of regular classes starting.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "During mobility",
    tag: "Learning Agreement"
  },
  {
    category: "Study mobility - Transcript of Records (incoming students, during mobility)",
    title: "Say good-bye!",
    subtitle: "Visit the Erasmus+ Academic Coordinator at your host institution one last time.",
    text: "Before you return to your home institution, make sure that you receive the Transcript of Records (ToR) from the host institution. The ToR is a document that clearly indicates all the courses you have followed, together with the grades you received in your exams and the ECTS credits you acquired. This is essential for your home institution to be able to recognise your courses. Be aware that in some cases the ToR will not be available on your date of departure, in which case the host institution must send it to you within a maximum of five weeks. The ToR should mention the first and last day at the institution. Please check that these are correct.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "During mobility",
    tag: "Transcript of Records"
  },
  {
    category: "Study mobility - Recognition (outgoing students, after mobility)",
    title: "Get your mobility recognised",
    subtitle: "Get your study abroad period recognised by your home institution.",
    text: "Your institution will convert your results into their own grading scale and have all of the courses recognised as part of your curriculum as outlined in your Online Learning Agreement. Your institution must recognise the credits (using ECTS credits or an equivalent system) as agreed in the Online Learning Agreement before or during the mobility and count them towards your degree, without any further requirements. Your mobility period should also be recorded in your Diploma Supplement if your institution is located in a Program Country.",
    programme: "Higher Education Study Mobility",
    mobilityPhase: "After mobility",
    tag: "Recognition"
  },
  {
    category: "Traineeship mobility - Information sessions (outgoing students, before application)",
    title: "Inform yourself",
    subtitle: "Check if your institution holds information sessions for students who are interested in mobility abroad",
    text: "Higher Education Institutions (HEIs) usually organise information sessions for students who want to spend time abroad, where they explain available opportunities for mobility. Look for relevant announcements and attend the sessions to learn about existing opportunities. There, you can also meet other interested students or those who have returned from mobility periods and want to share their experiences with you.",
    programme: "Higher Education Traineeship Mobility",
    mobilityPhase: "Before application",
    tag: "Information sessions"
  },
  {
    category: "Traineeship mobility - Check the deadlines (outgoing students, before application)",
    title: "Check the deadlines",
    subtitle: "Keep a lookout for important announcements and deadlines.",
    text: "Your institution will usually announce the necessary requirements you should fulfil and all the relevant information for a successful application. Pay attention to the internal deadlines set by your home institution and make sure you don’t miss them! For this, have a look at the website, social media posts of your institution or your mailbox.",
    programme: "Higher Education Traineeship Mobility",
    mobilityPhase: "Before application",
    tag: "Check the deadlines"
  },
  {
    category: "Traineeship mobility - Traineeships (outgoing students, during application)",
    title: "Find traineeship opportunities",
    subtitle: "Find a traineeship.",
    text: "The host organization where you will do your traineeship can be private or public for example a company, an NGO, a research lab or any other work place that can help prepare you for a career in your field. The only limitation are EU bodies and other EU institutions, including specialized agencies or organizations managing EU programs, such as National Agencies, which cannot be host organizations. It is useful to search on online platforms, such as www.erasmusintern.org or the European Employment Services (EURES) website, to find offers that fit your profile and interest or talk to the career services office at your institution and get advice!",
    programme: "Higher Education Traineeship Mobility",
    mobilityPhase: "During application",
    tag: "Accommodation"
  },
  {
    category: "Traineeship mobility - Student Visa (outgoing students, before you go)",
    title: "Make sure you get your Visa and Residence Permit on time",
    subtitle: "Check if you need a visa or a residence permit.",
    text: "Before you depart you will need to check whether your destination country requires a visa or a residence permit. If you do need a visa, make all the necessary arrangements. Institutions usually provide details of a contact person who can assist in solving visa-related issues and provide all necessary documents (such as invitation letters, certificates of acceptance, etc.). Establish contact with this person to get support if needed.",
    programme: "Higher Education Traineeship Mobility",
    mobilityPhase: "Before you go",
    tag: "Student Visa"
  },
  {
    category: "Traineeship mobility - Safety (outgoing students, during mobility)",
    title: "Stay safe",
    subtitle: "Be one-step ahead - stay safe",
    text: "Inform yourself about what policy measures related to safety, public health and other topics are in place in your host institution. Provide your local phone number (as soon as you have one) to your host and home institutions as well as your emergency contacts' details (close family or friends). Check regularly on your home country's Ministry of Foreign Affairs' website to see if there are any specific recommendations for the country where you are. If you are planning to travel by yourself in your host country, make sure you inform for example your Traineeship supervisor about your plans and to ask your host institution about information on safe travel. Going abroad during the pandemic? Follow the information on Re-open EU and explore what national tracing apps are being used.",
    programme: "Higher Education Traineeship Mobility",
    mobilityPhase: "During mobility",
    tag: "Safety"
  },
  {
    category: "Erasmus Mundus - Going green & Environment (outgoing students, before you go)",
    title: "I booked my travel",
    subtitle: "Think green when booking your travel to your host country.",
    text: "When booking your trip to the mobility destination, check if it is reachable via train or bus. This will not only lower the environmental impact of your mobility but you will also see other parts of Europe on your journey in addition to your host country. Also, when you choose a more environmentally friendly way to travel, you may receive a “top-up” to your grant. Your mobility coordinator will be able to provide you with further information.",
    programme: "Erasmus Mundus",
    mobilityPhase: "Before you go",
    tag: "Going green & Environment"
  },
  {
    category: "Erasmus Mundus - Student Visa (incoming students, before you go)",
    title: "Useful info - visa, insurance, accommodation",
    subtitle: "Check your visa requirements, get insurance, and find accommodation.",
    text: "Before you depart you will need to check whether your destination countries requires a visa or a residence permit. If this is the case, make all the necessary arrangements and ask the coordinator of your Erasmus Mundus Master for help with visa-related issues. Applications for authorisations must be submitted to the relevant authorities of the the first EU country you will stay in; prepare well in advance, as the process may take up to 90 days. If there is no Consulate of the European Programme Country in their country of residence, contact the Ministry of Foreign Affairs or the Immigration Office of that European Country to identify the responsible consulate. In terms of health insurance, you have the right to get a European Health Insurance Card, which is issued by your national health insurance provider and gives you access to medically necessary, state-provided healthcare during a temporary stay in any of the EU countries, plus Iceland, Liechtenstein, Norway and Switzerland, under the same conditions and at the same cost (free in some countries) as people insured in that country. Lastly, you are responsible for finding accommodation but some host institutions could provide support; you could also find help for it at your local Erasmus Mundus Association.",
    programme: "Erasmus Mundus",
    mobilityPhase: "Before you go",
    tag: "Student Visa"
  }];

export const programmeList = [
  "Higher Education Study Mobility",
  "Higher Education Traineeship Mobility",
  "Erasmus Mundus"
]

export const mobilityPhaseList = [
  "Before application",
  "During application",
  "Before you go",
  "During mobility",
  "After mobility"
]