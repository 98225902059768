import { useQuery } from '@apollo/client';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Table } from 'react-bootstrap';
import CustomButton from '../../../../../components/CustomButton';
import COLORS from '../../../../../data/colors';
import GET_NOMINATED_STUDENTS from '../../../../../graphql/queries/nomination/getNominatedStudents';

const DestinationHEIHeader = ({mobilityDetail, size, handleNominate, academicYear, isMobile}) => {
    

    const { data } = useQuery(
        GET_NOMINATED_STUDENTS,
        {
          variables: {
            homeErasmusCode: mobilityDetail?.field_home_erasmus_code, 
            hostErasmusCode: mobilityDetail?.field_host_erasmus_code,
            academicYear: academicYear
          },
        }
    );

    

  return (
    <>
    {!isMobile && (
        <Table>
            <thead/>
            <tbody className='tbody-accordion-font'>
                <tr className='d-flex'>
                    <td className="col-xl-3 col-lg-3 border-hidden body" style={{paddingRight: 0, paddingLeft: 0}}><span style={{fontWeight: "bold"}}>{mobilityDetail?.field_host_name}</span>
                    <br/>{mobilityDetail?.field_host_org_unit}</td>
                    {/* <td className="col-xl-3 col-lg-2 border-hidden body" style={{paddingLeft: 0}}>test Institution
                    <br/>Faculty test</td> */}
                    <td className="col-xl-2 col-lg-2 border-hidden body"  style={size === "lg" || size === "xl" ?
                    {paddingLeft: 20} : {paddingLeft: 65, justifyContent: "center", display: "flex"}}>{data?.nominatedStudents?.nominatedStudentsCount}
                    </td>
                    {/* <td className="col-xl-2 col-lg-2 border-hidden body"  style={size === "lg" || size === "xl" ?
                    {paddingLeft: 20} : {paddingLeft: 65, justifyContent: "center", display: "flex"}}>Faculty test
                    <br/>Faculty test</td> */}
                    <td className="col-xl-2 col-lg-3 border-hidden" style={{justifyContent: "center", display: "flex"}}>
                        {mobilityDetail?.field_mobility_details_status === "active" ? 
                        <Button style={{background: "#2B9436", borderRadius: 5, borderColor: "#2B9436"}} disabled> 
                        NOMINATED
                        </Button> : (mobilityDetail?.field_mobility_details_status === "rejected") || (mobilityDetail?.field_mobility_details_status === "old_rejected") ?
                        <Button style={{background: "#B61717", borderRadius: 5, borderColor: "#B61717"}} disabled> 
                        REJECTED
                        </Button> :
                        <CustomButton disabled={!mobilityDetail?.canBeAccepted} handleClick={() => handleNominate(mobilityDetail)} 
                        buttonType={"accept"} text={"Nominate"} small={true} icon={faCheck} hasIcon={true} iconColor={COLORS.white}/>}
                    </td>
                </tr>
            </tbody>
        </Table>
    )}
    {isMobile && (
        <Row style={{marginLeft: 25,marginRight: 25}}>
            <Col xs={6} sm={6} style={{marginTop: 17, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>INSTITUTION / FACULTY:</Col>
            <Col xs={6} sm={6} style={{marginTop: 17, paddingLeft: 0, paddingRight: 0}} className='tbody-font body'><span style={{fontWeight: "bold"}}>{mobilityDetail?.field_host_name}</span>
            <div>{mobilityDetail?.field_host_org_unit}</div></Col>
            {/* <Col xs={6} sm={6} style={{marginTop: 6, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>AVAILABLE SPOSTS:</Col>
            <Col xs={6} sm={6} style={{marginTop: 6, paddingLeft: 0, paddingRight: 0}} className='tbody-font body'>50</Col> */}
            <Col xs={6} sm={6} style={{marginTop: 6, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>NOMINATED STUDENTS:</Col>
            <Col xs={6} sm={6} style={{marginTop: 6, paddingLeft: 0, paddingRight: 0}} className='tbody-font body'>{data?.nominatedStudents?.nominatedStudentsCount}</Col>
            {/* <Col xs={6} sm={6} style={{marginTop: 6, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>DURATION:</Col>
            <Col xs={6} sm={6} style={{marginTop: 6, paddingLeft: 0, paddingRight: 0, marginBottom: 30}} className='tbody-font body'>6 months</Col> */}
        </Row>
    )}
    
    </>
  )
};

export default DestinationHEIHeader;