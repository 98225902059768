import { gql } from "@apollo/client";

const UPDATE_ACCOUNT = gql`
  mutation updateAccount(
    $id: ID!
    $name: String
    $email: String
    $functionId: ID!
    $phone: String!
    $defaultContact: String!
  ) {
    updateAccount(
      id: $id
      Name: $name
      Email: $email
      FunctionID: $functionId
      Phone: $phone
      DefaultContact: $defaultContact
    ) {
      name: Name
    }
  }
`;

export default UPDATE_ACCOUNT;
