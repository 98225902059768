import Form from "react-bootstrap/Form";

import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row, Table } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import COLORS from "../../data/colors";
import Tabs from "react-bootstrap/Tabs";
import CustomTab from "../../components/CustomTab";
import { useState } from "react";
import CustomModal from "../../components/CustomModal";
import IconButton from "../../components/IconButton";
import CustomTable from "../../components/Table";
import TableSkeleton from "../../components/Table/components/TableSkeleton";
import MultiSelect from "../../components/MultiSelect";
import useBreakpoint from "../../hooks/useBreakpoint";
import Select from "../../components/Select";

import "./index.scss";

const ReusableComponents = () => {
  const [show, setShow] = useState(false);
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [size] = useBreakpoint();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseFullScreenModal = () => setShowFullScreenModal(false);
  const handleShowFullScreenModal = () => setShowFullScreenModal(true);

  const options = [
    { value: "Greece", label: "Greece" },
    { value: "France", label: "France" },
    { value: "Belgium", label: "Belgium" },
  ];

  return (
    <>
      {" "}
      <Container fluid style={{ marginTop: 15 }}>
        <Tabs
          id="tabs-active"
          style={{ display: "flex", justifyContent: "center" }}
          fill
        >
          <CustomTab eventKey={"first-tab"} title="Buttons" activetab={+true}>
            <Row style={{ paddingTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"primary"}
                  disabled={false}
                  text={"Primary"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"primary"}
                  disabled={true}
                  text={"Primary"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"primary"}
                  disabled={false}
                  text={"Primary"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"primary"}
                  disabled={true}
                  text={"Primary"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"secondary"}
                  disabled={false}
                  text={"Secondary"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"secondary"}
                  disabled={true}
                  text={"Secondary"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"secondary"}
                  disabled={false}
                  text={"Secondary"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.black}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"secondary"}
                  disabled={true}
                  text={"Secondary"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={"rgba(0, 0, 0, 0.38)"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"outlined"}
                  disabled={false}
                  text={"Outlined"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"outlined"}
                  disabled={true}
                  text={"Outlined"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"outlined"}
                  disabled={false}
                  text={"Outlined"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.black}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"outlined"}
                  disabled={true}
                  text={"Outlined"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={"rgba(0, 0, 0, 0.38)"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"accept"}
                  disabled={false}
                  text={"Accept"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"accept"}
                  disabled={true}
                  text={"Accept"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"accept"}
                  disabled={false}
                  text={"Accept"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"accept"}
                  disabled={true}
                  text={"Accept"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"decline"}
                  disabled={false}
                  text={"Decline"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"decline"}
                  disabled={true}
                  text={"Decline"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"decline"}
                  disabled={false}
                  text={"Decline"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"decline"}
                  disabled={true}
                  text={"Decline"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"orange"}
                  disabled={false}
                  text={"orange"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"orange"}
                  disabled={true}
                  text={"orange"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"orange"}
                  disabled={false}
                  text={"orange"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"orange"}
                  disabled={true}
                  text={"orange"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={false}
                  text={"delete"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={true}
                  text={"delete"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={false}
                  text={"delete"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={true}
                  text={"delete"}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={false}
                  small={true}
                  text={"Small"}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={true}
                  small={true}
                  text={"Small"}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 35 }}>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={false}
                  text={"Small"}
                  small={true}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
              <Col lg={2} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  buttonType={"delete"}
                  disabled={true}
                  text={"Small"}
                  small={true}
                  icon={faFileArrowDown}
                  hasIcon={true}
                  iconColor={COLORS.white}
                />
              </Col>
            </Row>
          </CustomTab>
          <CustomTab eventKey={"second-tab"} title="Tabs" activetab={+true}>
            <Row
              style={{
                paddingTop: 45,
                paddingBottom: 200,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Tabs
                id="tabs-active"
                defaultActiveKey={"first-tab"}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 30,
                }}
              >
                <CustomTab
                  eventKey={"first-tab"}
                  title="Test Tab 1"
                  activetab={+true}
                >
                  <Tabs
                    id="tabs-inactive"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: 30,
                    }}
                  >
                    <CustomTab
                      eventKey={"first-tab"}
                      title={"TEST TABLE 1"}
                      activetab={+false}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th
                              style={{
                                paddingTop: 15,
                                paddingBottom: 2,
                                paddingLeft: 24,
                              }}
                              className="border-hidden label-style"
                            >
                              NAME
                            </th>
                            <th
                              style={{ paddingTop: 15, paddingBottom: 2 }}
                              className="border-hidden label-style"
                            >
                              EMAIL
                            </th>
                            <th
                              style={{ paddingTop: 15, paddingBottom: 2 }}
                              className="border-hidden label-style"
                            >
                              STUDY LEVEL
                            </th>
                            <th
                              style={{ paddingTop: 15, paddingBottom: 2 }}
                              className="border-hidden label-style"
                            >
                              STUDY FIELD
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                paddingTop: 0,
                                paddingBottom: 20,
                                paddingLeft: 24,
                              }}
                              className="border-hidden body"
                            >
                              Jane Doe
                            </td>
                            <td
                              style={{ paddingTop: 0, paddingBottom: 20 }}
                              className="border-hidden body"
                            >
                              jane.doe@mail.com
                            </td>
                            <td
                              style={{ paddingTop: 0, paddingBottom: 20 }}
                              className="border-hidden body"
                            >
                              Bachelor’s Degree
                            </td>
                            <td
                              style={{ paddingTop: 0, paddingBottom: 20 }}
                              className="border-hidden body"
                            >
                              031 - Applied Chemistry
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CustomTab>
                  </Tabs>
                </CustomTab>
                <CustomTab
                  eventKey={"second-tab"}
                  title="Test Tab 2"
                  activetab={+true}
                >
                  <Tabs
                    id="tabs-inactive"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: 30,
                    }}
                  >
                    <CustomTab
                      eventKey={"first-tab"}
                      title={"TEST TABLE 2"}
                      activetab={+false}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th
                              style={{
                                paddingTop: 15,
                                paddingBottom: 2,
                                paddingLeft: 24,
                              }}
                              className="border-hidden label-style"
                            >
                              NAME
                            </th>
                            <th
                              style={{ paddingTop: 15, paddingBottom: 2 }}
                              className="border-hidden label-style"
                            >
                              EMAIL
                            </th>
                            <th
                              style={{ paddingTop: 15, paddingBottom: 2 }}
                              className="border-hidden label-style"
                            >
                              STUDY LEVEL
                            </th>
                            <th
                              style={{ paddingTop: 15, paddingBottom: 2 }}
                              className="border-hidden label-style"
                            >
                              STUDY FIELD
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                paddingTop: 0,
                                paddingBottom: 20,
                                paddingLeft: 24,
                              }}
                              className="border-hidden body"
                            >
                              Jane Doe
                            </td>
                            <td
                              style={{ paddingTop: 0, paddingBottom: 20 }}
                              className="border-hidden body"
                            >
                              jane.doe@mail.com
                            </td>
                            <td
                              style={{ paddingTop: 0, paddingBottom: 20 }}
                              className="border-hidden body"
                            >
                              Bachelor’s Degree
                            </td>
                            <td
                              style={{ paddingTop: 0, paddingBottom: 20 }}
                              className="border-hidden body"
                            >
                              031 - Applied Chemistry
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CustomTab>
                  </Tabs>
                </CustomTab>
              </Tabs>
            </Row>
          </CustomTab>
          <CustomTab eventKey={"third-tab"} title="Modals" activetab={+true}>
            <Row
              style={{ paddingTop: 50, paddingBottom: 30, height: 700 }}
              className="justify-content-center"
            >
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: 50,
                }}
              >
                <CustomButton
                  buttonType={"primary"}
                  disabled={false}
                  text={"Open Full Screen Modal"}
                  handleClick={handleShowFullScreenModal}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: 50,
                }}
              >
                <CustomButton
                  buttonType={"primary"}
                  disabled={false}
                  text={"Open Modal"}
                  handleClick={handleShow}
                />
              </Col>
            </Row>
          </CustomTab>
          <CustomTab
            eventKey={"fourth-tab"}
            title="Icon buttons"
            activetab={+true}
          >
            <Row className="d-flex justify-content-around mt-3">
              {["accept", "reject", "delete", "edit", "add", "download"].map(
                (variant) => (
                  <IconButton
                    variant={variant}
                    disabled={false}
                    title={`${variant} request`}
                    onClick={(e) => console.log("clicked")}
                    key={variant}
                  />
                )
              )}
            </Row>
          </CustomTab>
          <CustomTab eventKey={"fifth-tab"} title="Inputs" activetab={+true}>
            {/* Text inputs */}
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={false}
                noValidate
              >
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test@test.com"}
                    required
                    onChange={(e) => console.log("on change")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid value prompt.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={true}
                noValidate
              >
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test"}
                    required
                    onChange={(e) => console.log("on change")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid value prompt.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={true}
                noValidate
              >
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test@test.com"}
                    required
                    onChange={(e) => console.log("on change")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid value prompt.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Row>
            {/* Check inputs */}
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={false}
                noValidate
              >
                <Form.Group controlId="formAuthorizations">
                  <Form.Check type="checkbox" id="check-authorizations">
                    <Form.Check.Input type="checkbox" required />
                    <Form.Check.Label>
                      I confirm, also on behalf of my HEI, that I have all the
                      authorisations
                    </Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                      Please conirm that you have all the authorizations to
                      upload the personal data and information you are providing
                    </Form.Control.Feedback>
                  </Form.Check>
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={true}
                noValidate
              >
                <Form.Group controlId="formAuthorizations">
                  <Form.Check type="checkbox" id="check-authorizations">
                    <Form.Check.Input type="checkbox" required />
                    <Form.Check.Label>
                      I confirm, also on behalf of my HEI, that I have all the
                      authorisations
                    </Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                      Please conirm that you have all the authorizations to
                      upload the personal data and information you are providing
                    </Form.Control.Feedback>
                  </Form.Check>
                </Form.Group>
              </Form>
            </Row>
            {/* Toggle switch inputs */}
            <Row className="d-flex justify-content-around mt-3">
              <Form onSubmit={(e) => console.log("handle submit")}>
                <Form.Check
                  type="switch"
                  id="switch1"
                  label="Enable this switch"
                />
              </Form>
            </Row>
            {/* Select boxes */}
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={false}
                noValidate
              >
                <Form.Group
                  as={
                    size !== "xs" && size !== "sm"
                      ? Col
                      : (props) => <Row className="mb-3" {...props} />
                  }
                  controlId="formInstitutionCountry"
                  className="col-3"
                >
                  <Form.Label>Institutional Country</Form.Label>
                  <Form.Select
                    required
                    value={""}
                    onChange={(e) => console.log("on change")}
                    disabled={false}
                  >
                    <option value="" disabled>
                      Choose country...
                    </option>
                    <option value="1">Greece</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a country for your institution
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={true}
                noValidate
              >
                <Form.Group
                  as={
                    size !== "xs" && size !== "sm"
                      ? Col
                      : (props) => <Row className="mb-3" {...props} />
                  }
                  controlId="formInstitutionCountry"
                  className="col-3"
                >
                  <Form.Label>Institutional Country</Form.Label>
                  <Form.Select
                    required
                    value={""}
                    onChange={(e) => console.log("on change")}
                    disabled={false}
                  >
                    <option value="" disabled>
                      Choose country...
                    </option>
                    <option value="1">Greece</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a country for your institution
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              <Form
                onSubmit={(e) => console.log("handle submit")}
                validated={true}
                noValidate
              >
                <Form.Group
                  as={
                    size !== "xs" && size !== "sm"
                      ? Col
                      : (props) => <Row className="mb-3" {...props} />
                  }
                  controlId="formInstitutionCountry"
                  className="col-3"
                >
                  <Form.Label>Institutional Country</Form.Label>
                  <Form.Select
                    required
                    value={"1"}
                    onChange={(e) => console.log("on change")}
                    disabled={false}
                  >
                    <option value="" disabled>
                      Choose country...
                    </option>
                    <option value="1">Greece</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a country for your institution
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Row>
            {/* Text areas */}
            <Row className="d-flex justify-content-around mt-3">
              {/* You need to handle validation by yourself using isValid and isInvalid prop*/}
              <Form onSubmit={(e) => console.log("handle submit")}>
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test@test.com"}
                    required
                    onChange={(e) => console.log("on change")}
                    as="textarea"
                  />
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              {/* You need to handle validation by yourself using isValid and isInvalid prop*/}
              <Form onSubmit={(e) => console.log("handle submit")}>
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test"}
                    required
                    onChange={(e) => console.log("on change")}
                    as="textarea"
                    isValid={true}
                  />
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              {/* You need to handle validation by yourself using isValid and isInvalid prop*/}
              <Form onSubmit={(e) => console.log("handle submit")}>
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Autosize height based on content lines"
                    value={"test@test.com"}
                    required
                    onChange={(e) => console.log("on change")}
                    as="textarea"
                    isInvalid={true}
                  />
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              {/* You need to handle validation by yourself using isValid and isInvalid prop*/}
              <Form onSubmit={(e) => console.log("handle submit")}>
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test@test.com"}
                    required
                    onChange={(e) => console.log("on change")}
                    disabled
                    as="textarea"
                  />
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              {/* You need to handle validation by yourself using isValid and isInvalid prop*/}
              <Form onSubmit={(e) => console.log("handle submit")}>
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test@test.com"}
                    required
                    onChange={(e) => console.log("on change")}
                    disabled
                    as="textarea"
                    isValid={true}
                  />
                </Form.Group>
              </Form>
            </Row>
            <Row className="d-flex justify-content-around mt-3">
              {/* You need to handle validation by yourself using isValid and isInvalid prop*/}
              <Form onSubmit={(e) => console.log("handle submit")}>
                <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={"test@test.com"}
                    required
                    onChange={(e) => console.log("on change")}
                    disabled
                    as="textarea"
                    isInvalid={true}
                  />
                </Form.Group>
              </Form>
            </Row>
          </CustomTab>
          <CustomTab
            eventKey={"sixth-tab"}
            title="Table Skeleton"
            activetab={+true}
          >
            <TableSkeleton
              columnsPerFieldMap={{
                institution: "col-3",
                email: "col-2",
                website: "col-2",
                phone: "col-2",
                registrationTime: "col-2",
                status: "col-1",
              }}
            />
          </CustomTab>
          <CustomTab eventKey={"seventh-tab"} title="Table" activetab={+true}>
            <CustomTable
              data={Array(10).fill({
                institution: "Test institution for loading",
                email: "t@h.be",
                website: "test.com",
                phone: "+223322332",
                registrationTime: "19 Jan 2018",
                status: 0,
              })}
              page={0}
              setPage={() => console.log("set page")}
              type={`requests-`}
              expandFilters={() => console.log("expand filters")}
              setExpandFilters={() => console.log("set expand filters")}
              filters={{
                erasmusCode: "",
                status: "",
                createdByStudent: "",
                country: [],
                academicYear: "",
                studentFirstName: "",
                studentLastName: "",
              }}
              setFilters={(filter, value) => console.log(filter, value)}
              sortBy={""}
              setSortBy={() => console.log("sort by")}
              columnsPerFieldMap={{
                institution: "col-2 col-xl-3",
                email: "col-2",
                website: "col-2",
                phone: "col-2",
                registrationTime: "col-3 col-xl-2",
                status: "col-1",
              }}
              rowClassName="reusable-components-table-row"
              headerClassName="reusable-components-table-header"
            >
              {Array(10)
                .fill({
                  institution: "Test institution for loading",
                  email: "t@h.be",
                  website: "test.com",
                  phone: "+223322332",
                  registrationTime: "19 Jan 2018",
                  status: 0,
                })
                .map((pendingRequest, index) => (
                  <div
                    key={pendingRequest?.id || index}
                    className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
                  >
                    <IconButton
                      variant="accept"
                      disabled={pendingRequest?.Valid === 1}
                      title="Accept request"
                      onClick={(e) => console.log("on click")}
                    />
                    <IconButton
                      variant="reject"
                      disabled={pendingRequest?.Valid === -1}
                      title="Reject request"
                      onClick={(e) => console.log("on click")}
                    />
                    <IconButton
                      variant="delete"
                      disabled={
                        pendingRequest?.Valid === 1 ||
                        pendingRequest?.Valid === 0
                      }
                      title="Delete request"
                      onClick={(e) => console.log("on click")}
                    />
                  </div>
                ))}
            </CustomTable>
          </CustomTab>
          <CustomTab
            eventKey={"eigth-tab"}
            title="Multi Select"
            activetab={+true}
          >
            <MultiSelect
              options={options}
              placeholder="Select many countries..."
              invalid={false}
              validated
            />
            <Select
              options={options}
              placeholder="Select single country..."
              invalid
              validated
            />
            <MultiSelect
              options={options}
              placeholder="Select many countries..."
            />
            <Select options={options} placeholder="Select single country..." />
          </CustomTab>
        </Tabs>
      </Container>
      <CustomModal
        show={show}
        handleClose={handleClose}
        body={"Modal body text goes here."}
        button={<CustomButton buttonType={"primary"} text={"Accept"} />}
        title={"Modal title"}
        subtitle={"This is a subtitle"}
        hasIcon
      />
      <CustomModal
        show={showFullScreenModal}
        handleClose={handleCloseFullScreenModal}
        body={"Modal body text goes here."}
        button={<CustomButton buttonType={"primary"} text={"Accept"} />}
        title={"Modal title"}
        subtitle={"This is a subtitle"}
        hasIcon
        fullscreen
      />
    </>
  );
};

export default ReusableComponents;
