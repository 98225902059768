// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/arrow-cond.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow-cond {\n  width: 31px;\n  height: 26px;\n  display: block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left center;\n  filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);\n}\n\n.flex-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: -9px;\n}", "",{"version":3,"sources":["webpack://./src/pages/IIAs/Create/ThirdStep/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,cAAA;EACA,yEAAA;EAEA,8FAAA;AAAJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AADJ","sourcesContent":[".arrow-cond {\n    width: 31px;\n    height: 26px;\n    display: block;\n    background: url('../../../../assets/arrow-cond.svg')\n      no-repeat left center;\n    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    \n  \n  }\n\n.flex-center {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: -9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
