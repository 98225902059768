import { gql } from "@apollo/client";

const GET_EXPORT_CHECK = gql`
query iiaBulkCsvCheck
    {iiaBulkCsvCheck{
        exists
        date
    }

    
}`
;

export default GET_EXPORT_CHECK;
