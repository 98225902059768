import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

import BasicHeader from "../../components/BasicHeader";
import BasicFooter from "../../components/BasicFooter";
import Main from "./components/Main";
import withTitle from "../../components/withTitle";

import { ReactComponent as KeyIcon } from "../../assets/key-in-circle.svg";

import useAuth from "../../hooks/useAuth";

const RegisterConfirmation = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.accessToken) {
      navigate("/", { replace: true });
    }
  });

  return (
    <main className="position-relative d-flex flex-column justify-content-between forgot-password">
      <div className="d-flex flex-column position-relative">
        <BasicHeader />
        <KeyIcon className="position-absolute start-50 translate-middle forgot-password-mail-icon d-none d-lg-block" />
        <Main />
      </div>
      <BasicFooter />
    </main>
  );
};

export default withTitle(RegisterConfirmation, "Confirm your account");
