import { gql } from "@apollo/client";

const GET_ACCOUNTS = gql`
  query accounts {
    accounts {
      id
      name: Name
      email: Email
      phone: Phone
      role: AccountFunction {
        id
        name: Name
        permissions: AccountAbility {
          id
          name: Name
          value: Value
        }
      }
    }
  }
`;

export default GET_ACCOUNTS;
