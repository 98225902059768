import { gql } from "@apollo/client";

const UPDATE_FACTSHEET = gql`
  mutation updateFactsheet($factsheetData: FactsheetInput!) {
    updateFactsheet(factsheetData: $factsheetData) {      
        accessibilities{
            info{
              website_urls{
                value
                language
              }
            }
          }
          calendar{
            student_app_autumn_term
            student_nom_autumn_term
          }
          decision_weeks_limit
          hei_id
    }
  }
`;

export default UPDATE_FACTSHEET;
