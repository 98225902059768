import { Row, Col } from 'react-bootstrap';

const StudentInfo = ({ data }) => {

    return (
        <Row style={{ paddingBottom: 10 }}>
            <Col md={12} lg={4}>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>First name:</Col><Col>{data?.learningAgreement?.field_student_firstname}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>Last name:</Col><Col>{data?.learningAgreement?.field_student_lastname}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>Email:</Col><Col>{data?.learningAgreement?.field_student_email}</Col></Row>
            </Col>
            <Col md={12} lg={4}>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>Birth date:</Col><Col>{new Date(data?.learningAgreement?.field_student_birthdate).toLocaleDateString('en-GB')}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>Gender:</Col><Col>{data?.learningAgreement?.gender?.map(g => g.name).toString()}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>Nationality:</Col><Col>{data?.learningAgreement?.nationality?.map(g => g.name).toString()}</Col></Row>

            </Col>
            <Col md={12} lg={4}>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={6}>Field of Education (ISCED):</Col><Col>{data?.learningAgreement?.isced?.map(g => g.name).toString()}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={6}>Field of Education (Clarification):</Col><Col></Col></Row>
            </Col>
        </Row>
    )
}

export default StudentInfo;