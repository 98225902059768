import { gql } from "@apollo/client";

const GET_INSTITUTIONS = gql`
  query institutions($cityLink: String) {
    institutions(CityLink: $cityLink) {
      ID
      ErasmusCode
      NameInLatinCharacterSet
      Country
      HeiID
    }
  }
`;

export default GET_INSTITUTIONS;
