import { gql } from "@apollo/client";

const UPDATE_ROLE = gql`
  mutation updateAccountFunction(
    $id: ID!
    $roleName: String!
    $permissions: [AccountAbilityInput]
  ) {
    updateRole: updateAccountFunction(
      id: $id
      FunctionName: $roleName
      accountAbilities: $permissions
    ) {
      name: Name
    }
  }
`;

export default UPDATE_ROLE;
