const COLORS = {
  pink: "#DF175F",
  white: "#ffffff",
  black: "#1f1f1f",
  greyVerticalBar: "rgba(70, 73, 78, 0.5)",
  greyText: "#5A5A5A",
  blueText: "#3D619C",
  greenCheck: "#73A6AD",
  redCross: "#E52323",
  greenAccept: "#3BBB48",
  removeMultiValueBackground: "#FAD1D1",
  removeMultiValue: "#B61717",
  multiSelectOnHover: "#D5E2F6",
};

export default COLORS;
