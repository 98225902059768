import { gql } from "@apollo/client";

const GET_DESTINATION_HEI_INFO = gql`
    query incomingApplicationRequirements(
    $erasmusCode: String!)
    {incomingApplicationRequirements(erasmusCode:$erasmusCode){
        otherRequirementArray{
            url
            uuid
            description
            isRequired
        }
        cvRequired
        cvURL
        cvDescription
        motivLetterRequired
        motivLetterURL
        motivLetterDescription
        ectsRequired
        ectsURL
        isced
        iscedURL
        eqfLevel
        eqfLevelURL
        torDescription
        torRequired
        torURL
        recomLetterDescription
        recomLetterRequired
        recomLetterURL
        extCertDescription
        extCertRequired
        extCertURL
        recomLetterRequired
        languageArray{
            uuid
            code
            isRequired
            certDescription
            certRequired
            level
            url
        }
      }
    }`
;

export default GET_DESTINATION_HEI_INFO;
