import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import GET_LA_PDF from "../../../../../graphql/queries/ola/laPdf";
import withTitle from "../../../../../components/withTitle";

const ExportOlaPdf = () => {
  const { uuid, vid } = useParams();

  const { loading, data, error } = useQuery(GET_LA_PDF, {
    variables: {
      id: uuid,
      vid: vid,
    },
  });

  if (error) {
    return "An error occured";
  }

  if (!loading) {
    let pdf = data?.laPdf?.file;

    if (pdf) {
      let byteCharacters = atob(pdf);
      let byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      const fileURL = URL.createObjectURL(file);

      return (
        <>
          <object
            style={{ width: "100%", height: "100vh" }}
            type="application/pdf"
            data={fileURL}
          >
            <p>
              Download the PDF by clicking <Link to={fileURL}>here</Link>.
            </p>
          </object>
        </>
      );
    }
  }
};

export default withTitle(
  ExportOlaPdf,
  "Export Online Learning Agreement to PDF"
);
