import { gql } from "@apollo/client";

const GET_IIA_LISTING = gql`
  query IIAs(
      $page: Int!,
      $optionalFilters: OptionalIIAFilters!,
      $sortBy: String!){
        iias(
        page: $page,
        optionalFilters: $optionalFilters,
        sortBy: $sortBy)
      {
        count
        iiaList{
        id
        changed_time
        created_time
        type_of_communication
        iia_status
        last_author
        approved_copy
        uuid
        terminated
        partners{
            iia_id
            institution{
            name
            erasmusCode
            heiID
            country
            }
        }
        }
        nextLink
        page
        
      }
    }`
;

export default GET_IIA_LISTING;
