import "./style.scss";
import { Container, Row, Col } from "react-bootstrap";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import useBreakpoint from "../../hooks/useBreakpoint";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faLifeRing,
  faGear,
  faRightFromBracket,
  faCircleInfo,
  faUserLock,
  faSitemap,
  faBuildingColumns,
  faEnvelope,
  faSquarePlus,
  faUpload,
  faList,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

import { ACCOUNT_TYPES } from "../../data/constants";
import COLORS from "../../data/colors";

import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import CustomButton from "../CustomButton";
import GET_EWP_SETTINGS from "../../graphql/queries/settings/getEWPSettings";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

const Sidebar = (Wrapped) => {

  const [size] = useBreakpoint();
  const { setAuth } = useAuth();
  const { setUser, user } = useUser();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const academicFilter = () => new Date().getMonth() < 6 ?  (new Date().getFullYear() - 1) + "/" + new Date().getFullYear() : new Date().getFullYear() + "/" + (new Date().getFullYear() + 1)


  const {
    loading: loadingEWPSettings,
    error: errorEWPSettings,
    data: dataEWPSettings, refetch: refetchQuery
  } = useQuery(GET_EWP_SETTINGS);

  useEffect(() => {
    refetchQuery();
  }, [navigate]);

  useEffect(() => {
    refetchQuery();
  }, []);

  const handleLogout = (event) => {
    event.preventDefault();

    setAuth({});
    setUser({});
    localStorage.removeItem("token");
    navigate("/login");
  };

  const toggleMenu = () => {
    setShow(!show)
  }

  const handleClose = () => {
    setShow(false);
    
  };

  const userHasAccessToPermission = (permission) => {
    return user?.permissions?.find(
      (currentPermission) => currentPermission.value === permission
    );
  };

  return (
    <>
      <Container
        fluid
        style={{ padding: 0, margin: 0, backgroundColor: "#F2F3F6" }}
      >
        <Row style={{ padding: 0, margin: 0 }}>
          {size !== "xs" && size !== "sm" && size !== "md" && (
            <>
              <Col
                md={2}
                lg={2}
                className="wrapper"
                style={
                  size === "md"
                    ? { padding: 0, width: "25%" }
                    : size === "lg"
                    ? { padding: 0, width: "20%" }
                    : { padding: 0, width: "14%" }
                }
              >
                <SidebarMenu expand={size} className="padding-top">
                  <SidebarMenu.Collapse
                    style={
                      size === "md"
                        ? { width: "25%" }
                        : size === "lg"
                        ? { width: "20%" }
                        : { width: 180 }
                    }
                  >
                    <SidebarMenu.Header className="padding-0">
                      <SidebarMenu.Brand
                        title=""
                        href=""
                        className="padding-top"
                      >
                        <span className="logo" onClick={(e) => navigate("/")} />
                      </SidebarMenu.Brand>
                    </SidebarMenu.Header>
                    {user.type === ACCOUNT_TYPES.eufAdmin && (
                      <SidebarMenu.Body className="padding-0">
                        <SidebarMenu.Nav>
                          <SidebarMenu.Sub eventKey={0}>
                            <SidebarMenu.Sub.Toggle className="h6-style">
                              <SidebarMenu.Nav.Title>
                                Institutions
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon className="margin-right" />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                  eventKey="requests"
                                  className="title-in padding-0"
                                  onClick={() => navigate("/requests")}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="arrow-out" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left h6-style">
                                    Requests
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                        </SidebarMenu.Nav>
                      </SidebarMenu.Body>
                    )}
                    {user.type === ACCOUNT_TYPES.hei && (
                      <SidebarMenu.Body className="padding-0">
                        <SidebarMenu.Nav>
                          <SidebarMenu.Sub eventKey={0}>
                            <SidebarMenu.Sub.Toggle className="font">
                              <SidebarMenu.Nav.Title>
                                My University
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon className="margin-right" />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                {userHasAccessToPermission(
                                  "creatingAccounts"
                                ) && (
                                  <SidebarMenu.Nav.Link
                                    eventKey="my-university-1"
                                    className="title-in padding-0"
                                    href="/accounts-and-access"
                                    onClick={(e) => {e.preventDefault();
                                      navigate("/accounts-and-access");
                                    }}
                                  >
                                    <SidebarMenu.Nav.Icon className="nav-icon">
                                      <FontAwesomeIcon
                                        icon={faUserLock}
                                        color={COLORS.black}
                                      />
                                    </SidebarMenu.Nav.Icon>
                                    <SidebarMenu.Nav.Title className="padding-left font-menu-item ms-1">
                                      Accounts & Access
                                    </SidebarMenu.Nav.Title>
                                  </SidebarMenu.Nav.Link>
                                )}
                                 {userHasAccessToPermission(
                                        "managingOUnits"
                                  ) && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_ounits && (<SidebarMenu.Nav.Link
                                  eventKey="my-university-2"
                                  className="title-in padding-0"
                                  href="/organizational-units"
                                  onClick={(e) => {
                                    e.preventDefault(); navigate("/organizational-units"); 
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <FontAwesomeIcon
                                      icon={faSitemap}
                                      color={COLORS.black}
                                    />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item ms-1">
                                    Organizational Units
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>)}
                                <SidebarMenu.Nav.Link
                                    eventKey="my-university-1"
                                    className="title-in padding-0"
                                    href="/ukraine-assistance"
                                    onClick={(e) => {e.preventDefault();
                                      navigate("/ukraine-assistance");
                                    }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <FontAwesomeIcon
                                      icon={faCircleInfo}
                                      color={COLORS.black}
                                    />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item ms-1">
                                    Ukraine Assistance
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          {(userHasAccessToPermission("managingStudents") ||
                            userHasAccessToPermission("viewingStudents") ||
                            userHasAccessToPermission("uploadingStudents")) && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_ola && (
                            <SidebarMenu.Sub eventKey={1}>
                              <SidebarMenu.Sub.Toggle className="font">
                                <SidebarMenu.Nav.Title>
                                  OLA
                                </SidebarMenu.Nav.Title>
                                <SidebarMenu.Nav.Icon className="margin-right" />
                              </SidebarMenu.Sub.Toggle>
                              <SidebarMenu.Sub.Collapse>
                                <SidebarMenu.Nav>
                                  {(userHasAccessToPermission(
                                    "managingStudents"
                                  ) ||
                                    userHasAccessToPermission(
                                      "viewingStudents"
                                    )) && (
                                    <>
                                      <SidebarMenu.Nav.Link
                                        eventKey="ola1"
                                        className="title-in padding-0"
                                        href="/ola/outgoing"
                                        onClick={(e) => {e.preventDefault(); localStorage.setItem('pageLAs', "0"); localStorage.setItem('sortByLAs', JSON.stringify("changed_descending")); localStorage.setItem('selectedFiltersLAs', JSON.stringify({
                                          erasmusCode: "",
                                          status: "",
                                          createdByStudent: "",
                                          country: [],
                                          academicYear: academicFilter(),
                                          studentFirstName: "",
                                          studentLastName: "",
                                        }));
                                          navigate("/ola/outgoing");}
                                        }
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <span className="arrow-out" />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          Outgoing Students
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                      <SidebarMenu.Nav.Link
                                        eventKey="ola2"
                                        className="title-in padding-0"
                                        href="/ola/incoming"
                                        onClick={(e) => {e.preventDefault(); localStorage.setItem('pageLAs', "0"); localStorage.setItem('sortByLAs', JSON.stringify("changed_descending")); localStorage.setItem('selectedFiltersLAs', JSON.stringify({
                                          erasmusCode: "",
                                          status: "",
                                          createdByStudent: "",
                                          country: [],
                                          academicYear: academicFilter(),
                                          studentFirstName: "",
                                          studentLastName: "",
                                        }));
                                          navigate("/ola/incoming");}
                                        }
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <span className="arrow-in" />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          Incoming Students
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                    </>
                                  )}
                                  {userHasAccessToPermission(
                                    "uploadingStudents"
                                  ) && (
                                    <SidebarMenu.Nav.Link
                                      eventKey="ola3"
                                      className="title-in padding-0"
                                      href="/ola/upload"
                                      onClick={(e) => {e.preventDefault(); navigate("/ola/upload");}}
                                    >
                                      <SidebarMenu.Nav.Icon className="nav-icon">
                                        <span className="upload-solid" />
                                      </SidebarMenu.Nav.Icon>
                                      <SidebarMenu.Nav.Title
                                        className="padding-left font-menu-item"
                                      >
                                        Upload
                                      </SidebarMenu.Nav.Title>
                                    </SidebarMenu.Nav.Link>
                                  )}
                                </SidebarMenu.Nav>
                              </SidebarMenu.Sub.Collapse>
                            </SidebarMenu.Sub>
                          )}
                          { (userHasAccessToPermission("managingStudents") ||
                            userHasAccessToPermission("viewingStudents") ||
                            userHasAccessToPermission("uploadingStudents")) && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_ola && <SidebarMenu.Sub eventKey={2}>
                            <SidebarMenu.Sub.Toggle className="font">
                              <SidebarMenu.Nav.Title>
                                Short Term
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon className="margin-right" />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                  eventKey="shortTerm1"
                                  className="title-in padding-0"
                                  href="/short-term/outgoing"
                                  onClick={(e) => {e.preventDefault(); localStorage.setItem('pageShortTerm', "0"); localStorage.setItem('selectedFiltersShortTerm', JSON.stringify({
                                    erasmusCode: "",
                                    status: "",
                                    country: [],
                                    academicYear: academicFilter(),
                                    studentFirstName: "",
                                    studentLastName: "",
                                  }));
                                    navigate("/short-term/outgoing");}}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="arrow-out" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Outgoing Students
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                <SidebarMenu.Nav.Link
                                  eventKey="shortTerm2"
                                  className="title-in padding-0"
                                  href="/short-term/incoming"
                                  onClick={(e) => {e.preventDefault(); localStorage.setItem('pageShortTerm', "0"); localStorage.setItem('selectedFiltersShortTerm', JSON.stringify({
                                    erasmusCode: "",
                                    status: "",
                                    country: [],
                                    academicYear: academicFilter(),
                                    studentFirstName: "",
                                    studentLastName: "",
                                  }));
                                    navigate("/short-term/incoming");}}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="arrow-in" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Incoming Students
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>}
                          {userHasAccessToPermission("nominations") && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_iia && (
                            <><SidebarMenu.Sub eventKey={3}>
                              <SidebarMenu.Sub.Toggle className="font">
                                <SidebarMenu.Nav.Title>
                                  Applications
                                </SidebarMenu.Nav.Title>
                                <SidebarMenu.Nav.Icon className="margin-right" />
                              </SidebarMenu.Sub.Toggle>
                              <SidebarMenu.Sub.Collapse>
                                <SidebarMenu.Nav>
                                  <SidebarMenu.Nav.Link
                                    eventKey="applications1"
                                    className="title-in padding-0"
                                    href="/applications-for-erasmus+/outgoing-students"
                                    onClick={(e) => {e.preventDefault(); localStorage.setItem('pageNominations', "0"); localStorage.setItem('selectedFiltersNominations', JSON.stringify({
                                      erasmusCode: "",
                                      status: "",
                                      academicTerm: "",
                                      academicYear: academicFilter(),
                                      studentName: "",
                                      studentEQF: "",
                                      studentISCED: "",
                                      studentEmail: "",
                                    }));
                                      navigate(
                                        "/applications-for-erasmus+/outgoing-students"
                                      );}
                                    }
                                  >
                                    <SidebarMenu.Nav.Icon className="nav-icon">
                                      <span className="arrow-out" />
                                    </SidebarMenu.Nav.Icon>
                                    <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                      Outgoing Students
                                    </SidebarMenu.Nav.Title>
                                  </SidebarMenu.Nav.Link>
                                  <SidebarMenu.Nav.Link
                                    eventKey="applications2"
                                    className="title-in padding-0"
                                    href="/applications-for-erasmus+/incoming-students"
                                    onClick={(e) =>{e.preventDefault(); localStorage.setItem('pageNominations', "0"); localStorage.setItem('selectedFiltersNominations', JSON.stringify({
                                      erasmusCode: "",
                                      status: "",
                                      academicTerm: "",
                                      academicYear: academicFilter(),
                                      studentName: "",
                                      studentEQF: "",
                                      studentISCED: "",
                                      studentEmail: "",
                                    }));
                                      navigate(
                                        "/applications-for-erasmus+/incoming-students"
                                      );}
                                    }
                                  >
                                    <SidebarMenu.Nav.Icon className="nav-icon">
                                      <span className="arrow-in" />
                                    </SidebarMenu.Nav.Icon>
                                    <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                      Incoming Students
                                    </SidebarMenu.Nav.Title>
                                  </SidebarMenu.Nav.Link>
                                  <SidebarMenu.Nav.Link
                                    eventKey="applications3"
                                    className="title-in padding-0"
                                    href="/applications-for-erasmus+/requirements"
                                    onClick={(e) =>
                                      {e.preventDefault(); navigate(
                                        "/applications-for-erasmus+/requirements"
                                      );}
                                    }
                                  >
                                    <SidebarMenu.Nav.Icon className="nav-icon">
                                      <span className="requirements-icon" />
                                    </SidebarMenu.Nav.Icon>
                                    <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                      Requirements
                                    </SidebarMenu.Nav.Title>
                                  </SidebarMenu.Nav.Link>
                                </SidebarMenu.Nav>
                              </SidebarMenu.Sub.Collapse>
                            </SidebarMenu.Sub></>
                          )}
                          {(userHasAccessToPermission("managingIIAs") ||
                          userHasAccessToPermission("creatingIIA") ||
                          userHasAccessToPermission("importIIA") ||
                          userHasAccessToPermission("defaultIIAData")) && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_iia ? (
                          <SidebarMenu.Sub eventKey={4}>
                            <SidebarMenu.Sub.Toggle className="font">
                              <SidebarMenu.Nav.Title>IIA</SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon className="margin-right" />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                {userHasAccessToPermission("managingIIAs") && <SidebarMenu.Nav.Link
                                  eventKey="IIA1"
                                  className="title-in padding-0"
                                  href="/iias/iia-list"
                                  onClick={(e) =>
                                    {e.preventDefault(); localStorage.setItem('pageIIAs', "0"); localStorage.setItem('sortByIIAs', JSON.stringify("changed_descending")); localStorage.setItem('selectedFiltersIIAs', JSON.stringify({partnerID: "", status: "",
                                    partnerHeiId: "",
                                    approvingPersonEmail: ""})); navigate(
                                      "/iias/iia-list"
                                    ); }
                                  }
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="handshake-simple-solid" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    IIA List
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link> 
                                }
                                {userHasAccessToPermission("creatingIIA") && <SidebarMenu.Nav.Link
                                  eventKey="IIA3"
                                  className="title-in padding-0"
                                  href="/iias/create-iia"
                                  onClick={(e) =>
                                    {e.preventDefault(); 
                                    navigate(
                                      "/iias/create-iia"
                                    ); localStorage.setItem('resetCreate', JSON.stringify("true"));}
                                  }
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <FontAwesomeIcon
                                      icon={faSquarePlus}
                                      color={COLORS.black}
                                    />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    New Digital IIA
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                }
                                {userHasAccessToPermission("importIIA") && <SidebarMenu.Nav.Link
                                  eventKey="IIA4"
                                  className="title-in padding-0"
                                  href="/iias/import"
                                  onClick={(e) =>
                                    {e.preventDefault(); navigate(
                                      "/iias/import"
                                    );}
                                  }
                                >
                                <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="upload-solid" />
                                </SidebarMenu.Nav.Icon>
                                <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Import IIA
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                }
                                {userHasAccessToPermission("managingIIAs") && <SidebarMenu.Nav.Link
                                  eventKey="IIA6"
                                  className="title-in padding-0"
                                  href="/iias/export"
                                  onClick={(e) =>
                                    {e.preventDefault(); navigate(
                                      "/iias/export"
                                    );}
                                  }
                                >
                                <SidebarMenu.Nav.Icon className="nav-icon">
                                      <FontAwesomeIcon
                                        icon={faDownload}
                                        color={COLORS.black}
                                      />
                                </SidebarMenu.Nav.Icon>
                                <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Export IIAs
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                }
                                {userHasAccessToPermission("defaultIIAData") && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_static && <SidebarMenu.Nav.Link
                                  eventKey="IIA5"
                                  className="title-in padding-0"
                                  href="/iias/edit-factsheet"
                                  onClick={(e) =>
                                    {e.preventDefault(); navigate(
                                      "/iias/edit-factsheet"
                                    );}
                                  }
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <FontAwesomeIcon
                                        icon={faList}
                                        color={COLORS.black}
                                      />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Default IIA Data
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                }
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                          </SidebarMenu.Sub>
                          ) : dataEWPSettings?.ewpConnections?.length > 0 && !dataEWPSettings?.ewpConnections[0]?.api_iia && userHasAccessToPermission("defaultIIAData") && dataEWPSettings?.ewpConnections[0]?.api_static && 
                          <SidebarMenu.Sub eventKey={4}>
                            <SidebarMenu.Sub.Toggle className="font">
                              <SidebarMenu.Nav.Title>IIA</SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon className="margin-right" />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                eventKey="IIA5"
                                className="title-in padding-0"
                                href="/iias/edit-factsheet"
                                onClick={(e) =>
                                  {e.preventDefault(); navigate(
                                    "/iias/edit-factsheet"
                                  );}
                                }
                              >
                                <SidebarMenu.Nav.Icon className="nav-icon">
                                  <FontAwesomeIcon
                                    icon={faList}
                                    color={COLORS.black}
                                  />
                                </SidebarMenu.Nav.Icon>
                                <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                  Default IIA Data
                                </SidebarMenu.Nav.Title>
                              </SidebarMenu.Nav.Link>
                        </SidebarMenu.Nav>
                        </SidebarMenu.Sub.Collapse>
                      </SidebarMenu.Sub>}
                      {userHasAccessToPermission("erasmus-app") &&<SidebarMenu.Sub eventKey={4}>
                            <SidebarMenu.Sub.Toggle className="font">
                              <SidebarMenu.Nav.Title>
                                Erasmus+ App
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon className="margin-right" />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp1"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/useful-info"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="useful-info-icon" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Useful Info
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp2"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/deals"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="handshake-simple-solid" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Deals
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp3"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/events"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="events-icon" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Events
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                      </SidebarMenu.Sub>}
                      {userHasAccessToPermission("managingStudents") &&<SidebarMenu.Sub eventKey={4}>
                            <SidebarMenu.Sub.Toggle className="font" onClick={() => {
                                    navigate(
                                      "/courses/list"
                                    ); handleClose();
                                  }}>
                              <SidebarMenu.Nav.Title>
                                Courses Evaluations
                              </SidebarMenu.Nav.Title>
                              {/* <SidebarMenu.Nav.Icon className="margin-right" /> */}
                            </SidebarMenu.Sub.Toggle>
                            {/* <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp1"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/useful-info"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="useful-info-icon" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Useful Info
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp2"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/deals"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="handshake-simple-solid" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Deals
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp3"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/events"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="events-icon" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Events
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse> */}
                      </SidebarMenu.Sub>}
                        </SidebarMenu.Nav>
                      </SidebarMenu.Body>
                      )}

                    <SidebarMenu.Footer className="mb-2 sidebar-bottom">
                      <SidebarMenu.Nav>
                      {userHasAccessToPermission("ewpSettings") &&  (<SidebarMenu.Nav.Link
                          className="title-in padding-0"
                          href="/ewp/settings"
                          onClick={(e) =>
                            {e.preventDefault(); navigate(
                              "/ewp/settings"
                            );}
                          }
                        >
                          <SidebarMenu.Nav.Icon className="nav-icon">
                            <span className="ewpsettings" />
                          </SidebarMenu.Nav.Icon>
                          <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                            EWP Settings
                          </SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Link>)}
                      </SidebarMenu.Nav>
                    </SidebarMenu.Footer>
                  </SidebarMenu.Collapse>
                </SidebarMenu>
              </Col>
              <Col
                md={10}
                lg={10}
                className="wrapped"
                style={
                  size === "md"
                    ? { width: "75%", height: "100%" }
                    : size === "lg"
                    ? { width: "80%", height: "100%" }
                    : { width: "86%", height: "100%" }
                }
              >
                <Wrapped />
              </Col>
            </>
          )}
          {(size === "xs" || size === "sm" || size === "md") && (
            <>
              <Navbar
                key={false}
                bg="light"
                expand={false}
                sticky="top"
                fixed="top"
              >
                <Container fluid>
                  <div className="toggle-icon">
                    <Navbar.Toggle
                      aria-controls={`offcanvasNavbar-expand-${false}`}
                      className="border-0"
                      onClick={toggleMenu}
                    />
                    <span className="logo-mobile" />
                  </div>
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${false}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                    placement="start"
                    className="offcanvas-mobile"
                    style={{ backgroundColor: "#F7F7F7", width: 300 }}
                    show={show} onHide={handleClose}
                  >
                    <Offcanvas.Header>
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-${false}`}
                        className="d-flex flex-column w-100 align-items-start"
                      >
                        <div className="text-xxl-end flex-grow-1 h6-style mb-3 d-flex align-items-stretch">
                          <span
                            className="me-3 d-inline-block"
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              size="lg"
                              color={COLORS.black}
                            />
                          </span>
                          <span>{user?.email}</span>
                        </div>
                        <div className="text-xxl-end flex-grow-1 h6-style d-flex align-items-stretch">
                          <span
                            className="me-3 d-inline-block"
                          >
                            <FontAwesomeIcon
                              icon={faBuildingColumns}
                              size="lg"
                              color={COLORS.black}
                            />
                          </span>
                          <span>{user?.heiID}</span>
                        </div>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="padding-0">
                      <SidebarMenu expand={size} className="padding-top">
                        <SidebarMenu.Collapse className="width">
                          <SidebarMenu.Header className="padding-0"></SidebarMenu.Header>
                          {user.type === ACCOUNT_TYPES.eufAdmin && (
                            <SidebarMenu.Body className="padding-0">
                              <SidebarMenu.Nav>
                                <SidebarMenu.Sub eventKey={0}>
                                  <SidebarMenu.Sub.Toggle className="h6-style">
                                    <SidebarMenu.Nav.Title>
                                      Institutions
                                    </SidebarMenu.Nav.Title>
                                    <SidebarMenu.Nav.Icon className="margin-right" />
                                  </SidebarMenu.Sub.Toggle>
                                  <SidebarMenu.Sub.Collapse>
                                    <SidebarMenu.Nav>
                                      <SidebarMenu.Nav.Link
                                        eventKey="requests"
                                        className="title-in padding-0"
                                        onClick={() => {navigate("/requests"); handleClose();}}
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <span className="arrow-out" />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left h6-style">
                                          Requests
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                    </SidebarMenu.Nav>
                                  </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Body>
                          )}
                          {user.type === ACCOUNT_TYPES.hei && (
                            <SidebarMenu.Body className="padding-0">
                              <SidebarMenu.Nav>
                                <SidebarMenu.Sub eventKey={0}>
                                  <SidebarMenu.Sub.Toggle className="font">
                                    <SidebarMenu.Nav.Title>
                                      My University
                                    </SidebarMenu.Nav.Title>
                                    <SidebarMenu.Nav.Icon className="margin-right" />
                                  </SidebarMenu.Sub.Toggle>
                                  <SidebarMenu.Sub.Collapse>
                                    <SidebarMenu.Nav>
                                      {userHasAccessToPermission(
                                        "creatingAccounts"
                                      ) && (
                                        <SidebarMenu.Nav.Link
                                          eventKey="my-university-1"
                                          className="title-in padding-0"
                                          onClick={() => {
                                            navigate("/accounts-and-access"); handleClose();
                                          }}
                                        >
                                          <SidebarMenu.Nav.Icon className="nav-icon">
                                            <FontAwesomeIcon
                                              icon={faUserLock}
                                              color={COLORS.black}
                                            />
                                          </SidebarMenu.Nav.Icon>
                                          <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                            Accounts & Access
                                          </SidebarMenu.Nav.Title>
                                        </SidebarMenu.Nav.Link>
                                      )}
                                       {userHasAccessToPermission(
                                        "managingOUnits"
                                      ) && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_ounits && (<SidebarMenu.Nav.Link
                                        eventKey="my-university-2"
                                        className="title-in padding-0"
                                        onClick={() => {
                                          navigate("/organizational-units"); handleClose();
                                        }}
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <FontAwesomeIcon
                                            icon={faSitemap}
                                            color={COLORS.black}
                                          />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          Organizational Units
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>)}
                                    </SidebarMenu.Nav>
                                  </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>
                                {(userHasAccessToPermission(
                                  "managingStudents"
                                ) ||
                                  userHasAccessToPermission(
                                    "viewingStudents"
                                  ) ||
                                  userHasAccessToPermission(
                                    "uploadingStudents"
                                  )) && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_ola && (
                                  <SidebarMenu.Sub eventKey={1}>
                                    <SidebarMenu.Sub.Toggle className="font">
                                      <SidebarMenu.Nav.Title>
                                        OLA
                                      </SidebarMenu.Nav.Title>
                                      <SidebarMenu.Nav.Icon className="margin-right" />
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse>
                                      <SidebarMenu.Nav>
                                        {(userHasAccessToPermission(
                                          "managingStudents"
                                        ) ||
                                          userHasAccessToPermission(
                                            "viewingStudents"
                                          )) && (
                                          <>
                                            <SidebarMenu.Nav.Link
                                              eventKey="ola1"
                                              className="title-in padding-0"
                                              onClick={() => {
                                                navigate("/ola/outgoing"); handleClose();}
                                              }
                                            >
                                              <SidebarMenu.Nav.Icon className="nav-icon">
                                                <span className="arrow-out" />
                                              </SidebarMenu.Nav.Icon>
                                              <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                                Outgoing Students
                                              </SidebarMenu.Nav.Title>
                                            </SidebarMenu.Nav.Link>
                                            <SidebarMenu.Nav.Link
                                              eventKey="ola2"
                                              className="title-in padding-0"
                                              onClick={() => {
                                                navigate("/ola/incoming"); handleClose();}
                                              }
                                            >
                                              <SidebarMenu.Nav.Icon className="nav-icon">
                                                <span className="arrow-in" />
                                              </SidebarMenu.Nav.Icon>
                                              <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                                Incoming Students
                                              </SidebarMenu.Nav.Title>
                                            </SidebarMenu.Nav.Link>
                                          </>
                                        )}
                                        {userHasAccessToPermission(
                                          "uploadingStudents"
                                        ) && (
                                          <SidebarMenu.Nav.Link
                                            eventKey="ola3"
                                            className="title-in padding-0"
                                          >
                                            <SidebarMenu.Nav.Icon className="nav-icon">
                                              <span className="upload-solid" />
                                            </SidebarMenu.Nav.Icon>
                                            <SidebarMenu.Nav.Title
                                              className="padding-left font-menu-item"
                                              onClick={() => {
                                                navigate("/ola/upload"); handleClose();}
                                              }
                                            >
                                              Upload
                                            </SidebarMenu.Nav.Title>
                                          </SidebarMenu.Nav.Link>
                                        )}
                                      </SidebarMenu.Nav>
                                    </SidebarMenu.Sub.Collapse>
                                  </SidebarMenu.Sub>
                                )}
                                { (userHasAccessToPermission(
                                  "managingStudents"
                                ) ||
                                  userHasAccessToPermission(
                                    "viewingStudents"
                                  ) ||
                                  userHasAccessToPermission(
                                    "uploadingStudents"
                                  )) && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_ola && <SidebarMenu.Sub eventKey={2}>
                                  <SidebarMenu.Sub.Toggle className="font">
                                    <SidebarMenu.Nav.Title>
                                      Short Term
                                    </SidebarMenu.Nav.Title>
                                    <SidebarMenu.Nav.Icon className="margin-right" />
                                  </SidebarMenu.Sub.Toggle>
                                  <SidebarMenu.Sub.Collapse>
                                    <SidebarMenu.Nav>
                                      <SidebarMenu.Nav.Link
                                        eventKey="shortTerm1"
                                        className="title-in padding-0"
                                        onClick={() => {navigate("/short-term/outgoing"); handleClose();}}
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <span className="arrow-out" />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          Outgoing Students
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                      <SidebarMenu.Nav.Link
                                        eventKey="shortTerm2"
                                        className="title-in padding-0"
                                        onClick={() => {navigate("/short-term/incoming"); handleClose();}}
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <span className="arrow-in" />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          Incoming Students
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                    </SidebarMenu.Nav>
                                  </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>}
                                {userHasAccessToPermission("nominations") && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_iia && (
                                  <><SidebarMenu.Sub eventKey={3}>
                                    <SidebarMenu.Sub.Toggle className="font">
                                      <SidebarMenu.Nav.Title>
                                        Applications
                                      </SidebarMenu.Nav.Title>
                                      <SidebarMenu.Nav.Icon className="margin-right" />
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse>
                                      <SidebarMenu.Nav>
                                        <SidebarMenu.Nav.Link
                                          eventKey="applications1"
                                          className="title-in padding-0"
                                          onClick={() => {
                                            navigate(
                                              "/applications-for-erasmus+/outgoing-students"
                                            ); handleClose();}
                                          }
                                        >
                                          <SidebarMenu.Nav.Icon className="nav-icon">
                                            <span className="arrow-out" />
                                          </SidebarMenu.Nav.Icon>
                                          <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                            Outgoing Students
                                          </SidebarMenu.Nav.Title>
                                        </SidebarMenu.Nav.Link>
                                        <SidebarMenu.Nav.Link
                                          eventKey="applications2"
                                          className="title-in padding-0"
                                          onClick={() => {
                                            navigate(
                                              "/applications-for-erasmus+/incoming-students"
                                            ); handleClose();}
                                          }
                                        >
                                          <SidebarMenu.Nav.Icon className="nav-icon">
                                            <span className="arrow-in" />
                                          </SidebarMenu.Nav.Icon>
                                          <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                            Incoming Students
                                          </SidebarMenu.Nav.Title>
                                        </SidebarMenu.Nav.Link>
                                        <SidebarMenu.Nav.Link
                                          eventKey="applications3"
                                          className="title-in padding-0"
                                          onClick={() => {
                                            navigate(
                                              "/applications-for-erasmus+/requirements"
                                            ); handleClose();}
                                          }
                                        >
                                          <SidebarMenu.Nav.Icon className="nav-icon">
                                            <span className="requirements-icon" />
                                          </SidebarMenu.Nav.Icon>
                                          <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                            Requirements
                                          </SidebarMenu.Nav.Title>
                                        </SidebarMenu.Nav.Link>
                                      </SidebarMenu.Nav>
                                    </SidebarMenu.Sub.Collapse>
                                  </SidebarMenu.Sub></>
                                )}
                                {dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_iia ? (
                                <SidebarMenu.Sub eventKey={4}>
                                  <SidebarMenu.Sub.Toggle className="font">
                                    <SidebarMenu.Nav.Title>
                                      IIA
                                    </SidebarMenu.Nav.Title>
                                    <SidebarMenu.Nav.Icon className="margin-right" />
                                  </SidebarMenu.Sub.Toggle>
                                  <SidebarMenu.Sub.Collapse>
                                    <SidebarMenu.Nav>
                                    {userHasAccessToPermission("managingIIAs") && <SidebarMenu.Nav.Link
                                        eventKey="IIA1"
                                        className="title-in padding-0"
                                        onClick={() => {
                                          navigate(
                                            "/iias/iia-list"
                                          ); handleClose();
                                        }}
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <span className="handshake-simple-solid" />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          IIA List
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                    }
                                      {userHasAccessToPermission("creatingIIA") && <SidebarMenu.Nav.Link
                                        eventKey="IIA3"
                                        className="title-in padding-0"
                                        onClick={() => {
                                          navigate(
                                            "/iias/create-iia"
                                          ); localStorage.setItem('resetCreate', JSON.stringify("true")); handleClose();
                                        }}
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                        <FontAwesomeIcon
                                          icon={faSquarePlus}
                                          color={COLORS.black}
                                        />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          New Digital IIA
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                      }
                                      {userHasAccessToPermission("importIIA") && <SidebarMenu.Nav.Link
                                        eventKey="IIA4"
                                        className="title-in padding-0"
                                        onClick={() =>
                                          navigate(
                                            "/iias/import"
                                          )
                                        }
                                      >
                                      <SidebarMenu.Nav.Icon className="nav-icon">
                                          <span className="upload-solid" />
                                      </SidebarMenu.Nav.Icon>
                                      <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          Import IIA
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                      }
                                      {userHasAccessToPermission("defaultIIAData") && dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_static && 
                                      <SidebarMenu.Nav.Link
                                        eventKey="IIA5"
                                        className="title-in padding-0"
                                        onClick={() => {
                                          navigate(
                                            "/iias/edit-factsheet"
                                          ); handleClose();
                                        }}
                                      >
                                        <SidebarMenu.Nav.Icon className="nav-icon">
                                          <FontAwesomeIcon
                                            icon={faList}
                                            color={COLORS.black}
                                          />
                                        </SidebarMenu.Nav.Icon>
                                        <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                          Default IIA Data
                                        </SidebarMenu.Nav.Title>
                                      </SidebarMenu.Nav.Link>
                                      }
                                    </SidebarMenu.Nav>
                                  </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>
                                ) : dataEWPSettings?.ewpConnections?.length > 0 && !dataEWPSettings?.ewpConnections[0]?.api_iia && userHasAccessToPermission("defaultIIAData") && dataEWPSettings?.ewpConnections[0]?.api_static && 
                                <SidebarMenu.Sub eventKey={4}>
                                  <SidebarMenu.Sub.Toggle className="font">
                                    <SidebarMenu.Nav.Title>
                                      IIA
                                    </SidebarMenu.Nav.Title>
                                    <SidebarMenu.Nav.Icon className="margin-right" />
                                  </SidebarMenu.Sub.Toggle>
                                  <SidebarMenu.Sub.Collapse>
                                    <SidebarMenu.Nav>
                                      <SidebarMenu.Nav.Link
                                      eventKey="IIA5"
                                      className="title-in padding-0"
                                      onClick={() => {
                                        navigate(
                                          "/iias/edit-factsheet"
                                        ); handleClose();
                                      }}
                                    >
                                      <SidebarMenu.Nav.Icon className="nav-icon">
                                        <FontAwesomeIcon
                                          icon={faList}
                                          color={COLORS.black}
                                        />
                                      </SidebarMenu.Nav.Icon>
                                      <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                        Default IIA Data
                                      </SidebarMenu.Nav.Title>
                                    </SidebarMenu.Nav.Link>
                                  </SidebarMenu.Nav>
                                  </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>}
                                {userHasAccessToPermission("erasmus-app") && <SidebarMenu.Sub eventKey={4}>
                            <SidebarMenu.Sub.Toggle className="font">
                              <SidebarMenu.Nav.Title>
                                Erasmus+ App
                              </SidebarMenu.Nav.Title>
                              <SidebarMenu.Nav.Icon className="margin-right" />
                            </SidebarMenu.Sub.Toggle>
                            <SidebarMenu.Sub.Collapse>
                              <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp1"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/useful-info"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="useful-info-icon" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Useful Info
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp2"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/deals"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="handshake-simple-solid" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Deals
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                                <SidebarMenu.Nav.Link
                                  eventKey="erasmusApp3"
                                  className="title-in padding-0"
                                  onClick={() => {
                                    navigate(
                                      "/erasmus+-app/events"
                                    ); handleClose();
                                  }}
                                >
                                  <SidebarMenu.Nav.Icon className="nav-icon">
                                    <span className="events-icon" />
                                  </SidebarMenu.Nav.Icon>
                                  <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                    Events
                                  </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                              </SidebarMenu.Nav>
                            </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>}
                              </SidebarMenu.Nav>
                            </SidebarMenu.Body>
                          )}
                         
                          <SidebarMenu.Footer className="mt-4">
                            <SidebarMenu.Nav>
                            {userHasAccessToPermission("ewpSettings") &&  (<SidebarMenu.Nav.Link
                                className="title-in padding-0"
                                onClick={() =>
                                  navigate(
                                    "/ewp/settings"
                                  )
                                }
                              >
                                <SidebarMenu.Nav.Icon className="nav-icon">
                                  <span className="ewpsettings" />
                                </SidebarMenu.Nav.Icon>
                                <SidebarMenu.Nav.Title className="padding-left font-menu-item">
                                  EWP Settings
                                </SidebarMenu.Nav.Title>
                              </SidebarMenu.Nav.Link>)}
                            </SidebarMenu.Nav>
                          </SidebarMenu.Footer>
                        </SidebarMenu.Collapse>
                      </SidebarMenu>
                      <div className="wrapper-button">
                        <div className="logout">
                          <CustomButton
                            buttonType={"delete"}
                            text={"Logout"}
                            small={true}
                            handleClick={handleLogout}
                            icon={faRightFromBracket}
                            hasIcon={true}
                            iconColor={COLORS.white}
                          />
                        </div>
                      </div>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
              <Wrapped />
            </>
          )}
        </Row>
      </Container>
    </>
  );
};
export default Sidebar;
