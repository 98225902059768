import { useQuery } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Accordion, Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import CustomButton from "../../../../components/CustomButton";
import IconButton from "../../../../components/IconButton";
import Select from "../../../../components/Select";
import GET_FACTSHEET from "../../../../graphql/queries/IIAs/getFactsheet";
import GET_HEI from "../../../../graphql/queries/IIAs/getHei";
import GET_OUNITS from "../../../../graphql/queries/IIAs/getOUnits";
import useUser from "../../../../hooks/useUser";

const ForthStep = ({updateMultipleValues, staffTeachers, staffTrainings, studentStudies, studentTraineeships, partnerSCHAC, contacts, 
    partnerContacts, ounit, partnerErasmusCode, partnerOUnitName, partnerCountry, checked }) => {
    const {user} = useUser();
    const [calendarNomAutumn, setCalendarNomAutumn] = useState("");
    const [calendarNomSpring, setCalendarNomSpring] = useState("");
    const [calendarAppSpring, setCalendarAppSpring] = useState("");
    const [calendarAppAutumn, setCalendarAppAutumn] = useState("");
    const [applicationInfoEmail, setApplicationInfoEmail] = useState("");
    const [applicationInfoPhone, setApplicationInfoPhone] = useState("");
    const [applicationInfoWebsite, setApplicationInfoWebsite] = useState([]);
    const [additionalRequirements, setAdditionalRequirements] = useState([]);
    const [accessibilities, setAccessibilities] = useState([]);
    const [additionalInfos, setAdditionalInfos] = useState([]);
    const [housingInfo, setHousingInfo] = useState({});
    const [visaInfo, setVisaInfo] = useState({});
    const [insuranceInfo, setInsuranceInfo] = useState({});
    const [calendarNomAutumnPartner, setCalendarNomAutumnPartner] = useState("");
    const [calendarNomSpringPartner, setCalendarNomSpringPartner] = useState("");
    const [calendarAppSpringPartner, setCalendarAppSpringPartner] = useState("");
    const [calendarAppAutumnPartner, setCalendarAppAutumnPartner] = useState("");
    const [applicationInfoEmailPartner, setApplicationInfoEmailPartner] = useState("");
    const [applicationInfoPhonePartner, setApplicationInfoPhonePartner] = useState("");
    const [applicationInfoWebsitePartner, setApplicationInfoWebsitePartner] = useState([]);
    const [additionalRequirementsPartner, setAdditionalRequirementsPartner] = useState([]);
    const [additionalInfosPartner, setAdditionalInfosPartner] = useState([]);
    const [accessibilitiesPartner, setAccessibilitiesPartner] = useState([]);
    const [housingInfoPartner, setHousingInfoPartner] = useState({});
    const [visaInfoPartner, setVisaInfoPartner] = useState({});
    const [insuranceInfoPartner, setInsuranceInfoPartner] = useState({});
    const [validated, setValidated] = useState(false);
    // const [checked, setChecked] = useState(false);

  const {
    loading: loadingHei,
    error: errorHei,
    data: dataHei,
  } = useQuery(GET_HEI);

  const { loading: loadingPartnerHei, error: errorPartnerHei, data: dataPartnerHei } = useQuery(GET_HEI, {
    variables: { heiID: partnerSCHAC }});

  const { loadingFactsheet, errorFactsheet, dataFactsheet, refetch: refetchFactsheet } = useQuery(
    GET_FACTSHEET,
    {
      variables: {
        heiID: user?.heiID
      }, onCompleted: (data) =>  { 

        setCalendarNomAutumn(data?.factsheet?.calendar?.student_nom_autumn_term);
        setCalendarNomSpring(data.factsheet?.calendar?.student_nom_spring_term);
        setCalendarAppAutumn(data?.factsheet?.calendar?.student_app_autumn_term);
        setCalendarAppSpring(data.factsheet?.calendar?.student_app_spring_term);
        setAdditionalRequirements(data.factsheet?.additional_requirements);
        setAccessibilities(data?.factsheet?.accessibilities);
        setAdditionalInfos(data?.factsheet?.additional_infos);
        setHousingInfo(data?.factsheet?.housing_info);
        setVisaInfo(data?.factsheet?.visa_info);
        setInsuranceInfo(data?.factsheet?.insurance_info);
        setApplicationInfoEmail(data?.factsheet?.application_info?.email);
        setApplicationInfoPhone(data.factsheet?.application_info?.phone_number?.e164);
        setApplicationInfoWebsite(data?.factsheet?.application_info?.website_urls);
        
    }
    }
);
const { loading: loadingFactsheetPartner, errorFactsheetPartner, dataFactsheetPartner, refetch: refetchFactsheetPartner } =
useQuery(
    GET_FACTSHEET, { variables: {
        heiID: partnerSCHAC
      },
        onCompleted: (data) =>  {
            setCalendarNomAutumnPartner(data?.factsheet?.calendar?.student_nom_autumn_term);
            setCalendarNomSpringPartner(data.factsheet?.calendar?.student_nom_spring_term);
            setCalendarAppAutumnPartner(data?.factsheet?.calendar?.student_app_autumn_term);
            setCalendarAppSpringPartner(data.factsheet?.calendar?.student_app_spring_term);
            setAdditionalRequirementsPartner(data.factsheet?.additional_requirements);
            setAccessibilitiesPartner(data?.factsheet?.accessibilities);
            setHousingInfoPartner(data?.factsheet?.housing_info);
            setVisaInfoPartner(data?.factsheet?.visa_info);
            setInsuranceInfoPartner(data?.factsheet?.insurance_info);
            setApplicationInfoEmailPartner(data?.factsheet?.application_info?.email);
            setApplicationInfoPhonePartner(data.factsheet?.application_info?.phone_number?.e164);
            setApplicationInfoWebsitePartner(data?.factsheet?.application_info?.website_urls);
            setAdditionalInfosPartner(data?.factsheet?.additional_infos);
        
        }}
);
  
  return (
    <>
    <Row style={{marginLeft: 25, marginRight: 25, background: "#EAECF3"}}>
                <Col style={{marginBottom: 12}} md={12} lg={12}>
                    
    <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                <div className="header">OVERVIEW & SUBMIT</div>
                <div><div className="h4-style">Static information applicable to all Inter-Institutional Agreements</div><div className="body">
*The institutions agree to cooperate for the exchange of students and/or staff in the context of the Erasmus programme. 
They commit to respect the quality requirements of the Erasmus Charter for Higher Education in all aspects related to the 
organisation and management of the mobility, in particular the automatic recognition of the credits awarded to students 
by the partner institution as agreed in the Learning Agreement and confirmed in the Transcript of Records, or according 
to the learning outcomes of the modules completed abroad, as described in the Course Catalogue, in line with the 
European Credit and Accumulation System. The institutions agree on exchanging their mobility related data in line 
with the technical standards of the European Student Card Initiative.</div>
<div className="h4-style" style={{marginTop: 7}}>Grading systems of the institutions</div>
<div className="body">It is recommended that receiving institutions provide a link to the 
statistical distribution of grades or makes the information available through EGRACONS according to the 
descriptions in the ECTS users’ guide. The information will facilitate the interpretation of each grade 
awarded to students and will facilitate the credit transfer by the sending institution.</div></div>
                <div className='h2-style' style={{marginTop: 20, color: "#3D619C"}}>General Info </div>
                <Row style={{marginRight: 5}}>
                <Col lg={6} style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="YOUR INSTITUTION DATA" style={{background: "#ffff"}}>
                        <Row style={{ paddingBottom: 15, paddingLeft: 10 }}>
                            <Col>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Name:</Col><Col>{dataHei?.hei?.name}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Country:</Col><Col>{dataHei?.hei?.country}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>SCHAC Code:</Col><Col>{user.heiID}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Erasmus Code:</Col><Col>{user.erasmusCode}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Org. Unit:</Col><Col>{ounit?.length > 0 && ounit[0]?.value}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Approving Person:</Col><Col></Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Contact Persons:</Col><Col><ul>{contacts?.length > 0 && contacts?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names[0]?.value}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                )}</ul></Col></Row>
                            </Col>
                        </Row>
                        </Tab>
                    </Tabs>
                </Col>
                <Col style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="PARTNER INSTITUTION DATA" style={{background: "#ffff"}}>
                            <Row style={{ paddingBottom: 15, paddingLeft: 10 }}>
                                <Col>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Name:</Col><Col>{dataPartnerHei?.hei?.name}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Country:</Col><Col>{dataPartnerHei?.hei?.country}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>SCHAC Code:</Col><Col>{partnerSCHAC}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Erasmus Code:</Col><Col>{dataPartnerHei?.hei?.erasmusCode}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Org. Unit:</Col><Col>{partnerOUnitName?.length > 0 && partnerOUnitName[0]?.value}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Approving Person:</Col><Col></Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Contact Persons:</Col><Col><ul>{partnerContacts?.length > 0 && partnerContacts?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names[0]?.value}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                )}</ul></Col></Row>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <div className='h2-style' style={{marginTop: 50, color: "#3D619C"}}>Your Factsheet Data </div>
            <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>CALENDAR</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                <div className='h6-style'>Student Nominations must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomAutumn}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomSpring}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Applications for Nominated Students must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppAutumn}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppSpring}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Application procedure for Nominated Students: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Email: </Col>
                                    <Col lg={10} className='body'>{applicationInfoEmail}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Phone: </Col>
                                    <Col lg={10} className='body'>{applicationInfoPhone}</Col>
                                </Row>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Website for information: </Col>
                                    <Col lg={10} className='body'>{applicationInfoWebsite?.length > 0 && applicationInfoWebsite}</Col>
                                </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL REQUIREMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <Table border={1} frame="void" rules="rows">
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>REQUIREMENT</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DETAILS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ECTS NUMBER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>SUBJECT FIELD (ISCED)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY LEVEL (EQF)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>WEBSITE</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {additionalRequirements?.map((elem, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.details}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.ects}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.isced_f_code + elem?.isced_f_code_name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.eqf_level}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'><a href={elem?.information_websites} target="_blank" style={{textDecoration: "none"}}>{elem?.information_websites}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will send its decision within 4 weeks, and no later than 5 weeks.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INCLUSION AND ACCESSIBILITY</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Infrastructure </div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilities?.map((elem, index) => (
                                            elem?.type?.startsWith("infrastructure") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Services</div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilities?.map((elem, index) => (
                                            elem?.type?.startsWith("service") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>HOUSING</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{housingInfo?.email}{housingInfo?.phone_number?.e164 ? ", " + housingInfo?.phone_number?.e164 : ""}{housingInfo?.website_urls ? ", " + housingInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>VISA</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{visaInfo?.email}{visaInfo?.phone_number?.e164 ? ", " + visaInfo?.phone_number?.e164 : ""}{visaInfo?.website_urls ? ", " + visaInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INSURANCE</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{insuranceInfo?.email}{insuranceInfo?.phone_number?.e164 ? ", " + insuranceInfo?.phone_number?.e164 : ""}{insuranceInfo?.website_urls ? ", " + insuranceInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL INFORMATION</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                {additionalInfos?.map((elem, index) => (
                                    <>
                                    <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>{elem?.type} </div>
                                    <Table responsive>
                                        <thead className='thead-font'>
                                            <tr>
                                            <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>CONTACT</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody-font'>
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls?.length > 0 ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </>
                                ))}
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className='h2-style' style={{marginTop: 50, color: "#3D619C"}}>Partner Factsheet Data </div>
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>CALENDAR</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                <div className='h6-style'>Student Nominations must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomAutumnPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomSpringPartner}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Applications for Nominated Students must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppAutumnPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppSpringPartner}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Application procedure for Nominated Students: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Email: </Col>
                                    <Col lg={10} className='body'>{applicationInfoEmailPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Phone: </Col>
                                    <Col lg={10} className='body'>{applicationInfoPhonePartner}</Col>
                                </Row>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Website for information: </Col>
                                    <Col lg={10} className='body'>{applicationInfoWebsitePartner?.length > 0 && applicationInfoWebsitePartner}</Col>
                                </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL REQUIREMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <Table border={1} frame="void" rules="rows">
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>REQUIREMENT</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DETAILS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ECTS NUMBER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>SUBJECT FIELD (ISCED)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY LEVEL (EQF)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>WEBSITE</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {additionalRequirementsPartner?.map((elem, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.details}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.ects}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.isced_f_code + elem?.isced_f_code_name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.eqf_level}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'><a href={elem?.information_websites} target="_blank" style={{textDecoration: "none"}}>{elem?.information_websites}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will send its decision within 4 weeks, and no later than 5 weeks.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INCLUSION AND ACCESSIBILITY</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Infrastructure </div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilitiesPartner?.map((elem, index) => (
                                            elem?.type?.startsWith("infrastructure") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Services</div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilitiesPartner?.map((elem, index) => (
                                            elem?.type?.startsWith("service") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>HOUSING</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{housingInfoPartner?.email}{housingInfoPartner?.phone_number?.e164 ? ", " + housingInfoPartner?.phone_number?.e164 : ""}{housingInfoPartner?.website_urls ? ", " + housingInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>VISA</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{visaInfoPartner?.email}{visaInfoPartner?.phone_number?.e164 ? ", " + visaInfoPartner?.phone_number?.e164 : ""}{visaInfoPartner?.website_urls ? ", " + visaInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INSURANCE</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{insuranceInfoPartner?.email}{insuranceInfoPartner?.phone_number?.e164 ? ", " + insuranceInfoPartner?.phone_number?.e164 : ""}{insuranceInfoPartner?.website_urls ? ", " + insuranceInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL INFORMATION</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                {additionalInfosPartner?.map((elem, index) => (
                                    <>
                                    <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>{elem?.type} </div>
                                    <Table responsive>
                                        <thead className='thead-font'>
                                            <tr>
                                            <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>CONTACT</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody-font'>
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls?.length > 0 ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </>
                                ))}
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className='h2-style' style={{marginTop: 50, color: "#3D619C"}}>Cooperation Conditions </div>

                    {staffTeachers.length > 0 && staffTeachers.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Staff Teacher:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>Staff Teachers</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + " days per year" : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0].split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1].split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {staffTrainings.length > 0 && staffTrainings.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Staff Training:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>Staff Trainings</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + " days per year" : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {studentStudies.length > 0 && studentStudies.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Student Study:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>EQF Level</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{"Student studies"}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + " months per year" : "" }</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0].split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1].split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level?.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {studentTraineeships.length > 0 && studentTraineeships.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Student Traineeship:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>EQF Level</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{"Student trainships"}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + " months per year" : "" }</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0].split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1].split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level?.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    <Form.Check type="checkbox" id="agree-terms" style={{marginTop: 30}}>
                        <Form.Check.Input
                            type={"checkbox"}
                            className="register"
                            // isInvalid={validated && !checked}
                            // isValid={validated && checked}
                            value={checked}
                            onClick={(e) => updateMultipleValues({checked: !checked})}
                        />
                        <Form.Check.Label>
                        <div className="h4-style">I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the 
                        relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard Terms & Conditions and Privacy Policy.</div>
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please agree that you have read the Privacy Policy and Terms and
                            Conditions.
                        </Form.Control.Feedback>
                    </Form.Check>
                </Col>
            </Row>
        </Col>
    </Row>
    </>
  );
};

export default ForthStep;