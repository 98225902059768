import withTitle from "../../components/withTitle";
import { useQuery } from "@apollo/client";
import GET_TERMS from "../../graphql/queries/terms-and-conditions/getTerms";
import './index.scss';
import { useNavigate } from "react-router-dom";

function TermsAndConditions() {
    const navigate = useNavigate();

  const {
    loading: termsLoading,
    error: termsError,
    data: termsData,
  } = useQuery(GET_TERMS);


  return (
    <main className="d-flex flex-column login">
        <div className="login-image-container mt-2 mb-2 col-8 col-sm-4 col-lg-2 pt-sm-5 ps-sm-5" onClick={() => navigate("/", { replace: true })} style={{cursor: 'pointer'}}>
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 119 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="M15.2172 30C23.6215 30 30.4345 23.2843 30.4345 15C30.4345 6.71573 23.6215 0 15.2172 0C6.81299 0 0 6.71573 0 15C0 23.2843 6.81299 30 15.2172 30Z"
                    fill="#73A6AD"
                    />
                    <path
                    d="M15.2173 15.0548L22.6426 4.42337C25.1661 6.13397 27.0013 8.6626 27.8287 11.5692C28.6561 14.4759 28.4235 17.5765 27.1712 20.3314C25.9189 23.0864 23.7262 25.3214 20.9748 26.6473C18.2235 27.9733 15.0875 28.3064 12.1127 27.5885L15.2173 15.0548Z"
                    fill="#F2F3F6"
                    />
                    <path
                    d="M15.245 25.1332C20.9071 25.1332 25.4972 20.6087 25.4972 15.0274C25.4972 9.44608 20.9071 4.92154 15.245 4.92154C9.58287 4.92154 4.9928 9.44608 4.9928 15.0274C4.9928 20.6087 9.58287 25.1332 15.245 25.1332Z"
                    fill="#73A6AD"
                    />
                    <path
                    d="M12.1099 27.594H15.1895L15.1895 16.6506H12.1099L12.1099 27.594Z"
                    fill="#F2F3F6"
                    />
                    <path
                    d="M41.4253 14.6496V15.4708H34.7109V3.97446H41.342V4.79562H35.5939V9.26278H40.9255V10.0839H35.5939V14.6496H41.4253Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M42.6916 3.97446H43.6246L46.6514 14.3047L49.9003 3.97446H50.95L54.2045 14.3047L57.2035 3.97446H58.1365L54.7543 15.4708H53.688L50.4224 5.10767L47.1401 15.4708H46.0738L42.6916 3.97446Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M67.7334 7.53834C67.7457 8.01081 67.66 8.48079 67.4816 8.91947C67.3031 9.35814 67.0357 9.75627 66.6956 10.0894C66.3555 10.4226 65.95 10.6838 65.5039 10.857C65.0578 11.0302 64.5805 11.1118 64.1012 11.0967H61.0189V15.4763H60.1359V3.97995H64.1012C64.5804 3.96528 65.0575 4.04712 65.5034 4.22047C65.9494 4.39382 66.3547 4.65503 66.6947 4.98813C67.0347 5.32122 67.3022 5.71918 67.4808 6.15768C67.6594 6.59617 67.7453 7.06597 67.7334 7.53834ZM66.8503 7.53834C66.8654 7.17724 66.8048 6.81696 66.6723 6.47996C66.5399 6.14297 66.3384 5.8365 66.0804 5.57967C65.8225 5.32283 65.5136 5.12115 65.1731 4.9872C64.8326 4.85325 64.4677 4.7899 64.1012 4.80111H61.0189V10.2756H64.1012C64.4678 10.2872 64.8329 10.2241 65.1736 10.0903C65.5143 9.9565 65.8233 9.75483 66.0814 9.49792C66.3394 9.241 66.5409 8.93436 66.6732 8.59718C66.8055 8.26001 66.8658 7.89954 66.8503 7.53834Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M44.591 22.3139C44.591 25.4836 42.3084 28.0621 39.0928 28.0621H34.7109V16.5657H39.0928C42.3084 16.5657 44.591 19.1442 44.591 22.3139ZM43.7246 22.3139C43.7246 19.5767 41.8419 17.3869 39.0928 17.3869H35.5939V27.2409H39.0928C41.8419 27.2409 43.7246 25.0402 43.7246 22.3139Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M54.8377 19.8504V28.0621H53.9879V26.2062C53.6353 26.8348 53.1154 27.3567 52.4842 27.7155C51.8529 28.0744 51.1343 28.2567 50.4058 28.2427C49.2525 28.2427 48.1464 27.7911 47.3309 26.9872C46.5153 26.1834 46.0572 25.0931 46.0572 23.9562C46.0572 22.8194 46.5153 21.7291 47.3309 20.9252C48.1464 20.1213 49.2525 19.6697 50.4058 19.6697C51.1343 19.6558 51.8529 19.838 52.4842 20.1969C53.1154 20.5558 53.6353 21.0776 53.9879 21.7062V19.8504H54.8377ZM53.9879 23.9562C53.983 23.2699 53.7719 22.6005 53.3814 22.0323C52.9908 21.4642 52.4382 21.0228 51.7934 20.7639C51.1486 20.5051 50.4405 20.4402 49.7584 20.5777C49.0762 20.7152 48.4507 21.0487 47.9607 21.5363C47.4707 22.0239 47.1382 22.6436 47.0052 23.3173C46.8722 23.9909 46.9446 24.6883 47.2133 25.3214C47.482 25.9546 47.935 26.495 48.515 26.8747C49.0951 27.2543 49.7762 27.456 50.4724 27.4544C50.9377 27.4574 51.399 27.3687 51.829 27.1935C52.2591 27.0184 52.6493 26.7603 52.9768 26.4344C53.3043 26.1085 53.5624 25.7214 53.7361 25.2959C53.9097 24.8703 53.9953 24.4148 53.9879 23.9562Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M63.0183 25.9106C63.0183 27.2573 61.8187 28.2427 60.0359 28.2427C58.3698 28.2427 57.2591 27.4708 56.8703 26.4197L57.5867 26.0091C57.8866 26.896 58.7863 27.4544 60.0359 27.4544C61.2022 27.4544 62.1852 26.9453 62.1852 25.9106C62.1852 23.677 57.1369 24.958 57.1369 22.0018C57.1369 20.7044 58.2698 19.6697 59.9693 19.6697C61.3189 19.6697 62.3518 20.3102 62.8017 21.312L62.1019 21.7062C61.752 20.8522 60.8857 20.458 59.9693 20.458C58.8863 20.458 57.9699 21.0055 57.9699 22.0018C57.9699 24.2026 63.0183 22.9051 63.0183 25.9106Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M71.9987 22.9544V28.0621H71.149V22.9544C71.149 21.4434 70.2659 20.458 68.6998 20.458C67.067 20.458 65.784 21.5091 65.784 23.7263V28.0621H64.951V16.5657H65.784V21.4106C66.4172 20.1953 67.4835 19.6697 68.7664 19.6697C70.7491 19.6697 71.9987 20.9672 71.9987 22.9544Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M83.0784 23.9562C83.0858 24.5184 82.9794 25.0764 82.7656 25.5976C82.5518 26.1188 82.2347 26.5927 81.833 26.9918C81.4313 27.3909 80.9529 27.707 80.4258 27.9218C79.8987 28.1366 79.3335 28.2457 78.7631 28.2427C78.0283 28.2581 77.3036 28.0711 76.6708 27.7026C76.0379 27.3341 75.5219 26.7988 75.181 26.1569V28.073H74.3479V16.5767H75.181V21.7774C75.5219 21.1355 76.0379 20.6002 76.6708 20.2318C77.3036 19.8633 78.0283 19.6762 78.7631 19.6916C79.3316 19.6887 79.895 19.7971 80.4206 20.0105C80.9462 20.2239 81.4236 20.5381 81.825 20.9349C82.2265 21.3316 82.544 21.803 82.7591 22.3216C82.9743 22.8403 83.0828 23.3959 83.0784 23.9562ZM82.2453 23.9562C82.242 23.2683 82.0321 22.5968 81.642 22.0264C81.2518 21.456 80.6991 21.0122 80.0533 20.7512C79.4076 20.4902 78.698 20.4236 78.0139 20.5598C77.3298 20.696 76.702 21.0289 76.2097 21.5165C75.7174 22.0041 75.3826 22.6245 75.2477 23.2994C75.1127 23.9743 75.1836 24.6736 75.4515 25.3088C75.7193 25.9441 76.172 26.4869 76.7525 26.8687C77.333 27.2506 78.0153 27.4544 78.7131 27.4544C79.1793 27.4577 79.6415 27.3694 80.0727 27.1945C80.5038 27.0197 80.8953 26.7619 81.2242 26.4362C81.5531 26.1104 81.8128 25.7234 81.9881 25.2976C82.1634 24.8717 82.2509 24.4157 82.2453 23.9562Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M84.5279 23.9562C84.523 23.1029 84.7751 22.2673 85.2523 21.5553C85.7295 20.8432 86.4103 20.2868 87.2085 19.9565C88.0068 19.6262 88.8865 19.5368 89.7363 19.6997C90.5861 19.8626 91.3677 20.2704 91.9822 20.8715C92.5967 21.4727 93.0164 22.24 93.1881 23.0764C93.3598 23.9128 93.2759 24.7806 92.9469 25.57C92.6179 26.3593 92.0586 27.0346 91.34 27.5104C90.6213 27.9862 89.7756 28.2411 88.9098 28.2427C88.3349 28.2511 87.764 28.1462 87.2307 27.9342C86.6974 27.7222 86.2124 27.4073 85.8042 27.008C85.3961 26.6087 85.0729 26.1331 84.8537 25.609C84.6346 25.085 84.5238 24.523 84.5279 23.9562ZM92.4254 23.9562C92.4204 23.2699 92.2094 22.6005 91.8188 22.0323C91.4282 21.4642 90.8757 21.0228 90.2309 20.7639C89.5861 20.505 88.8779 20.4402 88.1958 20.5777C87.5137 20.7152 86.8881 21.0487 86.3981 21.5363C85.9082 22.0239 85.5757 22.6436 85.4427 23.3173C85.3097 23.9909 85.3821 24.6883 85.6508 25.3214C85.9195 25.9546 86.3724 26.495 86.9525 26.8747C87.5325 27.2543 88.2136 27.456 88.9098 27.4544C89.3752 27.4574 89.8364 27.3687 90.2665 27.1935C90.6965 27.0184 91.0868 26.7603 91.4143 26.4344C91.7418 26.1085 91.9999 25.7214 92.1735 25.2959C92.3471 24.8703 92.4328 24.4148 92.4254 23.9562Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M103.455 19.8504V28.0621H102.605V26.2062C102.253 26.8348 101.733 27.3567 101.102 27.7155C100.47 28.0744 99.7518 28.2567 99.0232 28.2427C97.8699 28.2427 96.7638 27.7911 95.9483 26.9872C95.1328 26.1834 94.6746 25.0931 94.6746 23.9562C94.6746 22.8194 95.1328 21.7291 95.9483 20.9252C96.7638 20.1213 97.8699 19.6697 99.0232 19.6697C99.7518 19.6558 100.47 19.838 101.102 20.1969C101.733 20.5558 102.253 21.0776 102.605 21.7062V19.8504H103.455ZM102.605 23.9562C102.6 23.2699 102.389 22.6005 101.999 22.0323C101.608 21.4642 101.056 21.0228 100.411 20.7639C99.7661 20.5051 99.0579 20.4402 98.3758 20.5777C97.6937 20.7152 97.0681 21.0487 96.5781 21.5363C96.0882 22.0239 95.7557 22.6436 95.6227 23.3173C95.4897 23.9909 95.5621 24.6883 95.8308 25.3214C96.0995 25.9546 96.5524 26.495 97.1325 26.8747C97.7125 27.2543 98.3936 27.456 99.0898 27.4544C99.5552 27.4574 100.016 27.3687 100.446 27.1935C100.877 27.0184 101.267 26.7603 101.594 26.4344C101.922 26.1085 102.18 25.7214 102.354 25.2959C102.527 24.8703 102.613 24.4148 102.605 23.9562Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M109.636 19.7354V20.5292C108.204 20.5292 106.86 21.3996 106.86 23.5018V28.0675H106.026V19.8559H106.86V21.4599C107.42 20.1624 108.503 19.7354 109.636 19.7354Z"
                    fill="#1F1F1F"
                    />
                    <path
                    d="M119 16.5657V28.062H118.167V26.146C117.826 26.7878 117.31 27.3231 116.677 27.6916C116.044 28.0601 115.32 28.2472 114.585 28.2317C113.431 28.2317 112.325 27.7801 111.51 26.9763C110.694 26.1724 110.236 25.0821 110.236 23.9453C110.236 22.8084 110.694 21.7181 111.51 20.9142C112.325 20.1104 113.431 19.6588 114.585 19.6588C115.32 19.6433 116.044 19.8304 116.677 20.1989C117.31 20.5674 117.826 21.1027 118.167 21.7445V16.5438L119 16.5657ZM118.167 23.9562C118.164 23.2683 117.954 22.5967 117.564 22.0263C117.173 21.4559 116.621 21.0122 115.975 20.7512C115.329 20.4902 114.62 20.4236 113.936 20.5598C113.251 20.696 112.624 21.0289 112.131 21.5165C111.639 22.004 111.304 22.6244 111.169 23.2994C111.034 23.9743 111.105 24.6735 111.373 25.3088C111.641 25.944 112.094 26.4869 112.674 26.8687C113.255 27.2506 113.937 27.4544 114.635 27.4544C115.101 27.4577 115.563 27.3693 115.994 27.1945C116.425 27.0197 116.817 26.7619 117.146 26.4361C117.475 26.1104 117.734 25.7233 117.91 25.2975C118.085 24.8717 118.172 24.4157 118.167 23.9562Z"
                    fill="#1F1F1F"
                    />
                </svg>
            </div>
      <div className="d-flex flex-column align-items-center position-relative px-3 px-sm-0 py-5 mx-auto mt-0 mt-lg-5 py-xxl-5 mx-sm-auto terms-container rounded terms">
            
        <div style={{padding: 20}} dangerouslySetInnerHTML={{__html: termsData?.terms?.terms}}/>
      </div>
    </main>
  );
}

export default withTitle(TermsAndConditions, "Terms and Conditions");
