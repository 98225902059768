
const CEFRvalues = [
    {
        "id": 1619,
        "code": "A1",
        "title": "Breakthrough or beginner"
    },
    {
        "id": 1620,
        "code": "A2",
        "title": "Waystage or elementary"
    },
    {
        "id": 1621,
        "code": "B1",
        "title": "Threshold or intermediate"
    },
    {
        "id": 1622,
        "code": "B2",
        "title": "Vantage or upper intermediate"
    },
    {
        "id": 1623,
        "code": "C1",
        "title": "Effective operational proficiency or advanced"
    },
    {
        "id": 1624,
        "code": "C2",
        "title": "Mastery or proficiency"
    }
]

const languagesvalues = [
   {
      "id":1437,
      "title":"Bulgarian",
      "european":true,
      "code":"BG"
   },
   {
      "id":1438,
      "title":"Czech",
      "european":true,
      "code":"CS"
   },
   {
      "id":1439,
      "title":"Danish",
      "european":true,
      "code":"DA"
   },
   {
      "id":1440,
      "title":"German",
      "european":true,
      "code":"DE"
   },
   {
      "id":1441,
      "title":"Greek",
      "european":true,
      "code":"EL"
   },
   {
      "id":1442,
      "title":"English",
      "european":true,
      "code":"EN"
   },
   {
      "id":1443,
      "title":"Spanish",
      "european":true,
      "code":"ES"
   },
   {
      "id":1444,
      "title":"Estonian",
      "european":true,
      "code":"ET"
   },
   {
      "id":1445,
      "title":"Finnish",
      "european":true,
      "code":"FI"
   },
   {
      "id":1446,
      "title":"French",
      "european":true,
      "code":"FR"
   },
   {
      "id":1447,
      "title":"Irish",
      "european":true,
      "code":"GA"
   },
   {
      "id":1448,
      "title":"Hungarian",
      "european":true,
      "code":"HU"
   },
   {
      "id":1449,
      "title":"Italian",
      "european":true,
      "code":"IT"
   },
   {
      "id":1450,
      "title":"Lithuanian",
      "european":true,
      "code":"LT"
   },
   {
      "id":1451,
      "title":"Latvian",
      "european":true,
      "code":"LV"
   },
   {
      "id":1452,
      "title":"Maltese",
      "european":true,
      "code":"MT"
   },
   {
      "id":1453,
      "title":"Dutch",
      "european":true,
      "code":"NL"
   },
   {
      "id":1454,
      "title":"Polish",
      "european":true,
      "code":"PL"
   },
   {
      "id":1455,
      "title":"Portuguese",
      "european":true,
      "code":"PT"
   },
   {
      "id":1456,
      "title":"Romanian",
      "european":true,
      "code":"RO"
   },
   {
      "id":1457,
      "title":"Slovak",
      "european":true,
      "code":"SK"
   },
   {
      "id":1458,
      "title":"Slovenian",
      "european":true,
      "code":"SL"
   },
   {
      "id":1459,
      "title":"Swedish",
      "european":true,
      "code":"SV"
   },
   {
      "id":1460,
      "title":"Aragonese",
      "european":true,
      "code":"AN"
   },
   {
      "id":1461,
      "title":"Breton",
      "european":true,
      "code":"BR"
   },
   {
      "id":1462,
      "title":"Bosnian",
      "european":true,
      "code":"BS"
   },
   {
      "id":1463,
      "title":"Catalan",
      "european":true,
      "code":"CA"
   },
   {
      "id":1464,
      "title":"Corsican",
      "european":true,
      "code":"CO"
   },
   {
      "id":1465,
      "title":"Welsh",
      "european":true,
      "code":"CY"
   },
   {
      "id":1466,
      "title":"Basque",
      "european":true,
      "code":"EU"
   },
   {
      "id":1467,
      "title":"Faroese",
      "european":true,
      "code":"FO"
   },
   {
      "id":1468,
      "title":"Gaelic (Scots)",
      "european":true,
      "code":"GD"
   },
   {
      "id":1469,
      "title":"Gallegan",
      "european":true,
      "code":"GL"
   },
   {
      "id":1470,
      "title":"Limburgan",
      "european":true,
      "code":"LI"
   },
   {
      "id":1471,
      "title":"Norwegian Bokmael",
      "european":true,
      "code":"NB"
   },
   {
      "id":1472,
      "title":"Norwegian Nynorsk",
      "european":true,
      "code":"NN"
   },
   {
      "id":1473,
      "title":"Sardinian",
      "european":true,
      "code":"SC"
   },
   {
      "id":1474,
      "title":"Walloon",
      "european":true,
      "code":"WA"
   },
   {
      "id":1475,
      "title":"Church Slavic",
      "european":true,
      "code":"CU"
   },
   {
      "id":1476,
      "title":"Occitan (post 1500)",
      "european":true,
      "code":"OC"
   },
   {
      "id":1477,
      "title":"Raeto-Romance",
      "european":true,
      "code":"RM"
   },
   {
      "id":1478,
      "title":"Latin",
      "european":true,
      "code":"LA"
   },
   {
      "id":1479,
      "title":"Croatian",
      "european":true,
      "code":"HR"
   },
   {
      "id":1480,
      "title":"Icelandic",
      "european":true,
      "code":"IS"
   },
   {
      "id":1481,
      "title":"Letzeburgesch",
      "european":true,
      "code":"LB"
   },
   {
      "id":1482,
      "title":"Macedonian",
      "european":true,
      "code":"MK"
   },
   {
      "id":1483,
      "title":"Moldavian",
      "european":true,
      "code":"MO"
   },
   {
      "id":1484,
      "title":"Montenegrin",
      "european":true,
      "code":"ME"
   },
   {
      "id":1485,
      "title":"Norwegian",
      "european":true,
      "code":"NO"
   },
   {
      "id":1486,
      "title":"Russian",
      "european":true,
      "code":"RU"
   },
   {
      "id":1487,
      "title":"Serbo-Croatian",
      "european":true,
      "code":"SH"
   },
   {
      "id":1488,
      "title":"Albanian",
      "european":true,
      "code":"SQ"
   },
   {
      "id":1489,
      "title":"Serbian",
      "european":true,
      "code":"SR"
   },
   {
      "id":1490,
      "title":"Turkish",
      "european":true,
      "code":"TR"
   },
   {
      "id":1491,
      "title":"Ukrainian",
      "european":true,
      "code":"UK"
   },
   {
      "id":1492,
      "title":"Afar",
      "european":false,
      "code":"AA"
   },
   {
      "id":1493,
      "title":"Abkhazian",
      "european":false,
      "code":"AB"
   },
   {
      "id":1494,
      "title":"Avestan",
      "european":false,
      "code":"AE"
   },
   {
      "id":1495,
      "title":"Afrikaans",
      "european":false,
      "code":"AF"
   },
   {
      "id":1496,
      "title":"Akan",
      "european":false,
      "code":"AK"
   },
   {
      "id":1497,
      "title":"Amharic",
      "european":false,
      "code":"AM"
   },
   {
      "id":1498,
      "title":"Arabic",
      "european":false,
      "code":"AR"
   },
   {
      "id":1499,
      "title":"Assamese",
      "european":false,
      "code":"AS"
   },
   {
      "id":1500,
      "title":"Avaric",
      "european":false,
      "code":"AV"
   },
   {
      "id":1501,
      "title":"Aymara",
      "european":false,
      "code":"AY"
   },
   {
      "id":1502,
      "title":"Azerbaijani",
      "european":false,
      "code":"AZ"
   },
   {
      "id":1503,
      "title":"Bashkir",
      "european":false,
      "code":"BA"
   },
   {
      "id":1504,
      "title":"Belarusian",
      "european":false,
      "code":"BE"
   },
   {
      "id":1505,
      "title":"Bihari",
      "european":false,
      "code":"BH"
   },
   {
      "id":1506,
      "title":"Bislama",
      "european":false,
      "code":"BI"
   },
   {
      "id":1507,
      "title":"Bambara",
      "european":false,
      "code":"BM"
   },
   {
      "id":1508,
      "title":"Bengali",
      "european":false,
      "code":"BN"
   },
   {
      "id":1509,
      "title":"Tibetan",
      "european":false,
      "code":"BO"
   },
   {
      "id":1510,
      "title":"Chechen",
      "european":false,
      "code":"CE"
   },
   {
      "id":1511,
      "title":"Chamorro",
      "european":false,
      "code":"CH"
   },
   {
      "id":1512,
      "title":"Cree",
      "european":false,
      "code":"CR"
   },
   {
      "id":1513,
      "title":"Chuvash",
      "european":false,
      "code":"CV"
   },
   {
      "id":1514,
      "title":"Divehi",
      "european":false,
      "code":"DV"
   },
   {
      "id":1515,
      "title":"Dzongkha",
      "european":false,
      "code":"DZ"
   },
   {
      "id":1516,
      "title":"Ewe",
      "european":false,
      "code":"EE"
   },
   {
      "id":1517,
      "title":"Persian",
      "european":false,
      "code":"FA"
   },
   {
      "id":1518,
      "title":"Fulah",
      "european":false,
      "code":"FF"
   },
   {
      "id":1519,
      "title":"Fijian",
      "european":false,
      "code":"FJ"
   },
   {
      "id":1520,
      "title":"Frisian",
      "european":false,
      "code":"FY"
   },
   {
      "id":1521,
      "title":"Guarani",
      "european":false,
      "code":"GN"
   },
   {
      "id":1522,
      "title":"Gujarati",
      "european":false,
      "code":"GU"
   },
   {
      "id":1523,
      "title":"Manx",
      "european":false,
      "code":"GV"
   },
   {
      "id":1524,
      "title":"Hebrew",
      "european":false,
      "code":"HE"
   },
   {
      "id":1525,
      "title":"Hindi",
      "european":false,
      "code":"HI"
   },
   {
      "id":1526,
      "title":"Hiri Motu",
      "european":false,
      "code":"HO"
   },
   {
      "id":1527,
      "title":"Haitian",
      "european":false,
      "code":"HT"
   },
   {
      "id":1528,
      "title":"Armenian",
      "european":false,
      "code":"HY"
   },
   {
      "id":1529,
      "title":"Herero",
      "european":false,
      "code":"HZ"
   },
   {
      "id":1530,
      "title":"Indonesian",
      "european":false,
      "code":"ID"
   },
   {
      "id":1531,
      "title":"Interlingue",
      "european":false,
      "code":"IE"
   },
   {
      "id":1532,
      "title":"Igbo",
      "european":false,
      "code":"IG"
   },
   {
      "id":1533,
      "title":"Sichuan Yi",
      "european":false,
      "code":"II"
   },
   {
      "id":1534,
      "title":"Inupiaq",
      "european":false,
      "code":"IK"
   },
   {
      "id":1535,
      "title":"Ido",
      "european":false,
      "code":"IO"
   },
   {
      "id":1536,
      "title":"Inuktitut",
      "european":false,
      "code":"IU"
   },
   {
      "id":1537,
      "title":"Japanese",
      "european":false,
      "code":"JA"
   },
   {
      "id":1538,
      "title":"Javanese",
      "european":false,
      "code":"JV"
   },
   {
      "id":1539,
      "title":"Georgian",
      "european":false,
      "code":"KA"
   },
   {
      "id":1540,
      "title":"Kongo",
      "european":false,
      "code":"KG"
   },
   {
      "id":1541,
      "title":"Kikuyu",
      "european":false,
      "code":"KI"
   },
   {
      "id":1542,
      "title":"Kuanyama",
      "european":false,
      "code":"KJ"
   },
   {
      "id":1543,
      "title":"Kazakh",
      "european":false,
      "code":"KK"
   },
   {
      "id":1544,
      "title":"Kalaallisut",
      "european":false,
      "code":"KL"
   },
   {
      "id":1545,
      "title":"Khmer",
      "european":false,
      "code":"KM"
   },
   {
      "id":1546,
      "title":"Kannada",
      "european":false,
      "code":"KN"
   },
   {
      "id":1547,
      "title":"Korean",
      "european":false,
      "code":"KO"
   },
   {
      "id":1548,
      "title":"Kanuri",
      "european":false,
      "code":"KR"
   },
   {
      "id":1549,
      "title":"Kashmiri",
      "european":false,
      "code":"KS"
   },
   {
      "id":1550,
      "title":"Kurdish",
      "european":false,
      "code":"KU"
   },
   {
      "id":1551,
      "title":"Komi",
      "european":false,
      "code":"KV"
   },
   {
      "id":1552,
      "title":"Cornish",
      "european":false,
      "code":"KW"
   },
   {
      "id":1553,
      "title":"Kirghiz",
      "european":false,
      "code":"KY"
   },
   {
      "id":1554,
      "title":"Ganda",
      "european":false,
      "code":"LG"
   },
   {
      "id":1555,
      "title":"Lingala",
      "european":false,
      "code":"LN"
   },
   {
      "id":1556,
      "title":"Lao",
      "european":false,
      "code":"LO"
   },
   {
      "id":1557,
      "title":"Luba-Katanga",
      "european":false,
      "code":"LU"
   },
   {
      "id":1558,
      "title":"Malagasy",
      "european":false,
      "code":"MG"
   },
   {
      "id":1559,
      "title":"Marshall",
      "european":false,
      "code":"MH"
   },
   {
      "id":1560,
      "title":"Maori",
      "european":false,
      "code":"MI"
   },
   {
      "id":1561,
      "title":"Malayalam",
      "european":false,
      "code":"ML"
   },
   {
      "id":1562,
      "title":"Mongolian",
      "european":false,
      "code":"MN"
   },
   {
      "id":1563,
      "title":"Marathi",
      "european":false,
      "code":"MR"
   },
   {
      "id":1564,
      "title":"Malay",
      "european":false,
      "code":"MS"
   },
   {
      "id":1565,
      "title":"Burmese",
      "european":false,
      "code":"MY"
   },
   {
      "id":1566,
      "title":"Nauru",
      "european":false,
      "code":"NA"
   },
   {
      "id":1567,
      "title":"Ndebele, North",
      "european":false,
      "code":"ND"
   },
   {
      "id":1568,
      "title":"Nepali",
      "european":false,
      "code":"NE"
   },
   {
      "id":1569,
      "title":"Ndonga",
      "european":false,
      "code":"NG"
   },
   {
      "id":1570,
      "title":"Ndebele, South",
      "european":false,
      "code":"NR"
   },
   {
      "id":1571,
      "title":"Navajo",
      "european":false,
      "code":"NV"
   },
   {
      "id":1572,
      "title":"Chichewa",
      "european":false,
      "code":"NY"
   },
   {
      "id":1573,
      "title":"Ojibwa",
      "european":false,
      "code":"OJ"
   },
   {
      "id":1574,
      "title":"Oromo",
      "european":false,
      "code":"OM"
   },
   {
      "id":1575,
      "title":"Oriya",
      "european":false,
      "code":"OR"
   },
   {
      "id":1576,
      "title":"Ossetian",
      "european":false,
      "code":"OS"
   },
   {
      "id":1577,
      "title":"Panjabi",
      "european":false,
      "code":"PA"
   },
   {
      "id":1578,
      "title":"Pali",
      "european":false,
      "code":"PI"
   },
   {
      "id":1579,
      "title":"Pushto",
      "european":false,
      "code":"PS"
   },
   {
      "id":1580,
      "title":"Quechua",
      "european":false,
      "code":"QU"
   },
   {
      "id":1581,
      "title":"Rundi",
      "european":false,
      "code":"RN"
   },
   {
      "id":1582,
      "title":"Kinyarwanda",
      "european":false,
      "code":"RW"
   },
   {
      "id":1583,
      "title":"Sanskrit",
      "european":false,
      "code":"SA"
   },
   {
      "id":1584,
      "title":"Sindhi",
      "european":false,
      "code":"SD"
   },
   {
      "id":1585,
      "title":"Northern Sami",
      "european":false,
      "code":"SE"
   },
   {
      "id":1586,
      "title":"Sango",
      "european":false,
      "code":"SG"
   },
   {
      "id":1587,
      "title":"Sinhalese",
      "european":false,
      "code":"SI"
   },
   {
      "id":1588,
      "title":"Samoan",
      "european":false,
      "code":"SM"
   },
   {
      "id":1589,
      "title":"Shona",
      "european":false,
      "code":"SN"
   },
   {
      "id":1590,
      "title":"Somali",
      "european":false,
      "code":"SO"
   },
   {
      "id":1591,
      "title":"Swati",
      "european":false,
      "code":"SS"
   },
   {
      "id":1592,
      "title":"Sotho, Southern",
      "european":false,
      "code":"ST"
   },
   {
      "id":1593,
      "title":"Sundanese",
      "european":false,
      "code":"SU"
   },
   {
      "id":1594,
      "title":"Swahili",
      "european":false,
      "code":"SW"
   },
   {
      "id":1595,
      "title":"Tamil",
      "european":false,
      "code":"TA"
   },
   {
      "id":1596,
      "title":"Telugu",
      "european":false,
      "code":"TE"
   },
   {
      "id":1597,
      "title":"Tajik",
      "european":false,
      "code":"TG"
   },
   {
      "id":1598,
      "title":"Thai",
      "european":false,
      "code":"TH"
   },
   {
      "id":1599,
      "title":"Turkmen",
      "european":false,
      "code":"TK"
   },
   {
      "id":1600,
      "title":"Tagalog",
      "european":false,
      "code":"TL"
   },
   {
      "id":1601,
      "title":"Tswana",
      "european":false,
      "code":"TN"
   },
   {
      "id":1602,
      "title":"Tsonga",
      "european":false,
      "code":"TS"
   },
   {
      "id":1603,
      "title":"Tatar",
      "european":false,
      "code":"TT"
   },
   {
      "id":1604,
      "title":"Twi",
      "european":false,
      "code":"TW"
   },
   {
      "id":1605,
      "title":"Tahitian",
      "european":false,
      "code":"TY"
   },
   {
      "id":1606,
      "title":"Uighur",
      "european":false,
      "code":"UG"
   },
   {
      "id":1607,
      "title":"Un-identified",
      "european":false,
      "code":"UN"
   },
   {
      "id":1608,
      "title":"Urdu",
      "european":false,
      "code":"UR"
   },
   {
      "id":1609,
      "title":"Uzbek",
      "european":false,
      "code":"UZ"
   },
   {
      "id":1610,
      "title":"Venda",
      "european":false,
      "code":"VE"
   },
   {
      "id":1611,
      "title":"Vietnamese",
      "european":false,
      "code":"VI"
   },
   {
      "id":1612,
      "title":"Volapok",
      "european":false,
      "code":"VO"
   },
   {
      "id":1613,
      "title":"Wolof",
      "european":false,
      "code":"WO"
   },
   {
      "id":1614,
      "title":"Xhosa",
      "european":false,
      "code":"XH"
   },
   {
      "id":1615,
      "title":"Yiddish",
      "european":false,
      "code":"YI"
   },
   {
      "id":1616,
      "title":"Zhuang",
      "european":false,
      "code":"ZA"
   },
   {
      "id":1617,
      "title":"Chinese",
      "european":false,
      "code":"ZH"
   },
   {
      "id":1618,
      "title":"Zulu",
      "european":false,
      "code":"ZU"
   }
]

export { languagesvalues, CEFRvalues }
