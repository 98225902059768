import { gql } from "@apollo/client";

const GET_SETTINGS = gql`
query ewpConnections($erasmusCode: String,$heiID: String)
    {ewpConnections(erasmusCode: $erasmusCode,heiID:$heiID){
        api_iia
        api_ola
        ewp_api_iia 
    }

    
}`
;

export default GET_SETTINGS;
