export const StatusMapping = {
  "preliminary_unsigned": "Unsigned",
  "preliminary_signed-by-student": "Signed by student",
  "preliminary_signed-by-student-sending": "Signed by student/sending",
  "preliminary_signed-by-student-sending-receiving":
    "Signed by student/sending/receiving",
  "final_unsigned": "Changes unsigned",
  "final_signed-by-student": "Changes signed by student",
  "final_signed-by-student-sending": "Changes signed by student/sending",
  "final_signed-by-student-sending-receiving":
    "Changes signed by student/sending/receiving",
  0: "Pending",
  1: "Accepted",
  "-1": "Declined",
  "draft":"Draft",
  "submitted-p1": "Submitted by you",
  "approved-p1": "Approved by you",
  "submitted-p2": "Submitted by partner",
  "approved-p2":
    "Approved by partner",
  "approved-by-all": "Approved by all",
  "terminated": "Terminated",
  "terminated-submitted-p1": "Terminated by you",
  "terminated-submitted-p2": "Terminated by partner",
  "terminated-approved-p1": "Termination Approved by you",
  "terminated-approved-p2": "Termination Approved by partner",
  "terminated-approved-by-all": "Termination Approved by all"
};
