import { Row, Col, Container } from 'react-bootstrap';

const InstitutionInfoTable = ({ institution, contact_person, resp_person, size }) => {

    return (
        <Container>
            <Row style={{ paddingLeft: 10,paddingBottom: 15 }}>
                <Col>
                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Name:</Col><Col><div dangerouslySetInnerHTML={{__html: institution?.field_hei_name}}/></Col></Row>
                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Country:</Col><Col>{institution?.field_hei_country_label}</Col></Row>
                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Erasmus Code:</Col><Col>{institution?.field_hei_erasmus_code}</Col></Row>
                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Address:</Col><Col>{institution?.field_hei_address}</Col></Row>
                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Faculty:</Col><Col>{institution?.field_hei_faculty}</Col></Row>
                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Contact Person:</Col><Col>{contact_person?.map(c => ([c?.field_per_firstname + " " + c?.field_per_lastname, c?.field_per_email, c?.field_per_phone].filter(e => e !== null && e !== "null null").toString().replace(",", ", ")))}</Col></Row>
                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Res. Person:</Col><Col>{resp_person?.map(c => ([c?.field_per_firstname + " " + c?.field_per_lastname, c?.field_per_email, c?.field_per_phone].filter(e => e !== null && e !== "null null").toString().replace(",", ", ")))}</Col></Row>
                </Col>
            </Row>
        </Container>
    )
}

export default InstitutionInfoTable;