import { useNavigate } from "react-router-dom";
import "./index.scss";

const BasicFooter = ({ children }) => {
  const navigate = useNavigate();
  return (
    <footer className="basic text-center h-auto d-flex flex-column justify-content-evenly mt-5 px-3">
      {children}
      <div className="body">
        <div>
          Discover general information about the EWP Dashboard and other
          frequently asked questions{" "}
          <a
            className="text-decoration-none footer-bold-link"
            href="https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/20676644/Enhanced+EWP+Dashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </div>
        <div>
          Find the updated Erasmus Dashboard Privacy Policy and Terms and
          Conditions{" "}
          <span
            className="text-decoration-none footer-bold-link"
            // href="/privacy-policy-terms-conditions"
            onClick={() => navigate("/privacy-policy-terms-conditions", { replace: true })}
            // target="_blank"
            // rel="noopener noreferrer"
          >
            here
          </span>
        </div>
        <div>
          For any other issue, don’t hesitate to{" "}
          <a
            className="text-decoration-none footer-bold-link"
            href="https://esci-sd.atlassian.net/servicedesk/customer/portals"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact our support team
          </a>
        </div>
      </div>
    </footer>
  );
};

export default BasicFooter;
