import { gql } from "@apollo/client";

const GET_IIA = gql`
    query iia($id: String!){
        iia(id: $id) {
            changed_time
            last_author
            type_of_communication
            terminated
            valid_for_approval
            partner{
                iia_id
                institution{
                  name
                  country
                  erasmusCode
                  heiID
                }
                ounit_name
                signing_date
                iia_id
                signing_contact{
                  emails
                  phone_numbers {
                    e164
                  }
                  person_given_names 
                  contact_names
                }
                contact{
                    emails
                    phone_numbers {
                      e164
                    }
                    person_given_names
                    contact_names
                  }
                pdf
              }
              status
              cooperation_conditions{
                staff_teachers{
                    receiving_ounit_name
                              sending_ounit_name
                    receiving_institution {
                        heiID
                        name
                      }
                      sending_institution {
                        heiID
                        name
                      }
                  mobilities_per_year
                  other_info_terms
                  receiving_acad_year
                  receiving_contact {
                    emails
                    person_given_names 
                    phone_numbers {
                      e164
                    }
                  }
                  receiving_hei_id
                  receiving_ounit_id
                  language_skill {
                    cefr_level
                    isced_f_code
                    isced_clarification
                    language
                  }
                  sending_contact{
                    emails
                    person_given_names 
                    contact_names
                    phone_numbers {
                        e164
                      }
                  }
                  sending_hei_id
                  sending_ounit_id
                  subject_area {
                    isced_f_code
                    isced_clarification
                  }
                  total_days_per_year
                    
                  
                }
                staff_trainings{
                    receiving_institution {
                        heiID
                        name
                      }
                      sending_institution {
                        heiID
                        name
                      }
                      receiving_ounit_name
                              sending_ounit_name
                    mobilities_per_year
                    other_info_terms
                    receiving_acad_year
                    receiving_contact {
                      emails
                      person_given_names 
                      phone_numbers {
                        e164
                      }
                    }
                    receiving_hei_id
                    receiving_ounit_id
                    language_skill {
                      cefr_level
                      isced_f_code
                      isced_clarification
                      language
                    }
                    sending_contact{
                      emails
                      person_given_names 
                      contact_names
                      phone_numbers {
                        e164
                      }
                    }
                    sending_hei_id
                    sending_ounit_id
                    subject_area {
                      isced_f_code
                      isced_clarification
                    }
                    total_days_per_year
                      
                    }
                    student_studies{
                        receiving_institution {
                            heiID
                            name
                          }
                          sending_institution {
                            heiID
                            name
                          }
                          receiving_ounit_name
                              sending_ounit_name
                        blended
                        mobilities_per_year
                        other_info_terms
                        eqf_level
                        receiving_acad_year
                        
                        total_months_per_year
                        receiving_contact {
                          emails
                          person_given_names 
                          phone_numbers {
                            e164
                          }
                        }
                        receiving_hei_id
                        receiving_ounit_id
                        language_skill {
                          cefr_level
                          isced_f_code
                          isced_clarification
                          language
                        }
                        sending_contact{
                          emails
                          person_given_names 
                          contact_names
                          phone_numbers {
                            e164
                          }
                        }
                        sending_hei_id
                        sending_ounit_id
                        subject_area {
                          isced_f_code
                          isced_clarification
                        }
                          
                        }
                        student_traineeships{
                            receiving_institution {
                                heiID
                                name
                              }
                              sending_institution {
                                heiID
                                name
                              }
                              receiving_ounit_name
                              sending_ounit_name
                            blended
                            mobilities_per_year
                            total_months_per_year
                            other_info_terms
                            eqf_level
                            receiving_acad_year
                            
                            receiving_contact {
                              emails
                              person_given_names 
                              phone_numbers {
                                e164
                              }
                            }
                            receiving_hei_id
                            receiving_ounit_id
                            language_skill {
                              cefr_level
                              isced_f_code
                              isced_clarification
                              language
                            }
                            sending_contact{
                              emails
                              person_given_names 
                              contact_names
                              phone_numbers {
                                e164
                              }
                            }
                            sending_hei_id
                            sending_ounit_id
                            subject_area {
                              isced_f_code
                              isced_clarification
                            }
                              
                            }
              }
        }
    }`
;

export default GET_IIA;
