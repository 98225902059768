import { gql } from "@apollo/client";

const GET_CITIES = gql`
  query cities($countryLink: String) {
    cities(CountryLink: $countryLink) {
      ID
      CityName
      CityLink
    }
  }
`;

export default GET_CITIES;
