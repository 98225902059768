import { useState } from 'react';
import { Accordion, Button, Col, Form, Overlay, Row, Table, Tooltip } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import withFooter from '../../../components/WithFooter';
import withNavBar from '../../../components/WithNavBar';
import withTitle from "../../../components/withTitle";
import useBreakpoint from '../../../hooks/useBreakpoint';
import "./index.scss"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CustomButton from '../../../components/CustomButton';
import { faFloppyDisk, faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../components/IconButton';
import Select from '../../../components/Select';
import GET_APPICATION_SETTINGS from '../../../graphql/queries/nomination/getApplicationSettings';
import { useMutation, useQuery } from '@apollo/client';
import { CEFRvalues, languagesvalues } from './Taxonomy';
import APPLICATION_SETTINGS from '../../../graphql/mutations/nomination/applicationSettings';
import OUTGOING_STUDENTS_REQUIREMENTS from '../../../graphql/mutations/nomination/outgoingStudentsRequirements';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from '../../../data/toastConfiguration';

const maxHeiNumbers = Array.from({ length: 20 }, (_, i) => i + 1)


const RequirementsNominations = () => {
    const [ size ] = useBreakpoint();

    const [ectsErrorMessage, setEctsErrorMessage] = useState('')
    const [iscedErrorMessage, setIscedErrorMessage] = useState('')
    const [eqfErrorMessage, setEqfErrorMessage] = useState('')
    const [cvErrorMessage, setCvErrorMessage] = useState('')
    const [motivLetterErrorMessage, setMotivLetterErrorMessage] = useState('')
    const [torErrorMessage, setTorErrorMessage] = useState('')
    const [extCertErrorMessage, setExtCertErrorMessage] = useState('')
    const [recomLetterErrorMessage, setRecomLetterErrorMessage] = useState('')
    const [otherDocErrorMessage, setOtherDocErrorMessage] = useState('')
    const [otherReqErrorMessage, setOtherReqErrorMessage] = useState('')
    const [langLevelErrorMessage, setLangLevelErrorMessage] = useState('')
    const [refContactErrorMessage, setRefContactErrorMessage] = useState('')
    const [portfolioErrorMessage, setPortfolioErrorMessage] = useState('')
    const [winterStartDate, setWinterStartDate] = useState("")
    const [winterEndDate, setWinterEndDate] = useState("")
    const [summerStartDate, setSummerStartDate] = useState("")
    const [summerEndDate, setSummerEndDate] = useState("")
    const [cvURL, setCvURL] = useState("")
    const [cvDescription, setCvDescription] = useState("")
    const [cvRequired, setCvRequired] = useState(false)
    const [motivLetterURL, setMotivLetterURL] = useState("")
    const [motivLetterDescription, setMotivLetterDescription] = useState("")
    const [motivLetterRequired, setMotivLetterRequired] = useState(false)
    const [torURL, setTorURL] = useState("")
    const [torDescription, setTorDescription] = useState("")
    const [torRequired, setTorRequired] = useState(false)
    const [extCertURL, setExtCertURL] = useState("")
    const [extCertDescription, setExtCertDescription] = useState("")
    const [extCertRequired, setExtCertRequired] = useState(false)
    const [langRequired, setLangRequired] = useState("")
    const [recomLetterURL, setRecomLetterURL] = useState("")
    const [recomLetterDescription, setRecomLetterDescription] = useState("")
    const [recomLetterRequired, setRecomLetterRequired] = useState(false)
    const [otherDocRequired, setOtherDocRequired] = useState(false)
    const [maxHEInumber, setMaxHEInumber] = useState("")
    const [portfolioURL, setPortfolioURL] = useState("")
    const [portfolioDescription, setPortfolioDescription] = useState("")
    const [portfolioRequired, setPortfolioRequired] = useState(false)
    const [referenceContactURL, setReferenceContactURL] = useState("")
    const [referenceContactDescription, setReferenceContactDescription] = useState("")
    const [referenceContactRequired, setReferenceContactRequired] = useState(false)
    const [otherRequirementRequired, setOtherRequirementRequired] = useState(false)
    const [languageArray, setLanguageArray] = useState([])
    const [otherDocumentArray, setOtherDocumentArray] = useState([{
        uuid: "",
        isRequired: 0,
        url: "",
        description: ""
    }]);
    const [otherRequirementArray, setOtherRequirementArray] = useState([{
        uuid: "",
        isRequired: 0,
        url: "",
        description: ""
    }]);

    // Mutation for Application Settings
    const [updateApplicationSettings, { loading: loadingUpdateApplicationSettings }] = useMutation(APPLICATION_SETTINGS);

    const [updateApplicationRequirements, { loading: loadingUpdateOutgoingRequirements }] = useMutation(OUTGOING_STUDENTS_REQUIREMENTS);

    const { data, error, loading, refetch: refetchQuery } = useQuery(GET_APPICATION_SETTINGS, {
        
        // When request is completed, we set the state
        onCompleted: (data) =>  {

            console.log(data);
            if (data?.applicationRequirements && data?.applicationRequirements?.winterStartDate) {
                let date_string = data.applicationRequirements.winterStartDate + "/2022"; // Apr 03 2020
                let parts_of_date = date_string.split("/");
                let output = new Date(+parts_of_date[2], parts_of_date[1] - 1, +parts_of_date[0]);
                setWinterStartDate(output)
            } else {
                setWinterStartDate(data?.applicationRequirements?.winterStartDate)
            }

            if (data?.applicationRequirements && data?.applicationRequirements?.winterEndDate) {
                let date_string2 = data.applicationRequirements.winterEndDate + "/2022"; // Apr 03 2020
                let parts_of_date2 = date_string2.split("/");
                let output2 = new Date(+parts_of_date2[2], parts_of_date2[1] - 1, +parts_of_date2[0]);
                setWinterEndDate(output2)
            } else {
                setWinterEndDate(data?.applicationRequirements?.winterEndDate)
            }

            if (data?.applicationRequirements && data?.applicationRequirements?.summerStartDate) {
                let date_string3 = data.applicationRequirements.summerStartDate + "/2022"; // Apr 03 2020
                let parts_of_date3 = date_string3.split("/");
                let output3 = new Date(+parts_of_date3[2], parts_of_date3[1] - 1, +parts_of_date3[0]);
                setSummerStartDate(output3)
            } else {
                setSummerStartDate(data?.applicationRequirements?.summerStartDate)
            }

            if (data?.applicationRequirements && data?.applicationRequirements?.summerEndDate) {
                let date_string4 = data.applicationRequirements.summerEndDate + "/2022"; // Apr 03 2020
                let parts_of_date4 = date_string4.split("/");
                let output4 = new Date(+parts_of_date4[2], parts_of_date4[1] - 1, +parts_of_date4[0]);
                setSummerEndDate(output4)
            } else {
                setSummerEndDate(data?.applicationRequirements?.summerEndDate)
            }

            setMaxHEInumber(data?.applicationRequirements.maxHEInumber)

            // Checkboxes
            setCvRequired(data.applicationRequirements.cvRequired)
            setCvDescription(data.applicationRequirements.cvDescription)
            setCvURL(data.applicationRequirements.cvURL)

            setMotivLetterRequired(data.applicationRequirements.motivLetterRequired)
            setMotivLetterDescription(data.applicationRequirements.motivLetterDescription)
            setMotivLetterURL(data.applicationRequirements.motivLetterURL)

            setTorRequired(data.applicationRequirements.torRequired)
            setTorDescription(data.applicationRequirements.torDescription)
            setTorURL(data.applicationRequirements.torURL)

            setRecomLetterRequired(data.applicationRequirements.recomLetterRequired)
            setRecomLetterDescription(data.applicationRequirements.recomLetterDescription)
            setRecomLetterURL(data.applicationRequirements.recomLetterURL)

            setExtCertRequired(data.applicationRequirements.extCertRequired)
            setExtCertDescription(data.applicationRequirements.extCertDescription)
            setExtCertURL(data.applicationRequirements.extCertURL)

            setReferenceContactRequired(data.applicationRequirements.referenceContactRequired)
            setReferenceContactDescription(data.applicationRequirements.referenceContactDescription)
            setReferenceContactURL(data.applicationRequirements.referenceContactURL)

            setPortfolioRequired(data.applicationRequirements.portfolioRequired)
            setPortfolioDescription(data.applicationRequirements.portfolioDescription)
            setPortfolioURL(data.applicationRequirements.portfolioURL)

            setLanguageArray(data.applicationRequirements.languageArray)
            if (data.applicationRequirements && data.applicationRequirements.languageArray.length >= 1) {
                setLangRequired(true)
            }

            setOtherRequirementArray(data.applicationRequirements.otherRequirementArray)
            if (data.applicationRequirements && data.applicationRequirements.otherRequirementArray.length >= 1) {
                setOtherRequirementRequired(true)
            }

            setOtherDocumentArray(data.applicationRequirements.otherDocumentArray)
            if (data.applicationRequirements && data.applicationRequirements.otherDocumentArray.length >= 1) {
                setOtherDocRequired(true)
            }

        }
    });

    const handleAddOtherDocumentsFields = () => {
        const values = [...otherDocumentArray];
        values.push({ url: "", description: "", isRequired: 1 });
        setOtherDocumentArray(values);
    }

    const handleRemoveOtherDocumentsFields = (index) => {
        let values = otherDocumentArray.map((item) => ({
            ...item, 
            
          }))

        values.splice(index, 1);
        setOtherDocumentArray(values);
    }

    const handleInputOtherDocumentChange = (index, event) => {
        let values = otherDocumentArray.map((item) => ({
            ...item, 
          }))

        if (event.target.name === "url") {
            values[index].url = event.target.value;
        } else {
            values[index].description = event.target.value;
        }

        setOtherDocumentArray(values);
    }

    // Handle multiple Other Requirement
    const handleAddOtherRequirementFields = () => {
        const values = [...otherRequirementArray];
        values.push({ url: "", description: "", isRequired: 1 });
        setOtherRequirementArray(values);
    }

    const handleRemoveOtherRequirementFields = (index) => {
        const values = [...otherRequirementArray];
        values.splice(index, 1);
        setOtherRequirementArray(values);
    }

    const handleInputOtherRequirementChange = (index, event) => {
        let values = otherRequirementArray.map((item) => ({
            ...item, 
          }))
          if (event.target.name === "url") {
            values[index].url = event.target.value;
        } else {
            values[index].description = event.target.value;
        }

        setOtherRequirementArray(values);
    }


    // Handle multiple Language
    const handleAddLanguageFields = () => {
        const values = [...languageArray];
        values.push({ code: "", level: "", url: "", certRequired: false, certDescription: "", isRequired: 1 });
        setLanguageArray(values);
    }

    const handleRemoveLanguageFields = (index) => {
        let values = languageArray.map((item) => ({
            ...item, 
            
          }))
          values.splice(index, 1);
        
        setLanguageArray(values);
    }

    const handleInputLanguageLevelChange = (index, event) => {
        let values = languageArray.map((item) => ({
            ...item, 
            
          }))
        
        values[index].level = event.value;
        
        setLanguageArray(values);
    }

    const handleInputCertRequiredChange = (index, event) => {
        let values = languageArray.map((item) => ({
            ...item, 
          }))
        
        values[index].certRequired = !values[index].certRequired
        
        setLanguageArray(values);
    }

    const handleInputCertDescriptionChange = (index, event) => {
        let values = languageArray.map((item) => ({
            ...item, 
            
          }))
        values[index].certDescription = event.target.value;
        
        setLanguageArray(values);
    }

    const handleInputLanguageChange = (index, event) => {
        let values = languageArray.map((item) => ({
            ...item, 
            
          }))
        values[index].code = event.value;
        
        setLanguageArray(values);
    }

    const handleInputLanguageMoreInfoChange = (index, event) => {
        let values = languageArray.map((item) => ({
            ...item, 
            
          }))
        values[index].url = event.target.value;
        
        setLanguageArray(values);
    }


    const handleWinterStartChange = (date) => {
        setWinterStartDate(date);
    }

    const handleWinterEndChange = (date) => {
        setWinterEndDate(date);
    }

    const handleSummerStartChange = (date) => {
        setSummerStartDate(date);
    }

    const handleSummerEndChange = (date) => {
        setSummerEndDate(date);
    }

    const getValueForSelectField = (el) => {
        if (el === "" || el === null || el === undefined) {
          return null;
        } else {
          return [{ label: el }];
        }
      };

      const getValueFromSelectLanguage = (el) => {
        if (el === "" || el === null || el === undefined) {
            return null;
          } else {
            return languagesvalues.map((option) => {
                return option.code === el.code && ({ "label": option.title });
              });
          }
        
      };

      const getValueFromSelectLanguageLevel = (el) => {
        if (el === "" || el === null || el === undefined) {
            return null;
          } else {
            return CEFRvalues.map((option) => {
                return option.code === el.level && ({ "label": `${option.code}: ${option.title}` });
              });
          }
        
      };
    
    const handleSubmitApplicationSettings = async (event) => {
        event.preventDefault();
        try {
            const result = await updateApplicationSettings({
                variables: {
                    applicationSettingsInput: {
                        field_universities_number: maxHEInumber.toString(),
                        field_out_process_autumn_start: winterStartDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }),
                        field_out_process_autumn_end: winterEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }),
                        field_out_process_spring_start: summerStartDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }),
                        field_out_process_spring_end: summerEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' })
                    }
                }
            })
            if (!result?.errors) {
                toast.success("Application settings updated successfully.", toastConfiguration);
                refetchQuery();
            }
        } catch (error) {
            if (error) {
                toast.error("There was an error with updating application requirements.", toastConfiguration);
                alert("There was an error with updating application settings: " + error);
            }
        }

        
        refetchQuery();
    }

    const handleCancelApplicationSettings = async (event) => {
        event.preventDefault();

        await refetchQuery();
        if (data?.applicationRequirements && data?.applicationRequirements?.winterStartDate) {
            let date_string = data.applicationRequirements.winterStartDate + "/2022"; // Apr 03 2020
            let parts_of_date = date_string.split("/");
            let output = new Date(+parts_of_date[2], parts_of_date[1] - 1, +parts_of_date[0]);
            setWinterStartDate(output)
        } else {
            setWinterStartDate(data?.applicationRequirements?.winterStartDate)
        }

        if (data?.applicationRequirements && data?.applicationRequirements?.winterEndDate) {
            let date_string2 = data.applicationRequirements.winterEndDate + "/2022"; // Apr 03 2020
            let parts_of_date2 = date_string2.split("/");
            let output2 = new Date(+parts_of_date2[2], parts_of_date2[1] - 1, +parts_of_date2[0]);
            setWinterEndDate(output2)
        } else {
            setWinterEndDate(data?.applicationRequirements?.winterEndDate)
        }

        if (data?.applicationRequirements && data?.applicationRequirements?.summerStartDate) {
            let date_string3 = data.applicationRequirements.summerStartDate + "/2022"; // Apr 03 2020
            let parts_of_date3 = date_string3.split("/");
            let output3 = new Date(+parts_of_date3[2], parts_of_date3[1] - 1, +parts_of_date3[0]);
            setSummerStartDate(output3)
        } else {
            setSummerStartDate(data?.applicationRequirements?.summerStartDate)
        }

        if (data?.applicationRequirements && data?.applicationRequirements?.summerEndDate) {
            let date_string4 = data.applicationRequirements.summerEndDate + "/2022"; // Apr 03 2020
            let parts_of_date4 = date_string4.split("/");
            let output4 = new Date(+parts_of_date4[2], parts_of_date4[1] - 1, +parts_of_date4[0]);
            setSummerEndDate(output4)
        } else {
            setSummerEndDate(data?.applicationRequirements?.summerEndDate)
        }

        setMaxHEInumber(data?.applicationRequirements.maxHEInumber)
    }

    const handleCancelApplicationRequirements = async (event) => {
        event.preventDefault();

        await refetchQuery();
        setCvRequired(data.applicationRequirements.cvRequired)
        setCvDescription(data.applicationRequirements.cvDescription)
        setCvURL(data.applicationRequirements.cvURL)

        setMotivLetterRequired(data.applicationRequirements.motivLetterRequired)
        setMotivLetterDescription(data.applicationRequirements.motivLetterDescription)
        setMotivLetterURL(data.applicationRequirements.motivLetterURL)

        setTorRequired(data.applicationRequirements.torRequired)
        setTorDescription(data.applicationRequirements.torDescription)
        setTorURL(data.applicationRequirements.torURL)

        setRecomLetterRequired(data.applicationRequirements.recomLetterRequired)
        setRecomLetterDescription(data.applicationRequirements.recomLetterDescription)
        setRecomLetterURL(data.applicationRequirements.recomLetterURL)

        setExtCertRequired(data.applicationRequirements.extCertRequired)
        setExtCertDescription(data.applicationRequirements.extCertDescription)
        setExtCertURL(data.applicationRequirements.extCertURL)

        setReferenceContactRequired(data.applicationRequirements.referenceContactRequired)
        setReferenceContactDescription(data.applicationRequirements.referenceContactDescription)
        setReferenceContactURL(data.applicationRequirements.referenceContactURL)

        setPortfolioRequired(data.applicationRequirements.portfolioRequired)
        setPortfolioDescription(data.applicationRequirements.portfolioDescription)
        setPortfolioURL(data.applicationRequirements.portfolioURL)

        setLanguageArray(data.applicationRequirements.languageArray)
        if (data.applicationRequirements && data.applicationRequirements.languageArray.length >= 1) {
            setLangRequired(true)
        }
        else {
            setLangRequired(false)
        }

        setOtherRequirementArray(data.applicationRequirements.otherRequirementArray)
        if (data.applicationRequirements && data.applicationRequirements.otherRequirementArray.length >= 1) {
            setOtherRequirementRequired(true)
        }
        else {
            setOtherRequirementRequired(false)
        }

        setOtherDocumentArray(data.applicationRequirements.otherDocumentArray)
        if (data.applicationRequirements && data.applicationRequirements.otherDocumentArray.length >= 1) {
            setOtherDocRequired(true)
        }
        else {
            setOtherDocRequired(false)
        }
    }

    const handleSubmitApplicationRequirements = async (event) => {
        event.preventDefault();
        // construct language array
        let LanguageRequirementInput = [];
        let iterationCountLanguage = 0;
        let tempLangArray = languageArray
        if (!langRequired) {
            tempLangArray = languageArray.filter(language => language.hasOwnProperty("uuid"))
            setLanguageArray(tempLangArray)
        }

        LanguageRequirementInput = tempLangArray.map(language => ({
            uuid: (language.uuid !== undefined ? language.uuid : (iterationCountLanguage++).toString()),
            field_out_language: language.code, // when eworx ready, put CODE here. Right now we only have access to full name (English). Backend accepts code (EN)
            field_out_language_required: langRequired ? language.isRequired : false, // bulk delete all items if the external checkbox is false
            field_out_lang_cert_description: language.certDescription ? language.certDescription : "",
            field_out_lang_cert_required: (language.certRequired ? 1 : 0),
            field_out_language_level: language.level,
            field_out_language_url: { uri: language.url }
        }))
        //force empty, no other way to handle it
        //  if(langRequired == 0) {
        //    LanguageRequirementInput = [];
        //}
        // construct other requirements array
        let OtherRequirementInput = [];
        let iterationCountOtherReq = 0;
        let tempOtherRequirementArray = otherRequirementArray
        if (!otherRequirementRequired) {
            tempOtherRequirementArray = otherRequirementArray.filter(otherRequirement => otherRequirement.uuid !== undefined)
            setOtherRequirementArray(tempOtherRequirementArray)
        }
        OtherRequirementInput = tempOtherRequirementArray.map(otherRequirement => ({
            uuid: (otherRequirement.uuid !== undefined ? otherRequirement.uuid : (iterationCountOtherReq++).toString()),
            field_out_other_requirement_req: otherRequirementRequired == true ? otherRequirement.isRequired : 0,  // bulk delete all items if the external checkbox is false
            field_out_other_requirement_url: { uri: otherRequirement.url },
            field_out_other_requirement_d: otherRequirement.description ? otherRequirement.description : ""
        }))

        // construct other documents array
        let OtherDocumentInput = [];
        let iterationCountOtherDoc = 0;
        let tempOtherDocumentArray = otherDocumentArray
        if (!otherDocRequired) {
            tempOtherDocumentArray = otherDocumentArray.filter(otherDocument => otherDocument.uuid !== undefined)
            setOtherDocumentArray(tempOtherDocumentArray)
        }
        OtherDocumentInput = tempOtherDocumentArray.map(otherDocument => ({
            uuid: (otherDocument.uuid !== undefined ? otherDocument.uuid : (iterationCountOtherDoc++).toString()),
            field_out_other_document_req: otherDocRequired ? otherDocument.isRequired : 0,  // decide how to handle these fields, add them to state if necessary
            field_out_other_document_url: { uri: otherDocument.url },
            field_out_other_document_descr: otherDocument.description ? otherDocument.description : ""
        }))
        // console log arrays for testing
        try {
            const result = await updateApplicationRequirements({
                variables: {
                    applicationRequirementsInput: {
                        // flat fields
                        field_out_cv_description: cvDescription,
                        field_out_cv_required: cvRequired ? 1 : 0,
                        field_out_cv_url: { uri: cvURL },
                        field_out_motiv_letter_descr: motivLetterDescription,
                        field_out_motiv_letter_required: motivLetterRequired ? 1 : 0,
                        field_out_motiv_letter_url: { uri: motivLetterURL },
                        field_out_transcript_description: torDescription,
                        field_out_transcript_required: torRequired ? 1 : 0,
                        field_out_transcript_url: { uri: torURL },
                        field_out_recom_letter_descr: recomLetterDescription,
                        field_out_recom_letter_required: recomLetterRequired ? 1 : 0,
                        field_out_recom_letter_url: { uri: recomLetterURL },
                        field_out_ext_cert_description: extCertDescription,
                        field_out_ext_cert_required: extCertRequired ? 1 : 0,
                        field_out_ext_cert_url: { uri: extCertURL },
                        field_out_reference_description: referenceContactDescription,
                        field_out_reference_required: referenceContactRequired ? 1 : 0,
                        field_out_reference_url: { uri: referenceContactURL },
                        field_out_portfolio_description: portfolioDescription,
                        field_out_portfolio_required: portfolioRequired ? 1 : 0,
                        field_out_portfolio_url: { uri: portfolioURL },
                        field_out_language_requirements: LanguageRequirementInput,
                        field_out_other_requirements: OtherRequirementInput,
                        field_out_other_documents: OtherDocumentInput
                    }
                }
            })
            if (!result?.errors) {
                toast.success("Application requirements updated successfully.", toastConfiguration);
                refetchQuery();
            }
        } catch (error) {
            if (error) {
                toast.error("There was an error with updating application requirements.", toastConfiguration);
                alert("There was an error with updating application requirements: " + error);
            }
        }

        
        refetchQuery();
    }


  return (
    <>
    
    <Row style={!(size === "xs" || size === "sm" || size === "md") ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 82}}>
            <Tabs
            id="second-tab"
            >
            <Tab eventKey="general-info" id="general-info" title="Application Settings" style={{background: "#EAECF3"}}>
            <Row >
                <Col style={{marginLeft: 15, marginTop: 20}}>
                <div className='h5-style'>Application Settings</div>
                <div className='body' style={{color: "#1F1F1F"}}>Please choose the maximum number of Host HEIs for your Outgoing Students and set the deadlines for the Applications</div>
                <Form
                    onSubmit={(e) => console.log("handle submit")}
                    validated={false}
                    noValidate
                    style={{marginTop: 7}}
                >
                    <Form.Group
                        
                        controlId="formInstitutionCountry"
                        className={!(size === "xs" || size === "sm" || size === "md") ? "col-3" : "col-12"}
                        >
                        <Form.Label>Number Of HEIs <span style={{color: "red"}}>*</span></Form.Label>
                        <Select
                            options={maxHeiNumbers?.map((el) => ({
                                value: el,
                                label: el}))}
                            value={getValueForSelectField(maxHEInumber)} placeholder="Select number of HEIs" onChange={(event) => setMaxHEInumber(event.value)}
                            // invalid
                            // validated
                            
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select a country for your institution
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className='body' style={{color: "#6C757D"}}>Max number of priorities of host HEI per student application</div>
                    <div style={{color: "#1F1F1F", marginTop: 26}} className='h6-style'>Autumn/Winter Semester Application Period</div>
                    <Row>
                    <Col lg={4} xs={12}>
                    <Form.Group className="col-12" controlId="formBasicEmail">
                        <Form.Label>Start Date <span style={{color: "red"}}>*</span></Form.Label>
                        
                            <DatePicker selected={winterStartDate} dateFormat="dd/MM" onChange={(date) => handleWinterStartChange(date)} className="form-control" popperPlacement='bottom'/>

                        <Form.Control.Feedback type="invalid">
                            Please provide a valid value prompt.
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    </Col>
                    
                    <Col lg={4}>
                    <Form.Group className="col-12" controlId="formBasicEmail">
                        <Form.Label>End Date <span style={{color: "red"}}>*</span></Form.Label>
                        <DatePicker selected={winterEndDate} dateFormat="dd/MM" onChange={(date) => handleWinterEndChange(date)} className="form-control" popperPlacement='bottom'/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid value prompt.
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    </Row>
                    <div style={{color: "#1F1F1F", marginTop: 15}} className='h6-style'>Spring/Summer Semester Application Period</div>
                    <Row>
                    <Col lg={4}>
                    <Form.Group className="col-12" controlId="formBasicEmail">
                        <Form.Label>Start Date <span style={{color: "red"}}>*</span></Form.Label>
                        
                            <DatePicker selected={summerStartDate} dateFormat="dd/MM"
                                            onChange={(date) => handleSummerStartChange(date)} className="form-control" popperPlacement='bottom'/>

                        <Form.Control.Feedback type="invalid">
                            Please provide a valid value prompt.
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    </Col>
                    
                    <Col lg={4}>
                    <Form.Group className="col-12" controlId="formBasicEmail">
                        <Form.Label>End Date <span style={{color: "red"}}>*</span></Form.Label>
                        <DatePicker selected={summerEndDate} dateFormat="dd/MM"
                                            onChange={(date) => handleSummerEndChange(date)} className="form-control" popperPlacement='bottom'/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid value prompt.
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
                        <Col style={{marginTop: 76, marginBottom: 36, display: "flex", justifyContent: "flex-end"}}>
                            <CustomButton buttonType={"outlined"} text={"Cancel"} styles={{marginLeft: 20, background: "#EAECF3 !important"}} disabled={loading || loadingUpdateApplicationSettings} handleClick={(event) => handleCancelApplicationSettings(event)} small/>
                            <CustomButton buttonType={"primary"} text={"Save Application settings"} icon={faFloppyDisk} hasIcon disabled={loading || loadingUpdateApplicationSettings} styles={{marginLeft: 20}} handleClick={(event) => handleSubmitApplicationSettings(event)} small/>
                        </Col>
                    </Row>
                </Form>
                </Col>
            </Row>
            
            </Tab>
            <Tab eventKey="documents" id="documents" title="Requirements (Outgoing)" style={{background: "#EAECF3"}}>
                <Row style={{marginLeft: 30, marginRight: 30}}>
                    <div className='h5-style' style={{marginTop: 10}}>Required Documents</div>
                    <div className='body'>Please activate the switches next to the files that are required for the Outgoing Students of your HEI and provide the relevant URLs and descriptions</div>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            onChange={(event) => setCvRequired(prevState => !prevState)}
                            checked={cvRequired}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={cvRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>CV</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <Col lg={12}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                onChange={(event) => setCvURL(event.target.value)}
                                                required
                                                value={cvURL}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Description"
                                                as="textarea"
                                                required
                                                onChange={(event) => setCvDescription(event.target.value)}
                                                value={cvDescription}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        
                                        
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            onChange={(event) => setMotivLetterRequired(prevState => !prevState)}
                            checked={motivLetterRequired}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={motivLetterRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Motivation Letter</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <Col lg={12}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                onChange={(event) => setMotivLetterURL(event.target.value)}
                                                required
                                                value={motivLetterURL}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                placeholder="Description" onChange={(event) => setMotivLetterDescription(event.target.value)}
                                                value={motivLetterDescription}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        
                                        
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            onChange={(event) => setTorRequired(prevState => !prevState)}
                            checked={torRequired}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={torRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Transcript of Records</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <Col lg={12}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                value={torURL}
                                                required
                                                onChange={(event) => setTorURL(event.target.value)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                required
                                                value={torDescription}
                                                placeholder="Description" onChange={(event) => setTorDescription(event.target.value)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        
                                        
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            checked={extCertRequired} onChange={(event) => setExtCertRequired(prevState => !prevState)}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={extCertRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>External Certification</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <Col lg={12}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                value={extCertURL}
                                                required
                                                onChange={(event) => setExtCertURL(event.target.value)}
                                                // onInput={(event) => validateUrl(event.target.value, "extCert")}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Description"
                                                as="textarea"
                                                required
                                                value={extCertDescription}
                                                onChange={(event) => setExtCertDescription(event.target.value)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        
                                        
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            checked={recomLetterRequired} onChange={(event) => setRecomLetterRequired(prevState => !prevState)}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={recomLetterRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Recommendation Letter</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <Col lg={12}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                value={recomLetterURL}
                                                required
                                                onChange={(event) => setRecomLetterURL(event.target.value)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Description"
                                                as="textarea"
                                                required
                                                value={recomLetterDescription}
                                                onChange={(event) => setRecomLetterDescription(event.target.value)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        
                                        
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            checked={langRequired} onChange={(event) => setLangRequired(prevState => !prevState)}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={langRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Language Level</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 15, background: "#F2F3F6"}}>
                                        {languageArray.map((element, index) => 
                                        <>
                                        <Row style={{background: "white", borderRadius: 10}}>
                                        {(size === "xs" || size === "sm" || size === "md") ? <>
                                        <Col lg={11} xs={12} style={{display: "flex"}}>
                                        
                                        
                                        <Form.Group className={"col-12"} controlId="formBasicName" style={{marginBottom: 5, marginTop: 10, marginRight: 10}}>
                                        <Form.Label>Language Required</Form.Label>
                                        <Select
                                            options={languagesvalues?.map((el) => ({
                                                value: el.code,
                                                label: el.title}))}
                                              placeholder="Select a Language"
                                              onChange={(event) => handleInputLanguageChange(index, event)}
                                              
                                            value={getValueFromSelectLanguage(element)}
                                            
                                        />
                                        </Form.Group>
                                    
                                    
                                    </Col>
                                    <Col lg={11} xs={12} style={{display: "flex"}}>
                                        <Form.Group className={"col-12"} style={{marginBottom: 5, marginTop: 10, marginRight: 10}} controlId="formBasicName">
                                        <Form.Label>Language Level</Form.Label>
                                        <Select
                                            placeholder="Select a Language Level"
                                            
                                            options={CEFRvalues?.map((el) => ({
                                              value: el.code,
                                              label: `${el.code}: ${el.title}`}))}
                                        
                                          value={getValueFromSelectLanguageLevel(element)}
                                          onChange={(event) => handleInputLanguageLevelChange(index, event)}
                                            
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={11} xs={12} style={{display: "flex"}}>
                                        <Form.Group className="col-12" style={{marginBottom: 5, marginTop: 10}} controlId="formBasicName">
                                        <Form.Label>Language More Info</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="https://www.example.com"
                                            onChange={(event) => handleInputLanguageMoreInfoChange(index, event)}
                                            required
                                            value={element.url}
                                        />
                                        </Form.Group>
                                    
                                    
                                    </Col></>
                                      : <> <Col lg={11} xs={12} style={{display: "flex"}}>
                                        
                                        
                                      <Form.Group className={"col-4"} controlId="formBasicName" style={{marginBottom: 5, marginTop: 10, marginRight: 10}}>
                                      <Form.Label>Language Required</Form.Label>
                                      <Select
                                          options={languagesvalues?.map((el) => ({
                                            value: el.code,
                                            label: el.title}))}
                                          placeholder="Select a Language"
                                          onChange={(event) => handleInputLanguageChange(index, event)}
                                          
                                        value={getValueFromSelectLanguage(element)}
                                        name={'code'}
                                      />
                                      </Form.Group>
                                  
                                  
                                  
                                      <Form.Group className={"col-4"} style={{marginBottom: 5, marginTop: 10, marginRight: 10}} controlId="formBasicName">
                                      <Form.Label>Language Level</Form.Label>
                                      <Select
                                          placeholder="Select a Language Level"
                                          
                                          options={CEFRvalues?.map((el) => ({
                                            value: el.code,
                                            label: `${el.code}: ${el.title}`}))}
                                        
                                        value={getValueFromSelectLanguageLevel(element)}
                                        onChange={(event) => handleInputLanguageLevelChange(index, event)}
                                        
                                      />
                                      </Form.Group>
                                  
                                      <Form.Group className="col-4" style={{marginBottom: 5, marginTop: 10}} controlId="formBasicName">
                                      <Form.Label>Language More Info</Form.Label>
                                      <Form.Control
                                            type="text"
                                            placeholder="https://www.example.com"
                                            onChange={(event) => handleInputLanguageMoreInfoChange(index, event)}
                                            required
                                            value={element.url}
                                        />
                                      </Form.Group>
                                  
                                  
                                  </Col></>}
                                        
                                  {!(size === "xs" || size === "sm" || size === "md") && 
                                  <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 30}}>
                                  <IconButton
                                      variant="delete"
                                  
                                      title="Delete Language"
                                      onClick={() => handleRemoveLanguageFields(index)}
                                  />
                                </Col>
                                  }
                                        
                                        <Col lg={12} style={{marginTop: 60, marginBottom: 30, display: "flex"}}>
                                            <Form.Check
                                                type="switch"
                                                id="switch1"
                                                style={{width: 52, height: 26}}
                                                onChange={(event) => handleInputCertRequiredChange(index, event)}
                                                checked={element?.certRequired}
                                            />
                                            <span style={{marginLeft: 33, marginTop: 8}} className="label-style">Language Certification Required</span>
                                        </Col>
                                        
                                        {!!+element?.certRequired && 
                                            <Col lg={12} style={{marginBottom: 40}}>
                                                <Form
                                                    onSubmit={(e) => console.log("handle submit")}
                                                    noValidate
                                                    style={{marginBottom: 30}}
                                                >
                                                    <Form.Group className="col-12" controlId="formBasicName">
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        as="textarea"
                                                        required
                                                        value={element.certDescription} placeholder="Description"
                                                        onChange={(event) => handleInputCertDescriptionChange(index, event)}
                                                    />
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                        }
                                        {(size === "xs" || size === "sm" || size === "md") && 
                                        <Col lg={1} style={{display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center", marginTop: 5}}>
                                        <IconButton
                                            variant="delete"
                                        
                                            title="Delete Language"
                                            onClick={() => handleRemoveLanguageFields(index)}
                                        />
                                        </Col>
                                        }
                                        </Row>
                                        {languageArray.length - 1 !== index && <hr style={{border: "2px solid #F2F3F6"}}/>}
                                        </>
                                        )}
                                        
                                    </Row>
                                    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={"Add Language"} icon={faPlus} hasIcon styles={{marginLeft: 20}} handleClick={handleAddLanguageFields} small/>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 40}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            checked={otherDocRequired} onChange={(event) => setOtherDocRequired(prevState => !prevState)}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={otherDocRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 40}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Other Document</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 15, background: "#F2F3F6", borderRadius: 10}}>
                                        {otherDocumentArray.map((element, index) => 
                                        <Row style={{marginBottom: 5, marginTop: 10, background: "white", borderRadius: 10}}>
                                        <Col lg={11}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                onChange={(event) => handleInputOtherDocumentChange(index, event)}
                                                required
                                                name="url"
                                                value={element.url}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Description"
                                                as="textarea"
                                                required
                                                onChange={(event) => handleInputOtherDocumentChange(index, event)}
                                                value={element.description}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        <Col lg={1} style={!(size === "xs" || size === "sm" || size === "md") ? {display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"} : {display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center"}}>
                                            <IconButton
                                                variant="delete"
                                            
                                                title="Delete Requirement"
                                                onClick={() => handleRemoveOtherDocumentsFields(index)}
                                            />
                                        </Col>
                                        </Row>
                                        )}
                                        
                                    </Row>
                                    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={"Add Other Document"} icon={faPlus} hasIcon styles={{marginLeft: 20}} handleClick={handleAddOtherDocumentsFields} small/>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <div className='h5-style' style={{marginTop: 10}}>Required Documents</div>
                    <div className='body'>Please activate the switches next to the files that are required for the Outgoing Students of your HEI and provide the relevant URLs and descriptions</div>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            checked={referenceContactRequired} onChange={(event) => setReferenceContactRequired(prevState => !prevState)}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={referenceContactRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Reference Contact</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <Col lg={12}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                value={referenceContactURL} onChange={(event) => setReferenceContactURL(event.target.value)}
                                                required
                                                
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                required
                                                value={referenceContactDescription} placeholder="Description" onChange={(event) => setReferenceContactDescription(event.target.value)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        
                                        
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 15}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            checked={portfolioRequired} onChange={(event) => setPortfolioRequired(prevState => !prevState)}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={portfolioRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 15}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Portfolio</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <Col lg={12}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                value={portfolioURL} onChange={(event) => setPortfolioURL(event.target.value)}
                                                required
                                                
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                required
                                                value={portfolioDescription} placeholder="Description" onChange={(event) => setPortfolioDescription(event.target.value)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        
                                        
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={1} xs={1} className="flex-center" style={{marginBottom: 40}}>
                        <Form.Check
                            type="switch"
                            id="switch1"
                            style={!(size === "xs" || size === "sm" || size === "md") ? {width: 52, height: 26} : {width: 52, height: 26, marginRight: 15}}
                            checked={otherRequirementRequired} onChange={(event) => setOtherRequirementRequired(prevState => !prevState)}
                        />
                    </Col>
                    <Col lg={11} xs={11}>
                        <Accordion className='border-hidden label-style' id={otherRequirementRequired ? 'accordion-requirements-active' : 'accordion-requirements-inactive'} style={{marginBottom: 40}}>
                            <Accordion.Item eventKey="0" className='label-style' id={"accordion-first"}>
                                <Accordion.Header className='document-item label-style'>Other Requirement</Accordion.Header>
                                <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}}>
                                    
                                    <Row style={{marginBottom: 12, marginLeft: 15, background: "#F2F3F6", borderRadius: 10}}>
                                        {otherRequirementArray.map((element, index) => 
                                        <Row style={{marginBottom: 5, marginTop: 10, background: "white", borderRadius: 10}}>
                                        <Col lg={11}>
                                        <div style={{marginBottom: 5, marginTop: 10}} className='label-style'>

                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                value={element.url} onChange={(event) => handleInputOtherRequirementChange(index, event)}
                                                required
                                                name="url"
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        <div style={{marginBottom: 5, marginTop: 30}} className='label-style'>
                                        
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                            style={{marginBottom: 30}}
                                        >
                                            <Form.Group className="col-12" controlId="formBasicName">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                required
                                                value={element.description} placeholder="Description" onChange={(event) => handleInputOtherRequirementChange(index, event)}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        </Col>
                                        <Col lg={1} style={!(size === "xs" || size === "sm" || size === "md") ? {display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"} : {display: "flex", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "center"}}>
                                            <IconButton
                                                variant="delete"
                                            
                                                title="Delete Requirement"
                                                onClick={() => handleRemoveOtherRequirementFields(index)}
                                            />
                                        </Col>
                                        </Row>
                                        )}
                                        
                                    </Row>
                                    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={"Add Other Requirement"} icon={faPlus} hasIcon styles={{marginLeft: 20}} handleClick={handleAddOtherRequirementFields} small/>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col >
                        <Col style={{marginTop: 76, marginBottom: 36, display: "flex", justifyContent: "flex-end"}}>
                            <CustomButton buttonType={"outlined"} text={"Cancel"} styles={{marginLeft: 20, background: "#EAECF3 !important"}} disabled={loading || loadingUpdateOutgoingRequirements} handleClick={(event) => handleCancelApplicationRequirements(event)} small/>
                            <CustomButton buttonType={"primary"} text={"save requirements"} icon={faFloppyDisk} disabled={loading || loadingUpdateOutgoingRequirements} handleClick={(event) => handleSubmitApplicationRequirements(event)} hasIcon styles={{marginLeft: 20}} small/>
                        </Col>
                    </Col>
                </Row>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
  </>)
};

export default withFooter(
    withNavBar(withTitle(RequirementsNominations, "Application Settings & Requirements"), "Applications for Erasmus+", "Application Settings & Requirements")
);