import { reducer } from "../reducers/useCreateReducer";
import { useReducer, useCallback } from "react";
import useData from "../../../../hooks/useData";

import { UPDATE_MULTIPLE_VALUES_ACTION } from "../reducers/useCreateReducer";

export const defaultState = {
  institutionCountry: "",
  institutionCity: "",
  institution: "",
  contacts: [],
  partnerContacts: [],
  staffTeachers: [],
  staffTrainings: [],
  studentStudies: [],
  studentTraineeships: [],
  partnerSCHAC: "",
  partnerErasmusCode: "",
  partnerOUnit: "",
  partnerOUnitName: [],
  ounit: [],
  ounitID: "",
  cities: [],
  institutions: [],
  checked: false,
  type: "add",
  activeStep: 0,
  validateFirstEmail: false,
  validateSecondEmail: false
};

export const useCreate = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const {theData, setTheData } = useData();

  const updateMultipleValues = useCallback((data) => {
    dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data });
  }, []);

  setTheData((prevState) =>{
    if ((defaultState !== state) && (state.type === "add")) {
      return state;
    } else {
      return prevState;
    }
  })

  return {
    ...state,
    updateMultipleValues,
  };
};
