import { gql } from "@apollo/client";

const GET_DEALS = gql`
query deals
    {   
      deals{
      id
      externalID
      organisations
      title
    }  
}`
;

export default GET_DEALS;
