import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";

const RequireAuth = ({
  allowedUserTypes,
  allowedPermissions,
  extraConditions,
}) => {
  const { auth } = useAuth();
  const { user } = useUser();
  const location = useLocation();

  if (!auth?.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!allowedUserTypes?.includes(user.type)) {
    return (
      <Navigate
        to="/"
        state={{ from: location, showAuthorizationToast: true }}
        replace
      />
    );
  }

  let permissionsSatisfied = false;
  if (!allowedPermissions) permissionsSatisfied = true;
  // at least one permission needs to satisfy the allowedPermissions
  else
    for (const currentPermission of allowedPermissions) {
      if (
        user?.permissions.find(
          (permission) => permission.value === currentPermission
        )
      ) {
        permissionsSatisfied = true;
        break;
      }
    }

  let extraConditionsSatisfied = true;
  if (extraConditions) {
    // all extra conditions must be satisfied
    for (const currentCondition of extraConditions) {
      if (!currentCondition) {
        extraConditionsSatisfied = false;
        break;
      }
    }
  }

  if (permissionsSatisfied && extraConditionsSatisfied) return <Outlet />;

  return (
    <Navigate
      to="/"
      state={{ from: location, showAuthorizationToast: true }}
      replace
    />
  );
};

export default RequireAuth;
