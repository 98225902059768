import { gql } from "@apollo/client";

const GET_COUNTRIES = gql`
  query countries {
    countries {
      ID
      CountryName
      CountryLink
    }
  }
`;

export default GET_COUNTRIES;
