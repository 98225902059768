import { Col, Overlay, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import withFooter from '../../../../components/WithFooter';
import withNavBar from '../../../../components/WithNavBar';
import "./index.scss"
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClipboardList,
  faEye,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import COLORS from '../../../../data/colors';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import CustomButton from '../../../../components/CustomButton';
import CustomModal from '../../../../components/CustomModal';
import { useState } from 'react';
import { useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import GET_OUTGOING_NOMINATION from '../../../../graphql/queries/nomination/getOutgoingNomination';
import { StatusNomination } from '../../../../data/statusNomination';
import ACTIVATE_DESTINATION from '../../../../graphql/mutations/nomination/activateDestination';
import REJECT_NOMINATION from '../../../../graphql/mutations/nomination/rejectNomination';
import DELETE_NOMINATION from '../../../../graphql/mutations/nomination/deleteNomination';
import WAITLIST_NOMINATION from '../../../../graphql/mutations/nomination/waitlistNomination';
import DestinationHEIInfo from './DestinationHEIInfo';
import DestinationHEIHeader from './DestinationHEIHeader';
import ExportZip from '../../ExportZip';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from "../../../../data/toastConfiguration";
import { useEffect } from 'react';

const SingleViewOutgoingNominations = () => {
    const [ size ] = useBreakpoint();
    const tooltipTarget = useRef(null);
    const { id } = useParams();

    const [showTooltip, setShowTooltip] = useState(false);
    const [showModalWaiting, setShowModalWaiting] = useState(false);
    const [showModalReject, setShowModalReject] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalNominate, setShowModalNominate] = useState(false);
    const [mobilityId, setMobilityId] = useState("");

    const { loading, error, data, refetch: refetchQuery } = useQuery(
        GET_OUTGOING_NOMINATION,
        {
          variables: {
            id: id,
          },
        }
    );

    useEffect(() => {
        refetchQuery({
            id: id
        });
      }, []);

    const [
        activateDestination, { loading: loadingActivateDestination }
      ] = useMutation(ACTIVATE_DESTINATION);

    const [
        rejectNomination, { loading: loadingRejectNomination }
    ] = useMutation(REJECT_NOMINATION);

    const [
        deleteNomination, { loading: loadingDeleteNomination }
    ] = useMutation(DELETE_NOMINATION);

    const [
        waitlistNomination, { loading: loadingWaitListNomination }
    ] = useMutation(WAITLIST_NOMINATION);

    const handleCloseModalWaiting = () => setShowModalWaiting(false);
    const handleShowModalWaiting = () => setShowModalWaiting(true);
    const handleCloseModalReject = () => setShowModalReject(false);
    const handleShowModalReject = () => setShowModalReject(true);
    const handleCloseModalDelete = () => setShowModalDelete(false);
    const handleShowModalDelete = () => setShowModalDelete(true);
    const handleCloseModalNominate = () => setShowModalNominate(false);
    const handleShowModalNominate = () => setShowModalNominate(true);
    const handleShowTooltip = () => setShowTooltip(true);
    const handleCloseTooltip = () => setShowTooltip(false);

    if (loading) return (
        <div
            style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
            }}
        >
            <Spinner
                as="div"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="align-middle my-auto"
            />
            <span>Loading...</span>
        </div>
    )
    if (error)
      return (
        <div className="text-center text-danger mx-auto">An error occured</div>
      );

    const field_student_name = data.outgoingNomination.field_student_name;
    const field_student_email = data.outgoingNomination.field_student_email;
    const field_student_isced = data.outgoingNomination.field_student_isced;
    const field_id = data.outgoingNomination.id;
    const field_student_eqf = data.outgoingNomination.field_student_eqf;
    const field_cv = data.outgoingNomination.field_cv;
    const field_motivation_letter = data.outgoingNomination.field_motivation_letter;
    const field_external_cert = data.outgoingNomination.field_external_cert;
    const field_lang_level = data.outgoingNomination.field_lang_level;
    const field_other_document = data.outgoingNomination.field_other_document;
    const field_academic_year = data.outgoingNomination.field_academic_year;
    const field_academic_term = data.outgoingNomination.field_academic_term;
    const field_reference_contact = data.outgoingNomination.field_reference_contact;
    const field_portfolio = data.outgoingNomination.field_portfolio;
    const field_other_requirement = data.outgoingNomination.field_other_requirement;
    const nominationStatus = data.outgoingNomination.field_nomination_status;
    const createdDate = new Date(data.outgoingNomination.created).toLocaleDateString('en-GB',{
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
    });

    const handleNominateClick = (element) => {
        handleShowModalNominate(); 
        setMobilityId(element?.id);
    }

    const handleNominate = async () => {
        const toastId = toast.loading("Nominating application...", toastConfiguration);
        try {
            const result = await activateDestination({
                variables: {
                  id: mobilityId,
                },
              });
            setShowModalNominate(false);
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Succesfully nominated Application`,
                    type: "success",
                    isLoading: false,
                  });
                refetchQuery({
                    id: id
                });
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Nomination of application failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
        
        
        
    }

    const handleReject = async () => {
        const toastId = toast.loading("Rejecting application...", toastConfiguration);
        try {
            const result = await rejectNomination({
                variables: {
                  id: field_id,
                },
              });
              setShowModalReject(false);
              if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Succesfully rejected Application`,
                    type: "success",
                    isLoading: false,
                  });
                refetchQuery({
                    id: id
                });
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Rejection of application failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
        
        
    }

    const handleWaitlist = async () => {
        const toastId = toast.loading("Application putting in the waiting list...", toastConfiguration);
        try {
            const result = await waitlistNomination({
                variables: {
                  id: field_id,
                },
              });
            setShowModalWaiting(false);
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Succesfully updated Application`,
                    type: "success",
                    isLoading: false,
                  });
                refetchQuery({
                    id: id
                });
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Update of Application failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
        
        
        
    }

    const handleDelete = async () => {
        const toastId = toast.loading("Deleting application...", toastConfiguration);
        try {
            const result = await deleteNomination({
                variables: {
                  id: field_id,
                },
              });
              setShowModalDelete(false);
              if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully deleted Application`,
                    type: "success",
                    isLoading: false,
                  });
                refetchQuery({
                    id: id
                });
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Delete of application failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
        
    }

  return (
    <>
    {(size !== "xs" && size !== "sm" && size !== "md") && ( <>
    <Row style={ size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 75}}>
            <Tabs
            id="application-info"
            >
            <Tab eventKey="home" title="APPLICATION INFO" style={{background: "#ffff"}}>
            <Table responsive>
                <thead className='thead-font'>
                    <tr>
                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>ID</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STATUS</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEAR</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC TERM</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>SUBMISSION TIME</th>
                    <th className='border-hidden'></th>
                    </tr>
                </thead>
                <tbody className='tbody-font'>
                    <tr>
                    <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{field_id}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{StatusNomination[nominationStatus]}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{field_academic_year}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{field_academic_term}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{createdDate}</td>
                    <td style={{paddingTop: 0, marginTop: -5}} className="download-wrap border-hidden">
                        <Overlay
                            target={tooltipTarget.current}
                            show={showTooltip}
                            placement="top"
                        >
                            {(props) => (
                            <Tooltip
                                id="overlay-example"
                                {...props}
                                className="position-absolute"
                            >
                                    Download All Files
                            </Tooltip>
                            )}
                        </Overlay>
                        <div ref={tooltipTarget}>
                            <ExportZip id={field_id} onMouseEnter={handleShowTooltip} onMouseLeave={handleCloseTooltip} studentName={field_student_name}/>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </Table>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36}}>
            <Tabs
            id="second-tab"
            >
            <Tab eventKey="general-info" id="general-info" title="General Info" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="STUDENT INFO" style={{background: "#ffff"}}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>NAME</th>
                                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>EMAIL</th>
                                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY LEVEL</th>
                                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY FIELD</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody-font'>
                                    <tr>
                                    <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{field_student_name}</td>
                                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{field_student_email}</td>
                                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{field_student_eqf}</td>
                                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{field_student_isced}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="destination-heis"
                        >
                        <Tab eventKey="home" title="DESTINATION HEIs" style={{background: "#ffff"}}>
                            <Table  responsive="xl">
                                <thead>
                                    <tr className='thead-font d-flex' >
                                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className="col-xl-3 col-lg-3 border-hidden label-style">INSTITUTION / FACULTY</th>
                                    {/* <th style={{paddingTop: 15, paddingBottom: 2, paddingRight: 0}} className="col-xl-3 col-lg-2 border-hidden label-style">AVAILABLE SPOTS</th> */}
                                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 0}} className="col-xl-2 col-lg-2 border-hidden label-style">NOMINATED STUDENTS</th>
                                    {/* <th style={size === "lg" || size === "xl"? {paddingTop: 15, paddingBottom: 2, paddingLeft: 0} : {paddingTop: 15, paddingBottom: 2, paddingLeft: 0, justifyContent: "center", display: "flex"}} className="col-xl-2 col-lg-2 border-hidden label-style">DURATION</th> */}
                                    <th  className="col-xl-2 col-lg-3 border-hidden"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="6" className='border-hidden'>
                                        <Accordion defaultActiveKey="0" className='border-hidden'>
                                            {data.outgoingNomination?.field_mobility_details?.map((element, index) =>
                                            <> 
                                                <Accordion.Item eventKey={index} className='border-hidden'>
                                                    <Accordion.Header className='border-hidden'>
                                                        <DestinationHEIHeader size={size} mobilityDetail={element} academicYear={field_academic_year} handleNominate={handleNominateClick} isMobile={false}/>
                                                    </Accordion.Header>
                                                    <Accordion.Body className='border-hidden grey'>
                                                        <DestinationHEIInfo hostErasmusCode={element?.field_host_erasmus_code} isMobile={false}/>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            {data.outgoingNomination?.field_mobility_details.length - 1 !== index && <hr style={{marginLeft: 18, marginRight: 20, border: "2px solid #DDDDDD"}}/>}
                                            </>
                                            )}
                                    </Accordion>
                                    </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="documents" id="documents" title="Documents" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                {(field_cv?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>CV</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                            <a href={`/applications-for-erasmus+/outgoing-students/export/${id}/${field_cv?.id}`} target="_blank">
                                <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                            </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_portfolio?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>PORTFOLIO</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${field_portfolio?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_external_cert?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>EXTERNAL CERTIFICATION</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${id}/${field_external_cert?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                {(field_reference_contact?.id) && 
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>REFERENCE CONTACT</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${id}/${field_reference_contact?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                
                {(field_motivation_letter?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>MOTIVATION LETTER</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${id}/${field_motivation_letter?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_lang_level?.length > 0) &&
                <>
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>LANGUAGE CERTIFICATES</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_lang_level.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 13}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                        <a href={`/applications-for-erasmus+/outgoing-students/export/${id}/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }

                {(field_other_document?.length > 0) &&
                <>
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>OTHER DOCUMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_other_document.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 13}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 20}}>
                                        <a href={`/applications-for-erasmus+/outgoing-students/export/${id}/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }
                </Col>
            </Row>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36, display: "flex", justifyContent: "flex-end"}}>
            <CustomButton buttonType={"primary"} text={"Waiting List"} icon={faClipboardList} hasIcon={true} iconColor={COLORS.white} disabled={(nominationStatus === 'accepted_by_sending' 
            || nominationStatus === 'accepted_by_student' || nominationStatus === 'rejected_by_sending' || nominationStatus === 'cancelled' ) ? true : (nominationStatus === 'waiting_list') ? 
            true : (nominationStatus === 'rejected_by_student') ? true : false} handleClick={handleShowModalWaiting}/>
            <CustomButton buttonType={"decline"} text={"Reject"} styles={{marginLeft: 20}} icon={faXmark} hasIcon={true} iconColor={COLORS.white} disabled={(nominationStatus === 'accepted_by_sending' 
            || nominationStatus === 'accepted_by_student' || nominationStatus === 'rejected_by_sending' || nominationStatus === 'cancelled' ) ? true : (nominationStatus === 'waiting_list') ? 
            false : (nominationStatus === 'rejected_by_student') ? true : false} handleClick={handleShowModalReject}/>
            <CustomButton buttonType={"delete"} text={"Delete"} styles={{marginLeft: 20}} icon={faTrash} hasIcon={true} iconColor={COLORS.white} disabled={(nominationStatus === 'accepted_by_sending' 
            || nominationStatus === 'accepted_by_student' || nominationStatus === 'rejected_by_sending' || nominationStatus === 'cancelled' ) ? true : (nominationStatus === 'waiting_list') ? 
            false : (nominationStatus === 'rejected_by_student') ? false : false} handleClick={handleShowModalDelete}/>
        </Col>
    </Row>
</>
  )}
  {(size === "xs" || size === "sm" || size === "md") && (
    <>
    <Row style={{marginLeft: 2, marginRight: 5}}>
        <Col style={{marginTop: 15, paddingLeft: 0, paddingRight: 0}} xs={12} sm={12}>
            <Tabs
            id="application-info"
            >
            <Tab eventKey="home" title="APPLICATION INFO" style={{background: "#ffff"}}>
                <Row style={{marginLeft: 25,marginRight: 25}}>
                    <Col xs={6} sm={6} style={{marginTop: 17}} className='thead-font label-style'>ID:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 17}} className='tbody-font body'>{field_id}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>STATUS:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{StatusNomination[nominationStatus]}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>ACADEMIC YEAR:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_academic_year}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>ACADEMIC TERM:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_academic_term}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6,marginBottom: 25}} className='thead-font label-style'>SUBMISSION TIME:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6,marginBottom: 25}} className='tbody-font body'>{createdDate}</Col>
                    <Col xs={12} sm={12} style={{marginTop: 6,marginBottom: 15}} className='tbody-font body text-end'>
                    <Overlay
                            target={tooltipTarget.current}
                            show={showTooltip}
                            placement="top"
                        >
                            {(props) => (
                            <Tooltip
                                id="overlay-example"
                                {...props}
                                className="position-absolute"
                            >
                                    Download All Files
                            </Tooltip>
                            )}
                        </Overlay>
                        <div ref={tooltipTarget}>
                            <ExportZip id={field_id} onMouseEnter={handleShowTooltip} onMouseLeave={handleCloseTooltip} studentName={field_student_name}/>
                        </div>
                    </Col>
                </Row>
            </Tab>
            </Tabs>
        </Col>
        <Col style={{marginTop: 15, paddingLeft: 0, paddingRight: 0}} xs={12} sm={12}>
            <Tabs
            id="second-tab"
            >
            <Tab eventKey="general-info" id="general-info" title="General Info" style={{background: "#EAECF3"}}>
                <Row style={{marginLeft: 15, marginRight: 15}}>
                    <Col style={{marginTop: 20, marginBottom:38, paddingLeft: 0, paddingRight: 0}}>
                        <Tabs
                            defaultActiveKey="home"
                            id="student-info"
                        >
                            <Tab eventKey="home" title="STUDENT INFO" style={{background: "#ffff"}}>
                                <Row style={{marginLeft: 25,marginRight: 25}}>
                                    <Col xs={6} sm={6} style={{marginTop: 17}} className='thead-font label-style'>NAME:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 17}} className='tbody-font body'>{field_student_name}</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>EMAIL:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_student_email}</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>STUDY LEVEL:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{field_student_eqf}</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6, marginBottom: 25}} className='thead-font label-style'>STUDY FIELD:</Col>
                                    <Col xs={6} sm={6} style={{marginTop: 6, marginBottom: 25}} className='tbody-font body'>{field_student_isced}</Col>
                                    
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                <Row style={{marginLeft: 15, marginRight: 15}}>
                    <Col style={{marginTop: 20, marginBottom:38, paddingLeft: 0, paddingRight: 0}}>
                        <Tabs
                            defaultActiveKey="home"
                            id="destination-heis"
                        >
                            <Tab eventKey="home" title="DESTINATION HEIs" style={{background: "#ffff"}}>
                                {data.outgoingNomination?.field_mobility_details?.map((element, index) =>
                                <>
                                <div id='item-wrapper'>
                                    <DestinationHEIHeader size={size} mobilityDetail={element} academicYear={field_academic_year} isMobile/>
                                    <Accordion className='border-hidden' style={{marginBottom: 28}}>
                                        <Accordion.Item eventKey="0" className='border-hidden'>
                                            <Accordion.Header className='border-hidden' style={{marginBottom: 20}}>
                                            {element?.field_mobility_details_status === "active" ? 
                                                <Button style={{background: "#2B9436", borderRadius: 5, borderColor: "#2B9436"}} disabled> 
                                                NOMINATED
                                                </Button> : (element?.field_mobility_details_status === "rejected") || (element?.field_mobility_details_status === "old_rejected") ?
                                                <Button style={{background: "#B61717", borderRadius: 5, borderColor: "#B61717"}} disabled> 
                                                REJECTED
                                                </Button> :
                                                <CustomButton disabled={!element?.canBeAccepted} handleClick={() => {handleShowModalNominate(); setMobilityId(element?.id);}} 
                                                buttonType={"accept"} text={"Nominate"} small={true} icon={faCheck} hasIcon={true} iconColor={COLORS.white}/>
                                            }
                                            </Accordion.Header>
                                            <Accordion.Body className='border-hidden grey' style={{paddingTop: 0}}>
                                                <DestinationHEIInfo hostErasmusCode={element?.field_host_erasmus_code} isMobile/>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                {data.outgoingNomination?.field_mobility_details.length - 1 !== index ?
                                 <hr style={{marginLeft: 18, marginRight: 20, marginBottom: 17, border: "2px solid #DDDDDD"}}/> : data.outgoingNomination?.field_mobility_details.length !== index && 
                                 <hr style={{marginLeft: 18, marginRight: 20, marginBottom: 17, border: "0px solid #DDDDDD"}}/>
                                 }
                                </>
                                )}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="documents" id="documents" title="Documents" style={{background: "#EAECF3"}}>
                <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                {(field_cv?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>CV</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${field_cv?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_portfolio?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>PORTFOLIO</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${field_portfolio?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_external_cert?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>EXTERNAL CERTIFICATION</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${field_external_cert?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                {(field_reference_contact?.id) && 
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>REFERENCE CONTACT</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${field_reference_contact?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }
                
                {(field_motivation_letter?.id) &&
                <>
                    <Col style={{marginBottom: 12, paddingRight: 3}} md={12} lg={12}>
                        <div className='document-item label-style'><span style={{float: "left", paddingLeft: 17}}>MOTIVATION LETTER</span>
                            <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                <a href={`/applications-for-erasmus+/outgoing-students/export/${field_motivation_letter?.id}`} target="_blank">
                                    <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </>
                }

                {(field_lang_level?.length > 0) &&
                <>
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>LANGUAGE CERTIFICATES</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_lang_level.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 5}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                        <a href={`/applications-for-erasmus+/outgoing-students/export/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }

                {(field_other_document?.length > 0) &&
                <>
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>OTHER DOCUMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                {field_other_document.map((element, index) => 
                                <Col style={{marginBottom: 12, paddingRight: 3, background: "#F2F3F6"}} md={12} lg={12}>
                                <div className='document-language-item label-style'><span style={{float: "left", paddingLeft: 5}}>{element?.fileName}</span>
                                    <div style={{marginBottom: 12, float: "right", marginRight: 5}}>
                                        <a href={`/applications-for-erasmus+/outgoing-students/export/${element?.id}`} target="_blank">
                                            <CustomButton buttonType={"primary"} text={"View Pdf"} small={true} icon={faEye} hasIcon={true} iconColor={COLORS.white}/>
                                        </a>
                                    </div>
                                </div>
                                </Col>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                }
                </Col>
                </Row>
            </Tab>
            </Tabs>
        </Col>
        <Col style={{marginTop: 15, paddingLeft: 0, paddingRight: 0}} xs={6} sm={6}>
            <Button className='wrapper-waiting-mobile col-12' style={{display: "flex", justifyContent: "center"}} disabled={(nominationStatus === 'accepted_by_sending' 
            || nominationStatus === 'accepted_by_student' || nominationStatus === 'rejected_by_sending' || nominationStatus === 'cancelled' ) ? true : (nominationStatus === 'waiting_list') ? 
            true : (nominationStatus === 'rejected_by_student') ? true : false} onClick={handleShowModalWaiting}> 
                <span >
                    <FontAwesomeIcon
                    icon={faClipboardList}
                    color={COLORS.white}
                    className="button-waiting"
                    />
                </span>WAITING LIST
            </Button>
        </Col>
        <Col style={{marginTop: 15, paddingLeft: 5, display: "flex", justifyContent: "flex-end", paddingRight: 0}} xs={6} sm={6}>
            <Button className='wrapper-reject-mobile col-12' style={{display: "flex", justifyContent: "center"}} disabled={(nominationStatus === 'accepted_by_sending' 
            || nominationStatus === 'accepted_by_student' || nominationStatus === 'rejected_by_sending' || nominationStatus === 'cancelled' ) ? true : (nominationStatus === 'waiting_list') ? 
            false : (nominationStatus === 'rejected_by_student') ? true : false} onClick={handleShowModalReject}> 
                <span >
                    <FontAwesomeIcon
                    icon={faXmark}
                    color={COLORS.white}
                    className="button-waiting"
                    />
                </span>REJECT
            </Button>
        </Col>
        <Col style={{marginTop: 15, paddingLeft: 0, paddingRight: 0}} xs={12} sm={12}>
            <Button className='wrapper-delete-mobile col-12' style={{display: "flex", justifyContent: "center"}} disabled={(nominationStatus === 'accepted_by_sending' 
            || nominationStatus === 'accepted_by_student' || nominationStatus === 'rejected_by_sending' || nominationStatus === 'cancelled' ) ? true : (nominationStatus === 'waiting_list') ? 
            false : (nominationStatus === 'rejected_by_student') ? false : false} onClick={handleShowModalDelete}> 
                <span >
                    <FontAwesomeIcon
                    icon={faTrash}
                    color={COLORS.white}
                    className="button-waiting"
                    />
                </span>DELETE
            </Button>
        </Col>
    </Row>
    </>
  )}
    <CustomModal show={showModalReject}  handleClose={handleCloseModalReject} 
            body={"After rejecting the application, the student will not be able to apply for any other mobility program in the current academic year."}
            button={<CustomButton buttonType={"decline"} text={"Reject"}  icon={faXmark} disabled={loadingRejectNomination} hasIcon={true} iconColor={COLORS.white} handleClick={handleReject}/>} 
            title={<div>You are about to <span style={{color: "red"}}>reject</span> a student’s application</div>} hasIcon={false} disabledCancelButton={loadingRejectNomination}/>

    <CustomModal show={showModalWaiting}  handleClose={handleCloseModalWaiting} 
            body={"After putting the student’s application in the Waiting List, you will still be able to nominate the student later, if you wish to."}
            button={<CustomButton buttonType={"primary"} text={"Waiting List"} disabled={loadingWaitListNomination} icon={faClipboardList} hasIcon={true} iconColor={COLORS.white}  handleClick={handleWaitlist}/>} 
            title={<div>You are about to put a student’s application in the <span style={{color: "#3D619C"}}>Waiting List</span></div>} hasIcon disabledCancelButton={loadingWaitListNomination}/>

    <CustomModal show={showModalDelete}  handleClose={handleCloseModalDelete} 
            body={"After deleting the student’s application, you won’t be able to see it again."}
            button={<CustomButton buttonType={"delete"} text={"Delete"}  icon={faTrash} disabled={loadingDeleteNomination} hasIcon={true} iconColor={COLORS.white}  handleClick={handleDelete}/>} 
            title={<div>You are about to <span style={{color: "red"}}>delete</span> a student’s application</div>} hasIcon disabledCancelButton={loadingDeleteNomination}/>
    <CustomModal show={showModalNominate}  handleClose={handleCloseModalNominate} 
            body={"After nominating the student for a mobility to Host HEI, the Receiving Institution will be able to accept or reject the nomination. In case of rejection by the Host HEI, you will be able to nominate the student for a mobility in another HEI, if available."}
            button={<CustomButton buttonType={"accept"} text={"Nominate"}  icon={faCheck} disabled={loadingActivateDestination} hasIcon={true} iconColor={COLORS.white}  handleClick={handleNominate}/>} 
            title={<div>You are about to <span style={{color: "green"}}>nominate</span> a student’s application</div>} hasIcon disabledCancelButton={loadingActivateDestination}/>
    <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
  </>)
};

export default withFooter(
    withNavBar(SingleViewOutgoingNominations, "Applications for Erasmus+", "Outgoing Students")
);