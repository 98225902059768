import { gql } from "@apollo/client";

const GET_ROLES = gql`
  query accountFunctions {
    roles: accountFunctions {
      id
      name: Name
      permissions: AccountAbility {
        id
        name: Name
        value: Value
      }
    }
  }
`;

export default GET_ROLES;
