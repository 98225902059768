import { gql } from "@apollo/client";

const ADD_OUNIT = gql`
  mutation addOUnit($oUnitData: OUnitInput!) {
    addOUnit(oUnitData: $oUnitData) {      
        abbreviation
        ounit_name{
          name
          language
        }
    }
  }
`;

export default ADD_OUNIT;
