import { useEffect } from "react";

const withTitle = (Component, title) => (props) => {
  useEffect(() => {
    document.title = "EWP Dashboard | " + title;
  }, []);

  return <Component {...props} />;
};

export default withTitle;
