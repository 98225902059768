import { gql } from "@apollo/client";

const GET_SINGLE_ACCOUNT = gql`
  query singleAccount($id: ID) {
    singleAccount(id: $id) {
      Name
      Email
      Phone
      Institution {
        NameInLatinCharacterSet
      }
      AccountFunction {
        Name
      }
    }
  }
`;

export default GET_SINGLE_ACCOUNT;
