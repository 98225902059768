
import withNavBar from "../../../components/WithNavBar";
import withFooter from "../../../components/WithFooter";

import withTitle from "../../../components/withTitle";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import ThirdStep from "./ThirdStep";
import { defaultState, useCreate } from "./hooks/useCreate";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ADD_IIA from "../../../graphql/mutations/IIAs/addIIA";
import useUser from "../../../hooks/useUser";
import useData from "../../../hooks/useData";
import { StepLabel, Typography } from "@mui/material";
import ForthStep from "./ForthStep";
import GET_EWP_SETTINGS from "../../../graphql/queries/settings/getEWPSettings";
import { toast, ToastContainer } from "react-toastify";
import toastConfiguration from "../../../data/toastConfiguration";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import GET_EDIT_IIA from "../../../graphql/queries/IIAs/getEditIIA";
import GET_SETTINGS from "../../../graphql/queries/IIAs/getSettings";
import UPDATE_IIA from "../../../graphql/mutations/IIAs/updateIIA";

const steps = [
  'YOUR DETAILS',
  'PARTNER DETAILS',
  'CONDITIONS',
  'OVERVIEW & SUBMIT',
];

const CreateIIA = () => {
  const {
    institutionCountry,
    institutionCity,
    institution,
    contacts,
    partnerContacts,
    staffTeachers,
    staffTrainings,
    studentStudies,
    studentTraineeships,
    partnerSCHAC,
    partnerErasmusCode,
    partnerOUnitID,
    partnerOUnitName,
    ounit,
    ounitID,
    cities,
    institutions,
    checked,
    type,
    validateFirstEmail,
    validateSecondEmail,
    updateMultipleValues,
    activeStep
  } = useCreate();
  const {user} = useUser();
  const {theData} = useData();
  const { id } = useParams();
  const navigate = useNavigate();

  
  const [fetchQueryEdit, {
    loading: loadingEditIIA,
    error: errorEditIIA,
    data: dataEditIIA, refetch: refetchQueryEdit, called: calledEdit
  }] = useLazyQuery(GET_EDIT_IIA, {notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only', variables: {id: id}, onCompleted: (data) =>  {

    updateMultipleValues({staffTeachers: data?.iiaRaw?.cooperation_conditions?.staff_teachers ? data?.iiaRaw?.cooperation_conditions?.staff_teachers : [], staffTrainings: data?.iiaRaw?.cooperation_conditions?.staff_trainings ? data?.iiaRaw?.cooperation_conditions?.staff_trainings : [], 
      studentStudies: data?.iiaRaw?.cooperation_conditions?.student_studies ? data?.iiaRaw?.cooperation_conditions?.student_studies : [], studentTraineeships: data?.iiaRaw?.cooperation_conditions?.student_traineeships ? data?.iiaRaw?.cooperation_conditions?.student_traineeships : [], type: "edit", 
    partnerSCHAC: data?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? data?.iiaRaw?.partner[1]?.hei_id : data?.iiaRaw?.partner[0]?.hei_id, contacts: data?.iiaRaw?.partner[0]?.hei_id === user?.heiID ?
    data?.iiaRaw?.partner[0]?.contact : data?.iiaRaw?.partner[1]?.contact, partnerContacts: data?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? data?.iiaRaw?.partner[1]?.contact : data?.iiaRaw?.partner[0]?.contact,
    ounit:  data?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? data?.iiaRaw?.partner[0]?.ounit_name : data?.iiaRaw?.partner[1]?.ounit_name, 
    partnerOUnitName: data?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? data?.iiaRaw?.partner[1]?.ounit_name : data?.iiaRaw?.partner[0]?.ounit_name,
    ounitID: data?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? data?.iiaRaw?.partner[0]?.ounit_id : data?.iiaRaw?.partner[1]?.ounit_id,
    partnerOUnitID: data?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? data?.iiaRaw?.partner[1]?.ounit_id : data?.iiaRaw?.partner[0]?.ounit_id})
  }});

  const [
    fetchEWPSettings,
    {
      loading: loadingEWPSettings,
      error: errorEWPSettings,
      data: dataEWPSettings, refetch: refetchQuery,
      called: calledEWPSettings,
    },
  ] = useLazyQuery(GET_SETTINGS, {
  });

  useEffect(() => {
    if (partnerErasmusCode) {
      if (calledEWPSettings) {
        refetchQuery({ erasmusCode: partnerErasmusCode });
      } else {
        fetchEWPSettings({ variables: {erasmusCode: partnerErasmusCode } });
      }
    
    }

  }, [partnerErasmusCode]);

  useEffect(() => {
    id && !calledEdit && refetchQueryEdit({id: id});
    if (JSON.parse(localStorage.getItem('resetCreate')) === "true" && !id) {
      updateMultipleValues(defaultState);
    } else {
      !id && updateMultipleValues(theData);
    }
    
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('resetCreate')) === "true" && !id && activeStep > 0) {
      localStorage.setItem('resetCreate', JSON.stringify("false"));
    }
  }, [activeStep]);
  
  const handleBack = () => {
    updateMultipleValues({activeStep: activeStep - 1});
  };

  const handleBackSecond = () => {
    updateMultipleValues({activeStep: activeStep - 1});
  };

  const handleNext = () => {
    const hasNotIscedStudentStudies = studentStudies?.some(studentStudy => studentStudy?.subject_area?.some(subjectArea => subjectArea?.isced_f_code?.length !== 4));
    const hasNotIscedStudentTraineeships = studentTraineeships?.some(studentTraineeship => studentTraineeship?.subject_area?.some(subjectArea => subjectArea?.isced_f_code?.length !== 4));
    const hasNotIscedStaffTeachers = staffTeachers?.some(staffTeacher => staffTeacher?.subject_area?.some(subjectArea => subjectArea?.isced_f_code?.length !== 4));
    const hasNotIscedStaffTrainings = staffTrainings?.some(staffTraining => staffTraining?.subject_area?.some(subjectArea => subjectArea?.isced_f_code?.length !== 4));

    const hasNotLanguageStudentStudies = studentStudies?.every(studentStudy => !studentStudy?.language_skill?.length > 0);
    const hasNotLanguageStaffTeachers = staffTeachers?.every(staffTeacher => !staffTeacher?.language_skill?.length > 0);
    
    if (hasNotIscedStudentStudies && studentStudies?.length > 0) {
      alert("Invalid ISCED F-Codes are detected in the cooperation conditions. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.");
      return;
    }
    if (hasNotIscedStudentTraineeships && studentTraineeships?.length > 0) {
      alert("Invalid ISCED F-Codes are detected in the cooperation conditions. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.");
      return;
    }
    if (hasNotIscedStaffTeachers && staffTeachers?.length > 0) {
      alert("Invalid ISCED F-Codes are detected in the cooperation conditions. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.");
      return;
    }
    if (hasNotIscedStaffTrainings && staffTrainings?.length > 0) {
      alert("Invalid ISCED F-Codes are detected in the cooperation conditions. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.");
      return;
    }

    if (hasNotLanguageStudentStudies && studentStudies?.length > 0) {
      alert("You must fill at least one Language of Instruction in the Student Studies cooperation conditions to proceed");
      return;
    }
    if (hasNotLanguageStaffTeachers && staffTeachers?.length > 0) {
      alert("You must fill at least one Language of Instruction in the Staff Teachers cooperation conditions to proceed");
      return;
    }
    updateMultipleValues({activeStep: activeStep + 1});
  };

  const handleNextFirst = () => {
    if (contacts?.length > 0) {
      let missingName = false;
      for (let i = 0; i < contacts.length; i++) {
        if ( !contacts[i]?.contact_names[0]?.value) {
          missingName = true;
          break;
        }
      }
      if (missingName) {
        alert("You must fill the contact name to proceed");
        return;
      }
      if (validateFirstEmail) {
        alert("You must fill a valid email address to proceed");
        return;
      }
    }
    updateMultipleValues({activeStep: activeStep + 1});
  };

  const handleNextSecond = () => {
    if (type === "add" && !(dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_iia) && !(dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.ewp_api_iia)) {
      alert("The Higher Education Institution you selected is not ready to exchange Inter-Institutional Agreements via the Erasmus Without Paper Network as of yet.");
      return;
    }
    if (partnerContacts?.length > 0) {
      let missingName = false;
      for (let i = 0; i < partnerContacts.length; i++) {
        if ( !partnerContacts[i]?.contact_names[0]?.value) {
          missingName = true;
          break;
        }
      }
      if (missingName) {
        alert("You must fill the contact name to proceed");
        return;
      }
      if (validateSecondEmail) {
        alert("You must fill a valid email address to proceed");
        return;
      }
    }
    updateMultipleValues({activeStep: activeStep + 1});
  };

  const handleStep = (step) => () => {
    updateMultipleValues({activeStep: step});
  };

  const isStepFailed = (step) => {
    return step === 1 && partnerSCHAC === "";
  };

  const isStepFailedThird = (step) => {
    return step === 2 && staffTeachers?.length === 0 && staffTrainings?.length === 0 && studentStudies?.length === 0 && studentTraineeships?.length === 0;
  };

  const [
    addIIA, {
        loading: loadingAddIIA,
        error: errorAddIIA,
    },
] = useMutation(ADD_IIA);

const [
  updateIIA, {
      loading: loadingUpdateIIA,
      error: errorUpdateIIA,
  },
] = useMutation(UPDATE_IIA);

const handleSubmitIIA = async (status) => {
  if (!(dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_iia) && !(dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.ewp_api_iia)) {
    alert("The Higher Education Institution you selected is not ready to exchange Inter-Institutional Agreements via the Erasmus Without Paper Network as of yet.");
    return;
  }
  const toastId = toast.loading("Saving Changes...", toastConfiguration);

  try {

      let result = await addIIA({
          variables:  { iiaData: { 
              status: status, in_effect: false, type_of_communication:  dataEWPSettings?.ewpConnections?.length > 0 && dataEWPSettings?.ewpConnections[0]?.api_iia ? "dashboard" : "ewp",
              partner: [{hei_id: user?.heiID, contact: contacts, ounit_name: ounit, ounit_id: ounitID, conditions_hash: "", iia_code: ""},
                 {hei_id: partnerSCHAC, contact: partnerContacts, ounit_id: partnerOUnitID, ounit_name: partnerOUnitName, conditions_hash: "", iia_code: ""}],
              cooperation_conditions: {staff_teachers: staffTeachers, staff_trainings: staffTrainings, student_studies: studentStudies, student_traineeships: studentTraineeships}
          }}
            
          
      });
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: status === "draft" ? `Successfully saved as draft IIA` : "Successfully submitted IIA",
          type: "success",
          isLoading: false,
        });
        localStorage.setItem('resetCreate', JSON.stringify("true"));
        navigate("/iias/iia-list");
      }
  } catch (error) {
    toast.update(toastId, {
      ...toastConfiguration,
      render: `Error on saving changes.`,
      type: "error",
      isLoading: false,
    });
  }
};

const handleUpdateIIA = async (status) => {
  const toastId = toast.loading("Saving Changes...", toastConfiguration);

  try {

      let result = await updateIIA({
          variables:  { iiaData: { id: id,
              status: status, in_effect: false, type_of_communication: dataEditIIA?.iiaRaw?.type_of_communication,
              partner: [{hei_id: user?.heiID, contact: contacts, ounit_name: ounit, ounit_id: ounitID, conditions_hash: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.conditions_hash : dataEditIIA?.iiaRaw?.partner[1]?.conditions_hash, 
                iia_code: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.iia_code : dataEditIIA?.iiaRaw?.partner[1]?.iia_code,
                iia_id: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.iia_id : dataEditIIA?.iiaRaw?.partner[1]?.iia_id}, 
                {hei_id: partnerSCHAC, contact: partnerContacts, ounit_id: partnerOUnitID, ounit_name: partnerOUnitName, conditions_hash: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[1]?.conditions_hash : dataEditIIA?.iiaRaw?.partner[0]?.conditions_hash,
                  iia_id: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[1]?.iia_id : dataEditIIA?.iiaRaw?.partner[0]?.iia_id, iia_code: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[1]?.iia_code : dataEditIIA?.iiaRaw?.partner[0]?.iia_code}],
              cooperation_conditions: {staff_teachers: staffTeachers, staff_trainings: staffTrainings, student_studies: studentStudies, student_traineeships: studentTraineeships}
          }}
            
          
      });
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: status === "draft" ? `Successfully updated draft IIA` : "Successfully updated IIA",
          type: "success",
          isLoading: false,
        });
        navigate("/iias/single-view/" + id);
      }
  } catch (error) {
    toast.update(toastId, {
      ...toastConfiguration,
      render: `Error on saving changes.`,
      type: "error",
      isLoading: false,
    });
  }
};

  return (
    <>
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel style={{marginTop: 20, marginBottom: 20}}>
        {steps.map((label, index) => {
           const labelProps = {};
          
           if (isStepFailed(index)) {
             labelProps.optional = (
               <Typography variant="caption" color="error">
                 You must select one Partner SCHAC to proceed
               </Typography>
             );
 
             labelProps.error = true;
             return (
              <Step key={label}>
                <StepLabel {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
           } else if (isStepFailedThird(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                You must add at least one Cooperation Condition to proceed
              </Typography>
            );

            labelProps.error = true;
            return (
             <Step key={label}>
               <StepLabel {...labelProps}>
                 {label}
               </StepLabel>
             </Step>
           );
          }
           else {
            labelProps.optional = null;
            labelProps.error = false;
            return (
              <Step key={label}>
                <StepLabel {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
           }
           })}
      </Stepper>
      {activeStep === 0 ? (
        <React.Fragment>
          <FirstStep contacts={contacts} updateMultipleValues={updateMultipleValues} ounit={ounit} ounitID={ounitID}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <CustomButton buttonType={"primary"} text={"Next"} handleClick={handleNextFirst} small styles={{marginRight: 25}}/>
          </Box>
        </React.Fragment>
      ) : activeStep === 1 ? (
        <React.Fragment>
          <SecondStep institution={institution} institutionCity={institutionCity} cities={cities} institutions={institutions}
          institutionCountry={institutionCountry} updateMultipleValues={updateMultipleValues} type={type}
          partnerSCHAC={partnerSCHAC} partnerErasmusCode={partnerErasmusCode} partnerOUnitID={partnerOUnitID} partnerContacts={partnerContacts}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <CustomButton buttonType={"primary"} text={"Back"} handleClick={handleBack} styles={{marginLeft: 20}} small/>

            <Box sx={{ flex: '1 1 auto' }} />
            {((type === "edit" && dataEditIIA?.iiaRaw?.status === "draft") || type === "add")  && <CustomButton buttonType={"delete"} text={"SAVE AS DRAFT"} disabled={partnerSCHAC === "" || loadingAddIIA || loadingUpdateIIA} handleClick={() => type === "add" ? handleSubmitIIA("draft") : handleUpdateIIA("draft")} styles={{marginRight: 20}} small/>}
            <CustomButton buttonType={"primary"} text={"Next"} handleClick={handleNextSecond} disabled={partnerSCHAC === ""} styles={{marginRight: 20}} small/>
          </Box>
        </React.Fragment>
      ) : activeStep === 2 ? (
        <React.Fragment>
          <ThirdStep updateMultipleValues={updateMultipleValues} staffTeachers={staffTeachers} 
          staffTrainings={staffTrainings} studentStudies={studentStudies} studentTraineeships={studentTraineeships} partnerSCHAC={partnerSCHAC} actionType={type}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <CustomButton buttonType={"primary"} text={"Back"} handleClick={handleBackSecond} styles={{marginLeft: 20}} small/>

            <Box sx={{ flex: '1 1 auto' }} />
            
            {((type === "edit" && dataEditIIA?.iiaRaw?.status === "draft") || type === "add")  && <CustomButton buttonType={"delete"} text={"SAVE AS DRAFT"} disabled={partnerSCHAC === "" || loadingAddIIA || loadingUpdateIIA} handleClick={() => type === "add" ? handleSubmitIIA("draft") : handleUpdateIIA("draft")} styles={{marginRight: 20}} small/>}
             
            <CustomButton buttonType={"primary"} text={"Next"} disabled={staffTeachers.length === 0 && staffTrainings.length === 0 && studentStudies.length === 0 && studentTraineeships.length === 0} handleClick={() => {updateMultipleValues({checked: false}); handleNext();}} styles={{marginRight: 20}} small/>
          </Box>
        </React.Fragment>
      ) : <React.Fragment>
        <ForthStep updateMultipleValues={updateMultipleValues} staffTeachers={staffTeachers} 
          staffTrainings={staffTrainings} studentStudies={studentStudies} studentTraineeships={studentTraineeships} partnerSCHAC={partnerSCHAC} 
          contacts={contacts} partnerContacts={partnerContacts} ounit={ounit} partnerErasmusCode={partnerErasmusCode} partnerOUnitName={partnerOUnitName} partnerCountry={institutionCountry} checked={checked}
          />
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        
        <CustomButton buttonType={"primary"} text={"Back"} styles={{marginLeft: 20}} handleClick={handleBack} small/>
        <Box sx={{ flex: '1 1 auto' }} />
        {((type === "edit" && dataEditIIA?.iiaRaw?.status === "draft") || type === "add")  && <CustomButton buttonType={"delete"} text={"SAVE AS DRAFT"} disabled={partnerSCHAC === "" || loadingAddIIA || loadingUpdateIIA} handleClick={() => type === "add" ? handleSubmitIIA("draft") : handleUpdateIIA("draft")} styles={{marginRight: 20}} small/>}

        <CustomButton buttonType={"primary"} text={"Submit"} disabled={!checked || loadingAddIIA || loadingUpdateIIA} styles={{marginRight: 20}} handleClick={() => type === "add" ? handleSubmitIIA("submitted") : handleUpdateIIA("submitted")} small/>
      </Box>
    </React.Fragment>
      }
    </Box>
    <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    </>
  );
};

export default CreateIIA;