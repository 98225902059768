import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import withRow from "../../../../components/withRow";

const ChildRow = (props) => <Row className="mb-3 mx-0" {...props} />;

const Child = ({
  size,
  institutionalEmail,
  institutionalWebsite,
  updateMultipleValues,
}) => {
  return (
    <>
      <Form.Group
        as={size !== "xs" && size !== "sm" ? Col : ChildRow}
        controlId="formInstitutionalWebsite"
      >
        <Form.Label className="ps-0">Institutional Website</Form.Label>
        <Form.Control
          type="url"
          pattern="https?://.*"
          placeholder="https://www.auth.gr/en/"
          value={institutionalWebsite}
          required
          onChange={(e) =>
            updateMultipleValues({ institutionalWebsite: e.target.value })
          }
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid website url.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        as={size !== "xs" && size !== "sm" ? Col : ChildRow}
        controlId="formContactInstitutionalEmail"
      >
        <Form.Label className="ps-0">Contact Institutional Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="example@auth.gr"
          value={institutionalEmail}
          required
          onChange={(e) =>
            updateMultipleValues({ institutionalEmail: e.target.value })
          }
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email address.
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

const EmailWebsiteInput = (props) => {
  return withRow(Child, props.size !== "xs" && props.size !== "sm")(props);
};

export default EmailWebsiteInput;
