import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

import Header from "./components/Header";
import Main from "./components/Main";
import BasicFooter from "../../components/BasicFooter";
import withTitle from "../../components/withTitle";

import { ReactComponent as MailIcon } from "../../assets/mail.svg";

const ForgotPassword = () => {
  return (
    <main className="d-flex flex-column justify-content-around forgot-password">
      <div className="d-flex flex-column position-relative">
        <Header />
        <MailIcon className="position-absolute start-50 translate-middle forgot-password-mail-icon d-none d-lg-block" />
        <Main />
      </div>
      <BasicFooter>
        <h4 className="no-more-paper">no more paper; no more time wasted.</h4>
      </BasicFooter>
    </main>
  );
};

export default withTitle(ForgotPassword, "Forgot password");
