import { useState, useEffect, useRef } from "react";

import Form from "react-bootstrap/Form";
import {
  faPlus,
  faCheck,
  faFloppyDisk,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "../../../../components/Table";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal";
import MultiSelect from "../../../../components/MultiSelect";
import IconButton from "../../../../components/IconButton";

import COLORS from "../../../../data/colors";
import toastConfiguration from "../../../../data/toastConfiguration";
import permissions from "../../../../data/permissions";

import ADD_ROLE from "../../../../graphql/mutations/account/addRole";
import REMOVE_ROLE from "../../../../graphql/mutations/account/removeRole";
import UPDATE_ROLE from "../../../../graphql/mutations/account/updateRole";

const Roles = ({ accounts, roles, refetchRoles }) => {
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showRemovePermissionMessage, setShowRemovePermissionMessage] =
    useState(false);

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [buttonAction, setButtonAction] = useState("");

  const formRef = useRef(null);
  const showedRemovePermissionMessage = useRef(false);

  useEffect(() => {
    setValidated(false);
  }, [roleName, selectedPermissions]);

  const [addRole, { loading: loadingAddRole }] = useMutation(ADD_ROLE);

  const [removeRole, { loading: loadingRemoveRole }] = useMutation(REMOVE_ROLE);

  const [updateRole, { loading: loadingUpdateRole }] = useMutation(UPDATE_ROLE);

  const formIsValidated = (e) => {
    setValidated(true);
    e.preventDefault();

    if (!formRef.current.checkValidity() || selectedPermissions.length === 0) {
      e.stopPropagation();
      return false;
    }

    return true;
  };

  const handleAdd = async (e) => {
    if (!formIsValidated(e)) return;

    const toastId = toast.loading("Creating role...", toastConfiguration);
    try {
      const result = await addRole({
        variables: {
          roleName,
          permissions: selectedPermissions.map((permission) => ({
            Name: permission.label,
            Value: permission.value,
          })),
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: `Successfully created the role "${roleName}"`,
        type: "success",
        isLoading: false,
      });

      refetchRoles();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not create the role "${roleName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleDelete = async (e) => {
    const numOfAccountsThatHaveTheChosenRole = accounts.filter(
      (account) => account.role.id === selectedRoleId
    ).length;

    if (numOfAccountsThatHaveTheChosenRole !== 0) {
      toast.warning(
        `The role "${roleName}" is used by ${numOfAccountsThatHaveTheChosenRole} account${
          numOfAccountsThatHaveTheChosenRole > 1 ? "s" : ""
        }. Thus you cannot delete it.`,
        toastConfiguration
      );
      setSelectedRoleId("");
      setRoleName("");
      setSelectedPermissions([]);
      setShowModal(false);
      return;
    }

    const toastId = toast.loading("Deleting role...", toastConfiguration);
    try {
      const result = await removeRole({
        variables: {
          id: selectedRoleId,
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: `Successfully deleted the role "${roleName}"`,
        type: "success",
        isLoading: false,
      });

      refetchRoles();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not delete the role "${roleName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleEdit = async (e) => {
    if (!formIsValidated(e)) return;

    const toastId = toast.loading("Updating role...", toastConfiguration);
    try {
      const result = await updateRole({
        variables: {
          id: selectedRoleId,
          roleName: roleName,
          permissions: selectedPermissions.map((permission) => ({
            Name: permission.label,
            Value: permission.value,
          })),
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: `Successfully updated the role "${result.data.updateRole.name}"`,
        type: "success",
        isLoading: false,
      });

      refetchRoles();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not update the role "${roleName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  return (
    <>
      <Table
        data={roles.map((role) => ({
          name: role.name,
          permissions: role.permissions
            .map((permission) => permission.name)
            .join(", "),
        }))}
        page={0}
        setPage={() => {}}
        type={``}
        expandFilters={false}
        setExpandFilters={() => {}}
        filters={{}}
        setFilters={() => {}}
        sortBy={""}
        setSortBy={() => {}}
        columnsPerFieldMap={{
          roleName: "col-3",
          permissions: "col-9",
        }}
        rowClassName="accounts-and-access-table-row"
        headerClassName="accounts-and-access-table-header"
      >
        {roles.map((role) => (
          <div
            key={role.id}
            className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
          >
            <IconButton
              variant="edit"
              disabled={false}
              title="Edit role"
              onClick={(e) => {
                setButtonAction("edit");
                setSelectedRoleId(role.id);
                setRoleName(role.name);
                setSelectedPermissions(
                  role.permissions.map((permission) => ({
                    value: permission.value,
                    label: permission.name,
                  }))
                );
                setShowModal(true);
              }}
            />
            <IconButton
              variant="delete"
              disabled={loadingRemoveRole}
              title="Delete role"
              onClick={(e) => {
                setButtonAction("delete");
                setSelectedRoleId(role.id);
                setRoleName(role.name);
                setSelectedPermissions([]);
                setShowModal(true);
              }}
            />
          </div>
        ))}
      </Table>
      <div className="d-flex justify-content-end mx-1 mx-xxl-5">
        <CustomButton
          buttonType={"primary"}
          disabled={false}
          text={"Add new Role"}
          icon={faPlus}
          hasIcon={true}
          iconColor={COLORS.white}
          handleClick={(e) => {
            setSelectedRoleId("");
            setRoleName("");
            setSelectedPermissions([]);
            setShowModal(true);
          }}
        />
      </div>
      <CustomModal
        show={showModal}
        handleClose={() => {
          setSelectedRoleId("");
          setRoleName("");
          setSelectedPermissions([]);
          setShowModal(false);
        }}
        disabledCancelButton={
          loadingAddRole || loadingRemoveRole || loadingUpdateRole
        }
        body={
          (!selectedRoleId || buttonAction === "edit") && (
            <div>
              <Form validated={validated} noValidate ref={formRef}>
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>Role Name</Form.Label>
                  <Form.Control
                    placeholder="Role Name"
                    value={roleName}
                    required
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a role name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>Permissions</Form.Label>
                  <MultiSelect
                    options={permissions}
                    placeholder="Select permissions..."
                    onChange={(selectedValues) => {
                      if (selectedValues.length === 0) {
                        // if (showedRemovePermissionMessage.current) {
                        //   return;
                        // }

                        showedRemovePermissionMessage.current = true;
                        setShowRemovePermissionMessage(true);
                        setTimeout(() => {
                          setShowRemovePermissionMessage(false);
                        }, 3000);
                        return;
                      }
                      setSelectedPermissions(selectedValues);
                    }}
                    value={selectedPermissions}
                    validated={validated}
                    invalid={selectedPermissions.length === 0}
                    invalidText="Please select at least one permission for this role."
                  />
                  {showRemovePermissionMessage && (
                    <div className="invalid-feedback d-block">
                      You must have chosen at least one permission for a role.
                    </div>
                  )}
                </Form.Group>
              </Form>
            </div>
          )
        }
        button={
          !selectedRoleId ? ( // new
            <CustomButton
              buttonType={"primary"}
              disabled={loadingAddRole}
              text={"Add new Role"}
              icon={faCheck}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleAdd}
            />
          ) : buttonAction === "edit" ? ( // edit
            <CustomButton
              buttonType={"accept"}
              disabled={loadingUpdateRole}
              text={"Save Changes"}
              icon={faFloppyDisk}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleEdit}
            />
          ) :  buttonAction === "delete" && (
            // delete
            <CustomButton
              buttonType={"delete"}
              disabled={loadingRemoveRole}
              text={"Delete role"}
              icon={faTrash}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleDelete}
            />
          )
        }
        title={
          !selectedRoleId ? (
            "Add a new Role"
          ) : buttonAction === "edit" ? (
            "Editing an existing Role"
          ) : buttonAction === "delete" && (
            <>
              You are about to{" "}
              <span className="accounts-and-access-delete-text-on-modal">
                delete
              </span>{" "}
              the role{' "'}
              {`${roles.find((role) => role.id === selectedRoleId).name}"`}
            </>
          )
        }
        subtitle={
          !selectedRoleId ? (
            "Add the necessary information to add a new Role that you can later assign to your staff members"
          ) : buttonAction === "edit" ? (
            <>
              Edit the information of an existing Role and{" "}
              <span className="fw-bold">Save Changes</span>
            </>
          ) : buttonAction === "delete" && (
            "After deleting the role, you won’t be able to see it again."
          )
        }
        hasIcon
        fullscreen
      />
    </>
  );
};

export default Roles;
