import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal";
import COLORS from "../../../data/colors";
import Listing from "../components/Listing";
import { Form } from "react-bootstrap";
import "./../index.scss";
import Select from "../../../components/Select";
import { useRef, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import GET_USEFUL_INFO from "../../../graphql/queries/erasmusapp/getUsefulInfos";
import ADD_USEFUL_INFO from "../../../graphql/mutations/erasmusapp/addUsefulInfo";
import { toast, ToastContainer } from "react-toastify";
import toastConfiguration from "../../../data/toastConfiguration";
import { erasmusPlusUsefulInfoCategory, erasmusPlusUsefulInfoCategoryInfo } from "../../../data/erasmusPlusListData";
import DELETE_USEFUL_INFO from "../../../graphql/mutations/erasmusapp/deleteUsefulInfo";



const UsefulInfo = () => {
  const [showModal, setShowModal] = useState(false)
  const [validated, setValidated] = useState(false)
  const [category, setCategory] = useState(null)
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [website, setWebsite] = useState(null)
  const formRef = useRef(null);

  const { data, error, loading, refetch } = useQuery(GET_USEFUL_INFO);

  const [addUsefulInfo, { loading: loadingAddUsefulInfo }] = useMutation(ADD_USEFUL_INFO);

  const [deleteUsefulInfo, { loading: loadingDeleteUsefulInfo }] = useMutation(DELETE_USEFUL_INFO);

  const formIsValidated = (e) => {
    setValidated(true);
    e.preventDefault();

    if (!formRef.current.checkValidity()) {
      e.stopPropagation();
      return false;
    }

    return true;
  };

  const handleAdd = async (e) => {
    if (!formIsValidated(e)) return;

    const toastId = toast.loading("Creating useful info...", toastConfiguration);
    try {
      const result = await addUsefulInfo({
        variables: {
          usefulInfoInput: {
            title: title,
            body: { value: description },
            programmes: erasmusPlusUsefulInfoCategoryInfo.find(option => option.category === category)?.programme,
            mobilityPhases: erasmusPlusUsefulInfoCategoryInfo.find(option => option.category === category)?.mobilityPhase,
            tags: erasmusPlusUsefulInfoCategoryInfo.find(option => option.category === category)?.tag,
            website: website,
          }
        }
      });
      if (result?.data?.addUsefulInfo) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `Successfully created the useful info`,
          type: "success",
          isLoading: false,
        });
        refetch();
        handleCloseModal();
      }
      else {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `Could not create the useful info`,
          type: "error",
          isLoading: false,
        });
      }
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not create the useful info`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleDelete = async (id, externalID) => {

    const toastId = toast.loading("Deleting useful info...", toastConfiguration);
    try {
      const result = await deleteUsefulInfo({
        variables: {
          id: id,
          externalID: externalID
        }
      });
      if (result?.data?.deleteUsefulInfo) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `Successfully delete the useful info`,
          type: "success",
          isLoading: false,
        });
        refetch();
      }
      else {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `Could not delete the useful info`,
          type: "error",
          isLoading: false,
        });
      }
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not delete the useful info`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const renderText = () => {
    let optionText = erasmusPlusUsefulInfoCategoryInfo.find(option => option.category === category)
    return (
      <div className="modal-body-text">
        {optionText.title}<br /><br />
        {optionText.subtitle} <br /><br />
        {optionText.text}<br />
      </div>
    )
  }

  const handleCloseModal = () => {
    setCategory("");
    setTitle("");
    setDescription("");
    setWebsite("");
    setShowModal(false);
    setValidated(false);
  }

  return (
    <div>
      <Listing data={data?.usefulInfoList} changeShowModal={() => setShowModal(!showModal)} type={"Useful Info"} loading={loading} error={error} handleDelete={(id, externalID) => handleDelete(id, externalID)} />
      <CustomModal
        size={"lg"}
        show={showModal}
        handleClose={handleCloseModal}
        disabledCancelButton={loadingAddUsefulInfo}
        bodyClassName="modal-body"
        body={
          (
            <div style={{ marginTop: 15, marginLeft: 20, marginRight: 20, marginBottom: 15 }}>
              <Form validated={validated} noValidate id={"upload-ola-form"} onSubmit={() => setValidated(true)} ref={formRef}>
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>Category <span style={{ color: "red" }}>*</span></Form.Label>
                  <Select
                    options={erasmusPlusUsefulInfoCategory?.map((el) => ({
                      value: el,
                      label: el
                    }))}
                    placeholder="Select Category"
                    onChange={(event) => setCategory(event?.value)}
                    validated={validated}
                    invalid={!category}
                    value={{
                      "label": category
                    }}

                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a category.
                  </Form.Control.Feedback>
                </Form.Group>
                {category && <div>
                  <div className="modal-body-label">
                    Info on the Erasmus+ App
                  </div>
                  <br />
                  {renderText()}
                </div>}
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>Title <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    placeholder="Title"
                    value={title}
                    required
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a title.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>Description <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    value={description}
                    required
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a description.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    placeholder="Website"
                    value={website}
                    pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid website.
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="modal-body-text">
                  Only submit content that you own. By submitting an update, you warrant that you have all the necessary rights to use and license the content and that this does not violate, misappropriate or infringe any law or any third parties' rights, including, without limitation, privacy rights and copyright. See more information in Terms and Conditions and Privacy Policy.
                </div>

              </Form>
            </div>
          )
        }
        button={
          <CustomButton
            buttonType={"primary"}
            disabled={loadingAddUsefulInfo}
            text={"Publish useful info"}
            icon={faPlus}
            hasIcon={true}
            iconColor={COLORS.white}
            handleClick={handleAdd}
          />

        }
        title={
          <div>Add a new <span style={{ color: "#3D619C" }}>Useful Info</span></div>
        }
        subtitle={
          ""
        }
        hasIcon
        fullscreen
      />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default UsefulInfo;
