import { gql } from "@apollo/client";

const DELETE_DEAL = gql`
mutation deleteDeal($id: Int!, $externalID: String!)
    {  deleteDeal(id: $id, externalID: $externalID)
    
}`
;

export default DELETE_DEAL;
