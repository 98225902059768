import { gql } from "@apollo/client";

const GET_SHORT_TERM_LA_COUNT = gql`
  query shortTermLACount(
        $homeHeiID: String, 
        $hostHeiID: String, 
        $academicYear: String, 
        $status: String, 
        $studentFirstName: String,
        $studentLastName: String, 
        $erasmusCode: String, 
        $country: [String]
     ){
        shortTermLACount(
            homeHeiID: $homeHeiID,
            hostHeiID: $hostHeiID,
            academicYear: $academicYear,
            status: $status,
            studentFirstName: $studentFirstName,
            studentLastName: $studentLastName,
            erasmusCode: $erasmusCode,
            country: $country
        )
    }`
    ;

export default GET_SHORT_TERM_LA_COUNT;
