import { gql } from "@apollo/client";

const GET_OUTGOING_NOMINATION = gql`
    query outgoingNomination(
    $id: String!)
    {outgoingNomination(id: $id){
        id
        title
        created
        field_academic_year
        field_academic_term
        field_cv{id, fileName}
        field_cv_link
        field_req_ects
        field_student_email
        field_req_eqf
        field_student_esi
        field_external_cert{id, fileName}
        field_external_cert_link
        field_req_isced
        field_lang_level{id, fileName}
        field_lang_level_link
        field_motivation_letter{id, fileName}
        field_motiv_letter_link
        field_student_name
        field_nomination_status
        field_other_document{id, fileName}
        field_other_document_link
        field_other_requirement
        field_portfolio
        field_recom_letter{id, fileName}
        field_recom_letter_link
        field_reference_contact
        field_student_isced
        field_student_eqf
        field_tor{id, fileName}
        field_tor_link
        hasFiles
		field_mobility_details{
			id
            field_home_erasmus_code
            field_home_name
            field_home_org_unit
            field_host_erasmus_code
            field_host_name
            field_host_org_unit
            field_mobility_details_status
            field_end_date
            field_start_date
            field_coop_condition_id
            field_cv_required
            field_external_cert_required
            field_lang_level_required
            field_motivation_letter_required
            field_other_document_required
            field_recom_letter_required
            field_tor_required
            field_other_requirement_required
            field_portfolio_required
            field_reference_contact_required
            canBeAccepted
		}
      }
    }`
;

export default GET_OUTGOING_NOMINATION;
