import "./index.scss";

const Footer = () => {
  return (
    <footer className="px-3 mt-5 mt-lg-0 d-flex flex-grow-1 flex-column justify-content-evenly align-items-center register-footer">
      <div className="text-start mx-5 registration-process-text">
        *The registration process should be carried out by a manager from the
        International Relations Office, as this person will be the main
        institutional account holder and later on give access to other
        colleagues within the HEI so that they are able to use the Erasmus
        Dashboard using their own individual accounts.
      </div>
      <div className="text-center">
        <div>
          Discover general information about the EWP Dashboard and other
          frequently asked questions{" "}
          <a
            className="text-decoration-none footer-bold-link"
            href="https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/20676644/Enhanced+EWP+Dashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </div>
        <div>
          Find the updated Erasmus Dashboard Privacy Policy and Terms and
          Conditions{" "}
          <a
            className="text-decoration-none footer-bold-link"
            href="https://www.erasmus-dashboard.eu/institution/registration/termsandconditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </div>
        <div>
          For any other issue, don’t hesitate to{" "}
          <a
            className="text-decoration-none footer-bold-link"
            href="https://esci-sd.atlassian.net/servicedesk/customer/portals"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact our support team
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
