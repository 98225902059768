import { useCallback, useEffect, useRef } from "react";
import { VariableSizeList as List } from "react-window";

const optionHeight = 37;

const Row = ({ data, index, setSize }) => {
  const rowRef = useRef();

  useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [setSize, index]);

  return (
    <div
      ref={rowRef}
    >
      {data[index]}
    </div>
  );
};

const SelectMenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const listRef = useRef();
  const sizeMap = useRef({});

  const initialOffset = options.indexOf(value) * optionHeight;

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = index => sizeMap.current[index] || 50;

  if (!children.length) return <div>{children}</div>;

  return (
    <List
      height={maxHeight}
      ref={listRef}
      itemCount={children.length}
      itemSize={getSize}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div style={style}>
          <Row
            data={children}
            index={index}
            setSize={setSize}
          />
        </div>
      )}
    </List>
  );
};

export default SelectMenuList;
