import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import withNavBar from "../../components/WithNavBar";
import withFooter from "../../components/WithFooter";

import toastConfiguration from "../../data/toastConfiguration";
import { Row } from "react-bootstrap";
import "./index.scss";
import useBreakpoint from "../../hooks/useBreakpoint";
import withTitle from "../../components/withTitle";

const Home = () => {
  const location = useLocation();
  const [ size ] = useBreakpoint();
  const showedToast = useRef(false);

  useEffect(() => {
    if (showedToast.current) return;

    showedToast.current = true;
    if (location.state?.showAuthorizationToast) {
      toast.warning(
        "You are not authorized to access this page",
        toastConfiguration
      );
    }
  }, []);

  return (
    <>
    {!(size === "xs" || size === "sm" || size === "md") && ( <>
      <Row style={{marginLeft: 40, marginRight: 40, marginTop:40, backgroundColor: "white"}}>
        <div className="h1-style" style={{display: "flex", marginTop: 25, marginLeft: 8, marginRight: 30}}>Welcome to EWP Dashboard! <span className="ghost-icon"></span></div>
        <div className="body" style={{display: "flex", marginTop: 20, marginLeft: 8, marginRight: 30}}>Welcome to EWP Dashboard, the tool for managing your students' mobilities through the Erasmus+ program. 
        As an IRO, you play a critical role in helping your students make the most of their experiences abroad, and EWP Dashboard is here to support you in that effort. 
<br/>We are excited to announce that we have made some significant improvements to the user interface and user experience of the application.
 We have worked hard to make EWP Dashboard more intuitive and user-friendly, and we believe that these changes will make it easier for you to manage your students' mobilities and make the most of the opportunities offered by the Erasmus+ program.
</div>
      
        <div style={{display: "flex", marginTop: 20, marginBottom: 43}}>
        
          <a className="knowledge-icon" style={{marginRight: 34}} href={`https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/20676644/Enhanced+EWP+Dashboard`} target="_blank"/>
          
            <a className="service-desk-icon" style={{marginRight: 34}} href={`https://esci-sd.atlassian.net/servicedesk/customer/portals`} target="_blank"/>
      
        </div>

      </Row>
</>
    )}
    {(size === "xs" || size === "sm" || size === "md") && ( <>
      <Row style={{marginLeft: 15, marginRight: 15, marginTop:40, backgroundColor: "white"}}>
        <div className="h1-style" style={{display: "flex", marginTop: 25, marginLeft: 8, marginRight: 30}}>Welcome to EWP Dashboard! </div><div style={{display: "flex", justifyContent: "center"}}><span style={{marginLeft: 0}}className="ghost-icon"></span></div>
        <div className="body" style={{display: "flex", marginTop: 20, marginLeft: 8, marginRight: 30}}>Welcome to EWP Dashboard, the tool for managing your students' mobilities through the Erasmus+ program. 
        As an IRO, you play a critical role in helping your students make the most of their experiences abroad, and EWP Dashboard is here to support you in that effort. 
<br/>We are excited to announce that we have made some significant improvements to the user interface and user experience of the application.
 We have worked hard to make EWP Dashboard more intuitive and user-friendly, and we believe that these changes will make it easier for you to manage your students' mobilities and make the most of the opportunities offered by the Erasmus+ program.
 </div>
      
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 20, marginBottom: 43}}>
        
          <a className="knowledge-icon"  href={`https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/20676644/Enhanced+EWP+Dashboard`} target="_blank"/>
          <br/>
            <a className="service-desk-icon"  href={`https://esci-sd.atlassian.net/servicedesk/customer/portals`} target="_blank"/><br/>
      
        </div>

      </Row>
</>
    )}
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </>
  );
};

export default withFooter(
  withNavBar(withTitle(Home, "Home"), "Home Page", "")
);