import { useRef, useState, useContext, useLayoutEffect } from "react";

import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faBell,
  faCircleQuestion,
  faGear,
  faRightFromBracket,
  faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

import { ReactComponent as EWPDashboardLogo } from "../../assets/ewp-1.svg";

import COLORS from "../../data/colors";
import useBreakpoint from "../../hooks/useBreakpoint";
import useUser from "../../hooks/useUser";
import useAuth from "../../hooks/useAuth";
import AnimationContext from "../../context/AnimationContext";

const withNavBar = (Component, title, subtitle) => (props) => {
  const { setAuth } = useAuth();
  const { setUser, user } = useUser();
  const navigate = useNavigate();
  const [size] = useBreakpoint();
  const [showTooltip, setShowTooltip] = useState(false);
  const { showedAnimation, setShowedAnimation } = useContext(AnimationContext);

  const tooltipTarget = useRef(null);

  useLayoutEffect(() => {
    if (!showedAnimation && size !== "xs" && size !== "sm" && size !== "md") {
      setTimeout(() => {
        setShowedAnimation(true);
      }, 14000);
    }
  }, []);

  const handleLogout = (event) => {
    event.preventDefault();

    setAuth({});
    setUser({});
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
      {size !== "xs" && size !== "sm" && size !== "md" && (
        <>
          <Overlay
            target={tooltipTarget.current}
            show={
              showTooltip && size !== "xs" && size !== "sm" && size !== "md"
            }
            placement="bottom"
          >
            {(props) => (
              <Tooltip
                id="overlay-hei"
                {...props}
                className="position-absolute"
              >
                {user?.heiID}
              </Tooltip>
            )}
          </Overlay>
          <header
            className={
              size === "lg" || size === "xl"
                ? "d-flex justify-content-between mx-2 pt-4 pt-xxl-5"
                : "d-flex justify-content-between mx-5 pt-4 pt-xxl-5"
            }
          >
            <div className="flex-grow-1 align-items-center">
              <div className={`title h1-style ${subtitle ? "" : "mb-0"}`}>
                {title}
              </div>
              {subtitle && (
                <div className="subtitle fw-normal h3-style">{subtitle}</div>
              )}
            </div>
            <div className="d-flex justify-content-between flex-grow-1 align-items-center">
              <div className="text-center text-xxl-end nav-bar-email-container h6-style">
                <span
                  className="me-4 d-inline-block"
                  ref={tooltipTarget}
                  onMouseEnter={(e) => {
                    setShowTooltip(true);
                  }}
                  onMouseLeave={(e) => {
                    setShowTooltip(false);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBuildingColumns}
                    size="2xl"
                    color={COLORS.black}
                    className={showedAnimation ? "" : "hei-icon"}
                  />
                </span>
                {user?.email || "-"}
              </div>
              <div className="d-flex justify-content-between justify-content-xxl-evenly flex-grow-1 align-items-center">
                <span>
                  <div className="vl"></div>
                </span>
                <span className="icon text-end">
                  <a
                    href="https://erasmus-plus.ec.europa.eu/european-student-card-initiative/ewp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EWPDashboardLogo className="navbar-ewp-dashboard-logo" />
                  </a>
                </span>
                <span className="icon">
                  <a
                    href="https://esci-sd.atlassian.net/servicedesk/customer/portals"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      size="lg"
                      color={COLORS.black}
                    />
                  </a>
                </span>
                <span>
                  <div className="vl"></div>
                </span>
                <span className="icon" onClick={handleLogout}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    size="lg"
                    color={COLORS.black}
                  />
                </span>
              </div>
            </div>
          </header>
          <Component {...props} />
        </>
      )}
      {(size === "xs" || size === "sm" || size === "md") && (
        <>
          <div className="flex-grow-1 align-items-center">
            <div className="title h1-style">{title}</div>
            <div className="subtitle fw-normal h3-style">{subtitle}</div>
          </div>
          <Component {...props} />
        </>
      )}
    </>
  );
};

export default withNavBar;
