export const SET_INSTITUTIONAL_EMAIL_ACTION = "SET_INSTITUTIONAL_EMAIL_ACTION";
export const SET_PHONE_CODE_ACTION = "SET_PHONE_CODE_ACTION";
export const SET_INSTITUTIONAL_PHONE_ACTION = "SET_INSTITUTIONAL_PHONE_ACTION";
export const SET_INSTITUTIONAL_WEBSITE_ACTION =
  "SET_INSTITUTIONAL_WEBSITE_ACTION";
export const SET_INSTITUTION_COUNTRY_ACTION = "SET_INSTITUTION_COUNTRY_ACTION";
export const SET_INSTITUTION_CITY_ACTION = "SET_INSTITUTION_CITY_ACTION";
export const SET_INSTITUTION_ACTION = "SET_INSTITUTION_ACTION";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";

/**
 * Reducer for handling register associated actions and state changes
 */
export const reducer = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};
