export const StatusNomination = {
    "accepted_by_sending": "Accepted by Sending",
    "accepted_by_student": "Accepted by Student",
    "accepted_by_receiving": "Accepted by Receiving",
    "rejected_by_receiving": "Rejected by Receiving",
    "submitted": "Submitted",
    "rejected_by_sending": "Rejected by Sending",
    "cancelled": "Cancelled",
    "rejected_by_student": "Rejected by Student",
    "waiting_list": "Waiting List"
  };
  