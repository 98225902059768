import { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faXmark,
  faTrashCan,
  faPen,
  faPlus,
  faFileZipper,
} from "@fortawesome/free-solid-svg-icons";

import "./index.scss";
import COLORS from "../../data/colors";
import { Button } from "react-bootstrap";

const variantSizeIconMap = {
  accept: { icon: faCheck, iconSize: "lg" },
  reject: { icon: faXmark, iconSize: "lg" },
  delete: { icon: faTrashCan, iconSize: "1x" },
  edit: { icon: faPen, iconSize: "1x" },
  add: { icon: faPlus, iconSize: "lg" },
  download: { icon: faFileZipper, iconSize: "lg" },
};

const IconButton = forwardRef(
  (
    {
      variant,
      size = "lg",
      iconSize = "lg",
      disabled,
      onClick,
      title,
      ...rest
    },
    ref
  ) => {
    return (
      <Button
        className={`${variant}-icon-button px-2 py-1 border-0`}
        disabled={disabled}
        size="lg"
        onClick={onClick}
        ref={ref}
        title={title}
        {...rest}
      >
        <FontAwesomeIcon
          size={variantSizeIconMap[variant]?.iconSize || "1x"}
          icon={variantSizeIconMap[variant]?.icon || faCheck}
          color={COLORS.white}
        />
      </Button>
    );
  }
);

export default IconButton;
