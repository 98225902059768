import { useState } from "react";

import TableRow from "../TableRow";
import TableHeader from "../TableHeader";

import useBreakpoint from "../../../../hooks/useBreakpoint";

import { replaceUnderscoreAndCamelCaseToString } from "../../";

import "../../index.scss"; // original table styles
import "./index.scss"; // skeleton table styles

const TableSkeleton = ({ columnsPerFieldMap }) => {
  const [size] = useBreakpoint();
  const dummyData = Array(10).fill({
    id: "5a621472dc690a48f1308edc",
    institution: "Test institution for loading",
    email: "t@h.be",
    website: "test.com",
    phone: "+223322332",
    registrationTime: "19 Jan 2018",
    status: 0,
  });
  const tableHeaders = Object.keys(dummyData[0]).map((key) => "");
  const [isMobile, setIsMobile] = useState(
    size === "xs" || size === "sm" || size === "md"
  );

  return (
    <div className="py-2">
      <div className="col-12 pt-0 pl-3 pr-0 mx-auto">
        <div className="mx-5 table-skeleton-container">
          <div className="table-skeleton-filters"></div>
          <div className="row ms-0">
            <div className="table-skeleton-total ps-0"></div>
          </div>
          <>
            {!isMobile && (
              <TableHeader
                labels={tableHeaders}
                isMobile={isMobile}
                fieldToHeader={replaceUnderscoreAndCamelCaseToString}
                columnsPerFieldMap={columnsPerFieldMap}
                className="table-header table-skeleton-header"
              />
            )}
            {dummyData
              ?.map((row) => {
                return { ...Object.keys(row).forEach((key) => "") };
              })
              .map((row, index) => {
                return (
                  <TableRow
                    key={"row-id-" + (row.id || index)}
                    row={row}
                    labels={tableHeaders}
                    isMobile={isMobile}
                    fieldToHeader={replaceUnderscoreAndCamelCaseToString}
                    type={"requests-"}
                    columnsPerFieldMap={columnsPerFieldMap}
                    rowClassName={"table-skeleton-row"}
                  >
                    {!isMobile && (
                      <div
                        key={index}
                        className={`py-3 px-2 justify-content-around align-items-center table-skeleton-buttons-row flex-grow-1 d-flex`}
                      >
                        <div className="w-100 h-100"></div>
                      </div>
                    )}
                  </TableRow>
                );
              })}
          </>
        </div>
      </div>
    </div>
  );
};

export default TableSkeleton;
