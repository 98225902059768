import { gql } from "@apollo/client";

const REGISTER_INSTITUTION = gql`
  mutation registerInstitution(
    $erasmusCode: String
    $phone: String
    $email: String
    $website: String
  ) {
    registerInstitution(
      ErasmusCode: $erasmusCode
      Phone: $phone
      Email: $email
      Website: $website
    ) {
      id
    }
  }
`;

export default REGISTER_INSTITUTION;
