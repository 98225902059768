export const SET_TABLE_DATA_ACTION = "SET_TABLE_DATA";
export const SET_TABLE_HEADERS_ACTION = "SET_TABLE_HEADERS";
export const SET_IS_MOBILE_ACTION = "SET_IS_MOBILE";
export const SET_FILTERS_ACTION = "SET_FILTERS";
export const SET_TOTAL_ROWS = "SET_TOTAL_ROWS";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";

/**
 * Reducer for handling filter associated actions and state changes
 */
export const reducer = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};
