import { gql } from "@apollo/client";

const GET_ERASMUS_CODES = gql`
  query heis($countryCodes: [String]){
    heis(countryCodes: $countryCodes) {
      name
      erasmusCode
      country
      heiID
    }
  }
`;

export default GET_ERASMUS_CODES;