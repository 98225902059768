import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

const withFooter = (Component) => (props) => {
  return (
    <>
      <div className="page-container">
        <Component {...props} />
      </div>
      <footer className="text-center">
        <span>Copyright&nbsp;</span>
        <FontAwesomeIcon icon={faCopyright} />
        <span>&nbsp;{new Date().getFullYear()}. All rights reserved.</span>
      </footer>
    </>
  );
};

export default withFooter;
