import { gql } from "@apollo/client";

const GET_EWP_SETTINGS = gql`
query ewpConnections
    {ewpConnections{
        api_iia
        api_ola 
        api_static
        api_ounits
    }

    
}`
;

export default GET_EWP_SETTINGS;
