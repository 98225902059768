import { gql } from "@apollo/client";

const ADD_ROLE = gql`
  mutation addRole($roleName: String!, $permissions: [AccountAbilityInput]) {
    addRole: addAccountFunction(
      FunctionName: $roleName
      accountAbilities: $permissions
    ) {
      name: Name
    }
  }
`;

export default ADD_ROLE;
