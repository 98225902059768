export const getFilterValueForMultiSelectField = (el) => {
    if (el === [] || el === null || el === undefined) {
        return null;
    } else {
        return el.map((i) => ({ label: i }));
    }
};

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export const handleFileRead = async (event,setImage,setInvalidImageLength) => {
    const cancel = !event.target.files;

    if (cancel) return;

    const [{ size, name }] = event.target.files;
    const maxSize = 4 * 1024 * 1024; // 4 MB in bytes
    if (size < maxSize && [".png",".jpg",".jpeg"].some(v => name.includes(v))) {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setImage(base64);
        setInvalidImageLength(false);
    } else {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setImage(base64);
        setInvalidImageLength(true);
    }

}