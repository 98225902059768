import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";

const CustomButton = ({
  href,
  disabled,
  handleClick,
  icon,
  hasIcon,
  iconColor,
  buttonType,
  text,
  small,
  styles,
  type,
  children,
}) => {
  const buttonTypeToClassMap = {
    primary: {
      notDisabled: "primary-blue",
      disabled: "primary-blue-disabled",
    },
    secondary: {
      notDisabled: "secondary",
      disabled: "secondary-disabled",
    },
    outlined: {
      notDisabled: "outlined",
      disabled: "outlined-disabled",
    },
    accept: {
      notDisabled: "accept",
      disabled: "accept-disabled",
    },
    decline: {
      notDisabled: "decline",
      disabled: "decline-disabled",
    },
    orange: {
      notDisabled: "orange",
      disabled: "orange-disabled",
    },
    delete: {
      notDisabled: "delete",
      disabled: "delete-disabled",
    },
  };

  return (
    <>
      <Button
        type={type?type:"button"}
        href={href}
        className={`shadow-none ${
          buttonTypeToClassMap[buttonType]?.[
            disabled ? "disabled" : "notDisabled"
          ]
        }`}
        disabled={disabled}
        onClick={handleClick}
        id={small && "small"}
        style={styles}
      >
        {hasIcon && (
          <span>
            <FontAwesomeIcon
              icon={icon}
              color={iconColor}
              className="with-icon"
              id={small && "smallIcon"}
            />
          </span>
        )}
        {text && text.toString().toUpperCase()} {children}
      </Button>
    </>
  );
};

export default CustomButton;
