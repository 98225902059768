import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import client from "./lib/apollo";
import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { DataProvider } from "./context/DataContext";
import { AnimationProvider } from "./context/AnimationContext";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// disable react dev tools on production and test environments
if (process.env.NODE_ENV !== "development") {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <UserProvider>
            <DataProvider>
              <AnimationProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </AnimationProvider>
            </DataProvider>
          </UserProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
