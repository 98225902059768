import { gql } from "@apollo/client";

const LOGIN_REGISTERED_INSTITUTION = gql`
  mutation loginRegisteredInstitution($email: String, $password: String) {
    loginRegisteredInstitution(email: $email, password: $password) {
      id
      Phone
      Token
      CurrentHeiId
      CurrentErasmusCode
    }
  }
`;

export default LOGIN_REGISTERED_INSTITUTION;
