import { gql } from "@apollo/client";

const GET_INCOMING_NOMINATION = gql`
    query incomingNomination(
    $id: String!)
    {incomingNomination(id: $id){
        id
        title
        created
        field_cv{id, fileName}
        field_external_cert{id, fileName}
        field_lang_level{id, fileName}
        field_motivation_letter{id, fileName}
        field_other_document{id, fileName}
        field_recom_letter{id, fileName}
        field_tor{id, fileName}
        field_cv_link
        field_req_ects
        field_student_email
        field_academic_year
        field_academic_term
        field_req_eqf
        field_student_esi
        field_external_cert_link
        field_req_isced
        field_mobility_details{
            id
            field_home_erasmus_code
            field_home_name
            field_home_org_unit
            field_host_erasmus_code
            field_host_name
            field_host_org_unit
            field_start_date
            field_end_date
            field_mobility_details_status
            canBeAccepted
            field_coop_condition_id
        }
        field_motiv_letter_link
        field_student_name
        field_nomination_status
        field_other_requirement
        field_portfolio
        field_recom_letter_link
        field_reference_contact
        field_student_isced
        field_student_eqf
        field_tor_link
      }
    }`
;

export default GET_INCOMING_NOMINATION;
