import { reducer } from "../reducers/tableReducers";
import { useReducer, useCallback } from "react";
import {
  SET_TABLE_DATA_ACTION,
  SET_TABLE_HEADERS_ACTION,
  SET_FILTERS_ACTION,
  SET_IS_MOBILE_ACTION,
  SET_TOTAL_ROWS,
  UPDATE_MULTIPLE_VALUES_ACTION,
} from "../reducers/tableReducers";

// fallback state
export const defaultState = {
  tableData: [],
  tableHeaders: [],
  isMobile: "",
  filters: [],
  total: 0,
};

/**
 * Used to handle filters for IIA queries
 *
 * @param {Object} initialState the initial state of the filters, fallbacks to a default state with empty string and lists if no initial state is provided
 * @returns Filters state as well as handlers for changing the state
 */
export const useTable = (initialState = defaultState) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setTableData = useCallback((data) => {
    dispatch({ type: SET_TABLE_DATA_ACTION, payload: { tableData: data } });
  }, []);

  const setTableHeaders = useCallback((data) => {
    dispatch({
      type: SET_TABLE_HEADERS_ACTION,
      payload: { tableHeaders: data },
    });
  }, []);

  const setIsMobile = useCallback((data) => {
    dispatch({ type: SET_IS_MOBILE_ACTION, payload: { isMobile: data } });
  }, []);

  const setFilters = useCallback((data) => {
    dispatch({ type: SET_FILTERS_ACTION, payload: { filters: data } });
  }, []);

  const setTotal = useCallback((data) => {
    dispatch({ type: SET_TOTAL_ROWS, payload: { total: data } });
  }, []);

  const updateMultipleValues = useCallback((data) => {
    dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data });
  }, []);

  return {
    state,
    setTableData,
    setTableHeaders,
    setIsMobile,
    setTotal,
    setFilters,
    updateMultipleValues,
  };
};
