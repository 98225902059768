import { gql } from "@apollo/client";

const GET_HEI = gql`
query hei($heiID: String)
    {hei (heiID: $heiID) {
        erasmusCode
        country
        name
    }  
}`
;

export default GET_HEI;
