import { gql } from "@apollo/client";

const GET_ISCED_CODES = gql`
query iscedCodes
    {iscedCodes {
        name
        code
    }  
}`
;

export default GET_ISCED_CODES;
