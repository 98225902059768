export const SET_ACADEMIC_YEAR_ACTION = "SET_ACADEMIC_YEAR";
export const SET_ACADEMIC_TERM_ACTION = "SET_ACADEMIC_TERM";
export const SET_CREATED_BY_STUDENT_ACTION = "SET_CREATED_BY_STUDENT";
export const SET_ERASMUS_CODE_ACTION = "SET_ERASMUS_CODE";
export const SET_STUDENT_NAME_ACTION = "SET_STUDENT_NAME";
export const SET_STUDENT_EMAIL_ACTION = "SET_STUDENT_EMAIL";
export const SET_STUDENT_EQF_ACTION = "SET_STUDENT_EQF";
export const SET_STUDENT_ISCED_ACTION = "SET_STUDENT_ISCED";
export const SET_STATUS_ACTION = "SET_STATUS";
export const SET_EXPAND_FILTERS_ACTION = "SET_EXPAND_FILTERS";
export const SET_SELECTED_FILTERS_LIST_ACTION = "SET_SELECTED_FILTERS_LIST";
export const SET_SORT_BY_ACTION = "SET_SORT_BY";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";

/**
 * Reducer for handling filter associated actions and state changes
 */
export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload,
    }
}