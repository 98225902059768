import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BACKEND_URL } from "../data/constants";
import { onError } from "@apollo/client/link/error";

const operationNamesToExcludeOnError = [
  "registerInstitution",
  "accountLogin",
  "loginRegisteredInstitution",
  "changePasswordAccount",
  "changePasswordRegisteredInstitution",
];

const logout = () => {
  localStorage.removeItem("token");
  window.location.reload();
};

const httpLink = createHttpLink({
  uri: BACKEND_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token") || "";
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const logoutOnNetworkErrorLink = onError((args) => {
  (async () => {
    let internetConnectionPingTestResponse;
    try {
      internetConnectionPingTestResponse = await fetch(
        "https://www.google.com",
        {
          mode: "no-cors",
        }
      );
    } catch (err) {
      console.error(err);
    }
    // if user has internet connection and our server returns a 4xx or 5xx status code
    // it means that jwt expired (or backend services are not operating)
    // in case it is a unauthenticated operation there is no need to logout
    if (
      internetConnectionPingTestResponse?.status === 0 &&
      args.graphQLErrors === undefined &&
      !operationNamesToExcludeOnError.includes(args?.operation?.operationName)
    ) {
      logout();
    }
  })();
});

const client = new ApolloClient({
  link: from([authLink, logoutOnNetworkErrorLink, httpLink]),
  cache: new InMemoryCache({addTypename: false}),
});

export default client;
