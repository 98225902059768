import { Form, Row, Col, Container, Spinner } from 'react-bootstrap';
import { useQuery, useMutation } from "@apollo/client";
import CustomButton from '../../components/CustomButton';
import "./index.scss";
import {
    faFloppyDisk
} from "@fortawesome/free-solid-svg-icons";
import toastConfiguration from "../../data/toastConfiguration";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import GET_UKRAINE_ASSISTANCE_INFO from '../../graphql/queries/ukraineAssistance/getUkraineAssistanceInfo';
import CHANGE_UKRAINE_ASSISTANCE_INFO from '../../graphql/mutations/ukraineAssistance/changeUkraineAssistanceInfo';
import validator from 'validator';

const UkraineAssistanceSettings = () => {
    const [apiShelter, setApiShelter] = useState("");
    const [apiHealth, setApiHealth] = useState("");
    const [apiFastTrack, setApiFastTrack] = useState("");
    const [apiScholarship, setApiScholarship] = useState("");
    const [apiMoreInfoUrl, setApiMoreInfoUrl] = useState("");
    const [isUrlValid, setIsUrlValid] = useState(true);

    const {
        loading: loadingUkraineAssistance,
        error: errorUkraineAssistance,
        data: dataUkraineAssistance,
        refetch: refetchQuery,
    } = useQuery(GET_UKRAINE_ASSISTANCE_INFO, {
        onCompleted: (data) => {
            setApiShelter(data?.getUkraineAssistanceInfo[0]?.shelter || "Unknown");
            setApiHealth(data?.getUkraineAssistanceInfo[0]?.health || "Unknown");
            setApiFastTrack(data?.getUkraineAssistanceInfo[0]?.fast_track || "Unknown");
            setApiScholarship(data?.getUkraineAssistanceInfo[0]?.scholarship || "Unknown");
            setApiMoreInfoUrl(data?.getUkraineAssistanceInfo[0]?.more_info_url || "");
        },
    });

    const [updateUkraineAssistance] = useMutation(CHANGE_UKRAINE_ASSISTANCE_INFO);

    const handleSave = async () => {

        if (!validateSettings()) {
            return;
        }

        const toastId = toast.loading("Updating Ukraine Assistance Information...", toastConfiguration);

        try {
            const result = await updateUkraineAssistance({
                variables: {
                    shelter: apiShelter,
                    health: apiHealth,
                    fastTrack: apiFastTrack,
                    scholarship: apiScholarship,
                    moreInfoUrl: apiMoreInfoUrl,
                },
            });

            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully updated Ukraine Assistance Information`,
                    type: "success",
                    isLoading: false,
                });
                setTimeout(() => {
                    refetchQuery();
                }, 2000);
            }
        } catch (err) {
            toast.update(toastId, {
                ...toastConfiguration,
                render: `Could not update Ukraine Assistance Information`,
                type: "error",
                isLoading: false,
            });
        }
    };

    const handleCancel = () => {
      refetchQuery();
      setApiShelter(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.shelter || "Unknown");
      setApiHealth(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.health || "Unknown");
      setApiFastTrack(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.fast_track || "Unknown");
      setApiScholarship(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.scholarship || "Unknown");
      setApiMoreInfoUrl(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.more_info_url || "");
      setIsUrlValid(true);
    };

    const validateSettings = () => {
      // Validate at least one "YES" option is selected or a valid URL is provided
      if (!(apiShelter === "Yes" || apiHealth === "Yes" || apiFastTrack === "Yes" || apiScholarship === "Yes" || apiMoreInfoUrl)) {
        toast.error("Please select at least one 'Yes' option or provide a valid link");
        return false;
      }
  
      // Validate URL format
      if (apiMoreInfoUrl) {
          const isValid = validator.isURL(apiMoreInfoUrl);
  
          if (!isValid) {
            toast.error("Invalid URL");
            return false;
          }
      }
  
      return true;
    };

    const handleUrlChange = (e) => {
    const url = e.target.value;
    setApiMoreInfoUrl(url);

    // Check if the entered URL is valid
    const isValid = validator.isURL(url)
    setIsUrlValid(isValid);
    };

    return (
        <main className="h-auto text-center mt-4">
            {loadingUkraineAssistance ? (
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <Spinner
                        as="div"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="align-middle my-auto"
                    />
                    <span>Loading...</span>
                </div>
            ) : errorUkraineAssistance ? (
                <div className="text-center text-danger mx-auto">An error occurred</div>
            ) : (
                <div className="ukraine-assistance-box">
                    <div className="ukraine-assistance-title">War in Ukraine - Student Support Centre</div>
                    <span className="h5-style mb-4">In response to the dramatic events unfolding in Ukraine, we have developed a portal to provide information about assistance offered by Higher Education Institutions to students fleeing the war. If you would like to help us broadcast how your University is stepping up and making a difference, please indicate:</span>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      handleSave();
                    }}>
                    {/* ---SHELTER--- */}
                    <div className="ukraine-assistance-type-title">Does your Institution provide emergency shelter?</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Yes
                                    </div>
                                }
                                name="shelterRadio"
                                id="apiShelterYes"
                                checked={apiShelter === "Yes"}
                                onChange={() => setApiShelter("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        No
                                    </div>
                                }
                                name="shelterRadio"
                                id="apiShelterNo"
                                checked={apiShelter === "No"}
                                onChange={() => setApiShelter("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Unknown
                                    </div>
                                }
                                name="shelterRadio"
                                id="apiShelterUnknown"
                                checked={apiShelter === "Unknown"}
                                onChange={() => setApiShelter("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---HEALTH--- */}
                    <div className="ukraine-assistance-type-title">Is health and psychological support made available at your Institution?</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Yes
                                    </div>
                                }
                                name="healthRadio"
                                id="apiHealthYes"
                                checked={apiHealth === "Yes"}
                                onChange={() => setApiHealth("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        No
                                    </div>
                                }
                                name="healthRadio"
                                id="apiUnknownNo"
                                checked={apiHealth === "No"}
                                onChange={() => setApiHealth("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Unknown
                                    </div>
                                }
                                name="healthRadio"
                                id="apiHealthUnknown"
                                checked={apiHealth === "Unknown"}
                                onChange={() => setApiHealth("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---FAST TRACK--- */}
                    <div className="ukraine-assistance-type-title">Does your Institution provide a fast track application procedure for studies?</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Yes
                                    </div>
                                }
                                name="fastTrackRadio"
                                id="apiFastTrackYes"
                                checked={apiFastTrack === "Yes"}
                                onChange={() => setApiFastTrack("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        No
                                    </div>
                                }
                                name="fastTrackRadio"
                                id="apiFastTrackNo"
                                checked={apiFastTrack === "No"}
                                onChange={() => setApiFastTrack("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Unknown
                                    </div>
                                }
                                name="fastTrackRadio"
                                id="apiFastTrackYesUnknown"
                                checked={apiFastTrack === "Unknown"}
                                onChange={() => setApiFastTrack("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---SCHOLARSHIP--- */}
                    <div className="ukraine-assistance-type-title">Does your Institution offer special scholarships for studies?</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Yes
                                    </div>
                                }
                                name="scholarshipRadio"
                                id="apiScholarshipYes"
                                checked={apiScholarship === "Yes"}
                                onChange={() => setApiScholarship("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        No
                                    </div>
                                }
                                name="scholarshipRadio"
                                id="apiScholarshipNo"
                                checked={apiScholarship === "No"}
                                onChange={() => setApiScholarship("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        Unknown
                                    </div>
                                }
                                name="scholarshipRadio"
                                id="apiScholarshipUnknown"
                                checked={apiScholarship === "Unknown"}
                                onChange={() => setApiScholarship("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---MORE INFO URL--- */}
                    <div className="ukraine-assistance-type-title">Please provide a link to your Institution's dedicated webpage with more information on this topic, or to your Institution's relevant contacts page.</div>
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        type="text"
                        placeholder="https://www.example.com"
                        onChange={handleUrlChange}
                        value={apiMoreInfoUrl}
                        className={isUrlValid ? '' : 'is-invalid'}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid URL.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Container>
                        <Row>
                            <Col>
                                <Col
                                    style={{
                                        marginTop: 76,
                                        marginBottom: 10,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <CustomButton
                                        buttonType={"secondary"}
                                        text={"Cancel"}
                                        hasIcon={false}
                                        handleClick={handleCancel}
                                    />
                                    <CustomButton type="submit" buttonType={"primary"} text={"Save Ukraine Assistance Information"} hasIcon icon={faFloppyDisk} styles={{ marginLeft: 20 }} small />
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    </Form>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            )}
        </main>
    );
};

export default UkraineAssistanceSettings;
