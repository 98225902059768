import { gql } from "@apollo/client";

const GET_SHORT_TERM_LISTING = gql`
  query shortTermMobilities(
      $typeOfStudent: Int!,
      $page: Int!,
      $optionalSTLAFilters: OptionalSTLAFilters,
      $sortBy: String!){
      shortTermMobilities(
        typeOfStudent: $typeOfStudent,
        page: $page,
        optionalSTLAFilters: $optionalSTLAFilters,
        sortBy: $sortBy)
      {
        shortTermList{
          shortTermID
          title
          field_planned_period_from
          field_planned_period_to
          field_la_status
          sending_institution
          receiving_institution
          field_student_firstname
          field_student_lastname
        }
        page
        nextLink
      }
    }`
;

export default GET_SHORT_TERM_LISTING;
