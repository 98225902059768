import { useState, useEffect } from 'react';
import { useMutation,useQuery } from '@apollo/client/react';
import CREATE_OLA3_PREFILLED from '../../../graphql/mutations/ola/createOLA3Prefilled';
import useUser from '../../../hooks/useUser';

import { Form, Row, Col, Table } from "react-bootstrap";
import {
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import Papa from 'papaparse';
import CustomButton from '../../../components/CustomButton';

import COLORS from '../../../data/colors';
import './index.scss';
import CustomModal from '../../../components/CustomModal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from '../../../data/toastConfiguration';
import GET_ERASMUS_CODES from '../../../graphql/queries/registration/getErasmusCodes';

const Upload = () => {
    const { user } = useUser();

    const [checkedConsent, setCheckedConsent] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(['Please select a CSV file']);
    const [error, setError] = useState(true);
    const [pendingUpload, setPendingUpload] = useState(false);
    const [uploadData, setUploadData] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showModalSign, setShowModalSign] = useState(false);
    const [finishedUploading, setFinishedUploading] = useState(false);
    const [uploadResults, setUploadResults] = useState([]);
    const [uploadHasError, setUploadHasError] = useState(false);

    const handleCloseModalSign = () => setShowModalSign(false);
    const handleShowModalSign = () => setShowModalSign(true);

    const allowedColumns = {
        "First Name": "firstName",
        "Last Name": "lastName",
        "Email": "email",
        "Start of mobility": "startOfMobility",
        "End of mobility": "endOfMobility",
        "Receiving faculty": "receivingFaculty",
        "Receiving institution": "receivingInstitution",
        "Receiving contact first name": "receivingContactFirstName",
        "Receiving contact last name": "receivingContactLastName",
        "Receiving contact email": "receivingContactEmail",
        "Receiving responsible first name": "receivingResponsibleFirstName",
        "Receiving responsible last name": "receivingResponsibleLastName",
        "Receiving responsible email": "receivingResponsibleEmail",
        "Sending contact first name": "sendingContactFirstName",
        "Sending contact last name": "sendingContactLastName",
        "Sending contact email": "sendingContactEmail",
        "Sending responsible first name": "sendingResponsibleFirstName",
        "Sending responsible last name": "sendingResponsibleLastName",
        "Sending responsible email": "sendingResponsibleEmail"
    }
    const {
        loading: loadingEcodes,
        data: ecodes,
        error: errorEcodes,
        refetch: refetchEcodes
    } = useQuery(GET_ERASMUS_CODES, {
        variables: { countryCodes: [] }
    });

    const [
        createOLA3prefilled,
        {
            error: errorCreateOLA3prefilled
        },
    ] = useMutation(CREATE_OLA3_PREFILLED);


    useEffect(() => {
        if (uploadData) {
            uploadHandler();
        }
    }, [uploadData]);

    useEffect(() => {
        if (file?.length > 0) {
            fileHandler();
        }
    }, [file]);

    const checkConsent = () => {
        setCheckedConsent(!checkedConsent);
    }

    const fileHandler = () => {
        Papa.parse(file[0], {
            skipEmptyLines: true,
            header: true,
            complete: results => {
                csvHandler(results)
            }
        });

    }

    const csvHandler = (data) => {
        const header = Object.keys(data.data[0])
        if (!validateColumnNames(header) && !validateData(data.data)) {
            const newData = [...data.data]
            setSelectedFileData(newData);
            setSelectedFileError([]);
            setError(false);
        } else {
            setError(true);
        }
    }

    const validateEmail = (email) => {
        email = email.replace(/\s+/g, '');
        const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(String(email).toLowerCase());
    }

    const validateDate = (date) => {
        const dateString = String(date)
        const regEx = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!dateString.match(regEx)) {
            return false;
        } // Invalid format
        const parts = dateString.split("/");
        const d = new Date(parts[2], parts[1] - 1, parts[0]);
        const dNum = d.getTime();
        if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return true;
    }


    const validateColumnNames = (columns) => {
        let hasError = false;
        columns.forEach(element => {
            hasError = !Object.keys(allowedColumns).includes(element) ? true : false;
        });
        if (hasError) {
            setError(true);
            setSelectedFileError(["Error: Invalid column names. "]);
        }
        return hasError;
    }

    const validateData = (data) => {
        let hasError = false;
        let errors = [];
        for (var key in data) {
            let partnerHei = ecodes?.heis?.find(ec => data[key]["Receiving institution"] === ec?.erasmusCode);
            if(partnerHei===undefined){
                hasError = true;
                setError(true);
                errors.push("Invalid erasmus code "+ data[key]["Receiving institution"] +" in line " + (parseInt(key) + 2) + ".");
            }
            if (data[key]["Email"] != "") {
                if (!(validateEmail(data[key]["Email"]) && validateEmail(data[key]["Receiving contact email"]) && validateEmail(data[key]["Receiving responsible email"]) && validateEmail(data[key]["Sending contact email"]) && validateEmail(data[key]["Sending responsible email"]))) {
                    hasError = true;
                    setError(true);
                    errors.push("Email error in line " + (parseInt(key) + 2) + ": Check the email format of your data.");
                }
                if (!(validateDate(data[key]["Start of mobility"]) && validateDate(data[key]["End of mobility"]))) {
                    hasError = true;
                    setError(true);
                    errors.push("Date error in line " + (parseInt(key) + 2) + ": Make your the dates are in dd/mm/yyyy format.");
                }
            } else {
                //it is an emtpy line, so remove it.
                data.splice(key, 1);
            }
        }
        if (data.length > 25) {
            hasError = true;
            setError(true);
            errors.push("File error: You can only upload 25 LA's at once.");
        }
        setSelectedFileError(errors);
        return hasError;
    }


    const convertData = (data) => {
        const header = Object.keys(data[0])
        return data.map(item => {
            const newPrefilledLA = {}
            header.forEach(column => newPrefilledLA[allowedColumns[column]] = item[column])
            //Add Erasmus Code of current account as sending institution
            newPrefilledLA["sendingInstitution"] = user?.erasmusCode;
            return newPrefilledLA;
        })
    }

    const uploadHandler = async () => {
        const toastId = toast.loading("Uploading OLAs...", toastConfiguration);
        let tempUploadData = [...uploadResults];
        try {
            const convertedData = convertData(selectedFileData);

            const variables = {
                data: convertedData,
            }
            const dataCreateOLA3prefilled = await createOLA3prefilled({ variables });
            tempUploadData = tempUploadData.concat(dataCreateOLA3prefilled?.data?.createOLA3prefilled?.map((item) => <p className={`${item.includes("was not created") ? 'text-danger' : ''}`}>{item}</p>))
            const checkForUploadErrors = dataCreateOLA3prefilled?.data?.createOLA3prefilled?.find((item) => item.includes("was not created"));
            setUploadHasError(checkForUploadErrors ? true : false)
            toast.update(toastId, {
                ...toastConfiguration,
                render: `Successfully uploaded learning agreements`,
                type: "success",
                isLoading: false,
            });
            setFinishedUploading(true);
            setFile(null);
            setShowPreview(false);
            setCheckedConsent(false);
            setError(true);
            setSelectedFileData(null);
            setSelectedFileError(['Please select a CSV file']);
        } catch (error) {
            if (error) {
                tempUploadData = tempUploadData.concat(error?.map((item) => <p>{item?.message}</p>));
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `There were some errors while uploading OLAs.`,
                    type: "error",
                    isLoading: false,
                });
            }
        }
        setUploadResults(tempUploadData);
    }

    let rowNum = 0;


    const renderRow = (rowData, header) => {

        if (header) {
            const columns = rowData.map((column, index) => {
                return (
                    <th className='h6-style' key={index}>
                        {column}
                    </th>
                )
            })
            return (
                <tr className='main-background rounded custom-row ' style={{
                    border: "10px solid #F2F3F6",
                    borderBottom: "20px solid #F2F3F6"
                }} key={rowNum++ + "row"}>{columns}</tr>
            )
        }

        const columns = rowData.map((column, index) => {
            return (
                <td key={index}>
                    {header ? <h4>{column}</h4> : <p key={index}>{column}</p>}
                </td>
            )
        })
        return (
            <tr className='rounded custom-row' style={{
                border: "10px solid #F2F3F6"
            }} key={rowNum++ + "row"}>{columns}</tr>
        )
    }

    const renderTable = (data) => {
        let header = Object.keys(data[0])
        const body = data.map(item => {
            const rowData = header.map(columnName => item[columnName]);
            return renderRow(rowData)
        })
        header = renderRow(header, true)
        return (
            <div>
                <Table>
                    <thead>
                        {header}
                    </thead>
                    <tbody>
                        {body}
                    </tbody>
                </Table>
            </div>
        )
    }

    const returnToUploadPageAndReset = () => {
        setFile(null);
        setFinishedUploading(false);
        setUploadData(false);
        setShowPreview(false);
        setCheckedConsent(false);
        setPendingUpload(false);
        setError(true);
        setSelectedFileData(null);
        setSelectedFileError(['Please select a CSV file']);
        setUploadHasError(false);
        setUploadResults([]);
    }

    const cancelUpload = () => {
        setFile(null);
        setShowPreview(false);
        setCheckedConsent(false);
        setPendingUpload(false);
        setError(true);
        setSelectedFileData(null);
        setSelectedFileError(['Please select a CSV file']);
        setUploadHasError(false);
        setUploadResults([]);
    }


    const uploadAcceptedAfterPreview = () => {
        setUploadData(true);
        handleCloseModalSign();
        setShowPreview(false);
        setPendingUpload(true);
    }


    if (showPreview) {
        return (
            <main className="mx-5 pt-4 pt-xxl-5">
                <h3 className='h3-upload'>Uploading Learning Agreements</h3>
                {renderTable(selectedFileData)}
                <Row>
                    <Col style={{ marginTop: 36, display: "flex", justifyContent: "flex-end" }}>
                        <CustomButton buttonType={"secondary"} styles={{ marginRight: "10px" }} small={true} text={"Cancel"} handleClick={cancelUpload} />
                        <CustomButton buttonType={"primary"} small={true} text={"Upload"} hasIcon={true} iconColor={COLORS.white} icon={faUpload} handleClick={handleShowModalSign} />
                    </Col>
                </Row>
                <CustomModal show={showModalSign} handleClose={handleCloseModalSign}
                    fullscreen={false}
                    body={<div>Please make sure that the data is compliant with the <a href="https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/22282243/Creating+OLA+enhanced+EWP-Dashboard" style={{ color: "#3D619C" }} target="_blank">template</a></div>}
                    button={<CustomButton buttonType={"primary"} text={"Upload"} hasIcon={true} iconColor={COLORS.white} icon={faUpload} handleClick={uploadAcceptedAfterPreview} />}
                    title={<div style={{ paddingBottom: "20px" }}>You are about to <span style={{ color: "#3D619C" }}>upload</span> some OLAs</div>} />
            </main>
        )
    }

    if (pendingUpload) {
        return (
            <div className="mx-5 pt-4 pt-xxl-5">
                <h3 className='h3-upload'>Uploading Learning Agreements</h3>
                <p className='upload-subtitle'>This can take a while, please do not close this page!</p>
                {(uploadResults && uploadResults?.length > 0) && <div className='p-2 upload-results'>
                    {uploadResults}
                </div>}
                {(errorCreateOLA3prefilled || uploadHasError) && (
                    <div className="text-danger mx-auto mt-4">
                        <p style={{ "marginTop": "10px", fontFamily: 'Raleway' }}>If you see some errors, please check the Common Errors page available {" "}
                            <a rel="noopener noreferrer"
                                target="_blank"
                                href="https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/11862139/Common+errors+when+uploading+a+CSV+OLA">
                                here
                            </a>
                        </p>
                    </div>
                )}
                {finishedUploading &&
                    <Row>
                        <Col style={{ marginTop: 36, display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton buttonType={"primary"} small={true} text={"Return to Upload Page"} handleClick={returnToUploadPageAndReset} />
                        </Col>
                    </Row>
                }
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-between mx-5 pt-4 pt-xxl-5">
            <Form noValidate validated={true} id={"upload-ola-form"}>
                <h3 className='h3-upload'>
                    Upload CSV file to pre-fill the Online Learning Agreements
                </h3>
                <p className='body-upload'>
                    The Online Learning Agreement pre-filling functionality now supports the CSV upload. Upon entering the data you can overview the students list to make sure all the information is correct and submit the data. This action will trigger a creation of pre-filled Online Learning Agreements and a notification for the students inviting them to finalise the document.
                    <br />
                    The format of the CSV file can be verified through the use of a template spreadsheet made available on the EWP CC <a rel="noopener noreferrer"
                        target="_blank"
                        href="https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/22282243/Creating+OLA+enhanced+EWP-Dashboard">
                        here
                    </a>.
                </p>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control type="file" accept='.csv' onChange={(e) => setFile(e.target.files)} required isInvalid={error === true} />
                    <Form.Control.Feedback type="invalid">
                    {(selectedFileError && selectedFileError?.length > 0) && <div className='text-danger'>
                    {selectedFileError?.map(i => <>{i}<br/></>)}
                </div>}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formAuthorizations">
                    <Form.Check type="checkbox" id="check-authorizations">
                        <Form.Check.Input type="checkbox" required onChange={checkConsent} />
                        <Form.Check.Label>
                            I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard Terms & Conditions and Privacy Policy.
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            Please give consent on the treatment of data
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
                <Row>
                    <Col style={{ marginTop: 36, display: "flex", justifyContent: "flex-end" }}>
                        <CustomButton disabled={!(checkedConsent && file?.length > 0 && !error)} buttonType={"primary"} small={true} text={"Upload"} hasIcon={true} iconColor={COLORS.white} icon={faUpload} handleClick={() => {
                            setShowPreview(true);
                        }} />
                    </Col>
                </Row>
            </Form>
        </div>)
};

export default Upload;