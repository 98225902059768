import { useState, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from "react-bootstrap/Spinner";
import "./index.scss";
import {
    faFileZipper,
    faXmark
} from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

import COLORS from '../../../data/colors';
import { ACCOUNT_TYPES } from '../../../data/constants';
import useBreakpoint from '../../../hooks/useBreakpoint';
import useUser from '../../../hooks/useUser';

import CustomButton from '../../../components/CustomButton';
import GET_SINGLE_LA from '../../../graphql/queries/ola/getSingleLA';
import ACCEPT_OLA from '../../../graphql/mutations/ola/acceptOla';
import DECLINE_OLA from '../../../graphql/mutations/ola/declineOla';
import EDIT_PRELIMINARY_TABLE from '../../../graphql/mutations/ola/editPreliminaryTable';
import EDIT_FINAL_TABLE from '../../../graphql/mutations/ola/editFinalTable';
import { useQuery, useMutation } from '@apollo/client/react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import CustomTab from '../../../components/CustomTab';
import CustomModal from '../../../components/CustomModal';
import CustomTable from '../../../components/Table';
import IconButton from '../../../components/IconButton';
import CoursesTable from './components/CoursesTable';
import LearningAgreementInfoTable from './components/LearningAgreementInfoTable';
import InstitutionInfoTable from './components/InstitutionInfoTable';
import StudentInfo from './components/StudentInfo';
import { StatusActionPair } from '../../../data/statusActionPairs';
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from '../../../data/toastConfiguration';
import TorTable from './components/TorTable';

const SingleViewLA = ({ typeOfStudent }) => {
    const [size] = useBreakpoint();
    const { id } = useParams();
    const { user } = useUser();
    let location = useLocation();
    const navigate = useNavigate();
    const [showModalReject, setShowModalReject] = useState(false);
    const [showModalSign, setShowModalSign] = useState(false);
    const [rejectionMessage, setRejectionMessage] = useState("");
    const [rejectionAutoRecMessage, setRejectionAutoRecMessage] = useState(null);
    const [editPreliminaryTableData, setEditPreliminaryTableData] = useState([]);
    const [editFinalTableData, setEditFinalTableData] = useState([]);
    const [showModalPreliminaryRejectAutoRec, setShowModalPreliminaryRejectAutoRec] = useState(false);
    const [showModalFinalRejectAutoRec, setShowModalFinalRejectAutoRec] = useState(false);
    const [tableRowRejectAutoRec, setTableRowRejectAutoRec] = useState();
    const [isTorCreated,setIsTorCreated] = useState(true);
    const [signed, setSigned] = useState(false);
    const { loading, data, error, refetch } = useQuery(GET_SINGLE_LA, {
        variables: {
            ID: id,
            pathName: typeOfStudent
        }
    });


    const [
        acceptOlaMutation,
        {
            loading: loadingAcceptOla,
            client: acceptOlaClient,
            error: errorAcceptOlaMutation,
            reset: resetAcceptOlaMutation,
        },
    ] = useMutation(ACCEPT_OLA);

    const [
        declineOlaMutation, {
            loading: loadingDeclineOla,
            client: declineOlaClient,
            error: errorDeclineOlaMutation,
            reset: resetDeclineOlaMutation,
        },
    ] = useMutation(DECLINE_OLA);



    const [
        editPreliminaryTableMutation,
        {
            loading: loadingEditPreliminaryTable
        }
    ] = useMutation(EDIT_PRELIMINARY_TABLE);

    const [
        editFinalTableMutation,
        {
            loading: loadingEditFinalTable
        }
    ] = useMutation(EDIT_FINAL_TABLE);

    useEffect(() => {
        if(data){
            if(location?.pathname.includes("outgoing")){
                if (user?.erasmusCode!==data?.learningAgreement?.sending_institution?.field_hei_erasmus_code){
                    navigate("/unauthorized");   
                }
            }
            else{
                if (user?.erasmusCode!==data?.learningAgreement?.receiving_institution?.field_hei_erasmus_code){
                    navigate("/unauthorized");   
                }
            }
        }
        setEditPreliminaryTableData(concatPreliminaryCourseTables());
        setEditFinalTableData(concatFinalCourseTables());
    }, [data]);

    const position = typeOfStudent == "outgoing" ? "Responsible person at the Sending Institution" : "Responsible person at the Receiving Institution";

    const shouldDisableSignOlaButtons = (signed || StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.learningAgreement?.field_la_status] !== "act" || user?.permissions.find(
        (permission) => permission.value === "managingStudents"
    ) === undefined);

    const shouldDisableSignOlaButtonRejectAutoRec = (editPreliminaryTableData.filter(i => i?.isChanged === false)?.length !== editPreliminaryTableData?.length || editFinalTableData.filter(i => i?.isChanged === false)?.length !== editFinalTableData?.length);

    const shouldEditCoursesTables = (typeOfStudent === "outgoing" && StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.learningAgreement?.field_la_status] === "act");

    const handleCloseModalSign = () => setShowModalSign(false);
    const handleShowModalSign = () => setShowModalSign(true);
    const handleCloseModalReject = () => setShowModalReject(false);
    const handleShowModalReject = () => setShowModalReject(true);
    const handleCloseModalPreliminaryRejectAutoRec = () => setShowModalPreliminaryRejectAutoRec(false);
    const handleShowModalPreliminaryRejectAutoRec = () => setShowModalPreliminaryRejectAutoRec(true);
    const handleCloseModalFinalRejectAutoRec = () => setShowModalFinalRejectAutoRec(false);
    const handleShowModalFinalRejectAutoRec = () => setShowModalFinalRejectAutoRec(true);

    const concatPreliminaryCourseTables = () => {
        let tableToReturn = []
        if (data?.learningAgreement?.preliminary_table_b && data?.learningAgreement?.preliminary_table_b?.length > 0) {
            tableToReturn = tableToReturn.concat(data?.learningAgreement?.preliminary_table_b.map(e => ({
                tableID: e?.id,
                tableType: "b",
                autoRecoValue: e?.attributes?.field_pre_tbl_b_auto_recognised,
                reason: e?.attributes?.field_pre_tbl_b_auto_comment,
                isChanged: false
            })));
        }
        if (data?.learningAgreement?.preliminary_table_c && data?.learningAgreement?.preliminary_table_c?.length > 0) {
            tableToReturn = tableToReturn.concat(data?.learningAgreement?.preliminary_table_c.map(e => ({
                tableID: e?.id,
                tableType: "c",
                autoRecoValue: e?.attributes?.field_pre_tbl_c_auto_recognised,
                reason: e?.attributes?.field_pre_tbl_c_auto_comment,
                isChanged: false
            })));
        }
        return tableToReturn;
    }

    const concatFinalCourseTables = () => {

        let tableToReturn = []
        if (data?.learningAgreement?.final_table_b2 && data?.learningAgreement?.final_table_b2?.length > 0) {
            tableToReturn = tableToReturn.concat(data?.learningAgreement?.final_table_b2.map(e => ({
                tableID: e?.id,
                tableType: "b",
                autoRecoValue: e?.attributes?.field_final_table_b2_auto,
                reason: e?.attributes?.field_final_table_b2_auto_commen,
                isChanged: false
            })));
        }
        if (data?.learningAgreement?.final_table_c2 && data?.learningAgreement?.final_table_c2?.length > 0) {
            tableToReturn = tableToReturn.concat(data?.learningAgreement?.final_table_c2.map(e => ({
                tableID: e?.id,
                tableType: "c",
                autoRecoValue: e?.attributes?.field_final_table_c2_auto_recogn,
                reason: e?.attributes?.field_final_table_c2_auto_commen,
                isChanged: false
            })));
        }
        return tableToReturn;
    }

    const handleSignButtonClick = async () => {
        const toastId = toast.loading("Signing OLA...", toastConfiguration);
        setSigned(true);
        try {

            let result = await acceptOlaMutation({
                "variables": {
                    "email": user?.email,
                    "institution": typeOfStudent === "outgoing" ? 1 : 2,
                    "name": user?.userName ? user?.userName : user?.email,
                    "olaID": data?.learningAgreement?.olaID,
                    "position": position,
                    "status": data?.learningAgreement?.field_la_status
                }
            });
            handleCloseModalSign()
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully signed learning agreement`,
                    type: "success",
                    isLoading: false,
                });
                refetch();
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Error on signing.`,
                    type: "error",
                    isLoading: false,
                });
                setSigned(false);
            }
        }
    };
    const triggerEditTables = async () => {
        try {

            var tablePromise = [];
            if (editPreliminaryTableData.length > 0) {
                editPreliminaryTableData.forEach(async (item) => {
                    if (item?.isChanged) {
                        const variables = {
                            tableID: item.tableID,
                            tableType: item.tableType,
                            autoRecoValue: item.autoRecoValue,
                            reason: item.reason,
                        }

                        tablePromise.push(editPreliminaryTableMutation({ variables }));
                    }
                });
            }
            if (editFinalTableData.length > 0) {
                editFinalTableData.forEach(async (item) => {
                    if (item?.isChanged) {
                        const variables = {
                            tableID: item.tableID,
                            tableType: item.tableType,
                            autoRecoValue: item.autoRecoValue,
                            reason: item.reason,
                        }

                        tablePromise.push(editFinalTableMutation({ variables }));
                    }
                });
            }
            return await Promise.all(tablePromise);
        } catch (error) {
            if (error) {
                toast.error("Edit OLA course table failed.", toastConfiguration);
            }
        }
    }

    const handleDeclineButtonClick = async () => {
        const toastId = toast.loading("Declining OLA...", toastConfiguration);
        setSigned(true);
        try {
            await triggerEditTables();

            let result = await declineOlaMutation({
                "variables": {
                    "email": user?.email,
                    "institution": typeOfStudent === "outgoing" ? 1 : 2,
                    "name": user?.userName ? user?.userName : user?.email,
                    "olaID": data?.learningAgreement?.olaID,
                    "reason": rejectionMessage,
                    "position": position,
                    "status": data?.learningAgreement?.field_la_status
                }
            });
            handleCloseModalReject()
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Successfully declined learning agreement`,
                    type: "success",
                    isLoading: false,
                });
                refetch();
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Error on declining.`,
                    type: "error",
                    isLoading: false,
                });
                setSigned(false);
            }
        }
    };

    const handleEditPreliminaryTableReason = () => {
        let tempEditPreliminaryTable = editPreliminaryTableData;
        tempEditPreliminaryTable = tempEditPreliminaryTable.map(row => {
            if (row?.tableID === tableRowRejectAutoRec) {
                row["autoRecoValue"] = !row["autoRecoValue"];
                row["isChanged"] = true;
                row["reason"] = rejectionAutoRecMessage;
            }
            return row;
        })
        setEditPreliminaryTableData(tempEditPreliminaryTable);
        setRejectionAutoRecMessage(null);
        handleCloseModalPreliminaryRejectAutoRec();
    }

    const handleEditFinalTableReason = () => {
        let tempEditFinalTable = editFinalTableData;
        tempEditFinalTable = tempEditFinalTable.map(row => {
            if (row?.tableID === tableRowRejectAutoRec) {
                row["autoRecoValue"] = !row["autoRecoValue"];
                row["isChanged"] = true;
                row["reason"] = rejectionAutoRecMessage;
            }
            return row;
        })
        setEditFinalTableData(tempEditFinalTable);
        setRejectionAutoRecMessage(null);
        handleCloseModalFinalRejectAutoRec();

    }

    const handleEditPreliminaryTable = (tableRow) => {
        let tempEditPreliminaryTable = editPreliminaryTableData;
        tempEditPreliminaryTable = tempEditPreliminaryTable.map(row => {
            if (row?.tableID === tableRow) {
                if (row["autoRecoValue"]) {
                    handleShowModalPreliminaryRejectAutoRec();
                    setTableRowRejectAutoRec(tableRow);
                }
                else {
                    row["autoRecoValue"] = true;
                    row["reason"] = null;
                    row["isChanged"] = true;
                }
                setTableRowRejectAutoRec(tableRow);
            }
            return row;
        })
        setEditPreliminaryTableData(tempEditPreliminaryTable);
    }

    const handleEditFinalTable = (tableRow) => {
        let tempEditFinalTable = editFinalTableData;
        tempEditFinalTable = tempEditFinalTable.map(row => {
            if (row?.tableID === tableRow) {

                if (row["autoRecoValue"]) {
                    handleShowModalFinalRejectAutoRec();
                    setTableRowRejectAutoRec(tableRow);
                }
                else {
                    row["autoRecoValue"] = true;
                    row["reason"] = null;
                    row["isChanged"] = true;
                }
            }
            return row;
        })
        setEditFinalTableData(tempEditFinalTable);
    }

    if (error)
        return (
            <div className="text-center text-danger mx-auto">An error occured</div>
        );


    if (loading) {
        return (
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <Spinner
                    as="div"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="align-middle my-auto"
                />
                <span>Loading...</span>
            </div>
        )
    }

    return (
        <>
            <Row style={size !== "md" && size !== "lg" && size !== "xl" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                <Col style={{ marginTop: 75 }}>
                    <Tabs id="application-info">
                        <CustomTab eventKey="home" title="LEARNING AGREEMENT INFO" style={{ background: "#ffff" }}>
                            <LearningAgreementInfoTable typeOfStudent={typeOfStudent} data={data} size={size} />
                        </CustomTab>
                    </Tabs>
                </Col>
            </Row>
            <Row style={size !== "md" && size !== "lg" && size !== "xl" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                <Col style={{ marginTop: 36 }}>
                    <Tabs id="second-tab">
                        <CustomTab eventKey="general-info" id="general-info" title="General Info" style={{ background: "#EAECF3" }}>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 36, marginBottom: 10 }}>
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="student-info"
                                    >
                                        <CustomTab eventKey="home" title="STUDENT PERSONAL DATA" style={{ background: "#ffff" }}>
                                            <StudentInfo data={data} />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col md={6} style={{ marginTop: 33, marginBottom: 10 }}>
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="sending-hei"
                                    >
                                        <CustomTab eventKey="home" title="SENDING INSTITUTION INFO" style={{ background: "#ffff" }}>
                                            <InstitutionInfoTable size={size} institution={data?.learningAgreement?.sending_institution} contact_person={data?.learningAgreement?.sending_contact_person} resp_person={data?.learningAgreement?.sending_resp_person} />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                                <Col md={6} style={{ marginTop: 33, marginBottom: 36 }}>
                                    <Tabs defaultActiveKey="home" id="receiving-hei">
                                        <CustomTab eventKey="home" title="RECEIVING INSTITUTION INFO" style={{ background: "#ffff" }}>
                                            <InstitutionInfoTable size={size} institution={data?.learningAgreement?.receiving_institution} contact_person={data?.learningAgreement?.receiving_contact_person} resp_person={data?.learningAgreement?.receiving_resp_person} />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row >
                        </CustomTab>
                        <CustomTab eventKey="courses" id="courses" title="Courses" style={{ background: "#EAECF3" }}>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 36, marginBottom: 10 }}>
                                    <Tabs defaultActiveKey="table-a" id="courses">
                                        <CustomTab eventKey="table-a" title="TABLE A" style={{ background: "#ffff" }}>
                                            <CoursesTable type={"preliminary-a"} data={
                                                data?.learningAgreement?.preliminary_table_a?.map(
                                                    (course) => ({
                                                        code: course?.attributes?.field_pre_tbl_a_code,
                                                        title: course?.attributes?.field_pre_tbl_a_title,
                                                        ects: course?.attributes?.field_pre_tbl_a_ects,
                                                        semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name
                                                    })
                                                )
                                            }
                                                size={size}
                                            />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 33, marginBottom: 10 }}>
                                    <Tabs defaultActiveKey="table-b" id="courses">
                                        <CustomTab eventKey="table-b" title="TABLE B" style={{ background: "#ffff" }}>
                                            <CoursesTable type={"preliminary-b"} data={
                                                data?.learningAgreement?.preliminary_table_b?.map(
                                                    (course) => ({
                                                        code: course?.attributes?.field_pre_tbl_b_code,
                                                        title: course?.attributes?.field_pre_tbl_b_title,
                                                        ects: course?.attributes?.field_pre_tbl_b_ects,
                                                        semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name,
                                                        automaticRecognition: shouldEditCoursesTables ? (!editPreliminaryTableData.find(i => i.tableID === course?.id)?.isChanged ?
                                                            <Form.Check type="checkbox" id="check-authorizations">
                                                                <Form.Check.Input type="checkbox" checked={editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue} onClick={() => handleEditPreliminaryTable(course?.id)} />
                                                            </Form.Check> : editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue) : course?.attributes?.field_pre_tbl_b_auto_recognised,
                                                    })
                                                )
                                            }
                                                size={size}
                                            />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 33, marginBottom: 36 }}>
                                    <Tabs defaultActiveKey="table-c" id="courses">
                                        <CustomTab eventKey="table-c" title="TABLE C" style={{ background: "#ffff" }}>
                                            <CoursesTable type={"preliminary-c"} data={
                                                data?.learningAgreement?.preliminary_table_c?.map(
                                                    (course) => ({
                                                        code: course?.attributes?.field_pre_tbl_c_code,
                                                        title: course?.attributes?.field_pre_tbl_c_title,
                                                        ects: course?.attributes?.field_pre_tbl_c_ects,
                                                        semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name,
                                                        automaticRecognition: shouldEditCoursesTables ? (!editPreliminaryTableData.find(i => i.tableID === course?.id)?.isChanged ?
                                                            <Form.Check type="checkbox" id="check-authorizations">
                                                                <Form.Check.Input type="checkbox" checked={editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue} onClick={() => handleEditPreliminaryTable(course?.id)} />
                                                            </Form.Check> : editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue) : course?.attributes?.field_pre_tbl_c_auto_recognised,

                                                    })
                                                )
                                            }
                                                size={size}
                                            />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </CustomTab>
                        <CustomTab eventKey="changes" id="changes" title="Changes" style={{ background: "#EAECF3" }}>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 36, marginBottom: 10 }}>
                                    <Tabs defaultActiveKey="final-table-a" id="changes">
                                        <CustomTab eventKey="final-table-a" title="CHANGES TO TABLE A" style={{ background: "#ffff" }}>
                                            <CoursesTable type={"final-a2"} data={
                                                data?.learningAgreement?.final_table_a2?.map(
                                                    (course) => ({
                                                        code: course?.attributes?.field_final_table_a2_code,
                                                        title: course?.attributes?.field_final_table_a2_title,
                                                        ects: course?.attributes?.field_final_table_a2_ects,
                                                        semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name,
                                                        reason: course?.attributes?.field_component_added_or_deleted==="1"?course?.attributes?.field_final_table_a2_rsn_added:course?.attributes?.field_final_table_a2_rsn_deleted,
                                                        addedOrDeleted: course?.attributes?.field_component_added_or_deleted
                                                    })
                                                )
                                            }
                                                preliminaryTableEcts={
                                                    data?.learningAgreement?.preliminary_table_a.map(
                                                        (course) => ({
                                                            ects: course?.attributes?.field_pre_tbl_a_ects
                                                        })
                                                    )
                                                }
                                                size={size}
                                            />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 33, marginBottom: 10 }}>
                                    <Tabs defaultActiveKey="final-table-b" id="changes">
                                        <CustomTab eventKey="final-table-b" title="CHANGES TO TABLE B" style={{ background: "#ffff" }}>
                                            <CoursesTable type={"final-b2"} data={
                                                data?.learningAgreement?.final_table_b2?.map(
                                                    (course) => ({
                                                        code: course?.attributes?.field_final_table_b2_code,
                                                        title: course?.attributes?.field_final_table_b2_title,
                                                        ects: course?.attributes?.field_final_table_b2_ects,
                                                        semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name,
                                                        reason: course?.attributes?.field_component_added_or_deleted==="1"?course?.attributes?.field_final_table_b2_rsn_added:course?.attributes?.field_final_table_b2_rsn_deleted,
                                                        automaticRecognition: shouldEditCoursesTables ? (!editFinalTableData.find(i => i.tableID === course?.id)?.isChanged ?
                                                            <Form.Check type="checkbox" id="check-authorizations">
                                                                <Form.Check.Input type="checkbox" checked={editFinalTableData.find(i => i.tableID === course?.id)?.autoRecoValue} onClick={() => handleEditFinalTable(course?.id)} />
                                                            </Form.Check> : editFinalTableData.find(i => i.tableID === course?.id)?.autoRecoValue) : course?.attributes?.field_final_table_b2_auto,
                                                        addedOrDeleted: course?.attributes?.field_component_added_or_deleted
                                                    })
                                                )
                                            }
                                                preliminaryTableEcts={
                                                    data?.learningAgreement?.preliminary_table_b.map(
                                                        (course) => ({
                                                            ects: course?.attributes?.field_pre_tbl_b_ects
                                                        })
                                                    )
                                                }
                                                size={size}
                                            />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 33, marginBottom: 36 }}>
                                    <Tabs defaultActiveKey="final-table-c" id="changes">
                                        <CustomTab eventKey="final-table-c" title="CHANGES TO TABLE C" style={{ background: "#ffff" }}>
                                            <CoursesTable type={"final-c2"} data={
                                                data?.learningAgreement?.final_table_c2?.map(
                                                    (course) => ({
                                                        code: course?.attributes?.field_final_table_c2_code,
                                                        title: course?.attributes?.field_final_table_c2_title,
                                                        ects: course?.attributes?.field_final_table_c2_ects,
                                                        semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name,
                                                        reason: course?.attributes?.field_final_table_c2_added_or_de==="1"?course?.attributes?.field_final_table_c2_rsn_added:course?.attributes?.field_final_table_c2_rsn_deleted,
                                                        automaticRecognition: shouldEditCoursesTables ? (!editFinalTableData.find(i => i.tableID === course?.id)?.isChanged ?
                                                            <Form.Check type="checkbox" id="check-authorizations">
                                                                <Form.Check.Input type="checkbox" checked={editFinalTableData.find(i => i.tableID === course?.id)?.autoRecoValue} onClick={() => handleEditFinalTable(course?.id)} />
                                                            </Form.Check> : editFinalTableData.find(i => i.tableID === course?.id)?.autoRecoValue) : course?.attributes?.field_final_table_c2_auto_recogn,
                                                        addedOrDeleted: course?.attributes?.field_final_table_c2_added_or_de
                                                    })
                                                )
                                            }
                                                preliminaryTableEcts={
                                                    data?.learningAgreement?.preliminary_table_c.map(
                                                        (course) => ({
                                                            ects: course?.attributes?.field_pre_tbl_c_ects
                                                        })
                                                    )
                                                }
                                                size={size}
                                            />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </CustomTab>
                        {((data?.learningAgreement?.field_la_status == "preliminary_signed-by-student-sending-receiving" || data?.learningAgreement?.field_la_status == "final_signed-by-student-sending-receiving")  && (typeOfStudent === "outgoing" ? isTorCreated : true)) && <CustomTab eventKey="tors" id="tors" title={"ToR"} style={{ background: "#EAECF3" }}>
                            <TorTable 
                            type={typeOfStudent == "incoming" ? "tableC" : "tableD"} 
                            laId={data?.learningAgreement?.olaID} 
                            startDate={data?.learningAgreement?.field_planned_period_from} 
                            endDate={data?.learningAgreement?.field_planned_period_to} 
                            shouldDisplayTor={(b)=>setIsTorCreated(b)}
                            tableA={
                                data?.learningAgreement?.preliminary_table_a?.map(
                                    (course) => ({
                                        code: course?.attributes?.field_pre_tbl_a_code,
                                        title: course?.attributes?.field_pre_tbl_a_title,
                                        ects: course?.attributes?.field_pre_tbl_a_ects,
                                    })
                                )
                            }
                            tableA2={data?.learningAgreement?.final_table_a2?.map(
                                (course) => ({
                                    code: course?.attributes?.field_final_table_a2_code,
                                    title: course?.attributes?.field_final_table_a2_title,
                                    ects: course?.attributes?.field_final_table_a2_ects,
                                    addedOrDeleted: course?.attributes?.field_component_added_or_deleted
                                }))}
                                tableB={
                                    data?.learningAgreement?.preliminary_table_b?.map(
                                        (course) => ({
                                            code: course?.attributes?.field_pre_tbl_b_code,
                                            title: course?.attributes?.field_pre_tbl_b_title,
                                            ects: course?.attributes?.field_pre_tbl_b_ects,
                                            semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name,
                                            automaticRecognition: shouldEditCoursesTables ? (!editPreliminaryTableData.find(i => i.tableID === course?.id)?.isChanged ?
                                                <Form.Check type="checkbox" id="check-authorizations">
                                                    <Form.Check.Input type="checkbox" checked={editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue} onClick={() => handleEditPreliminaryTable(course?.id)} />
                                                </Form.Check> : editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue) : course?.attributes?.field_pre_tbl_b_auto_recognised,
                                        })
                                    )
                                }
                                tableB2={
                                    data?.learningAgreement?.final_table_b2?.map(
                                        (course) => ({
                                            code: course?.attributes?.field_final_table_b2_code,
                                            title: course?.attributes?.field_final_table_b2_title,
                                            ects: course?.attributes?.field_final_table_b2_ects,
                                            semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name,
                                            reason: course?.attributes?.field_final_table_b2_rsn_added,
                                            automaticRecognition: shouldEditCoursesTables ? (!editFinalTableData.find(i => i.tableID === course?.id)?.isChanged ?
                                                <Form.Check type="checkbox" id="check-authorizations">
                                                    <Form.Check.Input type="checkbox" checked={editFinalTableData.find(i => i.tableID === course?.id)?.autoRecoValue} onClick={() => handleEditFinalTable(course?.id)} />
                                                </Form.Check> : editFinalTableData.find(i => i.tableID === course?.id)?.autoRecoValue) : course?.attributes?.field_final_table_b2_auto,
                                            addedOrDeleted: course?.attributes?.field_component_added_or_deleted
                                        })
                                    )
                                }
                            toast={toast} 
                            toastConfiguration={toastConfiguration} />
                        </CustomTab>}
                        {/*Hide revisions tab*/}
                        {/* <CustomTab eventKey="documents" id="documents" title="Revisions" style={{ background: "#EAECF3" }}>
                            <CustomTable
                                data={data?.learningAgreement?.revisions?.map(r => ({
                                    revisionId: r?.nid,
                                    date: new Date(r?.revision_timestamp).toLocaleDateString() + " - " + new Date(r?.revision_timestamp).toLocaleTimeString(),
                                    status: r?.status
                                }))}
                                hasFilters={false}
                                hasPagination={false}
                                loading={false}
                                type={`requests-`}
                                columnsPerFieldMap={{
                                    revisionId: "col-3 col-xl-3",
                                    date: "col-4 col-xl-4",
                                    status: "col-4",

                                }}
                                rowClassName="revision-table-row"
                                headerClassName="revision-table-header"
                            >
                                {data?.learningAgreement?.revisions
                                    ?.map((r) => (
                                        <div
                                            key={r?.nid}
                                            className={`py-3 px-2 bg-none bg-md-light button-triplet-requests`}
                                        >
                                            <Link to={`/ola/${typeOfStudent}/export/${data?.learningAgreement?.olaID}/revision/${r?.vid}`} target="_blank">
                                                <IconButton
                                                    variant="download"
                                                    title="Export Revision to PDF"
                                                    onClick={(e) => { }}
                                                />
                                            </Link>

                                        </div>
                                    ))}
                            </CustomTable>
                        </CustomTab> */}
                    </Tabs>
                </Col>
                {!(size !== "md" && size !== "lg" && size !== "xl" && size !== "xxl") ?
                    <Row style={{ marginLeft: 35, marginRight: 35 }}>
                        <Col style={{ marginTop: 36, display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton disabled={shouldDisableSignOlaButtons} buttonType={"decline"} text={"Decline"} icon={faXmark} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalReject} />
                            <CustomButton disabled={shouldDisableSignOlaButtons || shouldDisableSignOlaButtonRejectAutoRec} buttonType={"accept"} text={"Sign OLA"} styles={{ marginLeft: 20 }} icon={faFileZipper} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalSign} />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col style={{ marginTop: 15, paddingLeft: 0, paddingRight: 0 }} xs={6} sm={6}>
                            <div className="col-12">
                                <CustomButton
                                    styles={{
                                        width: "100%"
                                    }}
                                    disabled={shouldDisableSignOlaButtons}
                                    buttonType={"decline"} text={"Decline"} icon={faXmark} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalReject} />
                            </div>
                        </Col>
                        <Col style={{ marginTop: 15, paddingLeft: 5, display: "flex", justifyContent: "flex-end", paddingRight: 0 }} xs={6} sm={6}>
                            <div className="col-12">
                                <CustomButton buttonType={"accept"} text={"Sign OLA"} icon={faFileZipper} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalSign}
                                    styles={{
                                        width: "100%"
                                    }}
                                    disabled={shouldDisableSignOlaButtons || shouldDisableSignOlaButtonRejectAutoRec}
                                />
                            </div>
                        </Col>
                    </Row>}
            </Row>
            <CustomModal show={showModalReject} handleClose={handleCloseModalReject}
                fullscreen={false}
                body={<div>After declining the OLA, the student will be able to edit it and introduce your proposed changes. Please fill the comments you want to be emailed to the student in field below: <br /><div style={{ background: "#EAECF3", padding: "5%" }}><h6 className='h6-style'>Reason For Declining</h6><textarea style={{ width: "100%" }} rows={3} value={rejectionMessage} onChange={(e) => setRejectionMessage(e.target.value)} /><h6 style={{ color: "#6C757D" }}> Note</h6></div></div>}
                button={<CustomButton disabled={!rejectionMessage || loadingDeclineOla || loadingEditPreliminaryTable || loadingEditFinalTable} buttonType={"decline"} text={"Decline"} icon={faXmark} hasIcon={true} iconColor={COLORS.white} handleClick={handleDeclineButtonClick} />}
                title={<div>You are about to <span style={{ color: "red" }}>decline</span> a student's OLA</div>} />
            <CustomModal show={showModalSign} handleClose={handleCloseModalSign}
                fullscreen={false}
                body={<div>By digitally signing this document, the student, the Sending Institution and the Receiving Institution confirm that they approve the Learning Agreement and that they will comply with all the arrangements agreed by all parties. Sending and Receiving Institutions undertake to apply all the principles of the Erasmus Charter for Higher Education relating to mobility for studies (or the principles agreed in the Inter-Institutional Agreement for institutions located in Partner Countries). The Beneficiary Institution and the student should also commit to what is set out in the Erasmus+ grant agreement. The Receiving Institution confirms that the educational components listed are in line with its course catalogue and should be available to the student. The Sending Institution commits to recognise all the credits or equivalent units gained at the Receiving Institution for the successfully completed educational components and to count them towards the student's degree. The student and the Receiving Institution will communicate to the Sending Institution any problems or changes regarding the study programme, responsible persons and/or study period.</div>}
                button={<CustomButton disabled={loadingAcceptOla} buttonType={"accept"} text={"Sign OLA"} hasIcon={true} iconColor={COLORS.white} icon={faFileZipper} handleClick={handleSignButtonClick} />}
                title={<div style={{ paddingBottom: "20px" }}>You are about to <span style={{ color: "green" }}>sign</span> a student's OLA</div>} />
            <CustomModal show={showModalPreliminaryRejectAutoRec} handleClose={handleCloseModalPreliminaryRejectAutoRec}
                fullscreen={false}
                body={<div>As per official guidelines from the European Commission, if the automatic recognition will not take place, please provide a clear justification and an indication on what other type of formal recognition will be applied.  <br /><div style={{ background: "#EAECF3", padding: "5%" }}><h6 className='h6-style'>Fill in reason of rejecting</h6><textarea style={{ width: "100%" }} rows={3} value={rejectionAutoRecMessage} onChange={(e) => setRejectionAutoRecMessage(e.target.value)} /></div></div>}
                button={<CustomButton disabled={!rejectionAutoRecMessage} buttonType={"accept"} text={"Confirm"} handleClick={handleEditPreliminaryTableReason} />}
                title={<div />} />
            <CustomModal show={showModalFinalRejectAutoRec} handleClose={handleCloseModalFinalRejectAutoRec}
                fullscreen={false}
                body={<div>As per official guidelines from the European Commission, if the automatic recognition will not take place, please provide a clear justification and an indication on what other type of formal recognition will be applied.  <br /><div style={{ background: "#EAECF3", padding: "5%" }}><h6 className='h6-style'>Fill in reason of rejecting</h6><textarea style={{ width: "100%" }} rows={3} value={rejectionAutoRecMessage} onChange={(e) => setRejectionAutoRecMessage(e.target.value)} /></div></div>}
                button={<CustomButton disabled={!rejectionAutoRecMessage} buttonType={"accept"} text={"Confirm"} handleClick={handleEditFinalTableReason} />}
                title={<div />} />
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>)
};

export default SingleViewLA;
