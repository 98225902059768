import { useEffect, useState, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import "./index.scss";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation, Link } from "react-router-dom";

import FORGOT_PASSWORD from "../../../../graphql/mutations/registration/forgotPassword";
import toastConfiguration from "../../../../data/toastConfiguration";

const Main = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const emailRef = useRef();

  const [
    forgotPasswordMutation,
    {
      loading: loadingForgotPassword,
      client,
      error: errorForgotPassword,
      reset: resetForgotPassword,
    },
  ] = useMutation(FORGOT_PASSWORD);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    resetForgotPassword();
    setErrorMessage("");
    setValidated(false);
  }, [email]);

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    try {
      const result = await forgotPasswordMutation({
        variables: {
          email,
        },
      });

      toast.success(
        "The forgot password email was sent successfully. You will be directed in the login page in 3 seconds.",
        toastConfiguration
      );
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      if (errorForgotPassword?.graphQLErrors?.length === 0) {
        // user has no internet connection or backend services are disconnected/do not operate
        setErrorMessage("Login failed; There is a network related problem.");
        return;
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-center position-relative px-3 px-sm-0 py-5 mx-auto mt-5 py-xxl-5 mx-sm-auto login-main-container rounded">
      <div className="forgot-password-container title mb-3">
        <div className="text-start mb-0 h1-style">Forgot password?</div>
        <div className="w-100 fs-6 text-wrap h5-style">
          No worries! Just enter the email associated with your account and we
          will send you a link to reset your password.
        </div>
      </div>

      <div className="form-container">
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              ref={emailRef}
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            className="w-100 submit-button login"
            disabled={loadingForgotPassword}
          >
            {loadingForgotPassword ? "LOADING..." : "SEND EMAIL"}
          </Button>
        </Form>
      </div>
      <div className="text-danger text-center mx-auto mt-3">
        {(errorForgotPassword?.graphQLErrors.length !== 0 &&
          errorForgotPassword?.graphQLErrors[0]?.message) ||
          errorMessage}
      </div>
      <div className="fs-6 w-100 text-center mb-3 h5-style">
        Remember your password?{" "}
        <Link className="sign-up-link text-decoration-none" to="/login">
          Back to Login page
        </Link>
      </div>
      <div className="position-absolute login-bottom-logo">
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            fill="#73A6AD"
          />
          <path
            d="M38 38.1248L56.5423 11.2059C62.844 15.5394 67.4266 21.9453 69.4929 29.3087C71.5591 36.6722 70.978 44.527 67.8508 51.5063C64.7236 58.4856 59.2483 64.1475 52.3776 67.5066C45.5069 70.8657 37.6759 71.7094 30.2474 69.8909L38 38.1248Z"
            fill="white"
          />
          <path
            d="M38.0694 63.6708C52.2087 63.6708 63.6708 52.2087 63.6708 38.0694C63.6708 23.9301 52.2087 12.4679 38.0694 12.4679C23.9301 12.4679 12.4679 23.9301 12.4679 38.0694C12.4679 52.2087 23.9301 63.6708 38.0694 63.6708Z"
            fill="#73A6AD"
          />
          <path
            d="M30.2405 69.8978H37.9307V42.1745H30.2405L30.2405 69.8978Z"
            fill="white"
          />
        </svg>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Main;
