import { gql } from "@apollo/client";

const GET_LA_PDF = gql`
  query laPdf(
        $id: String!, 
        $vid: String
     ){
      laPdf(
            id: $id,
            vid: $vid
        )
      {
        file
      }
    }`
    ;

export default GET_LA_PDF;
