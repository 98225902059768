import { Children, Fragment, cloneElement } from "react";
import { StatusActionPair } from "../../../../data/statusActionPairs";
import { StatusMapping } from "../../../../data/statusMapping";
import { StatusNomination } from "../../../../data/statusNomination";

const TableRow = ({
  row,
  labels,
  isMobile,
  fieldToHeader,
  type,
  columnsPerFieldMap,
  rowClassName,
  onClickHandler,
  className,
  onMouseDown,
  children,
}) => (
  <div
    className={`rounded mb-2 ${
      Children.count(children) !== 0
        ? "d-flex justify-content-between align-items-stretch gap-1 gap-xl-4"
        : ""
    } table-row ${className || ""}`}
  >
    <div
      className={`p-3 ${
        isMobile ? "" : "d-flex"
      } justify-content-around align-items-center custom-row ${
        Children.count(children) !== 0 ? `${rowClassName}` : ``
      } 
       ${
         (StatusActionPair[type + row.status] &&
           "action-" + StatusActionPair[type + row.status]) ||
         ""
       }
       ${isMobile ? "mobile" : "desktop"}
       `}
      onClick={onClickHandler}
      onMouseDown={onMouseDown}
    >
      {labels.map(
        (key, idx) =>
          key !== "id" && (
            <Fragment key={"row-col-" + idx}>
              {isMobile ? (
                <div className="row">
                  <div className="col table-header">
                    {fieldToHeader(key) ? fieldToHeader(key) + ":" : ""}
                  </div>
                  <div className="col body">
                    {key === "status"
                      ? type.includes("nominations-")
                        ? StatusNomination[row[key]]
                        : StatusMapping[row[key]]
                      : row[key]}
                  </div>
                </div>
              ) : (
                <div className={`px-2 ${columnsPerFieldMap[key]}`}>
                  <div className="text-lg-start text-break body">
                    {key === "status"
                      ? type.includes("nominations-")
                        ? StatusNomination[row[key]]
                        : StatusMapping[row[key]]
                      : row[key]}
                  </div>
                </div>
              )}
            </Fragment>
          )
      )}
      {isMobile &&
        Children.map(
          children,
          (child) =>
            child &&
            cloneElement(child, {
              onClick: (e) => {
                e.stopPropagation();
              },
            })
        )}
    </div>
    {!isMobile && children}
  </div>
);

export default TableRow;
