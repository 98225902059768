import Row from "react-bootstrap/Row";

const withRow = (Component, withRow) => (props) => {
  return withRow ? (
    <Row className="mb-3">
      <Component {...props} />
    </Row>
  ) : (
    <Component {...props} />
  );
};

export default withRow;
