const TableHeader = ({
  labels,
  sort,
  isMobile,
  fieldToHeader,
  columnsPerFieldMap,
  ...rest
}) => (
  <div
    className={`${
      isMobile ? "hide" : ""
    } row main-background text-center mx-0 mb-4 rounded ${
      rest?.className || ""
    }`}
  >
    <div
      className={`${
        isMobile ? "" : "p-3"
      } d-flex justify-content-around align-items-center position-relative`}
    >
      {labels.map(
        (label, idx) =>
          label !== "id" && (
            <div
              className={`px-2 ${columnsPerFieldMap[label] || ""} d-flex`}
              key={"header-col-" + idx}
            >
              <div className="table-header col h6-style text-lg-start">
                {fieldToHeader(label)}
              </div>
            </div>
          )
      )}
    </div>
  </div>
);

export default TableHeader;
