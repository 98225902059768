import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NetworkStatus } from "@apollo/client";

import withRow from "../../../../components/withRow";

// exclude these countries because react-phone-number-input
// cant find the corresponding calling codes and they will likely
// never have an institution on the dashboard
const countriesToExclude = ["AQ"];

const ChildRow = (props) => <Row className="mb-3 mx-0" {...props} />;

const Child = ({
  size,
  institutionCountry,
  institutionCity,
  updateMultipleValues,
  dataCountries,
  calledCitiesQuery,
  refetchCities,
  fetchCities,
  loadingCountries,
  loadingCities,
  dataCities,
  networkStatusCities,
  calledInstitutionsQuery,
  refetchInstitutions,
  fetchInstitutions,
}) => (
  <>
    <Form.Group
      as={size !== "xs" && size !== "sm" ? Col : ChildRow}
      controlId="formInstitutionCountry"
    >
      <Form.Label className="ps-0">Institutional Country</Form.Label>
      <Form.Select
        required
        value={institutionCountry}
        onChange={(e) => {
          const countryLink = dataCountries?.countries.find(
            (country) => country.ID === e.target.value
          ).CountryLink;

          if (calledCitiesQuery) {
            refetchCities({ countryLink });
          } else {
            fetchCities({ variables: { countryLink } });
          }

          updateMultipleValues({
            institutionCountry: e.target.value,
            phoneCode: e.target.value,
            institutionalPhone: "",
            institutionCity: "", partnerSCHAC: "", partnerErasmusCode: "", partnerOUnitID: ""
          });
        }}
        disabled={loadingCountries || dataCountries?.countries?.length === 0}
      >
        <option value="" data-current-value={institutionCountry} disabled>
          {loadingCountries ? "Loading..." : "Choose country..."}
        </option>
        {!loadingCountries &&
          dataCountries?.countries
            ?.filter((country) => !countriesToExclude.includes(country.ID))
            .sort((country1, country2) =>
              country1.CountryName > country2.CountryName ? 1 : -1
            )
            .map((country) => (
              <option key={country.ID} value={country.ID}>
                {country.CountryName}
              </option>
            ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        Please select a country for your institution
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group
      as={size !== "xs" && size !== "sm" ? Col : ChildRow}
      controlId="formInstitutionCity"
    >
      <Form.Label className="ps-0">Institutional City</Form.Label>
      <Form.Select
        required
        value={institutionCity}
        onChange={(e) => {
          const cityLink = dataCities?.cities.find(
            (city) => city.ID === e.target.value
          ).CityLink;

          if (calledInstitutionsQuery) {
            refetchInstitutions({ cityLink });
          } else {
            fetchInstitutions({ variables: { cityLink } });
          }

          updateMultipleValues({
            institutionCity: e.target.value,
            institution: "", partnerSCHAC: "", partnerErasmusCode: "", partnerOUnitID: ""
          });
        }}
        disabled={
          institutionCountry === "" ||
          loadingCities === true ||
          networkStatusCities === NetworkStatus.loading ||
          networkStatusCities === NetworkStatus.refetch ||
          dataCities?.cities?.length === 0
        }
      >
        <option value="" disabled>
          {loadingCities === true ||
          networkStatusCities === NetworkStatus.loading ||
          networkStatusCities === NetworkStatus.refetch
            ? "Loading..."
            : "Choose city..."}
        </option>
        {!loadingCities &&
          dataCities?.cities?.length !== 0 &&
          dataCities?.cities
            ?.slice()
            .sort((city1, city2) => (city1.CityName > city2.CityName ? 1 : -1))
            .map((city) => (
              <option key={city.ID} value={city.ID}>
                {city.CityName}
              </option>
            ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        Please select a city for your institution
      </Form.Control.Feedback>
    </Form.Group>
  </>
);

const CountryCityInput = (props) => {
  return withRow(Child, props.size !== "xs" && props.size !== "sm")(props);
};

export default CountryCityInput;
