import { gql } from "@apollo/client";

const GET_COURSE_SINGLE_VIEW = gql`
  query CourseEvaluation($courseId: String!) {
  course(course_id: $courseId) {
    count
    course {
      id
      title
      code
      term
      ects
      institution
    }
    evaluationSubmissions {
      ageGroups {
        group_16_18
        group_19_25
        group_26_30
        group_31_60
        group_61_plus
      }
      genderGroups {
        female
        male
        non_binary
        other
        prefer_not_to_say
        unsure_how_to_describe_myself
      }
    }
    evaluations {
      enumFields {
        generalParticipationMotives {
          academic_opportunities
          career_advancement
          experiencing_different_cultures
          other
          personal_motives
          quality_of_education
        }
        generalSatisfactionAspects {
          interaction_with_students
          the_assessment
          the_content
          the_learning_activities
          the_learning_materials
          the_lectures
          the_setup_design
          the_support_of_teachers
        }
        generalWouldRecommend {
          no
          not_sure
          other
          yes
        }
      }
      likertScaleFields {

        courseClearObjectives {
          one
          two
          three
          four
          five
        }
        
        courseFutureEmployment {
          one
          two
          three
          four
          five
        }
        courseMaterials {
          one
          two
          three
          four
          five
        }
        courseMotivationalActivities {
          one
          two
          three
          four
          five
        }
        courseOnlineTools {
          one
          two
          three
          four
          five
        }
        courseOrganisation {
          one
          two
          three
          four
          five
        }
        coursePrerequisites {
          one
          two
          three
          four
          five
        }
        courseQuality {
          one
          two
          three
          four
          five
        }
        courseRelevantLectures {
          one
          two
          three
          four
          five
        }
        courseSocialInteraction {
          one
          two
          three
          four
          five
        }
        courseSocietalReflexion {
          one
          two
          three
          four
          five
        }
        courseStudyLoad {
          one
          two
          three
          four
          five
        }
        courseTopicRelevance {
          one
          two
          three
          four
          five
        }
        culturalInternationalPerspective {
          one
          two
          three
          four
          five
        }
        culturalLanguageBarriers {
          one
          two
          three
          four
          five
        }
        culturalPrejudice {
          one
          two
          three
          four
          five
        }
        culturalStudentContact {
          one
          two
          three
          four
          five
        }
        culturalThoughtSharing {
          one
          two
          three
          four
          five
        }
        evaluationSharingOpportunities {
          one
          two
          three
          four
          five
        }
        evaluationSufficiency {
          one
          two
          three
          four
          five
        }
        evaluationTiming {
          one
          two
          three
          four
          five
        }
        methodsCommunicationCorrespondence {
          one
          two
          three
          four
          five
        }
        methodsCulturalAttention {
          one
          two
          three
          four
          five
        }
        methodsDetailsCommunication {
          one
          two
          three
          four
          five
        }
        methodsLearningSatisfaction {
          one
          two
          three
          four
          five
        }
        methodsSubjectConsistency {
          one
          two
          three
          four
          five
        }
        teacherApproachability {
          one
          two
          three
          four
          five
        }
        teacherContactMoments {
          one
          two
          three
          four
          five
        }
        teacherDiscussionOpportunities {
          one
          two
          three
          four
          five
        }
        teacherInternationalPerspective {
          one
          two
          three
          four
          five
        }
        teacherMotivatingManner {
          one
          two
          three
          four
          five
        }
        teacherSpecificNeeds {
          one
          two
          three
          four
          five
        }
      }
      textFields {
        generalRecommendationExplanation
        generalThoughts
        generalSatisfactionAspectsOther
        generalParticipationMotivesOther
        generalLikesDislikes
        generalWouldRecommendOther
      }
    }
  }
}`
;

export default GET_COURSE_SINGLE_VIEW;
