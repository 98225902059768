import { gql } from "@apollo/client";

const GET_TOR = gql`
  query tor($laID:String!){
      tor(laID:$laID)
      {
            id
            field_la_id
            field_study_period_end
            field_study_period_start
            field_tor_table_c{
                id
                field_tor_table_c_code
                field_tor_table_c_title
                field_tor_table_c_grade
                field_tor_table_c_ects
                field_tor_table_c_completed
            }
            field_tor_table_d{
                id
                field_tor_table_d_code
                field_tor_table_d_title
                field_tor_table_d_grade
                field_tor_table_d_ects
            }
        }
    }`
;

export default GET_TOR;
