import { useEffect, useState, useRef } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import jwtDecode from "jwt-decode";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation, Link } from "react-router-dom";

import "./index.scss";

import { ACCOUNT_TYPES } from "../../../../data/constants";
import useAuth from "../../../../hooks/useAuth";
import useUser from "../../../../hooks/useUser";
import LOGIN_REGISTERED_INSTITUTION from "../../../../graphql/mutations/login/loginRegisteredInstitution";
import LOGIN_SINGLE_ACCOUNT from "../../../../graphql/mutations/login/loginSingleAccount";

const Main = () => {
  const { setAuth } = useAuth();
  const { setUser } = useUser();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const emailRef = useRef();

  const [
    loginRegisteredInstitutionMutation,
    {
      loading: loadingRegisteredInstitution,
      client,
      error: errorRegisteredInstitutionMutation,
      reset: resetRegisteredInstitutionMutation,
    },
  ] = useMutation(LOGIN_REGISTERED_INSTITUTION);

  const [
    loginSingleAccountMutation,
    {
      loading: loadingSingleAccount,
      error: errorSingleAccountMutation,
      reset: resetSingleAccountMutation,
    },
  ] = useMutation(LOGIN_SINGLE_ACCOUNT);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    resetSingleAccountMutation();
    resetRegisteredInstitutionMutation();
    setErrorMessage("");
    setValidated(false);
  }, [email, password]);

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    let finalResultObject, result;
    try {
      result = await loginSingleAccountMutation({
        variables: { email, password },
      });

      finalResultObject = result.data.accountLogin;
    } catch (errorSingleAccount) {
      try {
        if (errorSingleAccount?.graphQLErrors?.length === 0) {
          // user has no internet connection or backend services are disconnected/do not operate
          setErrorMessage("Login failed; There is a network related problem.");
          return;
        }

        // try to login as registered institution if single account failed
        result = await loginRegisteredInstitutionMutation({
          variables: { email, password },
        });
        finalResultObject = result.data.loginRegisteredInstitution;
      } catch (errorRegisteredInstitution) {
        if (errorRegisteredInstitution?.graphQLErrors?.length === 0) {
          // user has no internet connection or backend services are disconnected/do not operate
          setErrorMessage("Login failed; There is a network related problem.");
          return;
        }

        // if both tries failed then the user did not provide correct email/password combination
        // or the email does not correspond to a user
        return;
      }
    }

    const token = finalResultObject.Token;
    const decodedToken = jwtDecode(token);

    let currentUser = {
      type:
        decodedToken.userType === "HEI"
          ? ACCOUNT_TYPES.hei
          : ACCOUNT_TYPES.eufAdmin,
      heiID: decodedToken.heiID,
      email: decodedToken.email,
    };

    if (currentUser.type === ACCOUNT_TYPES.hei) {
      currentUser = {
        ...currentUser,
        userId: decodedToken.userId,
        erasmusCode: decodedToken.erasmusCode,
        userName: decodedToken.userName,
        permissions: decodedToken.permissions,
      };
    }

    setUser(currentUser);
    setAuth({ accessToken: token });
    localStorage.setItem("token", token);
    setEmail("");
    setPassword("");
    client.clearStore();
    navigate(from, { replace: true });
  };

  return (
    <div className="d-flex flex-column align-items-center position-relative px-3 px-sm-0 py-5 mx-auto mt-0 mt-lg-5 py-xxl-5 mx-sm-auto login-main-container rounded">
      <div className="welcome-back-container title mb-3">
        <div className="text-start mb-0 h1-style">Welcome back!</div>
        <div className="w-100 fs-6 text-wrap h5-style">
          Enter your credentials to access your account.
        </div>
      </div>

      <div className="form-container">
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              ref={emailRef}
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="w-100">
              <div className="d-flex justify-content-between w-100">
                <span>Password</span>
                <Link
                  to="/forgot-password"
                  className="body text-decoration-none reset-password-link"
                >
                  Forgot your password?
                </Link>
              </div>
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a password
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            className="w-100 submit-button login"
            disabled={loadingRegisteredInstitution || loadingSingleAccount}
          >
            {loadingRegisteredInstitution || loadingSingleAccount
              ? "LOADING..."
              : "LOGIN"}
          </Button>
        </Form>
      </div>
      <div className="text-danger text-center mx-auto mt-3">
        {(errorRegisteredInstitutionMutation?.graphQLErrors.length !== 0
          ? errorRegisteredInstitutionMutation?.graphQLErrors[0]?.message
          : errorSingleAccountMutation?.graphQLErrors[0]?.message) ||
          errorMessage}
      </div>
      <div className="fs-6 w-100 text-center mb-3 h5-style">
        Don't have an account yet?{" "}
        <Link className="sign-up-link text-decoration-none" to="/register">
          Register your institution
        </Link>
        !
      </div>
      <div className="position-absolute login-bottom-logo">
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            fill="#73A6AD"
          />
          <path
            d="M38 38.1248L56.5423 11.2059C62.844 15.5394 67.4266 21.9453 69.4929 29.3087C71.5591 36.6722 70.978 44.527 67.8508 51.5063C64.7236 58.4856 59.2483 64.1475 52.3776 67.5066C45.5069 70.8657 37.6759 71.7094 30.2474 69.8909L38 38.1248Z"
            fill="white"
          />
          <path
            d="M38.0694 63.6708C52.2087 63.6708 63.6708 52.2087 63.6708 38.0694C63.6708 23.9301 52.2087 12.4679 38.0694 12.4679C23.9301 12.4679 12.4679 23.9301 12.4679 38.0694C12.4679 52.2087 23.9301 63.6708 38.0694 63.6708Z"
            fill="#73A6AD"
          />
          <path
            d="M30.2405 69.8978H37.9307V42.1745H30.2405L30.2405 69.8978Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default Main;
