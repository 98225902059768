import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import GET_SHORT_TERM_LISTING from "../../../graphql/queries/short-term/getShortTermListing";
import GET_COUNTRIES from "../../../graphql/queries/registration/getCountries";
import GET_SHORT_TERM_LA_COUNT from "../../../graphql/queries/short-term/getShortTermCount";

import CustomTable from "../../../components/Table";
import TableSkeleton from "../../../components/Table/components/TableSkeleton";
import Filters from "../../../components/Filters";

import { useEffect, useState } from "react";
import { useOlaFilters } from "./hooks/useOlaFilters";

const ShortTermMobilitiesList = ({ typeOfStudent, userHeiID }) => {
  const academicFilter = () => new Date().getMonth() < 6 ?  (new Date().getFullYear() - 1) + "/" + new Date().getFullYear() : new Date().getFullYear() + "/" + (new Date().getFullYear() + 1);
  const initialState = {
    academicYear: academicFilter(),
    country: [],
    erasmusCode: "",
    studentLastName: "",
    studentFirstName: "",
    status: "",
    sortBy: "changed_descending",
    selectedFiltersList: localStorage.getItem('selectedFiltersShortTerm') !== null ? JSON.parse(localStorage.getItem('selectedFiltersShortTerm')) : {
      erasmusCode: "",
      status: "",
      country: [],
      academicYear: academicFilter(),
      studentFirstName: "",
      studentLastName: "",
    },
  };

  const filters = useOlaFilters(initialState);
  const {
    state,
    setSelectedFiltersList,
    setExpandFilters,
    setSortBy,
    updateMultipleValues,
  } = filters;
  const {
    academicYear,
    country,
    erasmusCode,
    studentLastName,
    studentFirstName,
    status,
    selectedFiltersList,
    sortBy,
    expandFilters,
  } = state;
  const [page, setPage] = useState(localStorage.getItem('pageShortTerm') !== null ? parseInt(JSON.parse(localStorage.getItem('pageShortTerm'))) : 0);
  const navigate = useNavigate();


  const {
    loading: olaListingLoading,
    error: olaListingError,
    data: olaListingData,
    refetch: olaListingRefetch,
  } = useQuery(GET_SHORT_TERM_LISTING, {
    variables: {
      typeOfStudent: typeOfStudent,
      page: page,
      sortBy: sortBy,
      optionalSTLAFilters: {
        academicYear: academicYear,
        country: country,
        erasmusCode: erasmusCode,
        studentLastName: studentLastName,
        studentFirstName: studentFirstName,
        status: status,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: laCountLoading,
    error: laCountError,
    data: laCount,
    refetch: laCountRefetch,
  } = useQuery(GET_SHORT_TERM_LA_COUNT, {
    variables: {
      homeHeiID: typeOfStudent === 1 ? userHeiID : "",
      hostHeiID: typeOfStudent === 0 ? userHeiID : "",
      academicYear: academicYear,
      status: status,
      studentFirstName: studentFirstName,
      studentLastName: studentLastName,
      erasmusCode: erasmusCode,
      country: country,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
  } = useQuery(GET_COUNTRIES);

  const handleExpand = (value) => {
    setExpandFilters(value);
  };

  useEffect(() => {
    const data = localStorage.getItem('selectedFiltersShortTerm');

    if (data) {
      if (!Object.values(JSON.parse(data) || {})?.every(x => x === '')) {
        setSelectedFiltersList(JSON.parse(data));
        olaListingRefetch();
        // laCountRefetch();
      }
      
    }
  }, []);

  useEffect(() => {
    page !== null && localStorage.setItem('pageShortTerm', JSON.stringify(page));
  }, [page]);

  useEffect(() => {
    localStorage.setItem('selectedFiltersShortTerm', JSON.stringify(selectedFiltersList));
  }, [selectedFiltersList]);

  useEffect(() => {
    updateMultipleValues(selectedFiltersList);
    olaListingRefetch();
    // laCountRefetch();
  }, [
    selectedFiltersList,
    updateMultipleValues,
    olaListingRefetch,
    //laCountRefetch,
  ]);

  const handleRemoveFilter = (filter, value) => {
    if (filter === "country") {
      let temp = Object.assign({}, selectedFiltersList);
      temp[filter] = temp[filter].filter((i) => i !== value);
      setSelectedFiltersList(temp);
    } else {
      let temp = Object.assign({}, selectedFiltersList);
      temp[filter] = "";
      setSelectedFiltersList(temp);
    }
  };

  if (olaListingLoading || loadingCountries || laCountLoading) {
    return (
      <TableSkeleton
        columnsPerFieldMap={{
          id_:"col-1",
          first_name: "col-2",
          last_name: "col-2",
          planned_period: "col-2",
          status: "col-2",
          sending_institution: "col-3"
        }}
      />
    );
  }
  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(string).toLocaleDateString("en-UK",options);
  }

  if (olaListingError || errorCountries || laCountError)
    return (
      <div className="text-center text-danger mx-auto">An error occured</div>
    );

  return (
    <main className="h-auto text-center mt-4">
      <CustomTable
        data={typeOfStudent === 1 ? olaListingData.shortTermMobilities.shortTermList.map(
          ({
            shortTermID,
            title,
            field_la_status,
            field_planned_period_from,
            field_planned_period_to,
            receiving_institution,
            field_student_firstname,
            field_student_lastname
          }) => ({
            id: shortTermID,
            id_: title.replace("Short-term Mobility-",""),
            first_name: field_student_firstname,
            last_name: field_student_lastname,
            planned_period: formatDate(field_planned_period_from)+" - "+formatDate(field_planned_period_to),
            status: field_la_status,
            receiving_institution: receiving_institution,
          })
        ) : olaListingData.shortTermMobilities.shortTermList.map(
          ({
            shortTermID,
            title,
            field_la_status,
            field_planned_period_from,
            field_planned_period_to,
            sending_institution,
            field_student_firstname,
            field_student_lastname
          }) => ({
            id: shortTermID,
            id_: title.replace("Short-term Mobility-",""),
            first_name: field_student_firstname,
            last_name: field_student_lastname,
            planned_period: formatDate(field_planned_period_from)+" - "+formatDate(field_planned_period_to),
            status: field_la_status,
            sending_institution: sending_institution,
          })
        )}
        page={page}
        setPage={(p) => setPage(p)}
        type={"ola-typeOfStudent-" + typeOfStudent + "-"}
        expandFilters={expandFilters}
        setExpandFilters={(e) => setExpandFilters(e)}
        filters={selectedFiltersList}
        setFilters={(filter, value) => {handleRemoveFilter(filter, value); setPage(0);}}
        sortBy={sortBy}
        setSortBy={() => setSortBy("")}
        columnsPerFieldMap={typeOfStudent === 1 ? {
          id_:"col-1",
          first_name: "col-2",
          last_name: "col-2",
          planned_period: "col-2",
          status: "col-2",
          receiving_institution: "col-3",
        } : {
          id_:"col-1",
          first_name: "col-2",
          last_name: "col-2",
          planned_period: "col-2",
          status: "col-2",
          sending_institution: "col-3",
        }}
        pageLimit={30}
        totalCount={laCount?.shortTermLACount}
        hasPagination={true}
        hasFilters={true}
        onClickHandlers={olaListingData.shortTermMobilities.shortTermList.map(
          ({ shortTermID }) =>
            (e) => {
              navigate(
                typeOfStudent === 1
                  ? "/short-term/outgoing/" + shortTermID
                  : "/short-term/incoming/" + shortTermID
              );
            }
        )}
      />
      <Filters
        expand={expandFilters}
        handleExpand={handleExpand}
        selectedFiltersList={selectedFiltersList}
        setSelectedFiltersList={(e) => {setSelectedFiltersList(e); setPage(0);}}
        sortBy={sortBy}
        setSortBy={(e) => setSortBy(e)}
        countries={dataCountries?.countries}
        type={"short-term"}
      />
    </main>
  );
};

export default ShortTermMobilitiesList;
