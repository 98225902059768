import { gql } from "@apollo/client";

const GET_EXPORT = gql`
query iiaBulkCsv
    {iiaBulkCsv
        
}`
;

export default GET_EXPORT;
