import { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import "./index.scss";

import Header from "./components/Header";
import Main from "./components/Main";
import BasicFooter from "../../components/BasicFooter";
import withTitle from "../../components/withTitle";

import { ReactComponent as DashyInCircleIcon } from "../../assets/dashy-in-circle.svg";

import useAuth from "../../hooks/useAuth";

function Login() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const showedToast = useRef(false);

  useEffect(() => {
    if (auth?.accessToken) {
      navigate("/", { replace: true });
    }

    if (showedToast.current) return;

    showedToast.current = true;
    if (
      location.state?.showExpiredPasswordLinkToast &&
      location.state?.processName
    ) {
      toast.error(
        `The link you followed has expired. Please request a new one by restarting the flow of the ${location.state?.processName} process.`,
        {
          position: "bottom-left",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  });

  return (
    <main className="d-flex flex-column justify-content-around login">
      <div className="d-flex flex-column position-relative">
        <Header />
        <DashyInCircleIcon className="position-absolute start-50 translate-middle forgot-password-mail-icon d-none d-lg-block" />
        <Main />
      </div>
      <BasicFooter>
        <h4 className="no-more-paper">no more paper; no more time wasted.</h4>
      </BasicFooter>
      <ToastContainer
        position="bottom-left"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  );
}

export default withTitle(Login, "Login to your account");
