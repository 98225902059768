import { gql } from "@apollo/client";

const GET_IIA_PDF = gql`
query iiaPdf($id: String!)
    {iiaPdf(id: $id)

    
}`
;

export default GET_IIA_PDF;
