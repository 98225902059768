import usePlacesAutocomplete from "use-places-autocomplete";
import { getGeocode, getLatLng, getDetails } from "use-places-autocomplete";
import Select from "../../../../components/Select";

const PlacesAutocomplete = ({ validated, address, setLocation }) => {
    const {
        suggestions: { status, data },
        setValue,
    } = usePlacesAutocomplete({
        debounce: 300,
    });

    const handleInput = (e) => {
        setValue(e);
    };

    const handleSelect = async (val) => {

        let parameter = {
            address: val?.value,
        };

        let placeId, country, city, latLng;
        try {
            const results = await getGeocode(parameter)

            const { lat, lng } = getLatLng(results[0]);
            placeId = results[0]?.place_id;
            latLng = lat + "," + lng;


        } catch (error) {
            console.log("Error: ", error);
        };
        parameter["fields"] = ["formatted_address", "vicinity", "address_components"];
        parameter["placeId"] = placeId;
        try {
            const details = await getDetails(parameter)
            country = details?.address_components?.find(e => e?.types?.includes("country"))?.long_name;
            city = details?.address_components?.find(e => e?.types?.includes("locality"))?.long_name
        } catch (error) {
            console.log("Error: ", error);
        };
        setLocation(val?.value, city, country, latLng)

        setValue(val?.value, false);
    };

    return (
        <Select
            options={status === "OK" &&
                data ? data.map(({ description }) => (
                    { value: description, label: description }
                )) : []}
            placeholder="Select location"
            onInputChange={handleInput}
            onChange={handleSelect}
            validated={validated}
            invalid={!address}
            value={address ? {
                "label": address
            } : null}
        />
    );

};

export default PlacesAutocomplete;