import { useState, useEffect, useRef } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Collapse } from "react-bootstrap";

import {
  useMutation,
  useLazyQuery,
  useQuery,
  NetworkStatus,
} from "@apollo/client";
import { useNavigate, Link } from "react-router-dom";

import "./index.scss";

import SelectPhoneInput from "../../../../components/SelectPhoneInput";
import CountryCityInput from "../CountryCityInput";
import EmailWebsiteInput from "../EmailWebsiteInput";

import { useRegister } from "../../hooks/useRegister";
import useBreakpoint from "../../../../hooks/useBreakpoint";

import GET_COUNTRIES from "../../../../graphql/queries/registration/getCountries";
import GET_CITIES from "../../../../graphql/queries/registration/getCities";
import GET_INSTITUTIONS from "../../../../graphql/queries/registration/getInstitutions";
import REGISTER_INSTITUTION from "../../../../graphql/mutations/registration/registerInstitution";

const Main = () => {
  const {
    institutionalEmail,
    phoneCode,
    institutionalPhone,
    institutionalWebsite,
    institutionCountry,
    institutionCity,
    institution,
    updateMultipleValues,
  } = useRegister();

  const {
    loading: loadingCountries,
    error: errorCountries,
    data: dataCountries,
  } = useQuery(GET_COUNTRIES);

  const [
    fetchCities,
    {
      loading: loadingCities,
      error: errorCities,
      data: dataCities,
      called: calledCitiesQuery,
      networkStatus: networkStatusCities,
      refetch: refetchCities,
    },
  ] = useLazyQuery(GET_CITIES, {
    notifyOnNetworkStatusChange: true,
  });

  const [
    fetchInstitutions,
    {
      loading: loadingInstitutions,
      error: errorInstitutions,
      data: dataInstitutions,
      called: calledInstitutionsQuery,
      networkStatus: networkStatusInstitutions,
      refetch: refetchInstitutions,
    },
  ] = useLazyQuery(GET_INSTITUTIONS, {
    notifyOnNetworkStatusChange: true,
  });

  const [
    registerInstitution,
    { loading: loadingRegister, error: errorRegister },
  ] = useMutation(REGISTER_INSTITUTION);

  const navigate = useNavigate();
  const showedTooltip = useRef(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [size] = useBreakpoint();

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      loadingInstitutions ||
      loadingCountries ||
      loadingCities
    ) {
      event.stopPropagation();
      return;
    }

    try {
      setValidated(false);
      const result = await registerInstitution({
        variables: {
          erasmusCode: institution,
          phone: institutionalPhone,
          email: institutionalEmail,
          website: institutionalWebsite,
        },
      });

      if (result.data.registerInstitution.Error != null) {
        setErrorMessage(result.data.registerInstitution.Error);
        return;
      }
      navigate("/login");
    } catch (err) {
      setErrorMessage(
        err?.graphQLErrors?.length !== 0
          ? err?.graphQLErrors[0]?.message
          : "An error occured. Please try again."
      );
    }
  };

  useEffect(() => {
    let timeout;

    if (
      institutionCountry !== "" &&
      !showedTooltip.current &&
      size !== "xs" &&
      size !== "sm" &&
      size !== "md"
    ) {
      showedTooltip.current = true;
      setShowTooltip(true);

      timeout = setTimeout(() => {
        setShowTooltip(false);
      }, [5000]);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [institutionCountry]);

  useEffect(() => {
    setErrorMessage("");
    setValidated(false);
  }, [
    institutionalEmail,
    phoneCode,
    institutionalPhone,
    institutionalWebsite,
    institutionCountry,
    institutionCity,
    institution,
  ]);

  return (
    <div className="register-main-container rounded-3 mx-auto py-4 d-flex flex-column align-items-center position-relative">
      <div className="title mb-4">
        <h1 className="text-start register-text">Register your institution</h1>
        <div className="w-100 fs-6 text-wrap h5-style">
          Fill the necessary information to register your Institution in the EWP
          Dashboard application. Please make sure that you only use your{" "}
          <span className="fw-bold">institutional email addresses</span> (e.g.
          no Hotmail, Gmail etc. services).
        </div>
      </div>

      <div>
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <CountryCityInput
            size={size}
            key={"" + loadingCountries + loadingCities + loadingInstitutions}
            institutionCountry={institutionCountry}
            institutionCity={institutionCity}
            updateMultipleValues={updateMultipleValues}
            dataCountries={dataCountries}
            calledCitiesQuery={calledCitiesQuery}
            refetchCities={refetchCities}
            fetchCities={fetchCities}
            loadingCountries={loadingCountries}
            loadingCities={loadingCities}
            dataCities={dataCities}
            networkStatusCities={networkStatusCities}
            calledInstitutionsQuery={calledInstitutionsQuery}
            refetchInstitutions={refetchInstitutions}
            fetchInstitutions={fetchInstitutions}
          />
          <Collapse in={institutionCountry !== "" && institutionCity !== ""}>
            <Row className="mb-3 mx-0">
              <Form.Group
                controlId="formInstitutionalWebsite"
                className={"px-0"}
              >
                <Form.Label className="ps-0">Institution</Form.Label>
                <Form.Select
                  required
                  value={institution}
                  onChange={(e) =>
                    updateMultipleValues({ institution: e.target.value })
                  }
                  disabled={
                    loadingInstitutions ||
                    networkStatusInstitutions === NetworkStatus.loading ||
                    networkStatusInstitutions === NetworkStatus.refetch ||
                    dataInstitutions?.institutions?.length === 0
                  }
                >
                  <option value="" disabled>
                    {loadingInstitutions ||
                    networkStatusInstitutions === NetworkStatus.loading ||
                    networkStatusInstitutions === NetworkStatus.refetch
                      ? "Loading..."
                      : "Choose institution..."}
                  </option>
                  {!loadingInstitutions &&
                    dataInstitutions?.institutions
                      .slice()
                      .sort((institution1, institution2) =>
                        institution1.ID > institution2.ID ? 1 : -1
                      )
                      .map((institution) => (
                        <option
                          key={institution.ID}
                          value={institution.ErasmusCode}
                        >
                          {institution.ID}
                        </option>
                      ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select your institution.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Collapse>
          <EmailWebsiteInput
            key="email-website-input"
            size={size}
            institutionalEmail={institutionalEmail}
            institutionalWebsite={institutionalWebsite}
            updateMultipleValues={updateMultipleValues}
          />
          <SelectPhoneInput
            key="select-phone-input"
            size={size}
            phoneCode={phoneCode}
            showTooltip={showTooltip}
            phone={institutionalPhone}
            phoneLabel={"Contact Institutional Phone"}
            phonePropertyName={"institutionalPhone"}
            updateMultipleValues={updateMultipleValues}
          />
          <Row className="mb-3 mx-auto">
            <Form.Group
              controlId="formAuthorizations"
              onClick={(e) => {
                setErrorMessage("");
                setValidated(false);
              }}
            >
              <Form.Check type="checkbox" id="check-authorizations">
                <Form.Check.Input type="checkbox" required />
                <Form.Check.Label>
                  I confirm, also on behalf of my HEI, that I have all the
                  authorisations, including the consent of the relevant natural
                  persons, necessary to upload the personal data and information
                  I am providing, pursuant to the{" "}
                  <span
                    // href="/"
                    onClick={() => navigate("/privacy-policy-terms-conditions", { replace: true })}
                    className="text-decoration-none confirm-dashboard-terms-link"
                  >
                    Dashboard Terms & Conditions and Privacy Policy
                  </span>
                  .
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  Please conirm that you have all the authorizations to upload
                  the personal data and information you are providing
                </Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
          </Row>
          <Row className="mb-3 mx-auto">
            <Form.Group
              controlId="formTerms"
              onClick={(e) => {
                setErrorMessage("");
                setValidated(false);
              }}
            >
              <Form.Check type="checkbox" id="agree-terms">
                <Form.Check.Input type={"checkbox"} required />
                <Form.Check.Label>
                  I have read and agree to the{" "}
                  <span
                    // href="/"
                    onClick={() => navigate("/privacy-policy-terms-conditions", { replace: true })}
                    className="text-decoration-none read-privacy-policy-link"
                  >
                    Privacy Policy and Terms and Conditions
                  </span>
                  .
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  Please agree that you have read the Privacy Policy and Terms
                  and Conditions.
                </Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
          </Row>
          <Button
            type="submit"
            className="w-100 register-button register"
            disabled={
              loadingRegister ||
              loadingCountries ||
              loadingCities ||
              loadingInstitutions
            }
          >
            {loadingRegister ? "Loading..." : "REGISTER MY INSTITUTION"}
          </Button>
        </Form>
      </div>
      <div className="text-danger text-center mx-auto mt-3">{errorMessage}</div>
      <div className="fs-6 w-100 text-center mb-5 already-have-account">
        Already have an account?{" "}
        <Link className="login-link text-decoration-none" to="/login">
          Login
        </Link>
      </div>
      <div className="position-absolute register-bottom-logo">
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            fill="#73A6AD"
          />
          <path
            d="M38 38.1248L56.5423 11.2059C62.844 15.5394 67.4266 21.9453 69.4929 29.3087C71.5591 36.6722 70.978 44.527 67.8508 51.5063C64.7236 58.4856 59.2483 64.1475 52.3776 67.5066C45.5069 70.8657 37.6759 71.7094 30.2474 69.8909L38 38.1248Z"
            fill="white"
          />
          <path
            d="M38.0694 63.6708C52.2087 63.6708 63.6708 52.2087 63.6708 38.0694C63.6708 23.9301 52.2087 12.4679 38.0694 12.4679C23.9301 12.4679 12.4679 23.9301 12.4679 38.0694C12.4679 52.2087 23.9301 63.6708 38.0694 63.6708Z"
            fill="#73A6AD"
          />
          <path
            d="M30.2405 69.8978H37.9307V42.1745H30.2405L30.2405 69.8978Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default Main;
