import { useState, useRef, useEffect } from "react";

import Button from "react-bootstrap/Button";
import { Row, Col, Form } from "react-bootstrap";
import { Collapse } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  Navigate,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import zxcvbnEnPackage from "@zxcvbn-ts/language-en";

import VALIDATE_RESET_TOKEN from "../../../../graphql/queries/registration/validateResetToken";
import CHANGE_PASSWORD_ACCOUNT from "../../../../graphql/mutations/registration/changePasswordAccount";
import CHANGE_PASSWORD_REGISTERED_INSTITUTION from "../../../../graphql/mutations/registration/changePasswordRegisteredInstitution";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCircleCheck,
  faCircleXmark,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import COLORS from "../../../../data/colors";

import "./index.scss";

const strengthLabels = [
  { label: "weak", variant: "danger" },
  { label: "okay", variant: "warning" },
  { label: "good", variant: "info" },
  { label: "strong", variant: "success" },
];

const Main = () => {
  // Parameters
  const [searchParams] = useSearchParams();

  // Navigation
  const navigate = useNavigate();

  // State
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordSuggestions, setPasswordSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const showPasswordButtonRef = useRef();
  const inputGroupRef = useRef();
  const passwordInputRef = useRef();
  const mounted = useRef(false);

  // Queries - Mutations
  const [
    validateTokenQuery,
    { loading: loadingQuery, error: errorQuery, data },
  ] = useLazyQuery(VALIDATE_RESET_TOKEN);

  const [
    setPasswordMutation,
    {
      loading: loadingSetPassword,
      error: errorSetPassword,
      reset: resetSetPassword,
    },
  ] = useMutation(
    searchParams.get("account") === "staff"
      ? CHANGE_PASSWORD_ACCOUNT
      : CHANGE_PASSWORD_REGISTERED_INSTITUTION
  );

  const options = {
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    translations: zxcvbnEnPackage.translations,
  };
  zxcvbnOptions.setOptions(options);

  useEffect(() => {
    (async () => {
      try {
        const result = await validateTokenQuery({
          variables: {
            email: searchParams.get("e"),
            token: searchParams.get("t").replaceAll(" ", "+"),
            type: searchParams.get("account"),
          },
        });

        if (result?.data?.validateResetToken === false) {
          navigate("/login", {
            replace: true,
            state: {
              showExpiredPasswordLinkToast: true,
              processName: "password reset",
            },
          });
        }
      } catch (err) {
        console.log(JSON.stringify(err));
      }
    })();
  }, []);

  useEffect(() => {
    if (passwordInputRef?.current) passwordInputRef.current.focus();
  }, []);

  useEffect(() => {
    resetSetPassword();
    setErrorMessage("");
    setValidated(false);
    const zxcvbnResult = zxcvbn(password);
    setPasswordScore(zxcvbnResult?.score || 0);
    setPasswordSuggestions(zxcvbnResult.feedback.suggestions);
  }, [password]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    if (!validated && showPasswordButtonRef.current && inputGroupRef.current) {
      showPasswordButtonRef.current.classList.add("show-password-button-focus");
      inputGroupRef.current.classList.add("input-group-focus");
    }
  }, [validated]);

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();

    if (
      !(password.length >= 8) ||
      !(password.length <= 128) ||
      !/[A-Z]/.test(password) ||
      !/[!@#$%^&*]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      event.stopPropagation();
      return;
    }

    try {
      const result = await setPasswordMutation({
        variables: {
          email: searchParams.get("e"),
          password,
          token: searchParams.get("t").replaceAll(" ", "+"),
        },
      });
      navigate("/login", { replace: true });
    } catch (err) {
      setErrorMessage(
        "Reset password process failed; There is a problem with your network connection."
      );
    }
  };

  if (
    !searchParams.get("account") ||
    !searchParams.get("t") ||
    !searchParams.get("e") ||
    (searchParams.get("account") !== "staff" &&
      searchParams.get("account") !== "institutional")
  )
    return (
      <Navigate
        to="/login"
        state={{
          showExpiredPasswordLinkToast: true,
          processName: "password reset",
        }}
      />
    );

  if (errorQuery) {
    return (
      <Navigate
        to="/login"
        state={{
          showExpiredPasswordLinkToast: true,
          processName: "password reset",
        }}
      />
    );
  }

  return (
    <div className="register-confirmation-main-container rounded-3 mx-auto py-5 mt-5 d-flex flex-column position-relative">
      <div className="title mb-4">
        <h1 className="text-start check-text">Set a new password</h1>
        <div className="w-100 fs-6 text-wrap h5-style">
          Let's create a new password. Enter your new password below.
        </div>
      </div>
      <div className="mb-3">
        <Form onSubmit={handleSubmit} className="text-start">
          <Form.Group controlId="formPassword" className="mb-2">
            <Form.Label className="register-confirm-form-label">
              Password
            </Form.Label>
            <InputGroup ref={inputGroupRef}>
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => {
                  if (!validated) {
                    showPasswordButtonRef.current.classList.add(
                      "show-password-button-focus"
                    );
                    inputGroupRef.current.classList.add("input-group-focus");
                  }
                }}
                onBlur={(e) => {
                  showPasswordButtonRef.current.classList.remove(
                    "show-password-button-focus"
                  );
                  inputGroupRef.current.classList.remove("input-group-focus");
                }}
                isInvalid={
                  validated &&
                  (!(password.length >= 8) ||
                    !(password.length <= 128) ||
                    !/[A-Z]/.test(password) ||
                    !/[!@#$%^&*]/.test(password) ||
                    !/[0-9]/.test(password))
                }
                isValid={
                  validated &&
                  password.length >= 8 &&
                  password.length <= 128 &&
                  /[A-Z]/.test(password) &&
                  /[!@#$%^&*]/.test(password) &&
                  /[0-9]/.test(password)
                }
                className="border-end-0 register-confirm-form-control"
                ref={passwordInputRef}
              />
              <InputGroup.Text
                ref={showPasswordButtonRef}
                className="bg-transparent show-password-button"
                onClick={(e) => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  size="xl"
                  color={COLORS.black}
                />
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <ProgressBar
            data-variant={
              strengthLabels[
                passwordScore !== 0 ? passwordScore - 1 : passwordScore
              ].variant
            }
            label={
              passwordScore !== 0 && strengthLabels[passwordScore - 1].label
            }
            now={passwordScore * 25}
            className="mb-3 register-confirm-progress-bar register-confirm-roboto-regular"
          />
          <Row className="register-confirm-roboto-regular">
            <div>
              Your password <span className="fw-bold">must</span> contain:
            </div>
          </Row>
          <Row className="flex-nowrap  align-items-center register-confirm-roboto-regular">
            <FontAwesomeIcon
              icon={password.length >= 8 ? faCircleCheck : faCircleXmark}
              color={password.length >= 8 ? COLORS.greenCheck : COLORS.redCross}
              className="w-auto align-self-start pt-1"
            />
            <span className="w-auto ps-0">At least 8 characters</span>
          </Row>
          <Row className="flex-nowrap align-items-center register-confirm-roboto-regular">
            <FontAwesomeIcon
              icon={password.length <= 128 ? faCircleCheck : faCircleXmark}
              color={
                password.length <= 128 ? COLORS.greenCheck : COLORS.redCross
              }
              className="w-auto align-self-start pt-1"
            />
            <span className="w-auto ps-0">Maximum 128 characters</span>
          </Row>
          <Row className="flex-nowrap align-items-center register-confirm-roboto-regular">
            <FontAwesomeIcon
              icon={/[A-Z]/.test(password) ? faCircleCheck : faCircleXmark}
              color={
                /[A-Z]/.test(password) ? COLORS.greenCheck : COLORS.redCross
              }
              className="w-auto align-self-start pt-1"
            />
            <span className="w-auto ps-0">At least 1 uppercase letter</span>
          </Row>
          <Row className="flex-nowrap align-items-center register-confirm-roboto-regular">
            <FontAwesomeIcon
              icon={/[!@#$%^&*]/.test(password) ? faCircleCheck : faCircleXmark}
              color={
                /[!@#$%^&*]/.test(password)
                  ? COLORS.greenCheck
                  : COLORS.redCross
              }
              className="w-auto align-self-start pt-1"
            />
            <span className="w-auto ps-0">
              {"At least 1 special character (!@#$%^&*)"}
            </span>
          </Row>
          <Row className="flex-nowrap mb-3 align-items-center register-confirm-roboto-regular">
            <FontAwesomeIcon
              icon={/[0-9]/.test(password) ? faCircleCheck : faCircleXmark}
              color={
                /[0-9]/.test(password) ? COLORS.greenCheck : COLORS.redCross
              }
              className="w-auto align-self-start pt-1"
            />
            <span className="w-auto ps-0">At least 1 number</span>
          </Row>
          <Collapse
            in={passwordScore <= 2 && passwordSuggestions?.length > 0}
            className="mb-3 register-confirm-roboto-regular"
          >
            <div>
              <Row>
                <div>
                  <span className="fw-bold">Optional</span> tips to make your
                  password more secure:
                </div>
              </Row>

              {passwordSuggestions.map((suggestion) => (
                <Row className="flex-nowrap align-items-start" key={suggestion}>
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    color={COLORS.black}
                    className="w-auto align-self-start pt-1"
                  />
                  <span className="w-auto ps-0">{suggestion}</span>
                </Row>
              ))}
            </div>
          </Collapse>
          <Button
            type="submit"
            className="w-100 register-confirmation-button register-confirmation"
            disabled={loadingQuery || loadingSetPassword}
          >
            {loadingSetPassword ? "Loading..." : "SUBMIT"}
          </Button>
        </Form>
      </div>
      <div className="text-danger text-center mx-auto mb-2">
        {(errorSetPassword?.graphQLErrors.length !== 0 &&
          errorSetPassword?.graphQLErrors[0]?.message) ||
          errorMessage}
      </div>
      <div className="position-absolute reset-password-bottom-logo align-self-center">
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            fill="#73A6AD"
          />
          <path
            d="M38 38.1248L56.5423 11.2059C62.844 15.5394 67.4266 21.9453 69.4929 29.3087C71.5591 36.6722 70.978 44.527 67.8508 51.5063C64.7236 58.4856 59.2483 64.1475 52.3776 67.5066C45.5069 70.8657 37.6759 71.7094 30.2474 69.8909L38 38.1248Z"
            fill="white"
          />
          <path
            d="M38.0694 63.6708C52.2087 63.6708 63.6708 52.2087 63.6708 38.0694C63.6708 23.9301 52.2087 12.4679 38.0694 12.4679C23.9301 12.4679 12.4679 23.9301 12.4679 38.0694C12.4679 52.2087 23.9301 63.6708 38.0694 63.6708Z"
            fill="#73A6AD"
          />
          <path
            d="M30.2405 69.8978H37.9307V42.1745H30.2405L30.2405 69.8978Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default Main;
