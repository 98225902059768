import { gql } from "@apollo/client";

const GET_UKRAINE_ASSISTANCE_INFO = gql`
    query getUkraineAssistanceInfos {
        getUkraineAssistanceInfo {
            shelter
            health
            fast_track
            scholarship
            more_info_url
        }
    }
`;

export default GET_UKRAINE_ASSISTANCE_INFO;