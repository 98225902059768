import { gql } from "@apollo/client";

const GET_SINGLE_REGISTERED_INSTITUTION_ACCOUNT = gql`
  query singleRegisteredInstitutionAccount($id: ID) {
    singleRegisteredInstitutionAccount(id: $id) {
      Email
      Institution {
        NameInLatinCharacterSet
      }
      Phone
    }
  }
`;

export default GET_SINGLE_REGISTERED_INSTITUTION_ACCOUNT;
