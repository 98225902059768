import { gql } from "@apollo/client";

const DELETE_USEFUL_INFO = gql`
mutation deleteUsefulInfo($id: Int!, $externalID: String!)
    {  deleteUsefulInfo(id: $id, externalID: $externalID)
    
}`
;

export default DELETE_USEFUL_INFO;
