import { reducer } from '../reducers/useCoursesReducer';
import { useReducer, useCallback } from 'react';
import {
    SET_EXPAND_FILTERS_ACTION,
    SET_SELECTED_FILTERS_LIST_ACTION,
    UPDATE_MULTIPLE_VALUES_ACTION
} from '../reducers/useCoursesReducer';

// fallback state
export const defaultState = {
    courseTitle: "",
    courseCode: "",
    selectedFiltersList: [],
    expandFilters: false
}

/**
 * Used to handle filters for IIA queries
 * 
 * @param {Object} initialState the initial state of the filters, fallbacks to a default state with empty string and lists if no initial state is provided
 * @returns Filters state as well as handlers for changing the state
 */
export const useCourses = (initialState = defaultState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setExpandFilters = useCallback((data) => {
        dispatch({ type: SET_EXPAND_FILTERS_ACTION, payload: { expandFilters: data } });
    }, []);

    const setSelectedFiltersList = useCallback((data) => {
        dispatch({ type: SET_SELECTED_FILTERS_LIST_ACTION, payload: { selectedFiltersList: data } });
    }, []);

    const updateMultipleValues = useCallback((data) => {
        dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data })
    }, []);

    return {
        state,
        setExpandFilters,
        setSelectedFiltersList,
        updateMultipleValues
    };
}