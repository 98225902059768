import { gql } from "@apollo/client";

const DELETE_EVENT = gql`
mutation deleteEvent($id: Int!, $externalID: String!)
    {  deleteEvent(id: $id, externalID: $externalID)
    
}`
;

export default DELETE_EVENT;
