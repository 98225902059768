import { gql } from "@apollo/client";

const GET_NOMINATION_ZIP = gql`
query studentApplicationZip($uuid: String!)
    {studentApplicationZip(uuid: $uuid)

    
}`
;

export default GET_NOMINATION_ZIP;
