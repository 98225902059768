import { gql } from "@apollo/client";

const GET_APPICATION_SETTINGS = gql`
query applicationRequirements{
        applicationRequirements{
            winterStartDate
            winterEndDate
            summerStartDate
            summerEndDate
            maxHEInumber
            ectsRequired
            ectsURL
            iscedURL
            isced
            eqfLevel
            eqfLevelURL
            cvDescription
            cvRequired
            cvURL
            motivLetterDescription
            motivLetterRequired
            motivLetterURL
            torDescription
            torRequired
            torURL
            recomLetterDescription
            recomLetterRequired
            recomLetterURL
            languageArray {uuid, isRequired, code, level, url, certRequired, certDescription}
            extCertDescription
            extCertRequired
            extCertURL
            referenceContactDescription
            referenceContactRequired
            referenceContactURL
            portfolioDescription
            portfolioRequired
            portfolioURL
            otherRequirementArray {uuid, isRequired, url, description}
            otherDocumentArray {uuid, isRequired, url, description}
        }
    }
`
;

export default GET_APPICATION_SETTINGS;
