import { Form, Row, Col } from 'react-bootstrap';
import { NetworkStatus } from '@apollo/client';
import { useQuery, useMutation } from "@apollo/client";
import CustomModal from '../../components/CustomModal';
import CustomButton from '../../components/CustomButton';
import "./index.scss";

import GET_OUNITS from "../../graphql/queries/IIAs/getOUnits";
import ADD_OUNIT from '../../graphql/mutations/organizationalUnits/addOunit';
import UPDATE_OUNIT from '../../graphql/mutations/organizationalUnits/updateOnit';
import DELETE_OUNIT from '../../graphql/mutations/organizationalUnits/deleteOunit';

import CustomTable from "../../components/Table";
import TableSkeleton from "../../components/Table/components/TableSkeleton";

import toastConfiguration from "../../data/toastConfiguration";
import { ToastContainer, toast } from "react-toastify";

import { useState, useRef } from "react";
import IconButton from "../../components/IconButton";
import {
  faPlus,
  faCheck,
  faFloppyDisk,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import COLORS from "../../data/colors";
import Select from "../../components/Select";
import { languagesvalues } from '../Nominations/Requirements/Taxonomy';


const OrganizationalUnits = ({ userHeiID }) => {
  const formRef = useRef(null);
  const [ounitListingData,setOunitListingData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ounitName, setOunitName] = useState("");
  const [ounitId, setOunitId] = useState("");
  const [ounitCode, setOunitCode] = useState("");
  const [ounitNameLanguage, setOunitNameLanguage] = useState("");
  const [ounitAbbreviation, setOunitAbbreviation] = useState("");
  const [validated, setValidated] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");


  const {
    loading: ounitListingLoading,
    error: ounitListingError,
    refetch: refetchOunits,
  } = useQuery(GET_OUNITS, {
    notifyOnNetworkStatusChange: true,
    variables: { heiID: userHeiID },
        // When request is completed, we set the state
        onCompleted: (data) => {
          setOunitListingData(data)
        }
  });

  const [addOunit, { loading: loadingAddOunit }] = useMutation(ADD_OUNIT);

  const [removeOunit, { loading: loadingRemoveOunit }] =
    useMutation(DELETE_OUNIT);

  const [updateOunit, { loading: loadingUpdateOunit }] =
    useMutation(UPDATE_OUNIT);

  const formIsValidated = (e) => {
    setValidated(true);
    e.preventDefault();

    if (!formRef.current.checkValidity()) {
      e.stopPropagation();
      return false;
    }

    return true;
  };

  const handleAdd = async (e) => {
    setShowModal(false);
    if (!formIsValidated(e)) return;

    const toastId = toast.loading("Creating Organizational Unit...", toastConfiguration);
    try {
      const result = await addOunit({
        variables: {
          oUnitData: {
            abbreviation: ounitAbbreviation,
            name:
              [{
                value: ounitName,
                language: ounitNameLanguage
              }]
          }

        }
      }
      );
      setOunitName("");
      setOunitNameLanguage("");
      setOunitAbbreviation("");
      setValidated(false);
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `Successfully created the Organizational Unit with name "${ounitName}"`,
          type: "success",
          isLoading: false,
        });
        refetchOunits();
      }
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not create the Organizational Unit`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleDelete = async (e) => {
    setShowModal(false);
    const toastId = toast.loading("Deleting Organizational Unit...", toastConfiguration);
    try {
      const result = await removeOunit({
        variables: {
          ounit_id: ounitId,
        },
      });
      setValidated(false);
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `Successfully deleted the Organizational Unit`,
          type: "success",
          isLoading: false,
        });
        refetchOunits();
        
      }
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not delete the Organizational Unit`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleEdit = async (e) => {
    if (!formIsValidated(e)) return;
    setShowModal(false);
    const toastId = toast.loading("Updating Organizational Unit...", toastConfiguration);

    try {
      const result = await updateOunit({
        variables: {
          oUnitData: {
            abbreviation: ounitAbbreviation,
            name:
              [{
                value: ounitName,
                language: ounitNameLanguage
              }],
            ounit_id: ounitId,
            ounit_code: ounitCode
          }

        }
      });
      setOunitName("");
      setOunitNameLanguage("");
      setOunitAbbreviation("");
      setValidated(false);
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `Successfully updated the Organizational Unit "${ounitName}"`,
          type: "success",
          isLoading: false,
        });
        refetchOunits(); 
      }

    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: `Could not update the Organizational Unit`,
        type: "error",
        isLoading: false,
      });
    }
  };


  return (
    <main className="h-auto text-center mt-4">
      {ounitListingLoading ? <TableSkeleton
        columnsPerFieldMap={{
          id_: "col-4",
          partner: "col-4",
          last_edited: "col-4",

        }}
      /> : ounitListingError ?
        <div className="text-center text-danger mx-auto">An error occured</div> :
        <>
          <CustomTable
            type={"ounit-"}
            columnsPerFieldMap={{
              ounit_name_language: "col-4",
              ounit_name: "col-4",
              abbreviation: "col-4"
            }}
            hasPagination={false}
            hasFilters={false}
            rowClassName="ounits-listing-table-row"
            headerClassName="ounits-listing-table-header"

            data={ounitListingData?.ounits?.map(
              ({
                ounit_name,
                abbreviation
              }) => ({
                ounit_name_language: ounit_name?.language,
                ounit_name: ounit_name?.name,
                abbreviation: abbreviation
              })

            )
            }
          >
            {ounitListingData?.ounits?.map((ounit) => (
              <div
                key={ounit?.ounit_id}
                className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
              >
                {/* <Toggle/> */}
                <IconButton
                  variant="edit"
                  title="Edit Organizational Unit"
                  onClick={() => {
                    setSelectedAction("edit");
                    setOunitName(ounit?.ounit_name?.name);
                    setOunitNameLanguage(ounit?.ounit_name?.language);
                    setOunitAbbreviation(ounit?.abbreviation);
                    setOunitId(ounit?.ounit_id);
                    setOunitCode(ounit?.ounit_code);
                    setShowModal(true);

                  }}
                />
                <IconButton
                  variant="delete"
                  onClick={() => {
                    setSelectedAction("delete");
                    setOunitId(ounit?.ounit_id);
                    setShowModal(true);

                  }}
                  title="Delete Organizational Unit"
                />
              </div>
            ))}
          </CustomTable>
          <CustomModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
              setOunitName("");
              setOunitNameLanguage("");
              setOunitAbbreviation("");
              setValidated(false);
            }}
            disabledCancelButton={
              loadingAddOunit || loadingUpdateOunit || loadingRemoveOunit
            }
            body={
              (selectedAction === "edit" || selectedAction === "add") && (
                <div>
                  <Form validated={validated} noValidate ref={formRef}>
                    <Form.Group className="mb-3" controlId="formOunitName">
                      <Form.Label>Unit Name Language</Form.Label>
                      <Select
                        options={languagesvalues.map((role) => ({
                          label: role.title,
                          value: role.code,
                        }))}
                        placeholder="Select language..."
                        onChange={(newSelectOption) => {
                          setOunitNameLanguage(newSelectOption.value);
                        }}
                        value={
                          {
                            label: ounitNameLanguage,
                            value: ounitNameLanguage,
                          }
                        }
                        validated={validated}
                        invalid={!ounitNameLanguage}
                        invalidText="Please provide a language for the Organizational Unit Name."
                      />

                      <Form.Control.Feedback type="invalid">
                        Please provide an Organizational Unit name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOunitEmail">
                      <Form.Label>Unit Name</Form.Label>
                      <Form.Control
                        placeholder="Ounit Name"
                        value={ounitName}
                        required
                        onChange={(e) => setOunitName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Organizational Unit email.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOunitEmail">
                      <Form.Label>Unit Abbreviation</Form.Label>
                      <Form.Control
                        placeholder="Ounit Name"
                        value={ounitAbbreviation}
                        required
                        onChange={(e) => setOunitAbbreviation(e.target.value)}
                      />
                    </Form.Group>

                  </Form>
                </div>
              )
            }
            button={
              selectedAction === "add" ? ( // new
                <CustomButton
                  buttonType={"primary"}
                  disabled={loadingAddOunit}
                  text={"Add a new Organizational Unit"}
                  icon={faCheck}
                  hasIcon={true}
                  iconColor={COLORS.white}
                  handleClick={handleAdd}
                />
              ) : selectedAction === "edit" ? ( // edit
                <CustomButton
                  buttonType={"accept"}
                  disabled={loadingUpdateOunit}
                  text={"Save Changes"}
                  icon={faFloppyDisk}
                  hasIcon={true}
                  iconColor={COLORS.white}
                  handleClick={handleEdit}
                />
              ) : (
                // delete
                <CustomButton
                  buttonType={"decline"}
                  disabled={loadingRemoveOunit}
                  text={"Delete"}
                  icon={faTrash}
                  hasIcon={true}
                  iconColor={COLORS.white}
                  handleClick={handleDelete}
                />
              )
            }
            title={
              selectedAction === "add" ? (
                "Add a new Organizational Unit"
              ) : selectedAction === "edit" ? (
                "Edit an existing Organizational Unit"
              ) : (
                <>
                  You are about to{" "}
                  <span className="accounts-and-access-delete-text-on-modal">
                    delete
                  </span>{" "}
                  an Organizational Unit
                </>
              )
            }
            subtitle={
              selectedAction === "add" ? (
                "Add the necessary information to add a new Organizational Unit for your HEI"
              ) : selectedAction === "edit" ? (
                "Add the necessary information to edit an Organizational Unit for your HEI"
              ) : (
                "After deleting an Organizational Unit, you won't be able to see it again."
              )
            }
            hasIcon
            fullscreen
          />
          <Row>
            <Col>
              <Col style={{ marginTop: 76, marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
                <CustomButton type="submit" buttonType={"primary"} text={"Add a new Organizational Unit"} icon={faPlus} handleClick={() => {
                  setSelectedAction("add");
                  setShowModal(true);

                }} hasIcon styles={{ marginLeft: 20 }} small />
              </Col>
            </Col>
          </Row>
        </>}
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
    </main>
  );
};

export default OrganizationalUnits;
