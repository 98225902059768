import { gql } from "@apollo/client";

const GET_OLA_LISTING = gql`
  query LearningAgreements(
      $typeOfStudent: Int!,
      $page: Int!,
      $optionalOLAFilters: OptionalOLAFilters,
      $sortBy: String!){
      learningAgreements(
        typeOfStudent: $typeOfStudent,
        page: $page,
        optionalOLAFilters: $optionalOLAFilters,
        sortBy: $sortBy)
      {
        learningAgreementList{
          olaID
          title
          field_la_status
          field_student_lastname
          field_student_firstname
          field_academic_year
          field_planned_period_from
          field_planned_period_to
          sending_institution
          receiving_institution
          receiving_resp_person
          sending_contact_person
          receiving_institution_faculty
          field_dashboard_student_email
          sending_institution_country
          receiving_institution_country
          receiving_institution_hei
          sending_institution_hei
        }
        page
        nextLink
      }
    }`
;

export default GET_OLA_LISTING;
