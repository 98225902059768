import { createContext, useState } from "react";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const [theData, setTheData] = useState({});

    return (
    <DataContext.Provider value={{ theData, setTheData }}>
        {children}
    </DataContext.Provider>
    );
};

export default DataContext;
