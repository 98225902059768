import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import withTitle from "../../../components/withTitle";
import GET_IIA_PDF from "../../../graphql/queries/IIAs/getIIAPdf";

const ViewPdf = () => {
  const { id } = useParams();

  const { data } = useQuery(GET_IIA_PDF, {
    variables: {
      id: id,
    },
  });

  let pdf = data?.iiaPdf;

  if (pdf) {
    let byteCharacters = atob(pdf);
    let byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    const fileURL = URL.createObjectURL(file);

    return (
      <>
        <object
          style={{ width: "100%", height: "100vh" }}
          type="application/pdf"
          data={fileURL}
        >
          <p>
            Download the PDF by clicking <a href={fileURL}>here</a>.
          </p>
        </object>
      </>
    );
  }
};

export default withTitle(ViewPdf, "Export IIA to PDF");
