import { gql } from "@apollo/client";

const GET_COURSES_LISTING = gql`
  query courses(
      $page: Int,
      $course_code: String,
      $course_title: String,
      $limit: Int){
        courses(
        page: $page,
        course_code: $course_code,
        course_title: $course_title,
        limit: $limit)
      {
        courses{
            code
            ects
            id
            institution
            term
            title
        }
        pagination{
            currentPage
            limitPerPage
            totalCount
        }
        
      }
    }`
;

export default GET_COURSES_LISTING;
