import { gql } from "@apollo/client";

const GET_NOMINATED_STUDENTS = gql`
    query nominatedStudents(
        $homeErasmusCode: String!, $hostErasmusCode: String!, $academicYear: String!)
    {nominatedStudents(homeErasmusCode:$homeErasmusCode, hostErasmusCode:$hostErasmusCode,  academicYear:$academicYear)}
    `
;

export default GET_NOMINATED_STUDENTS;
