import { gql } from "@apollo/client";

const CHANGE_PASSWORD_REGISTERED_INSTITUTION = gql`
  mutation changePasswordRegisteredInstitution(
    $email: String
    $password: String
    $token: String
  ) {
    changePasswordRegisteredInstitution(
      email: $email
      password: $password
      token: $token
    ) {
      id
    }
  }
`;

export default CHANGE_PASSWORD_REGISTERED_INSTITUTION;
