import IconButton from '../../../../../components/IconButton';
import { Table, Row, Col, Container } from 'react-bootstrap';
import { StatusMapping } from '../../../../../data/statusMapping';
import { StatusActionPair } from '../../../../../data/statusActionPairs';
import { Link } from 'react-router-dom';

const ShortTermInfoTable = ({ typeOfStudent, data, size }) => {
    
    const getNidFromTitle = (str) =>{
        return str.substring(str.lastIndexOf("-")+1,str.length);
    } 

    return (
        <>
            {(size !== "md" && size !== "lg" && size !== "xl" && size !== "xxl") ?
                <div className={`${(StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.shortTermMobility?.field_la_status] ?
                    "action-" + StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.shortTermMobility?.field_la_status] : "")
                    }`}>
                    <Container>
                        <Row style={{ paddingLeft: 10 }}>
                            <Col>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>ID:</Col><Col xs={6}>{getNidFromTitle(data?.shortTermMobility?.title)}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>STATUS:</Col><Col xs={6}>{StatusMapping[data?.shortTermMobility?.field_la_status]}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>ACADEMIC YEAR:</Col><Col xs={6}>{data?.shortTermMobility?.field_academic_year}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>PLANNED PERIOD:</Col><Col xs={6}>{new Date(data?.shortTermMobility?.field_planned_period_from).toLocaleDateString() + " - " + new Date(data?.shortTermMobility?.field_planned_period_to).toLocaleDateString()}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>CREATED:</Col><Col xs={6}>{new Date(data?.shortTermMobility?.created).toLocaleDateString() + " - " + new Date(data?.shortTermMobility?.created).toLocaleTimeString()}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 10 }}><Col className='border-hidden label-style text-end'>
                                    <Link to={`/short-term/${typeOfStudent}/export/${data?.shortTermMobility?.shortTermID}`} target="_blank">
                                        <IconButton
                                            variant="download"
                                            title="Download All files"
                                        />
                                    </Link>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                :
                <div className={`${(StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.shortTermMobility?.field_la_status] ?
                    "action-" + StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.shortTermMobility?.field_la_status] : "")
                    }`}>
                    <Table responsive>
                        <thead className='thead-font'>
                            <tr>
                                <th style={{ paddingTop: 15, paddingBottom: 2, paddingLeft: 24 }} className='border-hidden label-style'>ID</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>STATUS</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>ACADEMIC YEAR</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>PLANNED PERIOD</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>CREATED</th>
                                <th className='download-wrap border-hidden'></th>
                            </tr>
                        </thead>
                        <tbody className='tbody-font'>
                            <tr>
                                <td style={{ paddingTop: 0, paddingBottom: 20, paddingLeft: 24 }} className='border-hidden body'>{getNidFromTitle(data?.shortTermMobility?.title)}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{StatusMapping[data?.shortTermMobility?.field_la_status]}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{data?.shortTermMobility?.field_academic_year}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{new Date(data?.shortTermMobility?.field_planned_period_from).toLocaleDateString() + " - " + new Date(data?.shortTermMobility?.field_planned_period_to).toLocaleDateString()}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{new Date(data?.shortTermMobility?.created).toLocaleDateString() + " - " + new Date(data?.shortTermMobility?.created).toLocaleTimeString()}</td>
                                <td style={{ paddingTop: 0, marginTop: -5 }} className="download-wrap border-hidden">
                                    <Link to={`/short-term/${typeOfStudent}/export/${data?.shortTermMobility?.shortTermID}`} target="_blank">
                                        <IconButton
                                            variant="download"
                                            title="Export to PDF"
                                            onClick={(e) => console.log("on click")}
                                        />
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

export default ShortTermInfoTable;