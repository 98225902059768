import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

import Footer from "./components/Footer";
import BasicHeader from "../../components/BasicHeader";
import Main from "./components/Main";
import withTitle from "../../components/withTitle";

import { ReactComponent as InstitutionIcon } from "../../assets/institution-in-circle.svg";

import useAuth from "../../hooks/useAuth";

const Register = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.accessToken) {
      navigate("/", { replace: true });
    }
  });

  return (
    <main className="position-relative d-flex flex-column justify-content-between register">
      <div className="d-flex flex-column position-relative">
        <BasicHeader />
        <InstitutionIcon className="position-absolute start-50 translate-middle forgot-password-mail-icon d-none d-lg-block" />
        <Main />
      </div>
      <Footer />
    </main>
  );
};

export default withTitle(Register, "Register for an account");
