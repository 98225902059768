export const StatusActionPair = {
  "ola-typeOfStudent-1-preliminary_unsigned": "wait",
  "ola-typeOfStudent-1-preliminary_signed-by-student": "act",
  "ola-typeOfStudent-1-preliminary_signed-by-student-sending": "wait",
  "ola-typeOfStudent-1-preliminary_signed-by-student-sending-receiving": "wait",
  "ola-typeOfStudent-1-final_unsigned": "wait",
  "ola-typeOfStudent-1-final_signed-by-student": "act",
  "ola-typeOfStudent-1-final_signed-by-student-sending": "wait",
  "ola-typeOfStudent-1-final_signed-by-student-sending-receiving": "complete",
  "ola-typeOfStudent-0-preliminary_unsigned": "wait",
  "ola-typeOfStudent-0-preliminary_signed-by-student": "wait",
  "ola-typeOfStudent-0-preliminary_signed-by-student-sending": "act",
  "ola-typeOfStudent-0-preliminary_signed-by-student-sending-receiving": "wait",
  "ola-typeOfStudent-0-final_unsigned": "wait",
  "ola-typeOfStudent-0-final_signed-by-student": "wait",
  "ola-typeOfStudent-0-final_signed-by-student-sending": "act",
  "ola-typeOfStudent-0-final_signed-by-student-sending-receiving": "complete",
  "requests-0": "requests-pending",
  "requests-1": "requests-accepted",
  "requests--1": "requests-declined",
  "nominations-incoming-submitted": "blue",
  "nominations-outgoing-submitted": "act",
  "nominations-incoming-rejected_by_receiving": "requests-declined",
  "nominations-outgoing-rejected_by_receiving": "requests-declined",
  "nominations-incoming-rejected_by_sending": "requests-declined",
  "nominations-outgoing-rejected_by_sending": "requests-declined",
  "nominations-incoming-rejected_by_student": "requests-declined",
  "nominations-outgoing-rejected_by_student": "requests-declined",
  "nominations-incoming-waiting_list": "waiting-list",
  "nominations-outgoing-waiting_list": "waiting-list",
  "nominations-incoming-accepted_by_receiving": "requests-accepted",
  "nominations-outgoing-accepted_by_receiving": "requests-accepted",
  "nominations-incoming-accepted_by_student": "act",
  "nominations-outgoing-accepted_by_student": "blue",
  "nominations-incoming-accepted_by_sending": "blue",
  "nominations-outgoing-accepted_by_sending": "blue",
  "nominations-incoming-cancelled": "cancelled",
  "nominations-outgoing-cancelled": "cancelled",
  "iia-draft":"waiting-list",
  "iia-submitted-p1": "blue",
  "iia-approved-p1": "blue",
  "iia-submitted-p2": "act",
  "iia-approved-p2":
    "act",
  "iia-approved-by-all": "complete", 
   "iia-deleted": "cancelled",
   "iia-terminated": "cancelled",
   "iia-terminated-submitted-p1": "cancelled",
  "iia-terminated-submitted-p2": "cancelled",
  "iia-terminated-approved-p1": "cancelled",
  "iia-terminated-approved-p2": "cancelled",
  "iia-terminated-approved-by-all": "cancelled"
  
};
