import { gql } from "@apollo/client";

const GET_SHORT_TERM_PDF = gql`
  query shortTermLAPdf(
        $ID: String!
     ){
      shortTermLAPdf(
            ID: $ID
        )
      {
        file
      }
    }`
    ;

export default GET_SHORT_TERM_PDF;
