import { gql } from "@apollo/client";

const GET_DASHBOARD_EWP_SETTINGS = gql`
query dashboardConnections
    {dashboardConnections{
        api_iia
        api_ola 
        api_static
        api_ounits
        api_inst
    }
}`
;

export default GET_DASHBOARD_EWP_SETTINGS;
