

const unicodeWord = /^\\pL+$/;
const phoneNumberFormat = /\+[0-9]{1,15}/;
const codeFormat = /\\p{ASCII}/;
const mailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const urlValidator = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/


const validationSpaces = (value) => {
    if (/^\s/.test(value)) {
        return "This field may not begin with a space.";
    }
    if (/\s$/.test(value)) {
        return "This field may not end with a space.";
    }
}

export const validateRegularNamesOfRequiredFields = (value) => {
    if (/^\s/.test(value) || /\s$/.test(value)) {
        return "This value cannot start or end with whitespace (space, tab or line break)."
    }
    if (!value || value.length === 0) {
        return "This field may not be empty.";
    }
    return "";
}

export const validateRegularNames = (value) => {
    if (!value) { return ""; }

    const unicodeValue = value.replace(/[\s-]/g, "");
    if (!unicodeWord.test(unicodeValue) || /^\s/.test(value) || /\s$/.test(value)) {
        if (!unicodeWord.test(unicodeValue)) {
            return "This field may not contain any symbols or numbers.";
        }
        return validationSpaces(value);
    }
    return "";
}

export const validateErasmusCode = (value, canBeEmpty) => {
    if (canBeEmpty && isEmpty(value)) { return ""; }

    if (/^\s/.test(value) || /\s$/.test(value)) {
        if (value.length === 0) {
            return "This field may not be empty.";
        }
        if (!(/^[A-Z|\s]{1,3}[A-Z-.]{1,7}\d{2,3}\s?$/.test(value))) {
            return "Invalid Erasmus code.";
        }
    }
    return "";
}

export const validateEmailFormat = (value, canBeEmpty) => {
    if (!value) {
        value = "";
        return "";
    }

    if (canBeEmpty && isEmpty(value)) { return ""; }

    if (!mailValidator.test(value) || /^\s/.test(value) || /\s$/.test(value)) {
        if (value.length === 0) {
            return "This field may not be empty.";
        }
        if (!mailValidator.test(value)) {
            return "Wrong mail format.";
        }
        return validationSpaces(value);
    }
    return "";
}

export const validatePhoneFaxNumber = (value, canBeEmpty) => {
    if (value === null) {
        value = "";
    }

    if (canBeEmpty && isEmpty(value)) { return ""; }

    if (!phoneNumberFormat.test(value)) {
        return "Format of the phone number: +phone_number. \n" +
            "The length of phone_number must be between 10 and 15.\n" +
            "ex: +0000000000";
    }

    return "";
}

export const validateUrlFormat = (value, canBeEmpty) => {
    if (value === null) {
        value = "";
    }

    if (canBeEmpty && isEmpty(value)) { return ""; }

    if (!urlValidator.test(value) || /^\s/.test(value) || /\s$/.test(value)) {
        if (!urlValidator.test(value)) {
            return "Wrong URL format.";
        }
        return validationSpaces(value);
    }
    return "";
}

export const validateCodes = (value, canBeEmpty) => {
    if (canBeEmpty && isEmpty(value)) { return ""; }

    if (!codeFormat.test(value) || /^\s/.test(value) || /\s$/.test(value)) {
        if (value.length === 0) {
            return "This field may not be empty.";
        }
        if (!codeFormat.test(value)) {
            return "Wrong code format.";
        }
        return validationSpaces(value);
    }
    return "";
}

/**
 * The value is valid if is an integer and it is between the 'min' and the 'max' values,
 * exclusive.
 * 
 * @param {Boolean} canBeEmpty 
 * @param {Number} min 
 * @param {Number} max 
 */
 export const validateNumbers = (value, canBeEmpty, min, max) => {
    if (canBeEmpty && isEmpty(value)) { return ""; }

    if (value > min && value < max) {
        return "";
    }
    return "The value must be between " + min + " and " + max + ", exclusive.";
}

const isEmpty = (value) => {
    if (value.length === 0) { return true; }
    return false;
}


