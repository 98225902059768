import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

import Header from "./components/Header";
import BasicFooter from "../../components/BasicFooter";
import Main from "./components/Main";
import withTitle from "../../components/withTitle";

import { ReactComponent as KeyIcon } from "../../assets/key-in-circle.svg";

import useAuth from "../../hooks/useAuth";

const ResetPassword = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.accessToken) {
      navigate("/", { replace: true });
    }
  });

  return (
    <main className="position-relative d-flex flex-column justify-content-between register-confirmation">
      <div className="d-flex flex-column position-relative">
        <Header />
        <KeyIcon className="position-absolute start-50 translate-middle key-in-circle-icon d-none d-lg-block" />
        <Main />
      </div>
      <BasicFooter>
        <h4 className="no-more-paper">no more paper; no more time wasted.</h4>
      </BasicFooter>
    </main>
  );
};

export default withTitle(ResetPassword, "Reset your password");
