import { gql } from "@apollo/client";

const UPDATE_IIA = gql`
  mutation updateIIA($iiaData: IiaInput!) {
    updateIIA(iiaData: $iiaData) {
        id
    }
  }
`;

export default UPDATE_IIA;
