import { gql } from "@apollo/client";

const LOGIN_SINGLE_ACCOUNT = gql`
  mutation accountLogin($email: String, $password: String) {
    accountLogin(email: $email, password: $password) {
      id
      Phone
      Token
      CurrentHeiId
      CurrentErasmusCode
    }
  }
`;

export default LOGIN_SINGLE_ACCOUNT;
