import { NetworkStatus, useLazyQuery, useQuery } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import CustomButton from "../../../../components/CustomButton";
import IconButton from "../../../../components/IconButton";
import Select from "../../../../components/Select";
import GET_OUNITS from "../../../../graphql/queries/IIAs/getOUnits";
import GET_CITIES from "../../../../graphql/queries/registration/getCities";
import GET_COUNTRIES from "../../../../graphql/queries/registration/getCountries";
import GET_INSTITUTIONS from "../../../../graphql/queries/registration/getInstitutions";
import CountryCityInput from "../../../Register/components/CountryCityInput";
import { useCreate } from "../hooks/useCreate";

const SecondStep = ({institution, type, institutionCity, institutionCountry, partnerSCHAC, partnerErasmusCode, partnerOUnitID, partnerContacts, cities, institutions, updateMultipleValues}) => {
    const [validated, setValidated] = useState(false);

    const {
        loading: loadingCountries,
        error: errorCountries,
        data: dataCountries,
        refetch: refetchCountries
      } = useQuery(GET_COUNTRIES);
    
      const [
        fetchCities,
        {
          loading: loadingCities,
          error: errorCities,
          data: dataCities,
          called: calledCitiesQuery,
          networkStatus: networkStatusCities,
          refetch: refetchCities,
        },
      ] = useLazyQuery(GET_CITIES, {
        notifyOnNetworkStatusChange: true,
      });
    
      const [
        fetchInstitutions,
        {
          loading: loadingInstitutions,
          error: errorInstitutions,
          data: dataInstitutions,
          called: calledInstitutionsQuery,
          networkStatus: networkStatusInstitutions,
          refetch: refetchInstitutions,
        },
      ] = useLazyQuery(GET_INSTITUTIONS, {
        notifyOnNetworkStatusChange: true,
      });

      const [
        fetchOUnits,
        {
          loading: loadingOUnits,
          error: errorOUnits,
          data: dataOUnits,
          called: calledOUnits,
          refetch: refetchOUnits,
        },
      ] = useLazyQuery(GET_OUNITS, {
        notifyOnNetworkStatusChange: true,
      });

      useEffect(() => {

        dataCities && updateMultipleValues({cities: dataCities});

        dataInstitutions && updateMultipleValues({institutions: dataInstitutions});
        // if (dataInstitutions) {
        //     if (calledOUnits) {
        //         refetchOUnits({ heiID: partnerSCHAC });
        //         updateMultipleValues({partnerOUnitID: ""});
        //       } else {
        //         fetchOUnits({ variables: {heiID: partnerSCHAC } });
        //       }
        // }
        
        
        
      }, [dataCities, dataInstitutions]);

      const handleAddContact = () => {
        const values = [...partnerContacts];
        values.push({ contact_names: [], emails: [], phone_numbers: []});
        updateMultipleValues({partnerContacts: values});
    }
    
    const handleRemoveContact = (index) => {
        let values = partnerContacts.map((item) => ({
            ...item, 
            
          }))
          values.splice(index, 1);
        
          updateMultipleValues({partnerContacts: values});
    }
    
    const handleInputContactNameChange = (index, event) => {
        let values = partnerContacts.map((item) => ({
            ...item, 
          }));
          let validate = false;
          if (event.target.name === "name") {
            values[index].contact_names = [...values[index].contact_names];
            values[index].contact_names.pop();
            values[index].contact_names.push({value: event.target.value, language:"en"});
            } else if (event.target.name === "email") {
                const form = event.currentTarget;
                if (form.checkValidity() === false) {
                    event.target.value ? validate = true : validate = false;
                    event.preventDefault();
                    event.stopPropagation();
                } 
                setValidated(true);
                values[index].emails = [...values[index].emails];
                values[index].emails.pop();
                values[index].emails.push(event.target.value);
    
                // values[index].emails[0] = event.target.value;
            }
            else {
                values[index].phone_numbers = [...values[index].phone_numbers];
                values[index].phone_numbers.pop();
                values[index].phone_numbers.push({e164: event.target.value});
            }
    
            updateMultipleValues({partnerContacts: values, validateSecondEmail: validate});
    }

  return (
    <>
    <Row style={{marginLeft: 25, marginRight: 25, background: "#EAECF3"}}>
                <Col style={{marginBottom: 12}} md={12} lg={12}>
                    
    <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                <div className="header">PARTNER DETAILS</div>
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>Partner Institution Data</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}} >
                            <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                {type === "add" ? <><CountryCityInput
                                    // size={size}
                                    key={"" + loadingCountries + loadingCities + loadingInstitutions}
                                    institutionCountry={institutionCountry}
                                    institutionCity={institutionCity}
                                    updateMultipleValues={updateMultipleValues}
                                    dataCountries={dataCountries}
                                    calledCitiesQuery={calledCitiesQuery}
                                    refetchCities={refetchCities}
                                    fetchCities={fetchCities}
                                    loadingCountries={loadingCountries}
                                    loadingCities={loadingCities}
                                    dataCities={cities}
                                    networkStatusCities={networkStatusCities}
                                    calledInstitutionsQuery={calledInstitutionsQuery}
                                    refetchInstitutions={refetchInstitutions}
                                    fetchInstitutions={fetchInstitutions}
                                />
                                <Row className="mb-3 mx-0">
                                <Form.Group
                                    controlId="formInstitutionalWebsite"
                                    className={"px-0"}
                                >
                                    <Form.Label className="ps-0">Institution *</Form.Label>
                                    <Form.Select
                                    required
                                    value={partnerErasmusCode}
                                    onChange={(e) => { console.log(e);
                                        if (calledOUnits) {
                                            refetchOUnits({ heiID: e.target.options[e.target.options.selectedIndex].getAttribute('data-key') });
                                          } else {
                                            fetchOUnits({ variables: {heiID: e.target.options[e.target.options.selectedIndex].getAttribute('data-key') } });
                                          }
                                
                                    
                                        updateMultipleValues({ institution: e.target.value, 
                                            partnerSCHAC: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'), 
                                            partnerErasmusCode: e.target.value, partnerOUnitID: "", studentStudies: [], staffTeachers: [], staffTrainings : [], studentTraineeships: [] })}
                                    }
                                    disabled={
                                        institutionCountry === "" || institutionCity === "" ||
                                        loadingCities === true ||
                                        networkStatusCities === NetworkStatus.loading ||
                                        networkStatusCities === NetworkStatus.refetch ||
                                        dataCities?.cities?.length === 0
                                      }
                                    >
                                    <option value="" disabled>
                                        {loadingInstitutions ||
                                        networkStatusInstitutions === NetworkStatus.loading ||
                                        networkStatusInstitutions === NetworkStatus.refetch
                                        ? "Loading..."
                                        : "Choose institution..."}
                                    </option>
                                    {!loadingInstitutions &&
                                        institutions?.institutions
                                        ?.slice()
                                        .sort((institution1, institution2) =>
                                            institution1.ID > institution2.ID ? 1 : -1
                                        )
                                        .map((institution) => (
                                            <option
                                            key={institution.HeiID}
                                            value={institution.ErasmusCode}
                                            data-key={institution.HeiID}
                                            >
                                            {institution.ID}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                    Please select your institution.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                <Row >
                                    <Col lg={6}>
                                <Form.Group
                                    controlId="formInstitutionalWebsite"
                                    className={"px-0"}
                                >
                                    <Form.Label className="ps-0">SCHAC Code *</Form.Label>
                                    <Form.Select
                                    required
                                    value={partnerSCHAC}
                                    onChange={(e) =>
                                        // updateMultipleValues({ partnerSCHAC: e.target.value })
                                        { console.log(e);
                                            if (calledOUnits) {
                                                refetchOUnits({ heiID: e.target.value });
                                              } else {
                                                fetchOUnits({ variables: {heiID: e.target.value } });
                                              }
                                        updateMultipleValues({ 
                                            partnerErasmusCode: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'),
                                            partnerSCHAC: e.target.value, partnerOUnitID: "", studentStudies: [], staffTeachers: [], staffTrainings : [], studentTraineeships: [] })
                                        }
                                    }
                                    disabled={
                                        institutionCountry === "" || institutionCity === "" ||
                                        loadingCities === true ||
                                        networkStatusCities === NetworkStatus.loading ||
                                        networkStatusCities === NetworkStatus.refetch ||
                                        dataCities?.cities?.length === 0
                                      }
                                    >
                                    <option value="" disabled>
                                        {loadingInstitutions ||
                                        networkStatusInstitutions === NetworkStatus.loading ||
                                        networkStatusInstitutions === NetworkStatus.refetch
                                        ? "Loading..."
                                        : "Choose SCHAC Code..."}
                                    </option>
                                    {!loadingInstitutions &&
                                        institutions?.institutions
                                        ?.slice()
                                        .sort((institution1, institution2) =>
                                            institution1.ID > institution2.ID ? 1 : -1
                                        )
                                        .map((institution) => (
                                            <option
                                            key={institution.ID}
                                            value={institution.HeiID}
                                            data-key={institution.ErasmusCode}
                                            >
                                            {institution.HeiID}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                    Please select your SCHAC Code.
                                    </Form.Control.Feedback>
                                </Form.Group></Col>
                                <Col lg={6}>
                                <Form.Group
                                    controlId="formInstitutionalWebsite"
                                    className={"px-0"}
                                >
                                    <Form.Label className="ps-0">Erasmus Code *</Form.Label>
                                    <Form.Select
                                    required
                                    value={partnerErasmusCode}
                                    onChange={(e) =>
                                        { console.log(e);
                                            if (calledOUnits) {
                                                refetchOUnits({ heiID: e.target.options[e.target.options.selectedIndex].getAttribute('data-key') });
                                              } else {
                                                fetchOUnits({ variables: {heiID: e.target.options[e.target.options.selectedIndex].getAttribute('data-key') } });
                                              }
                                        updateMultipleValues({ 
                                            partnerErasmusCode: e.target.value, partnerSCHAC: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'),
                                             partnerOUnitID: "", studentStudies: [], staffTeachers: [], staffTrainings : [], studentTraineeships: [] })
                                        }
                                    }
                                    disabled={
                                        institutionCountry === "" || institutionCity === "" ||
                                        loadingCities === true ||
                                        networkStatusCities === NetworkStatus.loading ||
                                        networkStatusCities === NetworkStatus.refetch ||
                                        dataCities?.cities?.length === 0
                                      }
                                    >
                                    <option value="" disabled>
                                        {loadingInstitutions ||
                                        networkStatusInstitutions === NetworkStatus.loading ||
                                        networkStatusInstitutions === NetworkStatus.refetch
                                        ? "Loading..."
                                        : "Choose Erasmus Code..."}
                                    </option>
                                    {!loadingInstitutions &&
                                        institutions?.institutions
                                        ?.slice()
                                        .sort((institution1, institution2) =>
                                            institution1.ID > institution2.ID ? 1 : -1
                                        )
                                        .map((institution) => (
                                            <option
                                            key={institution.ID}
                                            value={institution.ErasmusCode}
                                            data-key={institution.HeiID}
                                            >
                                            {institution.ErasmusCode}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                    Please select your Erasmus Code.
                                    </Form.Control.Feedback>
                                </Form.Group></Col>
                                </Row>
                                <Row className="mt-3 mx-0">
                                <Form.Group
                                    controlId="formInstitutionalWebsite"
                                    className={"px-0"}
                                >
                                    <Form.Label className="ps-0">Organizational Unit</Form.Label>
                                    <Form.Select
                                    required
                                    value={partnerOUnitID}
                                    onChange={(e) => { 
                                        let temp = [];
                                        temp.push({value: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'), language: "en"});

                                        updateMultipleValues({ partnerOUnitID: e.target.value, partnerOUnitName: temp })}
                                    }
                                    disabled={
                                        institutionCountry === "" || institutionCity === "" || partnerErasmusCode === "" || partnerSCHAC === "" || 
                                        loadingCities === true ||
                                        networkStatusCities === NetworkStatus.loading ||
                                        networkStatusCities === NetworkStatus.refetch ||
                                        dataCities?.cities?.length === 0
                                      }
                                    >
                                    <option value="" disabled>
                                        {loadingInstitutions ||
                                        networkStatusInstitutions === NetworkStatus.loading ||
                                        networkStatusInstitutions === NetworkStatus.refetch
                                        ? "Loading..."
                                        : "Choose OUnit..."}
                                    </option>
                                    {!loadingOUnits &&
                                        dataOUnits?.ounits
                                        ?.slice()
                                        .map((OUnit) => (
                                            <option
                                            key={OUnit.ounit_id}
                                            value={OUnit.ounit_id}
                                            data-key={OUnit?.ounit_name?.name}
                                            >
                                            {OUnit?.ounit_name?.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                    Please select your OUnit.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </Row></> : 
                                <Form
                                    onSubmit={(e) => console.log("handle submit")}
                                    noValidate
                                >
                                    <Form.Group className="col-6" controlId="formBasicName">
                                    <Form.Label>SCHAC Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        // placeholder="Enter name.."
                                        required
                                        name="name"
                                        value={partnerSCHAC}
                                        disabled
                                    />
                                    </Form.Group>
                                </Form>
                                }
                                </div>

                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>Contact Persons</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}} >
                            {partnerContacts?.length > 0 && partnerContacts?.map((elem, index) => <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        <Col lg={11}>
                                        <div style={{marginBottom: 30, marginTop: 20}} className='label-style'>
                                        <Col lg={12} style={{display: "flex"}}>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Name *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter name.."
                                                onChange={(event) => handleInputContactNameChange(index, event)}
                                                required
                                                name="name"
                                                value={elem?.contact_names[0]?.value}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate validated={validated}
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email.."
                                                onChange={(event) => handleInputContactNameChange(index, event)}
                                                required
                                                name="email"
                                                value={elem?.emails[0]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email address.
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Phone.."
                                                onChange={(event) => handleInputContactNameChange(index, event)}
                                                required
                                                name="phone"
                                                value={elem?.phone_numbers[0]?.e164}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        </Col>
                                        </div>
                                        </Col>
                                        <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                            <IconButton
                                                variant="delete"
                                            
                                                title="Delete Contact"
                                                onClick={() => handleRemoveContact(index)}
                                            />
                                        </Col>
                                </Row>)}
                                <Row style={{marginLeft: 35, marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={"add contact person"} handleClick={handleAddContact} icon={faPlus} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {/* <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>Websites</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}} >
                            <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        
                                        <div style={{marginBottom: 30, marginTop: 20}} className='label-style'>
                                        <Col lg={12} style={{display: "flex"}}>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>General</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                // onChange={(event) => handleInputOtherDocumentChange(index, event)}
                                                required
                                                name="url"
                                                // value={element.url}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Faculty / Faculties</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                // onChange={(event) => handleInputOtherDocumentChange(index, event)}
                                                required
                                                name="url"
                                                // value={element.url}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Course Catalogue</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://www.example.com"
                                                // onChange={(event) => handleInputOtherDocumentChange(index, event)}
                                                required
                                                name="url"
                                                // value={element.url}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        </Col>
                                        </div>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> */}
                </Col>
            </Row>
        </Col>
    </Row>
    </>
  );
};

export default SecondStep;