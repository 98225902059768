import { gql } from "@apollo/client";

const GET_INSTITUTION_REGISTRATIONS = gql`
  query institutionRegistrationFilter(
    $valid: Int
    $contactEmail: String
    $institutionName: String
    $offset: Int
    $resultsNum: Int!
    $sortBy: String!
  ) {
    institutionRegistrationFilter(
      valid: $valid
      contactEmail: $contactEmail
      institutionName: $institutionName
      offset: $offset
      resultsNum: $resultsNum
      sortBy: $sortBy
    ) {
      institutions {
        id
        Institution {
          NameInLatinCharacterSet
        }
        Email
        Website
        Phone
        TimeStamp
        Valid
      }
      count
    }
  }
`;

export default GET_INSTITUTION_REGISTRATIONS;
