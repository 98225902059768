import { useEffect } from "react";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";

import { ToastContainer, toast } from "react-toastify";

import GET_INSTITUTION_REGISTRATIONS from "../../graphql/queries/requests/getInstitutionRegistrations";
import CHANGE_VALIDATION_REGISTERED_INSTITUTION from "../../graphql/mutations/requests/changeValidationRegisteredInstitution";
import REMOVE_REGISTERED_INSTITUTION from "../../graphql/mutations/requests/removeRegisteredInstitution";

import IconButton from "../../components/IconButton/";
import CustomTable from "../../components/Table";
import Filters from "../../components/Filters";

import withFooter from "../../components/WithFooter";
import withNavBar from "../../components/WithNavBar";
import withTitle from "../../components/withTitle";

import { StatusMapping } from "../../data/statusMapping";

import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import TableSkeleton from "../../components/Table/components/TableSkeleton";
import { usePendingRequests } from "./hooks/usePendingRequests";

const toastConfiguration = {
  position: "bottom-left",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const PendingRequests = () => {
  const {
    state,
    setSelectedFiltersList,
    setPage,
    setExpandFilters, setSortBy,
    updateMultipleValues,
  } = usePendingRequests();
  const { page, expandFilters, selectedFiltersList, sortBy } = state;
  const { institutionName, contactEmail, status } = selectedFiltersList;

  const {
    data: dataQuery,
    loading: loadingQuery,
    error: errorQuery,
    refetch: refetchQuery,
    networkStatus: networkStatusQuery,
  } = useQuery(GET_INSTITUTION_REGISTRATIONS, {
    variables: {
      resultsNum: 10,
      institutionName: institutionName ? institutionName : undefined,
      contactEmail: contactEmail ? contactEmail : undefined,
      valid: isNaN(parseInt(status)) ? undefined : parseInt(status),
      offset: page * 10,
      sortBy: sortBy
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const [
    changeValidation,
    { loading: loadingChangeValidation, error: errorChangeValidation },
  ] = useMutation(CHANGE_VALIDATION_REGISTERED_INSTITUTION);

  const [
    removeRegisteredInstitution,
    {
      loading: loadingRemoveRegisteredInstitution,
      error: errorRemoveRegisteredInstitution,
    },
  ] = useMutation(REMOVE_REGISTERED_INSTITUTION);

  const handleButtonClick = (
    newValidValue,
    toastMessage,
    pendingRequest,
    actionType,
    toastLoadingMessage
  ) => {
    return async (event) => {
      const toastId = toast.loading(toastLoadingMessage, toastConfiguration);
      try {
        await changeValidation({
          variables: {
            id: pendingRequest?.id,
            valid: newValidValue,
            email: pendingRequest?.Email,
          },
        });
        toast.update(toastId, {
          ...toastConfiguration,
          render: `${toastMessage}${
            pendingRequest?.Institution?.NameInLatinCharacterSet
              ? " for institution " +
                pendingRequest?.Institution?.NameInLatinCharacterSet
              : ""
          }`,
          type: "success",
          isLoading: false,
        });

        refetchQuery();
      } catch (err) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: `
          Could not ${actionType} the request${
            pendingRequest?.Institution?.NameInLatinCharacterSet
              ? " for institution " +
                pendingRequest?.Institution?.NameInLatinCharacterSet
              : ""
          }`,
          type: "error",
          isLoading: false,
        });
      }
    };
  };

  let loading =
    loadingQuery ||
    networkStatusQuery === NetworkStatus.loading ||
    networkStatusQuery === NetworkStatus.refetch ||
    loadingChangeValidation ||
    loadingRemoveRegisteredInstitution;

  return (
    <main className="h-auto text-center mt-4">
      {loading ? (
        <TableSkeleton
          columnsPerFieldMap={{
            institution: "col-3",
            email: "col-2",
            website: "col-2",
            phone: "col-2",
            registrationTime: "col-2",
            status: "col-1",
          }}
        />
      ) : (
        <>
          <CustomTable
            data={dataQuery.institutionRegistrationFilter.institutions.map(
              (pendingRequest, index) => {
                const result = {
                  id: pendingRequest?.id,
                  institution:
                    pendingRequest?.Institution?.NameInLatinCharacterSet || "-",
                  email: pendingRequest?.Email || "-",
                  website: pendingRequest?.Website || "-",
                  phone: pendingRequest?.Phone || "-",
                };

                const date = new Date(Number(pendingRequest?.TimeStamp));

                if (date) {
                  result.registrationTime = `${date.getDate()} ${date.toLocaleString(
                    "default",
                    { month: "short" }
                  )} ${date.getFullYear()}`;
                } else {
                  result.registrationTime = "-";
                }

                result.status = pendingRequest?.Valid;

                return result;
              }
            )}
            page={page}
            setPage={setPage}
            type={`requests-`}
            expandFilters={expandFilters}
            setExpandFilters={setExpandFilters}
            filters={{
              ...selectedFiltersList,
              status: StatusMapping?.[status] || "",
            }}
            setFilters={(filter, value) => {
              setSelectedFiltersList({
                ...selectedFiltersList,
                [filter]: "",
              });
              setPage(0);
            }}
            sortBy={sortBy}
            setSortBy={() => setSortBy("")}
            columnsPerFieldMap={{
              institution: "col-2 col-xl-3",
              email: "col-2",
              website: "col-2",
              phone: "col-2",
              registrationTime: "col-3 col-xl-2",
              status: "col-1",
            }}
            rowClassName="pending-requests-table-row"
            headerClassName="pending-requests-table-header"
            pageLimit={10}
            totalCount={dataQuery.institutionRegistrationFilter.count}
            hasPagination={true}
            hasFilters={true}
          >
            {dataQuery.institutionRegistrationFilter.institutions.map(
              (pendingRequest, index) => (
                <div
                  key={pendingRequest?.id}
                  className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
                >
                  <IconButton
                    variant="accept"
                    disabled={pendingRequest?.Valid === 1}
                    title="Accept request"
                    onClick={handleButtonClick(
                      1,
                      "Successfully accepted the request",
                      pendingRequest,
                      "accept",
                      "Accepting the request..."
                    )}
                  />
                  <IconButton
                    variant="reject"
                    disabled={pendingRequest?.Valid === -1}
                    title="Reject request"
                    onClick={handleButtonClick(
                      -1,
                      "Successfully rejected the request",
                      pendingRequest,
                      "reject",
                      "Rejecting the request..."
                    )}
                  />
                  <IconButton
                    variant="delete"
                    disabled={
                      pendingRequest?.Valid === 1 || pendingRequest?.Valid === 0
                    }
                    title="Delete request"
                    onClick={async (e) => {
                      const toastId = toast.loading(
                        "Deleting request...",
                        toastConfiguration
                      );
                      try {
                        await removeRegisteredInstitution({
                          variables: {
                            id: pendingRequest?.id || "",
                          },
                        });

                        toast.update(toastId, {
                          ...toastConfiguration,
                          render: `Successfully deleted the request${
                            pendingRequest?.Institution?.NameInLatinCharacterSet
                              ? " for institution " +
                                pendingRequest?.Institution
                                  ?.NameInLatinCharacterSet
                              : ""
                          }`,
                          type: "success",
                          isLoading: false,
                        });

                        refetchQuery();
                      } catch (err) {
                        toast.update(toastId, {
                          ...toastConfiguration,
                          render: `Could not delete the request${
                            pendingRequest?.Institution?.NameInLatinCharacterSet
                              ? " for institution " +
                                pendingRequest?.Institution
                                  ?.NameInLatinCharacterSet
                              : ""
                          }`,
                          type: "error",
                          isLoading: false,
                        });
                      }
                    }}
                  />
                </div>
              )
            )}
          </CustomTable>
          <Filters
            expand={expandFilters}
            handleExpand={setExpandFilters}
            selectedFiltersList={selectedFiltersList}
            setSelectedFiltersList={setSelectedFiltersList}
            sortBy={sortBy}
            setSortBy={(e) => setSortBy(e)}
            type={"pending-requests"}
            setPage={setPage}
          />
        </>
      )}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  );
};

export default withFooter(
  withNavBar(
    withTitle(PendingRequests, "Registration Requests"),
    "Registration Requests",
    ""
  )
);
