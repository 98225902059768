import { gql } from "@apollo/client";

const GET_EDIT_IIA = gql`
    query iiaRaw($id: String!){
        iiaRaw(id: $id) {
            changed_time
            last_author
            approved_copy
            type_of_communication
            partner{
                iia_id
                hei_id
                signing_date
                ounit_id
                conditions_hash
                iia_code
                ounit_name{value
                     language}
                signing_contact{
                  emails
                  phone_numbers {
                    e164
                  }
                   
                  contact_names{value
                language}
                }
                contact{
                    emails
                    phone_numbers {
                      e164
                    }
                    
                    contact_names{value
                        language}
                  }
                pdf
              }
              status
              cooperation_conditions{
                staff_teachers{
                              receiving_hei_id
                              sending_hei_id
                              receiving_ounit_name{value
                                language}
                              sending_ounit_name{value
                                language}
                  mobilities_per_year
                  other_info_terms
                  receiving_acad_year
                  receiving_contact {
                    emails
                    contact_names{value
                        language}
                    phone_numbers {
                      e164
                    }
                  }
                  receiving_hei_id
                  receiving_ounit_id
                  language_skill {
                    cefr_level
                    isced_f_code
                    isced_clarification
                    language
                  }
                  sending_contact{
                    emails
                     
                    contact_names{value
                        language}
                    phone_numbers {
                        e164
                      }
                  }
                  sending_hei_id
                  sending_ounit_id
                  subject_area {
                    isced_f_code
                    isced_clarification
                  }
                  total_days_per_year
                    
                  
                }
                staff_trainings{
                    receiving_hei_id
                              sending_hei_id
                              receiving_ounit_name{value
                                language}
                              sending_ounit_name{value
                                language}
                    mobilities_per_year
                    other_info_terms
                    receiving_acad_year
                    receiving_contact {
                      emails
                      contact_names{value}
                      phone_numbers {
                        e164
                      }
                    }
                    receiving_hei_id
                    receiving_ounit_id
                    language_skill {
                      cefr_level
                      isced_f_code
                      isced_clarification
                      language
                    }
                    sending_contact{
                      emails
                       
                      contact_names{value
                        language}
                      phone_numbers {
                        e164
                      }
                    }
                    sending_hei_id
                    sending_ounit_id
                    subject_area {
                      isced_f_code
                      isced_clarification
                    }
                    total_days_per_year
                      
                    }
                    student_studies{
                        receiving_hei_id
                        receiving_ounit_name{value
                            language}
                        sending_ounit_name{value
                            language}
                        sending_hei_id
                        blended
                        mobilities_per_year
                        other_info_terms
                        eqf_level
                        receiving_acad_year
                        
                        total_months_per_year
                        receiving_contact {
                          emails
                          contact_names{value
                            language}
                          phone_numbers {
                            e164
                          }
                        }
                        receiving_hei_id
                        receiving_ounit_id
                        language_skill {
                          cefr_level
                          isced_f_code
                          isced_clarification
                          language
                        }
                        sending_contact{
                          emails
                           
                          contact_names{value
                            language}
                          phone_numbers {
                            e164
                          }
                        }
                        sending_hei_id
                        sending_ounit_id
                        subject_area {
                          isced_f_code
                          isced_clarification
                        }
                          
                        }
                        student_traineeships{
                            receiving_hei_id
                              sending_hei_id
                              receiving_ounit_name{value
                                language}
                              sending_ounit_name{value
                                language}
                            blended
                            mobilities_per_year
                            total_months_per_year
                            other_info_terms
                            eqf_level
                            receiving_acad_year
                            
                            receiving_contact {
                              emails
                              contact_names{value
                                language}
                              phone_numbers {
                                e164
                              }
                            }
                            receiving_hei_id
                            receiving_ounit_id
                            language_skill {
                              cefr_level
                              isced_f_code
                              isced_clarification
                              language
                            }
                            sending_contact{
                              emails
                     
                              contact_names{value
                                language}
                              phone_numbers {
                                e164
                              }
                            }
                            sending_hei_id
                            sending_ounit_id
                            subject_area {
                              isced_f_code
                              isced_clarification
                            }
                              
                            }
              }
        }
    }`
;

export default GET_EDIT_IIA;
