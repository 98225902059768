import { gql } from "@apollo/client";

const ADD_ACCOUNT = gql`
  mutation addAccount(
    $name: String!
    $email: String
    $functionId: ID!
    $phone: String!
    $defaultContact: String!
  ) {
    addAccount(
      Name: $name
      Email: $email
      FunctionID: $functionId
      Phone: $phone
      DefaultContact: $defaultContact
    ) {
      name: Name
    }
  }
`;

export default ADD_ACCOUNT;
