const permissions = [
  { value: "editingGeneralInfo", label: "Editing general info" },
  { value: "creatingAccounts", label: "Creating accounts" },
  { value: "viewingStudents", label: "Viewing students" },
  { value: "managingStudents", label: "Managing students" },
  { value: "uploadingStudents", label: "Uploading students" },
  { value: "managingIIAs", label: "Managing IIAs" },
  { value: "managingOUnits", label: "Managing Organizational Units" },
  { value: "creatingIIA", label: "Creating IIA" },
  { value: "defaultIIAData", label: "Default IIA Data" },
  { value: "editingStepByStep", label: "Editing step by step" },
  { value: "covidRestrictions", label: "Covid-19 Settings" },
  { value: "importIIA", label: "Import IIA" },
  { value: "ewpSettings", label: "EWP Settings" },
  { value: "erasmus-app", label: "Erasmus+ App" },
  { value: "nominations", label: "Applications for Erasmus+" },
];

export default permissions;
