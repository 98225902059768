import pendingRequestsReducer from "../reducers/pendingRequestsReducer";
import { useReducer, useCallback } from "react";
import {
  SET_SELECTED_FILTERS_LIST_ACTION,
  SET_PAGE_ACTION,
  SET_EXPAND_FILTERS_ACTION,
  UPDATE_MULTIPLE_VALUES_ACTION, SET_SORT_BY_ACTION
} from "../reducers/pendingRequestsReducer";

// fallback state
export const defaultState = {
  selectedFiltersList: {
    institutionName: "",
    contactEmail: "",
    status: "",
  },
  page: 0,
  sortBy: "changed_descending",
  expandFilters: false,
};

/**
 * Used to handle filters for Pending requests
 *
 * @param {Object} initialState the initial state of the filters, fallbacks to a default state with empty string and lists if no initial state is provided
 * @returns Filters state as well as handlers for changing the state
 */
export const usePendingRequests = (initialState = defaultState) => {
  const [state, dispatch] = useReducer(pendingRequestsReducer, initialState);

  const setSelectedFiltersList = useCallback((data) => {
    dispatch({
      type: SET_SELECTED_FILTERS_LIST_ACTION,
      payload: { selectedFiltersList: data },
    });
  }, []);

  const setExpandFilters = useCallback((value) => {
    dispatch({
      type: SET_EXPAND_FILTERS_ACTION,
      payload: { expandFilters: value },
    });
  }, []);

  const setPage = useCallback((value) => {
    dispatch({ type: SET_PAGE_ACTION, payload: { page: value } });
  }, []);

  const setSortBy = useCallback((data) => {
    dispatch({ type: SET_SORT_BY_ACTION, payload: { sortBy: data } });
}, []);

  const updateMultipleValues = useCallback((data) => {
    dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data });
  }, []);

  return {
    state,
    setSelectedFiltersList,
    setExpandFilters,
    setPage,
    updateMultipleValues, setSortBy
  };
};
