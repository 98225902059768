import { useQuery } from '@apollo/client';
import IconButton from '../../../components/IconButton';
import GET_NOMINATION_ZIP from '../../../graphql/queries/nomination/getNominationZip';

const ExportZip = ({id, onMouseEnter, onMouseLeave, studentName, title}) => {
    
    const { data } = useQuery(
        GET_NOMINATION_ZIP,
        {
          variables: {
            uuid: id
          },
        }
    );

    let zip = data?.studentApplicationZip;

    if (zip) {
        let byteCharacters = atob(zip);
        let byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);
        let file = new Blob([byteArray], { type: "application/zip" });
        const fileURL = URL.createObjectURL(file);
        let zipName = studentName.replace(/\s+/g,"_");
    

    

        return (
            <>
                <a href={fileURL} download={zipName+".zip"}>
                    <IconButton
                        variant={"download"}
                        disabled={false}
                        onMouseEnter={onMouseEnter} 
                        onMouseLeave={onMouseLeave} 
                        title={title}
                    />
                </a>
            </>
        );
    }
    else {
        return (
            <>
                
                    <IconButton
                        variant={"download"}
                        disabled={true}
                        onMouseEnter={onMouseEnter} 
                        onMouseLeave={onMouseLeave} 
                        title={title}
                    />
            </>
        );
    }
};
    
export default ExportZip;