import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faCheck,
  faXmark,
  faCircleMinus
} from "@fortawesome/free-solid-svg-icons";
import COLORS from '../../../../../data/colors';
import { Table, Row, Col, Container } from 'react-bootstrap';


const CoursesTable = ({ type, data, preliminaryTableEcts, size }) => {

  const shouldRenderAutoRec = (type === "preliminary-b" || type === "preliminary-c" || type === "final-b2" || type === "final-c2");

  function generateReason(reason_int, reason_txt) {
    let reason = "";
    const olaReasons = {
      1: "Previously selected educational component is not available at the Receiving Institution",
      2: "Component is in a different language than previously specified in the course catalogue",
      3: "Timetable conflict",
      4: "Other (please specify)",
      5: "Substituting a deleted component",
      6: "Extending the mobility period",
      7: "Other (please specify)",
    }

    if (reason_int > 0) {
      reason = olaReasons[reason_int];
    }
    if (reason_int === 4 || reason_int === 7) {
      reason = `Other (${reason_txt})`;
    }
    return reason;
  }

  const calculateECTStotal = (ects) => {
    let totalECTS = 0;
    if (type.includes("preliminary")) {

      data?.map((c) => {
        totalECTS += Number(c?.ects);
      });
      return totalECTS;
    }
    else {
      preliminaryTableEcts.map((c) => {
        totalECTS += Number(c?.ects);
      });
      data?.map((c) => {
        if (c?.addedOrDeleted === "1") {
          totalECTS += Number(c?.ects);
        }
        else {
          totalECTS -= Number(c?.ects);

        }
      });
      return totalECTS;
    }
  }

  if (data?.length === 0) {
    return (
      <h5 className="text-center p-4">
        No courses to show
      </h5>
    )
  }

  return (
    <>
      {(size !== "md" && size !== "lg" && size !== "xl" && size !== "xxl") ?
        <>
          <div className='text-end' style={size === "xs" ? { transform: "translateY(-260%)" } : { transform: "translateY(-130%)" }}>Total ECTS credits: {calculateECTStotal()}</div>
          <Container>
            {data?.map((course, index) => (

              <Row style={{ paddingLeft: 10 }}>
                {type.includes("final") &&
                  <Col xs={1} style={{ paddingTop: 15 }}>
                    <FontAwesomeIcon
                      icon={course?.addedOrDeleted === "1" ? faCirclePlus : faCircleMinus}
                      size="lg"
                      color={course?.addedOrDeleted === "1" ? COLORS.greenAccept : COLORS.redCross}
                    />
                  </Col>

                }
                <Col>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>CODE:</Col><Col>{course?.code}</Col></Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>SUBJECT:</Col><Col>{course?.title}</Col></Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>ECTS:</Col><Col>{course?.ects}</Col></Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={2}>SEMESTER:</Col><Col>{course?.semester}</Col></Row>
                  {type.includes("final") &&
                    <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>REASON:</Col><Col>{generateReason(course?.reason, "")}</Col></Row>
                  }
                  {(course?.automaticRecognition === true || course?.automaticRecognition === false) ?
                    <Row style={{ paddingTop: 15, paddingBottom: 2 }}>
                      <Col className='border-hidden label-style' xs={4} sm={3}>AUTOMATIC RECOGNITION:</Col>
                      <Col>
                        <FontAwesomeIcon
                          icon={course?.automaticRecognition ? faCheck : faXmark}
                          size="2x"
                          color={course?.automaticRecognition ? COLORS.greenAccept : COLORS.redCross}
                        />
                      </Col>
                    </Row> : course?.automaticRecognition}
                </Col>
                <Row style={{ paddingTop: 15, paddingBottom: 2 }}>
                  {data?.length - 1 !== index && <hr style={{ border: "1px solid grey" }} />}

                </Row>
              </Row>
            ))}
          </Container>
        </>
        :
        <>
          <div className='text-end' style={{ transform: "translateY(-130%)" }}>Total ECTS credits: {calculateECTStotal()}</div>
          <Table responsive>
            <thead>
              <tr>
                {type.includes("final") && <>
                  <th style={{ paddingBottom: 2 }} className="border-hidden label-style col-1"></th>
                </>}
                <th style={{ paddingTop: 15, paddingBottom: 2, paddingLeft: type.includes("preliminary") ? 24 : 0 }} className={`border-hidden label-style ${type.includes("preliminary-a")?'col-3':'col-1'}`}>CODE</th>
                <th style={{ paddingTop: 15, paddingBottom: 2 }} className={`border-hidden label-style ${type.includes("preliminary-a")?'col-3':'col-2'}`}>SUBJECT</th>
                <th style={{ paddingTop: 15, paddingBottom: 2 }} className={`border-hidden label-style ${type.includes("preliminary-a")?'col-3':'col-1'}`}>ECTS</th>
                <th style={{ paddingTop: 15, paddingBottom: 2 }} className={`border-hidden label-style ${type.includes("preliminary-a")?'col-3':'col-2'}`}>SEMESTER</th>
                {type.includes("final") &&
                  <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style col-2'>REASON</th>}
                {shouldRenderAutoRec && <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style col-2'>AUTOMATIC RECOGNITION</th>}

              </tr>
            </thead>
            <tbody className='tbody-font'>
              {data?.map((course, index) => (
                <>
                  <tr>
                    {type.includes("final") && <>
                      <td style={{ paddingBottom: 2 }} className='border-hidden col-1 text-center'> <FontAwesomeIcon
                        icon={course?.addedOrDeleted === "1" ? faCirclePlus : faCircleMinus}
                        size="lg"
                        color={course?.addedOrDeleted === "1" ? COLORS.greenAccept : COLORS.redCross}
                      /></td>
                    </>}
                    <td style={{ paddingTop: 10, paddingBottom: 20, paddingLeft: type.includes("preliminary") ? 24 : 0 }} className='border-hidden col-1'>{course?.code}</td>
                    <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden col-2'>{course?.title}</td>
                    <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden col-1'>{course?.ects}</td>
                    <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden col-2'>{course?.semester}</td>
                    {type.includes("final") &&
                      <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden col-2'>{generateReason(course?.reason, "")}</td>
                    }
                    {(course?.automaticRecognition === true || course?.automaticRecognition === false) ? <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden justify-content-center d-flex col-8'>
                      <FontAwesomeIcon
                        icon={course?.automaticRecognition ? faCheck : faXmark}
                        size="2x"
                        color={course?.automaticRecognition ? COLORS.greenAccept : COLORS.redCross}
                      />
                    </td> :
                      <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden justify-content-center d-flex col-8'>
                        {course?.automaticRecognition}
                      </td>
                    }
                  </tr>
                  <tr>
                    <td colSpan="6" className='border-hidden'>
                      {data?.length - 1 !== index && <hr style={{ marginLeft: 18, marginRight: 20, border: "1px solid grey" }} />}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </>
      }
    </>
  )
}

export default CoursesTable;