import { gql } from "@apollo/client";

const GET_USEFUL_INFO = gql`
query usefulInfoList
    {usefulInfoList{
        id
        title
        externalID
        organisations
    }

    
}`
;

export default GET_USEFUL_INFO;
