import Tabs from "react-bootstrap/Tabs";
import { useQuery, NetworkStatus } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import withFooter from "../../components/WithFooter";
import withNavBar from "../../components/WithNavBar";
import withTitle from "../../components/withTitle";
import CustomTab from "../../components/CustomTab";
import Accounts from "./components/Accounts";
import Roles from "./components/Roles";
import TableSkeleton from "../../components/Table/components/TableSkeleton";

import GET_ROLES from "../../graphql/queries/account/getRoles";
import GET_ACCOUNTS from "../../graphql/queries/account/getAccounts";

import "./index.scss";

const AccountsAndAccess = () => {
  const {
    loading: loadingAccounts,
    error: errorAccounts,
    data: dataAccounts,
    refetch: refetchAccounts,
    networkStatus: networkStatusAccounts,
  } = useQuery(GET_ACCOUNTS, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingRoles,
    error: errorRoles,
    data: dataRoles,
    refetch: refetchRoles,
    networkStatus: networkStatusRoles,
  } = useQuery(GET_ROLES, { notifyOnNetworkStatusChange: true });

  let loading =
    loadingAccounts ||
    loadingRoles ||
    networkStatusAccounts === NetworkStatus.loading ||
    networkStatusAccounts === NetworkStatus.refetch ||
    networkStatusRoles === NetworkStatus.loading ||
    networkStatusRoles === NetworkStatus.refetch;

  return (
    <main className="h-auto text-center mt-4 px-3">
      {loading ? (
        <TableSkeleton
          columnsPerFieldMap={{
            institution: "col-3",
            email: "col-2",
            website: "col-2",
            phone: "col-2",
            registrationTime: "col-2",
            status: "col-1",
          }}
        />
      ) : (
        <Tabs id="tabs-active" className="d-flex justify-content-start">
          <CustomTab
            eventKey={"first-tab"}
            title="Accounts"
            activetab={+true}
            className="p-3 pb-4"
          >
            <Accounts
              accounts={dataAccounts.accounts}
              roles={dataRoles.roles}
              refetchAccounts={refetchAccounts}
            />
          </CustomTab>
          <CustomTab
            eventKey={"second-tab"}
            title="Roles"
            activetab={+true}
            className="p-3 pb-4"
          >
            <Roles
              accounts={dataAccounts.accounts}
              roles={dataRoles.roles}
              refetchRoles={refetchRoles}
            />
          </CustomTab>
        </Tabs>
      )}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  );
};

export default withFooter(
  withNavBar(
    withTitle(AccountsAndAccess, "Accounts and Access"),
    "Accounts and Access",
    ""
  )
);
