import { useQuery } from '@apollo/client';
import { Col, Row, Table } from 'react-bootstrap';
import GET_DESTINATION_HEI_INFO from '../../../../../graphql/queries/nomination/getDestinationHEIInfo';

const DestinationHEIInfo = ({hostErasmusCode, isMobile}) => {
    

    const { data } = useQuery(
        GET_DESTINATION_HEI_INFO,
        {
          variables: {
            erasmusCode: hostErasmusCode,
          },
        }
    );

    

  return (
    <>
    {!isMobile && (
        <Table>
            <thead className='thead-font'>
                <tr>
                <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>ECTS</th>
                <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY LEVEL</th>
                <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY FIELD</th>
                <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>REQUIRED DOCUMENTS</th>
                <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>OTHER REQUIREMENTS</th>
                </tr>
            </thead>
            <tbody className='tbody-font'>
                <tr>
                <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{data?.incomingApplicationRequirements?.ectsRequired ? data?.incomingApplicationRequirements?.ectsRequired : "-"}</td>
                <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{data?.incomingApplicationRequirements?.eqfLevel ? data?.incomingApplicationRequirements?.eqfLevel : "-"}</td>
                <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{data?.incomingApplicationRequirements?.isced ? data?.incomingApplicationRequirements?.isced : "-"}</td>
                <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{data?.incomingApplicationRequirements?.cvRequired ? "CV" : ""}
                {data?.incomingApplicationRequirements?.motivLetterRequired ? data?.incomingApplicationRequirements?.cvRequired ? ", Motivation Letter" : "Motivation Letter" : ""}</td>
                <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden body'>{data?.incomingApplicationRequirements?.otherRequirementArray.length > 0 ? data?.incomingApplicationRequirements?.otherRequirementArray.map((elem, index) => 
                index !== data?.incomingApplicationRequirements?.otherRequirementArray.length - 1 ? elem?.description + ", " : elem?.description
                ) : "-"}</td>
                </tr>
            </tbody>
        </Table>
    )}
    {isMobile && (
        <Row style={{marginLeft: 0,marginRight: 25}}>
            <Col xs={12} sm={12} style={{marginTop: 17, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>ECTS <br/> <span className='tbody-font body'>{data?.incomingApplicationRequirements?.ectsRequired ? data?.incomingApplicationRequirements?.ectsRequired : "-"}</span></Col>
            <Col xs={12} sm={12} style={{marginTop: 10, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>STUDY LEVEL <br/> <span className='tbody-font body'>{data?.incomingApplicationRequirements?.eqfLevel ? data?.incomingApplicationRequirements?.eqfLevel : "-"}</span></Col>
            <Col xs={12} sm={12} style={{marginTop: 10, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>STUDY FIELD <br/> <span className='tbody-font body'>{data?.incomingApplicationRequirements?.isced ? data?.incomingApplicationRequirements?.isced : "-"}</span></Col>
            <Col xs={12} sm={12} style={{marginTop: 10, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>REQUIRED DOCUMENTS <br/> <span className='tbody-font body'>{data?.incomingApplicationRequirements?.cvRequired ? "CV" : ""}
            {data?.incomingApplicationRequirements?.motivLetterRequired ? data?.incomingApplicationRequirements?.cvRequired ? ", Motivation Letter" : "Motivation Letter" : ""}</span></Col>
            <Col xs={12} sm={12} style={{marginTop: 10, paddingLeft: 0, paddingRight: 0}} className='thead-font label-style'>OTHER REQUIREMENTS <br/> <span className='tbody-font body'>{data?.incomingApplicationRequirements?.otherRequirementArray.length > 0 ? data?.incomingApplicationRequirements?.otherRequirementArray.map((elem, index) => 
                index !== data?.incomingApplicationRequirements?.otherRequirementArray.length - 1 ? elem?.description + ", " : elem?.description
                ) : "-"}</span></Col>
        </Row>
    )}
    </>
  )
};

export default DestinationHEIInfo;