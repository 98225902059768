import { gql } from "@apollo/client";

const ADD_EVENT = gql`
mutation addEvent($eventInput: EventInput!)
    {  addEvent(eventInput: $eventInput)
    
}`
;

export default ADD_EVENT;
